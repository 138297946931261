import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import style from "./style.module.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ProductRegistration from "./components/ProductRegistration";

const { TabPane } = Tabs;

const AdminPMS = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [value, setValue] = useState("course");

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className={style.container_Dashboard}>
      <div className={style.content_Dashboard}>
        <p className={style.dashboard_title}>PMS</p>
        <Tabs
          defaultActiveKey="1"
          onChange={handleSetValue}
          className={style.AdminPMS}
        >
          <TabPane>
            <ProductRegistration />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminPMS;
