import React, { useEffect, useMemo, useState } from "react";

import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { APIService } from "../../services/apiService";
import EBookStore from "./EBookStore";
import ViewEBook from "./ViewEBook";

const CustomEBook = () => {
  const navigate = useNavigate();
  const [indexSelectedItem, setIndexSelectedItem] = useState(0);
  const [isActiveView, setIsActiveView] = useState(false);
  const [cookies] = useCookies(["access_token"]);
  const [listEBooks, setListEBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log("listEBooks", listEBooks);

  useEffect(() => {
    const accessToken = cookies["access_token"];
    setIsLoading(true);
    APIService.get_list_ebook(accessToken)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setListEBooks(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [cookies]);

  const itemSelected = useMemo(
    () => listEBooks[indexSelectedItem],
    [indexSelectedItem, listEBooks]
  );

  const backToEBookNavigation = () => {
    navigate("/e-mybook");
  };

  const backToEBookStore = () => {
    setIsActiveView(false);
  };

  const handleSelectItem = (index) => {
    setIndexSelectedItem(index);
  };

  const actionActiveView = () => {
    setIsActiveView(true);
  };

  return (
    <>
      {!isActiveView ? (
        <EBookStore
          isLoading={isLoading}
          listEBooks={listEBooks}
          indexSelectedItem={indexSelectedItem}
          itemSelected={itemSelected}
          actionButton={backToEBookNavigation}
          handleSelectItem={handleSelectItem}
          actionActiveView={actionActiveView}
        />
      ) : (
        <ViewEBook
          indexSelectedItem={indexSelectedItem}
          itemSelected={itemSelected}
          actionButton={backToEBookStore}
        />
      )}
    </>
  );
};

export default CustomEBook;
