import { createSlice } from "@reduxjs/toolkit";

export const petSlice = createSlice({
  name: "pets",
  initialState: {
    pets: [],
    curentlyPet: null,
  },
  reducers: {
    setPets: (state, { payload }) => {
      state.pets = payload.pets;
      state.curentlyPet = payload.pets[0];
    },
    setCurentlyPet: (state, { payload }) => {
      console.log("payload", payload);
      state.curentlyPet = payload;
    },
  },
});

export const { setPets, setCurentlyPet } = petSlice.actions;
export default petSlice.reducer;
