import React, { useEffect, useState } from "react";
import { Tooltip, Col, Input, Row } from "antd";
import style from "../style.module.css";
import btn_home from "../../../assets/icon_svg/buton_home.png";
import logo_ebook from "../../../assets/icon_svg/logo-ebook.png";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { APIService } from "../../../services/apiService";
import { AiOutlinePlus } from "react-icons/ai";

const MyBookCollection = ({ setOpenEbook, handleSelectEBook, listEBooks }) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const handleOpenEBook = (index) => {
    setOpenEbook(true);
    handleSelectEBook(index);
  };

  // const [listEbooks, setListEbooks] = useState([]);
  const [listBookFilter, setListBookFilter] = useState([]);

  const navigateToDashboard = () => {
    window.location.href = "dashboard";
  };
  const navigateToEBookMaking = () => {
    navigate("/ebook-making/");
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const accessToken = cookies["access_token"];
  //       const data = await APIService.get_ebook(accessToken);
  //       setListEbooks(data.data);
  //       setListBookFilter(data.data);
  //     } catch (error) {}
  //   }
  //   fetchData();
  // }, []);
  //search
  // const onHandleNameSearch = (e) => {
  //   const value = e.target.value;
  //   onHandleSearch(value);
  // };
  // const onHandleSearch = (name) => {
  //   const nameData = listEbooks.filter((item) => {
  //     const internalResult = item.ebook_name.toLowerCase().trim();
  //     const external = name.toLowerCase().trim();
  //     return internalResult.includes(external);
  //   });
  //   setListBookFilter(nameData);
  // };
  //
  // const renderNavBar = () => {
  //   return (
  //     <div
  //       className={style.header_ebook}
  //       style={{ position: "absolute", bottom: "75%" }}>
  //       <img
  //         className={style.logo_ebook_home}
  //         src={logo_ebook}
  //         alt="btn-logo_ebook"
  //         style={{ width: "15%", height: "30%" }}
  //       />
  //       <Input.Search
  //         className={style.input_search}
  //         placeholder="Search Name"
  //         onChange={onHandleNameSearch}
  //       />
  //     </div>
  //   );
  // };
  // console.log("listBookFilter", listBookFilter);
  return (
    <div className="lg:max-w-7xl md:max-w-2xl max-w-xs mx-auto w-full">
      <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-x-14 gap-x-0 gap-y-20 py-20">
        {listEBooks.map((item, index) => (
          <div className="w-full h-[24rem]">
            <img
              className={style.ebook_image}
              src={item.ebook_link}
              alt="ebook"
              onClick={() => handleOpenEBook(index)}
            />
            <div className="w-full text-center relative truncate font-semibold text-xl mt-2">
              {item.ebook_name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyBookCollection;
