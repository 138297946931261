import React, { useEffect, useState } from "react";
import ParentDiv from "./component/dragable";
import ImageCarousel from "./component/stableDiffusion";
import style from "../style.module.css";

const StableDiffusionAndSticker = ({
  onPrev,
  setWritingToStable,
  handleBack,
}) => {
  console.log("handleBack", handleBack);
  const [showComponents, setShowComponents] = useState(false);
  const [nextValue, setNextValue] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [prevToWriting, setPrevToWriting] = useState(null);
  const [callOnPrev, setCallOnPrev] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setShowComponents(nextValue);
  }, [nextValue]);

  useEffect(() => {
    setShowComponents(prevValue);
  }, [prevValue]);

  useEffect(() => {
    if (callOnPrev) {
      onPrev(prevToWriting);
      setCallOnPrev(false);
    }
  }, [callOnPrev, onPrev, prevToWriting]);

  const handleNext = (eventOrValue, selectedImage) => {
    setNextValue(eventOrValue);
    setPrevValue(true);
    setSelectedImage(selectedImage);
  };

  const handlePrev = (eventOrValue) => {
    setPrevValue(eventOrValue);
    setNextValue(false);
  };

  function handlePrevToWriting(eventOrValue) {
    setPrevToWriting(eventOrValue);
    setCallOnPrev(true);
  }

  return (
    <>
      <div className={style.container_stable}>
        {!showComponents ? (
          <ImageCarousel
            onNext={handleNext}
            onPrev={handlePrevToWriting}
            mainIMG={setSelectedImage}
            handleBack={handleBack}
            setWritingToStable={setWritingToStable}
          />
        ) : (
          <ParentDiv onPrev={handlePrev} imageData={{ selectedImage }} />
        )}
      </div>
    </>
  );
};

export default StableDiffusionAndSticker;
