import React, { useState } from "react";
import { Tabs } from "antd";
import style from "./style.module.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import ClassManager from "./components/ClassManager";
import EbookManager from "./components/EbookManager";
import ApprovalRequest from "./components/ApprovalRequest";

const { TabPane } = Tabs;

const CampusApprovalRequest = (props) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [value, setValue] = useState("course");

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className={style.container_Dashboard}>
      <div className={style.content_Dashboard}>
        <p className={style.dashboard_title}>Approval Request to Admin</p>
        <ApprovalRequest is_admin={false} />
      </div>
    </div>
  );
};

export default CampusApprovalRequest;
