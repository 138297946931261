import { motion } from "framer-motion";
import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import bg from "../../assets/background/home-bg.jpg";
import gptLogo from "../../assets/logo/gpt.png";

const OurservicePage = () => {
  const [cookies] = useCookies(["access_token"]);
  const navigate = useNavigate();
  return (
    <div className="w-screen h-[calc(100vh-80px)] relative font-itim">
      <img
        className="w-full h-full object-cover object-top"
        src={bg}
        alt="bg"
      />
      <button
        onClick={() => {
          if (cookies["access_token"]) {
            navigate("/dashboard");
          } else {
            navigate("/login");
          }
        }}
        className="scale-[0.60] 3xl:scale-100 origin-bottom absolute left-1/2 -translate-x-1/2 bottom-[47px] bg-[#FFCFB2] rounded-full h-[88px] w-[530px] text-[48px] text-white font-itim">
        Currently in Beta
      </button>
      <div className="absolute top-0 right-0 w-[875px] mr-[38px] scale-[0.80] 3xl:scale-100 origin-top-right pt-7">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}>
          <img
            className="absolute top-14 right-[58px]"
            src={gptLogo}
            alt="logo"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}>
          <div className="flex flex-col items-end w-fit">
            <h1 className="text-[70px] text-[#166AAE] leading-[72px]">
              Wing Writings
            </h1>

            <h3 className="text-[32px] text-[#F6D7C8]">Powered by AI</h3>
          </div>
        </motion.div>
        <div className="flex gap-7 mt-[3.5rem] w-[81%] ml-auto">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <div>
              <h3 className="text-[#6CB7DF] text-[32px] leading-[38.4px]">
                Brainstorm
              </h3>
              <p className="text-[#000000] opacity-[0.23] text-[22px] leading-[26.4px]">
                with GPT-4, the most advanced AI from OpenAI
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}>
            <div>
              <h3 className="text-[#6CB7DF] text-[32px] leading-[38.4px]">
                Create
              </h3>
              <p className="text-[#000000] opacity-[0.23] text-[22px] leading-[26.4px]">
                your ideas, illuminate with DALL-E 3
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}>
            <div>
              <h3 className="text-[#6CB7DF] text-[32px] leading-[38.4px]">
                Correct
              </h3>
              <p className="text-[#000000] opacity-[0.23] text-[22px] leading-[26.4px]">
                not only your grammar but also context
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}>
            <div>
              <h3 className="text-[#6CB7DF] text-[32px] leading-[38.4px]">
                Improve
              </h3>
              <p className="text-[#000000] opacity-[0.23] text-[22px] leading-[26.4px]">
                your level with more challenging vocabulary
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OurservicePage;
