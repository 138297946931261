import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import course_icon from "../../assets/icon_svg/Frame 238137.png";
import inqury_icon from "../../assets/icon_svg/Frame 238137 (1).png";
import point_icon from "../../assets/icon_svg/Frame 238137 (2).png";
import { RiListSettingsFill, RiLogoutBoxLine } from "react-icons/ri";
import { useCookies } from "react-cookie";
import { Col, Row, Divider } from "antd";
import PopupSetting from "../Popup/PopupSetting";
import { setTypeHistory } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
const customPosition = {
  position: "relative",
  bottom: "33%",
  cursor: "pointer",
};
const UserDashboard = (props) => {
  const dispatch = useDispatch();
  const { className, handle, state, type, classImga } = props;
  let user = useSelector((state) => state.userSlice);
  console.log("user", user);
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [activeRow, setActiveRow] = useState(null);
  const [open, setOpen] = useState(false);

  const onLogOut = () => {
    removeCookie("access_token");
    window.location.href = "our-service";
  };
  const [text, setText] = useState(user.user.username);
  useEffect(() => {
    setText(user.user.username);
  }, [user.user.username]);
  const limit = 8;
  const shortenedText = text.slice(0, limit);

  console.log(user);

  return (
    <>
      <p className="text-[#22668D] font-medium text-xl mt-4">
        {user?.user?.campus_name}
      </p>
      <p className={type ? style.title : <></>}>
        {type ? (
          <>{user.user.username}</>
        ) : (
          <>
            <span className={style.name}> Welcome to {shortenedText}...</span>
          </>
        )}
      </p>
      {/* {type && <p className={style.textEmail}>{user.user.email}</p>} */}
      <div onClick={handle} className={style.box_avatar}>
        <img src={user.user.avatar} alt="alt" className={style.img_user} />
      </div>
      {type && (
        <>
          {user.user.level !== "Administrator" &&
            user.user.level !== "Campus" && (
              <>
                <p className={style.level}> {user.user.level.toUpperCase()}</p>
                <Row
                  className={`${style.row} ${
                    activeRow === "course" ? style.activeRow : ""
                  }`}
                  onClick={() => {
                    dispatch(
                      setTypeHistory({ type_history: "course_history" })
                    );
                    setActiveRow("course");
                  }}
                  justify="center"
                  style={{ width: "100%" }}>
                  <Col span={3} offset={4}>
                    <img src={course_icon} width={25} height={25} alt="icon" />
                  </Col>
                  <Col span={7}>
                    <p className={style.user_dashboard}>Course</p>
                  </Col>
                  <Col span={8} offset={2}>
                    <span
                      className={style.user_dashboard}
                      style={{ color: "#0094FF" }}>
                      {user.user.course_done}
                    </span>
                  </Col>
                </Row>
                {/* <Row
                  className={`${style.row} ${
                    activeRow === "inquiry_history" ? style.activeRow : ""
                  }`}
                  onClick={() => {
                    dispatch(
                      setTypeHistory({ type_history: "inquiry_history" })
                    );
                    setActiveRow("inquiry_history");
                  }}
                  justify="center"
                  style={{ width: "100%" }}>
                  <Col span={3} offset={4}>
                    <img src={inqury_icon} width={25} height={25} alt="icon" />
                  </Col>
                  <Col span={7}>
                    <p className={style.user_dashboard}>Inquiry</p>
                  </Col>
                  <Col span={8} offset={2}>
                    <span
                      className={style.user_dashboard}
                      style={{ color: "#0094FF" }}>
                      {user.user.inquiry_done}
                    </span>
                  </Col>
                </Row> */}

                <Row
                  className={`${style.row} ${
                    activeRow === "point_history" ? style.activeRow : ""
                  }`}
                  onClick={() => {
                    dispatch(setTypeHistory({ type_history: "point_history" }));
                    setActiveRow("point_history");
                  }}
                  justify="center"
                  style={{ width: "100%" }}>
                  <Col span={3} offset={4}>
                    <img src={point_icon} width={25} height={25} alt="icon" />
                  </Col>
                  <Col span={7}>
                    <p className={style.user_dashboard}>Point</p>
                  </Col>
                  <Col span={8} offset={2}>
                    <span
                      className={style.user_dashboard}
                      style={{ color: "#0094FF" }}>
                      {user.point.total_point}
                    </span>
                  </Col>
                </Row>
              </>
            )}

          <Divider className={style.divider} />
          {/* 
          <Row
            onClick={() => setOpen(!open)}
            className={style.btnSL}
            justify="space-evenly"
            style={{ width: "100%" }}
          >
            <Col offset={2} span={8}>
              <RiListSettingsFill width={30} height={30} />
            </Col>
            <Col span={8}>
              <p style={customPosition}>Setting</p>
            </Col>
          </Row> */}

          <Row
            className={style.btnSL}
            onClick={onLogOut}
            justify="space-evenly"
            style={{ width: "100%" }}>
            <Col offset={2} span={8}>
              <RiLogoutBoxLine width={30} height={30} />
            </Col>
            <Col span={8}>
              <p style={customPosition}>LogOut</p>
            </Col>
          </Row>
        </>
      )}
      {open && <PopupSetting user={user} />}
      {/* 
      {state === true && (
        <>
          <div className={style.popup}>
            <p>{user.user.username}</p>
            <p>{user.user.email}</p>
            <p>
              Course <span>('/')</span>
            </p>
            <p>
              Inquiry <span>('/')</span>
            </p>
            <p>
              Point <span>('/')</span>
            </p>
          </div>
        </>
      )} */}
    </>
  );
};

export default UserDashboard;
