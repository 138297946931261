import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import {
  TableDataCampusEnrolment,
  TableDataCategory,
  TableDataInquiry,
  TableDataListCampus,
} from "../../../../components/TableData";
import { Notification } from "../../../../components/Notification/index";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
import PopupCampusManager from "../../../../components/Popup/PopupCampusManager";
import PopupInquiryQuestion from "../../../../components/Popup/PopupInquiryQuestion";
import title from "../../../../assets/background/title.png";
import logo1 from "../../../../assets/background/lion UFO.png";
import logo2 from "../../../../assets/background/bear with plane.png";
import FormPointManager from "../../../../components/Form/FormPointManager";

const CategoryCampus = ({ children }) => {
  const [form] = Form.useForm();
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [typePopup, setTypePopup] = useState("Category");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalIquiry, setIsModalIquiry] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openInquiry, setOpenInquiry] = useState(false);
  const [inquiryRecord, setInquiryRecord] = useState(null);
  const [answerInput, setAnswerInput] = useState("");
  const [formValues, setFormValues] = useState({
    questionTitle: "",
    textArea: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleClickRow = (record) => {
    setInquiryRecord(record);
    setIsModalOpen(true);
  };

  async function fetchData() {
    setIsLoading(true);
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_inquiry_history(accessToken);
      setDataUser(data.data);
    } catch (error) {}
    setIsLoading(false);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalIquiry(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalIquiry(false);
    setIsModalOpen(false);
  };

  const handleSearchInquiry = (searchText) => {
    setIsLoading(true);
    APIService.search_inquiry_management({
      body: { email: searchText.trim() },
      access_token: cookies["access_token"],
    })
      .then((res) => {
        if (!res?.data) {
          Notification("error", "Not found");
          return;
        }
        setDataUser(res.data);
      })
      .catch((err) => {
        Notification("error", "Error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateInquiry = async () => {
    try {
      await form.validateFields();
      APIService.answer_inquiry(
        {
          id: inquiryRecord.ID,
          anwser: form.getFieldValue("answer"),
        },
        cookies["access_token"]
      )
        .then((response) => {
          setAnswerInput("");
          setIsModalOpen(false);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);
  const customOkButtonProps = {
    className: "custom-ok-button",
  };

  useEffect(() => {
    console.log("check data user", dataUsers);
  }, [dataUsers]);

  return (
    <>
      <div className={style.container_listCampus}>
        <Col span={24}>
          <Row gutter={[10, 20]}>
            <Col span={24} className="flex justify-start">
              {/* <p className={style.title_listCampus}>Inquiry History</p> */}
            </Col>
            <Col span={24}>
              <FormPointManager
                placeholder="Search by email"
                setDataUser={(list) => {
                  setDataUser(list);
                }}
                setIsLoading={(value) => {
                  setIsLoading(value);
                }}
                isLoading={isLoading}
                handleSearch={(text) => {
                  handleSearchInquiry(text);
                }}
              />
            </Col>
            <Col span={24}>
              <div className={style.list_tableCampus}>
                <TableDataCategory
                  isLoading={isLoading}
                  dataUsers={dataUsers}
                  handleClickRow={handleClickRow}
                />
              </div>
            </Col>
            {/* <Col span={4}>
                    <Button onClick={() => setOpenPopup(true)} className={style.btn_inquiryManager}>
                      REGISTRATION
                    </Button>
                  </Col> */}
            <Modal
              title="Answer"
              open={isModalOpen}
              onOk={handleCreateInquiry}
              onCancel={handleCancel}
              okText="Answer"
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={customOkButtonProps}>
              <Form form={form}>
                <Form.Item name="question" label={"Question"}>
                  <p> {inquiryRecord?.question}</p>
                </Form.Item>
                <Form.Item
                  label="Answer"
                  name="answer"
                  rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </Row>
        </Col>
        {openPopup && (
          <PopupCampusManager
            setOpenPopup={setOpenPopup}
            typePopup={typePopup}
          />
        )}
        {openInquiry && <PopupInquiryQuestion typePopup={"category"} />}
      </div>
    </>
  );
};

export default CategoryCampus;
