import { Col, Row } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Notification } from "../../../../components/Notification/index";
import { TableDataEbookLMS } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";

const EbookManager = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [type, setType] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  async function fetchData() {
    setIsLoading(true);

    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_ebook_lms_campus(accessToken);
      setDataUser(data.data);
      setFilteredData(data.data);
    } catch (error) {}
    setIsLoading(false);
  }

  const formatGroupByFullName = (data) => {
    return Object.values(
      data?.reduce((acc, current) => {
        const { fullname } = current;
        if (!acc[fullname]) {
          acc[fullname] = { ...current, details: [] };
        }
        acc[fullname].details.push({
          ...current,
        });
        return acc;
      }, {})
    );
  };

  const onSearch = (value, _e, info) => {
    if (value === "") {
      setFilteredData(dataUsers);
      return;
    }
    const resultSearch = dataUsers?.filter(
      (item) =>
        item?.campus?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.fullname?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setFilteredData(resultSearch);
  };

  const handleSearchEBook = (searchText) => {
    setIsLoading(true);
    APIService.search_lms_ebook_campus({
      body: { username: searchText.trim() },
      access_token: cookies["access_token"],
    })
      .then((res) => {
        if (res?.data?.length === 0) {
          Notification("error", "Not found");
          return;
        }
        setDataUser(res.data);
      })
      .catch((err) => {
        Notification("error", "Error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Col span={24}>
        <Row gutter={[16, 15]}>
          <Col span={24}>
            <div className="flex justify-end mt-4">
              <Search
                style={{
                  width: 400,
                }}
                placeholder="Search by campus, full name"
                allowClear
                enterButton="Search"
                onSearch={onSearch}
              />
            </div>
          </Col>
          <Col span={24}>
            <TableDataEbookLMS
              isLoading={isLoading}
              dataUsers={formatGroupByFullName(filteredData)}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default EbookManager;
