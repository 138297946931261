import Translator from "./Translator";

import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import { Button } from "antd";
// import "antd/dist/antd.css";
import React, { useCallback, useState } from "react";

const TranslatorModal = () => {
  const [visible, setVisible] = useState(false);
  const onOk = useCallback(() => setVisible(true), []);
  const onCancel = useCallback(() => setVisible(false), []);
  return (
    <DraggableModalProvider>
      <Button
        className="bg-orange-600 hover:!bg-orange-600 absolute right-20 top-1/2 -translate-y-1/2"
        type="primary"
        onClick={onOk}
      >
        Dictionary
      </Button>
      <DraggableModal
        className="!z-40"
        width={500}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <Translator />
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default TranslatorModal;
