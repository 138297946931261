import { https } from "./urlConfig";

export const chatService = {
  chat_bot_image: (data, access_token) => {
    let uri = "gpt/chat_bot_image";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
        "content-type": "multipart/form-data",
      },
    });
  },
  chat: (data, access_token) => {
    let uri = "gpt/chat_bot";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  translateToKorea: (data, access_token) => {
    let uri = "gpt/translate";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  chat_pet: (data, access_token) => {
    let uri = "pets/chat_mascot";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
};
