import { Col, Row } from "antd";
import React from "react";
import style from "./style.module.css";

const CategoryManagement = () => {
  return (
    <div className={style.container_Infor}>
      <Col span={24}>
        <Row gutter={[10, 10]}>
          <p className="font-bold text-4xl w-full text-center mt-44">
            Coming soon
          </p>
        </Row>
      </Col>
    </div>
  );
};

export default CategoryManagement;
