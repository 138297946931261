import React from "react";
import { Outlet } from "react-router";
import Mainheader from "../MainLayout/header";
const SubLayout = () => {
  return (
    <>
      <Mainheader />
      <Outlet />
    </>
  );
};

export default SubLayout;
