import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Row, Space, Table } from "antd";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Highlighter from "react-highlight-words";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setProductList } from "../../redux/campusSlice";
import { APIService } from "../../services/apiService";
import style from "./style.module.css";

function PopupListProduct({ setOpenPopup, typePopup }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectionRow, setSelectionRow] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const searchInput = useRef(null);

  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });

  useEffect(() => {
    APIService.campus_product_list(cookies["access_token"])
      .then((response) => {
        // // console.log(response.data)
        if (response && response.status_code === 200 && response.data) {
          let datares = [];
          // // console.log(response)
          response.data.forEach((element, index) => {
            // // console.log(element)
            datares.push({
              key: index,
              category: element.category,
              product_id: element.product_id,
              product_name: element.product_name,
              courses_name: element.course_names.map((item, index) => ({
                id: element.course_ids[index],
                name: item,
              })),
              // period: element.period,
              price: element.price,
            });
          });

          datares = datares.map((i) => {
            if (i.product_name !== "HIGH SCHOOL") return i;
            const courses = i.courses_name;
            courses.sort((a, b) => {
              const numA = parseInt(a.name.split(" ")[1]);
              const numB = parseInt(b.name.split(" ")[1]);
              return numA - numB;
            });
            return { ...i, courses_name: courses };
          });
          setSelectedCourse(
            datares?.map((item) => ({
              product_id: item.product_id,
              courses: [],
              product_name: item.product_name,
            }))
          );

          setData(datares);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined></SearchOutlined>}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      // ...getColumnSearchProps("category"),
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      // ...getColumnSearchProps("product_name"),
    },
    {
      title: "Courses",
      dataIndex: "courses_name",
      key: "courses_name",
      render: (item, record) => {
        const index = selectedCourse.findIndex(
          (item) => item.product_id === record.product_id
        );
        const newDate = JSON.parse(JSON.stringify(selectedCourse));
        return (
          <Checkbox.Group
            value={selectedCourse[index].courses}
            className="w-full grid grid-cols-2 gap-2"
            onChange={(value) => {
              newDate[index].courses = value;
              setSelectedCourse(newDate);
            }}
          >
            {item?.map((item) => (
              <Checkbox value={item.id}>{item.name}</Checkbox>
            ))}
          </Checkbox.Group>
        );
      },
      // ...getColumnSearchProps("courses_name"),
    },
    // {
    //   title: "Valid Period of Study",
    //   dataIndex: "period",
    //   key: "period",
    // },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      // sorter: (a, b) => a.price - b.price,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      render: (value, record) => {
        const { product_id } = record;
        const foundItem = selectedCourse.find(
          (item) => item.product_id === product_id
        );
        const index = selectedCourse.findIndex(
          (item) => item.product_id === product_id
        );
        const newData = JSON.parse(JSON.stringify(selectedCourse));
        return (
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                newData[index].courses = record.courses_name.map(
                  (item) => item.id
                );
              } else {
                newData[index].courses = [];
              }
              setSelectedCourse(newData);
            }}
            indeterminate={
              foundItem.courses.length > 0 &&
              foundItem.courses.length < record.courses_name.length
            }
            checked={foundItem.courses.length === record.courses_name.length}
          />
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRowKeys", selectedRowKeys);
      console.log("selectedRows", selectedRows);
      const newDate = JSON.parse(JSON.stringify(selectedCourse));
      for (let i of selectedRows) {
        const { product_id, courses_name } = i;
        const index = selectedCourse.findIndex(
          (item) => item.product_id === product_id
        );
        newDate[index].courses = courses_name?.map((item) => item.id);
      }
      setSelectedCourse(newDate);

      // const { product_id, courses_name } = selectedRows;
      // console.log("product_id", product_id);

      // setSelectionRow(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const confirmSelectProduct = () => {
    dispatch(
      setProductList({
        product_list: selectedCourse,
      })
    );
    setOpenPopup(false);
  };

  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 6 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 3 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 3 };
      break;
    default:
      paginationConfig = { pageSize: 3 };
      break;
  }
  return (
    <>
      <div
        className={classNames(
          style.container_popup_product,
          "overflow-y-visible"
        )}
      >
        <div
          className={classNames(
            style.content_popup_detail,
            "overflow-y-visible"
          )}
        >
          <Col span={24}>
            <Row gutter={[10, 10]} justify={"center"}>
              <Col span={22}>
                <Row justify={"end"}>
                  <AiOutlineCloseCircle
                    className={style.btn_close_popup}
                    onClick={() => setOpenPopup(false)}
                  />
                </Row>
              </Col>
              <Col span={22}>
                <Row justify="start">
                  <p className={style.title_PopupProductList}>Product List</p>
                </Row>
              </Col>
              <Col span={22}>
                <Table
                  className={style.tableProduct}
                  columns={columns}
                  dataSource={data}
                  pagination={paginationConfig}
                />
              </Col>
              <Col span={22}>
                <Row justify="center">
                  <Button onClick={confirmSelectProduct}>Confirm</Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
}

export default PopupListProduct;
