import { Button } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../../components/Notification/index";
import {
  setSelfEditing,
  setTempSelfWriting,
  setTitle,
} from "../../redux/lessionSlice";
import { APIService } from "../../services/apiService";
import TemplateEditing from "./TemplateEditing";
import styles from "./style.module.css";
const InputFieldSelfEditing = (props) => {
  const dispatch = useDispatch();
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const user = useSelector((state) => state.userSlice.user);
  const [cookies] = useCookies(["access_token"]);
  const [inputValue, setInputValue] = useState(lesson?.content_original);
  const [inputSelfValue, setInputSelfValue] = useState(
    lesson?.content_original
  );
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [inputTitle, setInputTitle] = useState("");
  const onChangeInputTitle = (e) => {
    dispatch(setTitle(e.target.value));
    setInputTitle(e.target.value);
  };

  const handleSaveWriting = async () => {
    setIsLoadingSave(true);
    const body = {
      userId: user?.id,
      lessonId: lesson.lessonId,
      status: "Self-editing",
      self_editing: inputSelfValue,
      writingTitle: inputTitle,
    };
    try {
      await APIService.save_writing({
        access_token: cookies["access_token"],
        body,
      });
      Notification("success", "Save successfully");
    } catch (error) {
      Notification("error", "Error occurred");
    }
    setIsLoadingSave(false);
  };

  useEffect(() => {
    if (!lesson?.writing_title) return;
    setInputTitle(lesson?.writing_title);
  }, [lesson?.writing_title]);

  useEffect(() => {
    const textarea = document.getElementById("notebook-textarea");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [inputValue]);

  useEffect(() => {
    if (lesson.temp_self_writing) {
      setInputSelfValue(lesson.temp_self_writing);
      dispatch(setSelfEditing(lesson.temp_self_writing));
      return;
    }
    if (lesson.self_editing) {
      setInputSelfValue(lesson.self_editing);
      dispatch(setSelfEditing(lesson.self_editing));
      return;
    }
    if (!lesson?.history) {
      dispatch(setSelfEditing(lesson?.content_original));
      return;
    }
    if (!lesson?.history?.essay_self_edited) {
      setInputSelfValue(lesson?.history?.essay_original);
      dispatch(setSelfEditing(lesson?.history?.essay_original));
    } else {
      setInputSelfValue(lesson?.history?.essay_self_edited);
      dispatch(setSelfEditing(lesson?.history?.essay_self_edited));
    }
  }, [lesson.reloadEditing]);

  const handleInputChange = (e) => {
    setInputSelfValue(e.target.value);
    dispatch(setSelfEditing(e.target.value));
    dispatch(setTempSelfWriting(e.target.value));
  };

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.textareaWrapper)}>
        <div className="w-full h-full flex gap-4">
          <div className="flex-1 relative bg-white/60 p-4 h-[95%] mt-12">
            <TemplateEditing />
          </div>
          <div className="flex-1">
            <div className="w-full flex items-center gap-2 bg-white/30 py-2 px-2 rounded-lg mb-1 ">
              <span className="-translate-y-0.5 font-medium">Title:</span>
              <input
                value={inputTitle}
                onChange={onChangeInputTitle}
                type="text"
                className="w-full text-center mr-10 bg-transparent focus:outline-none focus:border-none font-medium"
              />
            </div>
            <textarea
              className="!text-base"
              id="notebook-textarea"
              placeholder="Input..."
              value={inputSelfValue}
              onChange={handleInputChange}
            />
            <Button
              loading={isLoadingSave}
              onClick={handleSaveWriting}
              size="large"
              className="bg-[#5BA3B9] hover:!bg-[#4c91a7] mx-auto ml-40"
              type="primary">
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputFieldSelfEditing;
