import React, { useEffect, useState } from "react";
import { Radio, Tooltip } from "antd";
import style from "../style.module.css";
import EBookAirwork from "./components/EbookAirwork";
import MakeEBook from "./components/MakeEbook";
import logo_ebook from "../../../assets/icon_svg/logo-ebook.png";
import btn_home from "../../../assets/icon_svg/btn_backhome.png";
import "./Switch.css";
import "./antCustomCss.css";
import { useNavigate, useParams } from "react-router";
const options = [
  {
    label: (
      <Tooltip className="hover:text-gray-50" title="Create ArtWork">
        Create ArtWork
      </Tooltip>
    ),
    value: false,
  },
  {
    label: (
      <Tooltip className="hover:text-gray-50" title="Make EBook">
        Make EBook
      </Tooltip>
    ),
    value: true,
  },
];
const Switch = ({ isOn, handleToggle, onColor }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        htmlFor={`react-switch-new`}>
        <span className={`react-switch-button`} />
        {isOn ? (
          <span className="off-text">Create ArtWork</span>
        ) : (
          <span className="off-text">Make E Book</span>
        )}
      </label>
    </>
  );
};

const EBookMaking = () => {
  const [open, setOpen] = useState(false);
  const { type } = useParams();
  const navigate = useNavigate();
  const loadingFunction = (item) => {
    if (item === "edit" || item === "list" || item === "create") {
      setOpen(true);
    }
  };
  useEffect(() => {
    loadingFunction(type);
  }, []);
  const renderWithoutEdit = () => {
    return (
      <div className={style.container_ebook}>
        <div className={style.content_ebook}>
          <div className={`flex flex-col ${style.header_contentEbook}`}>
            <img
              src={logo_ebook}
              alt=""
              className={`${style.logo_ebook}`}
              onClick={() => navigate("/e-mybook/ebook")}
            />
            <div className={`${style.btn_switch}`}>
              <Radio.Group
                options={options}
                optionType="button"
                buttonStyle="solid"
                onChange={() => {
                  setOpen(!open);
                }}
                value={open}
                id="makeBookCheck"
              />
              {/* <img
                className={style.btn_back}
                src={btn_home}
                alt="btn-home"
                onClick={() => navigate("/e-mybook/list")}
              /> */}
            </div>
          </div>

          <div className={style.ebook_edit}>
            {open ? <MakeEBook /> : <EBookAirwork />}
          </div>
        </div>
      </div>
    );
  };
  const renderWithEdit = () => {
    return (
      <div className={style.container_ebook}>
        <div className={style.content_ebook}>
          <div className={style.ebook_edit}>
            {open ? <MakeEBook /> : <EBookAirwork />}
          </div>
        </div>
      </div>
    );
  };
  return type === "edit" ? renderWithEdit() : renderWithoutEdit();
};

export default EBookMaking;
