import {
  Button,
  DatePicker,
  Select,
  Space,
  Table,
  Input,
  Col,
  Row,
  Modal,
} from "antd";
import { useMediaQuery } from "react-responsive";
import { IoEyeSharp } from "react-icons/io5";
import { BsPencilFill } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
import Loading from "../../../../components/Loading/Loading";
import logo_ebook from "../../../../assets/icon_svg/logo-ebook.png";
import "./custom-antd.css";
import { useNavigate } from "react-router";

const { Search } = Input;

const { RangePicker } = DatePicker;
const EBookAirwork = () => {
  const [cookies] = useCookies(["access_token"]);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedView, setSelectedView] = useState("");

  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  const [lessionID, setLessionId] = useState(null);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [filteredTableData, setFilteredTableData] = useState([]);

  const showModal = (view) => {
    setSelectedView(view);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpdateAirWork = (lessonId) => {
    navigate(`/stable-diffusion/${lessonId}`);
  };

  const columns = [
    { title: "Lesson", dataIndex: "lesson", key: "lesson" },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
    },
    { title: "Product", dataIndex: "product", key: "product" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <IoEyeSharp
          onClick={() => showModal(record.view)}
          className="icon_view"
        />
      ),
    },
    {
      title: "Modify",
      dataIndex: "modify",
      key: "modify",
      render: (record) => (
        <BsPencilFill
          onClick={() => handleUpdateAirWork(record)}
          className="icon_modify"
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  useEffect(() => {
    const accessToken = cookies["access_token"];
    APIService.get_all_artwork(accessToken).then((response) => {
      const row = response.data;
      setTableData(
        row?.map((item, index) => ({
          key: index + 1,
          lesson: item.lesson_name,
          course: item.course_name,
          product: item.product_name,
          date: item.created_at,
          status: item.status,
          view: item.link_artwork,
          modify: item.lesson_id,
        }))
      );
    });
  }, []);

  const tableConfig = {
    dataSource: filteredTableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
  };
  useEffect(() => {
    const filteredData = tableData.filter(
      (item) =>
        item.lesson.includes(searchValue) || item.course.includes(searchValue)
    );
    setFilteredTableData(filteredData);
  }, [searchValue, tableData]);

  let paginationConfig;

  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 8 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 5 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 6 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  return (
    <>
      <Row className="talbe_makeEbook" gutter={[20, 20]}>
        <Col span={24} className="relative" style={{ top: "20%" }}>
          <Row>
            <Col span={24}>
              <Row justify={"end"}>
                {/* <Col span={4}>
                  <Select
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={8}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <RangePicker
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Space>
                </Col>
                <Col span={2}>
                  <Button> Today </Button>
                </Col> */}
                {/* <Col span={4} className="relative" style={{ right: "40%", bottom: "1vw" }} >
                  <img src={logo_ebook} alt="" style={{ width: "90%" }} onClick={() => navigate("/e-mybook/list")} />
                </Col> */}
                <Col span={10} className="relative" style={{ right: "30%" }}>
                  <Search
                    placeholder="Input Lesson Search"
                    allowClear
                    enterButton="Search"
                    onSearch={(value) => setSearchValue(value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="tableData relative" style={{ top: "20%" }}>
          {tableData.length > 0 ? (
            <>
              <Table {...tableConfig} pagination={paginationConfig} />
              <Modal
                title="ArtWork Modal"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: "none" } }}
                className="popup_modelArtWork"
              >
                <img src={selectedView} alt="" />
              </Modal>
            </>
          ) : (
            <Loading></Loading>
          )}
        </Col>
      </Row>
    </>
  );
};

export default EBookAirwork;
