import classNames from "classnames";
import React from "react";
import bg1 from "../../../assets/artwork/bg1.png";
import rollButton from "../../../assets/artwork/roll-button.png";
import wingCursor from "../../../assets/ebook/wing-cursor.png";

const ListCourses = ({
  selectedLesson,
  listArtworks,
  handleSelectCourse,
  indexSelectedCourse,
}) => {
  // console.log("listArtworks", listArtworks);
  console.log("indexSelectedCourse", indexSelectedCourse);
  return (
    <div
      className={classNames(
        "w-full relative duration-500",
        selectedLesson ? "-translate-x-[2000px]" : "-translate-x-0"
      )}>
      <img
        src={bg1}
        alt="bg1"
        className="absolute top-0 left-0 w-full object-cover"
      />
      <div className="relative text-white text-2xl font-medium">
        <p
          className={classNames(
            "2xl:h-16 h-12 flex items-center 2xl:px-10 px-4",
            "md:h-10"
          )}>
          Course
        </p>
        <button
          type="button"
          className="absolute -bottom-14 left-1/2 -translate-x-1/2 2xl:h-12 h-10">
          <img src={rollButton} alt="button" className="h-full object-cover" />
        </button>
        <div
          className={classNames(
            "2xl:h-[39.5rem] lg:h-[25rem] 2xl:pl-10 pl-4 w-full",
            "h-[21.2rem]"
          )}>
          {/* item component */}
          <div className="w-full h-full overflow-y-auto overflow-x-hidden">
            {listArtworks?.map((item, index) => (
              <div
                className="2xl:pt-8 pt-6 relative 2xl:pr-14 pr-12  cursor-pointer"
                key={index}
                onClick={() => {
                  handleSelectCourse(index);
                }}>
                {indexSelectedCourse === index && (
                  <button className="absolute top-0 -right-4 h-20">
                    <img
                      src={wingCursor}
                      alt="btn"
                      className="h-full object-cover"
                    />
                  </button>
                )}
                {item?.course_name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCourses;
