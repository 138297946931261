import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import { Button, Col, Input, Row, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { APIService } from "../../../services/apiService";
import { useCookies } from "react-cookie";
import ModalStart from "../../../assets/icon_svg/svg/medal-star.svg";
import PopupMascot from "../../../components/Popup/PopupMascot";
import ButtonBack from "../../../components/Button/ButtonBack";
import { useSelector } from "react-redux";
import { cloneUniformsGroups } from "three";
import { LeftOutlined } from "@ant-design/icons";
import { set } from "react-hook-form";
import point_icon from "../../../assets/icon_svg/medal-star_status.png";
import rectangle from "../../../assets/icon_svg/Rectangle.png";
import heartadd from "../../../assets/icon_svg/Heart-add.png";
import heart from "../../../assets/icon_svg/Heart.png";
import { useNavigate } from "react-router";
import "./custom.css";
import classNames from "classnames";
const PetList = (props) => {
  const { userPoint } = props;
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [filteredData, setFilteredData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState(null);
  const [sortOrder2, setSortOrder2] = useState(null);
  const [sortOrder3, setSortOrder3] = useState(null);
  const [isShowPopUpMascot, setIsShowPopUpMascot] = useState(false);
  const [dataShowMascot, setDataShowMascot] = useState({});
  const point = useSelector((state) => state.userSlice.point);
  const navigate = useNavigate();
  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_inforList_Pet(accessToken);
      setFilteredData(data.data);
      setInitialData(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
    if (value === "") {
      setFilteredData(initialData);
    } else {
      const filtered = initialData.filter((item) =>
        item.pet_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const stylingBGCard = (point_exchange) => {
    if (point_exchange < 100) return "bg-amber-600/70";
    if (point_exchange >= 500) return "bg-yellow-300/70";
    return "bg-slate-50/70";
  };

  const handleSort = (key) => {
    let sorted;
    if (key === "ascend") {
      sorted = [...filteredData];
      sorted.sort((a, b) => {
        const nameA = a.pet_name.toLowerCase();
        const nameB = b.pet_name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      setSortOrder("ascend");
    } else if (key === "descend") {
      sorted = [...filteredData];
      sorted.sort((a, b) => {
        const nameA = a.pet_name.toLowerCase();
        const nameB = b.pet_name.toLowerCase();
        return nameB.localeCompare(nameA);
      });
      setSortOrder("descend");
    } else if (key === "cardPet_Owned") {
      sorted = initialData.filter((item) => item.is_owned === true);
      setSortOrder("cardPet_Owned");
    } else if (key === "cardPet_notOwned") {
      sorted = initialData.filter((item) => item.is_owned === false);
      setSortOrder("cardPet_notOwned");
    } else if (key === "cardPet_canGet") {
      sorted = initialData.filter((item) => userPoint <= item.point_exchange);
      setSortOrder("cardPet_canGet");
    } else {
      sorted = [...initialData];
      setSortOrder(null);
    }
    setFilteredData(sorted);

    if (sortOrder2 !== null) {
      let filteredSorted;
      if (sortOrder2 === "cardPet_Owned") {
        filteredSorted = sorted.filter((item) => item.is_owned === true);
      } else if (sortOrder2 === "cardPet_notOwned") {
        filteredSorted = sorted.filter((item) => item.is_owned === false);
      } else if (sortOrder2 === "cardPet_canGet") {
        filteredSorted = sorted.filter(
          (item) => userPoint <= item.point_exchange
        );
      }
      setFilteredData(filteredSorted);
    }
  };

  const handleSort2 = (key) => {
    let sorted;
    if (key === "cardPet_Owned") {
      sorted = initialData.filter((item) => item.is_owned === true);
      setSortOrder2("cardPet_Owned");
    } else if (key === "cardPet_notOwned") {
      sorted = initialData.filter((item) => item.is_owned === false);
      setSortOrder2("cardPet_notOwned");
    } else if (key === "cardPet_canGet") {
      sorted = initialData.filter((item) => userPoint <= item.point_exchange);
      setSortOrder2("cardPet_canGet");
    } else {
      sorted = [...initialData];
      setSortOrder2(null);
    }
    setFilteredData(sorted);

    if (sortOrder !== null) {
      let sortedFiltered;
      if (sortOrder === "ascend") {
        sortedFiltered = sorted.sort((a, b) => {
          const nameA = a.pet_name.toLowerCase();
          const nameB = b.pet_name.toLowerCase();
          return nameA.localeCompare(nameB);
        });
      } else if (sortOrder === "descend") {
        sortedFiltered = sorted.sort((a, b) => {
          const nameA = a.pet_name.toLowerCase();
          const nameB = b.pet_name.toLowerCase();
          return nameB.localeCompare(nameA);
        });
      }
      setFilteredData(sortedFiltered);
    }
  };

  const handleSort3 = (key) => {
    let sorted;
    if (key === "0-100") {
      sorted = initialData.filter(
        (item) => item.point_exchange >= 0 && item.point_exchange <= 100
      );
      setSortOrder3("0-100");
    } else if (key === "100-500") {
      sorted = initialData.filter(
        (item) => item.point_exchange >= 100 && item.point_exchange <= 500
      );
      setSortOrder3("100-500");
    } else if (key === "500-1000") {
      sorted = initialData.filter(
        (item) => item.point_exchange >= 500 && item.point_exchange <= 1000
      );
      setSortOrder3("500-1000");
    } else if (key === "1000-5000") {
      sorted = initialData.filter(
        (item) => item.point_exchange >= 1000 && item.point_exchange <= 5000
      );
      setSortOrder3("1000-5000");
    } else {
      sorted = [...initialData];
      setSortOrder3(null);
    }
    setFilteredData(sorted);
  };
  const styleSortMenu = {
    backgroundColor: "rgba(255,255,255,60%)",
  };
  const sortMenu = (
    <Menu className="customMenu" onClick={(e) => handleSort(e.key)}>
      <Menu.Item key="ascend">A-Z</Menu.Item>
      <Menu.Item key="descend">Z-A</Menu.Item>
    </Menu>
  );
  console.log(sortOrder2);
  const sortMenu2 = (
    <Menu className="customMenu" onClick={(e) => handleSort2(e.key)}>
      <Menu.Item key="ALL">All</Menu.Item>
      <Menu.Item key="cardPet_Owned">Owned</Menu.Item>
      <Menu.Item key="cardPet_notOwned">Not owned yet</Menu.Item>
    </Menu>
  );

  const sortMenu3 = (
    <Menu className="customMenu" onClick={(e) => handleSort3(e.key)}>
      <Menu.Item key="ALL">All</Menu.Item>
      <Menu.Item key="0-100">0-100</Menu.Item>
      <Menu.Item key="100-500">100-500</Menu.Item>
      <Menu.Item key="500-1000">500-1000</Menu.Item>
    </Menu>
  );

  const onShowPopMascot = (data) => {
    setDataShowMascot(data);
    setIsShowPopUpMascot(true);
  };

  const updatetInitialData = (pet_id) => {
    const updatedFilteredData = [...initialData];
    updatedFilteredData.forEach((item) => {
      if (item.id == pet_id) {
        item.is_owned = true;
      }
    });
    setInitialData(updatedFilteredData);
    setFilteredData(updatedFilteredData);
  };
  return (
    <>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <div className={style.content_PetList}>
            <Row gutter={[16, 16]}>
              <Col>
                <Dropdown overlay={sortMenu} trigger={["click"]}>
                  <Button className={style.btnOrder}>
                    {sortOrder == "descend" ? "Z-A" : "A-Z"}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col>
                <Dropdown overlay={sortMenu3} trigger={["click"]}>
                  <Button className={style.btnOrder}>
                    {sortOrder3 ? (
                      sortOrder3
                    ) : (
                      <img
                        className={style.point_icon_status}
                        src={point_icon}
                        alt=""
                      />
                    )}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col>
                <Dropdown overlay={sortMenu2} trigger={["click"]}>
                  <Button className={style.btnOrder_style}>
                    {sortOrder2
                      ? sortOrder2 == "cardPet_Owned"
                        ? "Owned"
                        : "Not Owned"
                      : "STATUS MASCOT"}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            <Input.Search
              className={style.btnSearch}
              placeholder="Search for name"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </Col>
        <Col span={24}>
          <div
            className={`${style.content_listPet_item} ${
              filteredData.length <= 7 ? style.content_listPet_item__short : ""
            }`}>
            <Col span={24}>
              <Row gutter={[10, 10]}>
                {filteredData.map((item, index) => (
                  <Col
                    xl={4}
                    xxl={4}
                    lg={4}
                    md={4}
                    onClick={() => {
                      onShowPopMascot({
                        pet_id: item.id,
                        avatar_pet: item.pet_avatar,
                        point: item.point_exchange,
                        pet_name: item.pet_name,
                        pet_description: item.description,
                        is_owned: item.is_owned,
                        pet_level: item.level,
                        pet_point_exchange: item.point_exchange,
                      });
                    }}
                    key={index}>
                    <div
                      className={classNames(
                        "backdrop-blur-md",
                        stylingBGCard(item.point_exchange),
                        style.status,
                        item.is_owned ? style.cardPet_Owned : null,
                        !item.is_owned ? style.cardPet_notOwned : null
                      )}>
                      <img src={rectangle} className={style.rectangle} alt="" />

                      {item.is_owned > 0 ? (
                        <div className={style.heart}>
                          <img
                            src={heart}
                            className={style.heart_image}
                            alt=""
                          />
                        </div>
                      ) : null}
                      <img
                        src={item.pet_avatar}
                        className={style.pet_avatar}
                        alt="AvatarPet"
                      />
                      <p className={style.pet_name}>{item.pet_name}</p>
                      <div className={style.rowPointExchange}>
                        <img src={ModalStart} alt="" />
                        <p className={style.pointExchange}>
                          {item.point_exchange}
                        </p>
                      </div>
                      <div
                        style={
                          item.is_owned
                            ? { borderColor: "#0094FF", color: "#0094FF" }
                            : {
                                borderColor: "rgba(47,48,53,0.60)",
                                color: "rgba(47,48,53,0.60)",
                              }
                        }
                        className={style.pet_message}>
                        {item.is_owned
                          ? "Hello my friend"
                          : "Do you want to be my friend?"}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </div>
        </Col>
      </Row>

      {isShowPopUpMascot ? (
        <PopupMascot
          dataShowMascot={dataShowMascot}
          setIsShowPopUpMascot={setIsShowPopUpMascot}
          updatetInitialData={updatetInitialData}
          statusPopupMascot={isShowPopUpMascot}></PopupMascot>
      ) : null}
    </>
  );
};

export default PetList;
