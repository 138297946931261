import { Button, Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Notification } from "../../../../components/Notification/index";
import { TableDataApprovalRequestAdmin } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";
import style from "./style.module.css";

const ApprovalRequestAdmin = ({ is_admin }) => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [selectedApproval, setSelectedApproval] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const triggerRefetch = () => {
    setRefetch((prev) => !prev);
  };

  const handleFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  async function fetchData() {
    setIsLoading(true);
    try {
      const data = await APIService.get_all_admin_approval(
        cookies["access_token"]
      );
      if (!data) return;
      setDataUser(data.data);
    } catch (error) {}
    setIsLoading(false);
  }
  const onSendAcceptApproval = () => {
    if (selectedApproval.length > 0) {
      // console.log(selectedApproval);
      APIService.accept_approval_admin(
        {
          approval_ids: selectedApproval,
        },
        cookies["access_token"]
      ).then((response) => {
        if (response.data === "accepted") {
          Notification("success", "Success approval");
          triggerRefetch();
          setSearchText("");
        } else {
          Notification("error", "Fail approval");
        }
      });
    }
  };

  const handleSearchClassApproval = (searchText) => {
    setIsLoading(true);
    APIService.search_class_approval({
      body: { username: searchText.trim() },
      access_token: cookies["access_token"],
    })
      .then((res) => {
        if (res?.data?.length === 0) {
          Notification("error", "Not found");
          return;
        }
        setDataUser(res.data);
      })
      .catch((err) => {
        Notification("Error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  return (
    <>
      <div className={style.content_approval}>
        <div className="mb-6">
          <Col span={24}>
            <Row gutter={[20, 20]} justify={"end"}>
              <Col span={14}>
                <Row gutter={[10, 10]}>
                  <Col span={16}>
                    {/* <Checkbox>Search user name</Checkbox> */}
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={isLoading}
                      placeholder="Search campus name, email, full name"
                      value={searchText}
                      onChange={handleFilterChange}
                    />
                  </Col>

                  <Col span={4}>
                    <Button
                      className={style.btn_addPage}
                      onClick={() => onSendAcceptApproval()}>
                      Approve
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>

        {/* <FormApprovalRequesAdmin
            onSendAcceptApproval={onSendAcceptApproval}
          /> */}
        <div className={style.table_approval}>
          <TableDataApprovalRequestAdmin
            fetchData={fetchData}
            isLoading={isLoading}
            filterValue={searchText}
            dataUsers={dataUsers}
            setSelectedApproval={setSelectedApproval}
          />
        </div>
      </div>
    </>
  );
};

export default ApprovalRequestAdmin;
