import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import logoTalksam from "../../../assets/logo/logo-talksam.jpg";
import { Notification } from "../../../components/Notification/index";
import { APIService } from "../../../services/apiService";
const ModalTalksamLogin = ({ isModalOpen, setIsModalOpen }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    setIsLoading(true);
    APIService.login_talksam(values)
      .then((res) => {
        const { status_code, message } = res;
        if (!status_code) {
          Notification("error", message);
          return;
        }
        const { access_token } = res?.data;
        let expires = new Date();

        expires.setTime(expires.getTime() + 60 * 60 * 24 * 3 * 1000);
        setCookie("access_token", access_token, {
          path: "/",
          expires,
        });
        Notification("success", message);
        form.resetFields();
        navigate("/dashboard");
      })
      .catch(() => {
        Notification("error", "Something went wrong, please try again!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Modal
        width={500}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}>
        <img
          src={logoTalksam}
          alt="logo"
          className="h-24 object-cover mx-auto"
        />
        <Form
          form={form}
          disabled={isLoading}
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}>
          <Form.Item
            name="talksam_id"
            rules={[
              {
                required: true,
                message: "Please input your Talksam ID!",
              },
            ]}>
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Talksam ID"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}>
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button w-full !bg-blue-400">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ModalTalksamLogin;
