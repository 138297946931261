import { Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import feedback from "../../../../../assets/sticker/E3 4.png";
import drafting from "../../../../../assets/sticker/E4 3.png";
import brainstorming from "../../../../../assets/sticker/E7 3.png";
import final from "../../../../../assets/sticker/E7 4.png";
import "./custom-antd.css";
export default function MyStepBar(props) {
  const [percent, setPercent] = useState("0");
  const { currentComponent } = props;
  const [keyStep, setKeyStep] = useState(currentComponent);
  const [step, setStep] = useState(-1);
  const backgroundFinish = "linear-gradient(to bottom right, #00EEE0, #0057FF)";
  const backgroundWait = "linear-gradient(to bottom right, #00EEE0, #fff)";
  const lesson = useSelector((state) => state.lessionSlice.lesson);

  useEffect(() => {
    setKeyStep(currentComponent);
  }, [currentComponent]);

  useEffect(() => {
    setPercent(calculatePercent(keyStep));
  }, [keyStep]);

  function calculatePercent(step) {
    if (step === 1) {
      return "0";
    } else if (step === 2) {
      return "33.3";
    } else if (step === 3) {
      return "66.6";
    } else if (step === 4) {
      return "100";
    }
  }

  useEffect(() => {
    if (step !== -1) return;
    let status = lesson.status;
    console.log("Progress status", status);
    switch (status !== null) {
      case status === "Saved":
        setKeyStep(4);
        setStep(4);
        setPercent("100");
        break;

      case status === "Class Over":
        setKeyStep(4);
        setStep(4);
        setPercent("100");
        break;

      case status === "Self-Editing":
        setKeyStep(4);
        setStep(4);
        setPercent("100");
        break;
      case status === "Writing":
        setKeyStep(2);
        setStep(2);
        setPercent("33.3");
        break;
      // case status === "Submited":
      //   setKeyStep(3);
      //   setPercent("66.6");
      //   break;
      case status === "Revising Complete & Evaluation":
        setKeyStep(3);
        setStep(3);
        setPercent("66.6");
        break;
      case status === "Brain Storming":
        setKeyStep(1);
        setStep(1);
        setPercent("0");
        break;
      default:
        break;
    }
  });

  function handleStep() {
    if (keyStep === 1) {
      setPercent("0");
      setKeyStep(currentComponent);
    } else if (keyStep === 2) {
      setPercent("33.3");
      setKeyStep(3);
    } else if (keyStep === 3) {
      setPercent("66.6");
      setKeyStep(4);
    } else if (keyStep === 4) {
      setPercent("10");
      setKeyStep(1);
    }
  }

  return (
    <div className="container_processBard">
      <Row className="row_progressbar">
        <Col span={12} className="custom_col_processBar">
          <ProgressBar
            percent={percent}
            filledBackground="linear-gradient(to right, #00A1F0, #00A1F0)">
            <Step transition="scale" onChange={handleStep}>
              {({ accomplished }) => (
                <Tooltip
                  title="Brain Storming"
                  color="#fff"
                  overlayClassName="custom-tooltip"
                  arrow={true}
                  placement="topLeft"
                  visible={keyStep === 1}>
                  <img
                    style={{
                      background: backgroundFinish,
                      borderRadius: "75%",
                    }}
                    width="50"
                    src={brainstorming}
                    alt="Brain Storming"
                  />
                </Tooltip>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <Tooltip
                  title="Writing"
                  color="#fff"
                  overlayClassName="custom-tooltip"
                  arrow={true}
                  placement="topLeft"
                  visible={keyStep === 2}>
                  <img
                    style={{
                      background: backgroundFinish,
                      borderRadius: "75%",
                    }}
                    width="50"
                    src={drafting}
                    alt="Writing"
                  />
                </Tooltip>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <Tooltip
                  title="Suggestion and Evaluation"
                  color="#fff"
                  overlayClassName="custom-tooltip"
                  arrow={true}
                  placement="topLeft"
                  visible={keyStep === 3}>
                  <img
                    style={{
                      background: backgroundFinish,
                      borderRadius: "75%",
                    }}
                    width="50"
                    src={feedback}
                    alt="Suggestion and Evaluation"
                  />
                </Tooltip>
              )}
            </Step>

            <Step transition="scale">
              {({ accomplished }) => (
                <Tooltip
                  title="Self-Editing"
                  color="#fff"
                  overlayClassName="custom-tooltip"
                  arrow={true}
                  placement="topLeft"
                  visible={keyStep === 4}>
                  <img
                    style={{
                      background: backgroundWait,
                      borderRadius: "75%",
                      zIndex: 0,
                    }}
                    width="50"
                    src={final}
                    alt="Self Editing"
                  />
                </Tooltip>
              )}
            </Step>
          </ProgressBar>
        </Col>
      </Row>
    </div>
  );
}
