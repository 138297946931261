import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  InputNumber,
} from "antd";
import { APIService } from "../../services/apiService";
import { useCookies } from "react-cookie";
import TextArea from "antd/es/input/TextArea";
import { set } from "react-hook-form";
import { setDisable } from "../../redux/chatSlice";
import { Notification } from "../../components/Notification/index";

export const FormCmsCourse = ({ datalesson }) => {
  const [form] = Form.useForm();
  const [cookies] = useCookies(["access_token"]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(null);
  const [registration, setIsRegistration] = useState(false);
  const [valueLesson, setValueLesson] = useState(null);
  const [change, setChange] = useState(false);
  const [value, setValue] = useState(1);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const handleCheckBoxChange = (value) => {
    if (value !== selectedCheckBox) {
      setSelectedCheckBox(value);
    }
  };
  useEffect(() => {
    setChange(false);
    setIsRegistration(false);
    setIsFormDisabled(true);
    if (datalesson) {
      if (datalesson.information) {
        // console.log(datalesson.information)
        setValueLesson(datalesson);
        form.setFieldValue("count", 0);
        form.setFieldValue("course", datalesson.information.course_name);
        form.setFieldValue("title", datalesson.information.lesson_name);
        form.setFieldValue("subtitle", datalesson.information.lesson_name);
        form.setFieldValue("topic", datalesson.information.topic);
        form.setFieldValue(
          "book_review",
          datalesson.information.book_review.is_used
        );
        form.setFieldValue(
          "book_review_text",
          datalesson.information.book_review.summary_content
        );
        form.setFieldValue(
          "book_review_url",
          datalesson.information.book_review.url_book
        );
        form.setFieldValue("vocabulary", datalesson.information.vocabulary);

        form.setFieldValue("grammar", datalesson.information.grammar);
        form.setFieldValue(
          "min_character",
          datalesson.information.minimum_words
        );
        form.setFieldValue(
          "max_character",
          datalesson.information.maximum_words
        );
      }
    }
  }, [datalesson]);
  const onChangeRadia = (e) => {
    setValue(e.target.value);
  };

  const handleChange = () => {
    setChange(true);
    setIsFormDisabled(false);
  };

  const onCheckDuplicate = () => {
    APIService.check_duplicate(
      {
        course_name: form.getFieldValue("course"),
      },
      cookies["access_token"]
    )
      .then((response) => {
        // console.log(response);
        if (response.data === true) {
          Notification("error", "Duplicate name");
        } else {
          setIsRegistration(true);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const onSaveLesson = () => {
    const data = {
      course_name: form.getFieldValue("course"),
      count: form.getFieldValue("count"),
      title: form.getFieldValue("title"),
      sub_title: form.getFieldValue("subtitle"),
      topic: form.getFieldValue("topic"),
      is_book_review: form.getFieldValue("book_review"),
      book_review_text: form.getFieldValue("book_review_text"),
      book_review_img:
        "https://back.writing.ac/media/book-review/url_book_1.jpg",
      book_review_url: form.getFieldValue("book_review_url"),
      image_location: 0,
      vocabulary: form.getFieldValue("vocabulary"),
      grammar: form.getFieldValue("grammar"),
      minimum_of_characters: form.getFieldValue("min_character"),
      maximum_of_characters: form.getFieldValue("max_character"),
    };
    // console.log(data)
    APIService.create_lesson(data, cookies["access_token"])
      .then((response) => {
        Notification("success", response.data);
      })
      .catch((error) => {});
  };

  const onRegistrationCourse = () => {
    // form.resetFields();
    APIService.create_course(
      {
        course_name: form.getFieldValue("course"),
      },
      cookies["access_token"]
    )
      .then((response) => {
        Notification("success", response.data);
        setIsRegistration(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const onDeleteLesson = () => {
    APIService.delete_lesson(
      {
        lesson_id: datalesson.information.id,
      },
      cookies["access_token"]
    )
      .then((response) => {
        Notification("success", response.data);
        setIsRegistration(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const onRegistrationNew = () => {
    form.resetFields();
    setIsFormDisabled(false);
    setChange(true);
  };

  return (
    <>
      <div className="relative flex justify-evenly my-2">
        <Button className={style.btn_addPage} onClick={onRegistrationNew}>
          Registration New
        </Button>
        {change ? (
          <>
            <Button className={style.btn_addPage} onClick={onSaveLesson}>
              Saved
            </Button>
          </>
        ) : (
          <>
            <Button className={style.btn_addPage} onClick={handleChange}>
              Change
            </Button>
          </>
        )}
        <Button className={style.btn_addPage} onClick={onDeleteLesson}>
          Delete
        </Button>
      </div>

      <Form
        form={form}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 20,
        }}
        disabled={isFormDisabled}
      >
        <Form.Item label="Course Registration">
          <Form.Item name="course">
            <Input onChange={() => setIsRegistration(false)} />
          </Form.Item>
          <Form.Item>
            <Button onClick={onCheckDuplicate}>Duplicate check</Button>
          </Form.Item>

          {registration ? (
            <Form.Item>
              <Button onClick={onRegistrationCourse}>Registration</Button>{" "}
            </Form.Item>
          ) : null}
        </Form.Item>

        {/* <p>Lesson basic information</p>  */}
        <Form.Item name="count" label="Count">
          <InputNumber min={0} max={4} />
        </Form.Item>
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
        <Form.Item name="subtitle" label="Sub Title">
          <Input />
        </Form.Item>
        <Form.Item name="topic" label="Topic">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item name="book_review" label="Book Review">
          <Radio.Group onChange={onChangeRadia} value={value}>
            <Radio value={true}>Used</Radio>
            <Radio value={false}>Not use</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="book_review_text" label="Book Review Text">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="book_review_url" label="Book Review Url">
          <Input />
        </Form.Item>
        <Form.Item name="book_review_img" label="Book Review Image">
          <Input type="file" />
        </Form.Item>
        <Form.Item label="Image location">
          <Radio.Group
            onChange={handleCheckBoxChange}
            defaultValue={1}
            name="radiogroup"
          >
            <Radio value={1}>Above Text</Radio>
            <Radio value={2}>Below The Text</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="vocabulary" label="Vocabulary">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="grammar" label="Grammar">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="min_character" label="Minimum number of characters">
          <Input />
        </Form.Item>
        <Form.Item name="max_character" label="Maximum number of characters">
          <Input />
        </Form.Item>
      </Form>
    </>
  );
};
