import React, { useEffect, useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
import LionAvatar from "../../../assets/avatar/Lion.png";
import MedalStart from "../../../assets/icon_svg/svg/medal-star.svg";
import petRoom_hover from "../../../assets/logo/logo_Petroom-hover.png";
import ModelViewer from "./ModelPet/ModelPetView";
import "./custom.css";
import style from "./style.module.css";

const PetRoomPage = () => {
  let user = useSelector((state) => state.userSlice.user);
  let point = useSelector((state) => state.userSlice.point);
  const [color, setColor] = useState("");
  const currentPet = useSelector((state) => state.petSlice.pets[0]);
  const [caseValue, setCaseValue] = useState(null);
  const [avatarPet, setAvatarPet] = useState(currentPet?.url_avatar);

  useEffect(() => {
    // console.log("currentPet", currentPet);
    setAvatarPet(currentPet?.url_avatar);
    setCaseValue(currentPet?.affection_point);
  }, [currentPet]);

  useEffect(() => {
    handleColorChange(caseValue);
  }, [caseValue]);

  const handleColorChange = (caseValue) => {
    switch (true) {
      case caseValue >= 0 && caseValue <= 20:
        setColor("#FFFFFF");
        break;
      case caseValue >= 21 && caseValue <= 40:
        setColor("#65BFCC");
        break;
      case caseValue >= 41 && caseValue <= 60:
        setColor("#6B7AFF, #1430C6");
        break;
      case caseValue >= 61 && caseValue <= 80:
        setColor("#F2994A");
        break;
      case caseValue >= 81 && caseValue <= 100:
        setColor("#EB5757");
        break;
      default:
        setColor("#FFFFFF");
    }
  };
  const [quantity, setQuantity] = useState(
    Array.from(Array(100), (_, index) => index + 1)
  );

  const optionsDison = [
    {
      label: "3D",
      value: "3D",
    },
    {
      label: "2D",
      value: "2D",
    },
  ];
  const [is2DChecked, setIs2DChecked] = useState(true);
  const [is2D, setIs2D] = useState(optionsDison[0].value);
  {
    /*
  // Button 2D 3D
  // Check condition Point
  const isCheckScore = () => {
    return point.total_point >= 300;
  };
  // OnClick when change
  const onChangeDimansion = ({ target: { value } }) => {
    console.log("isCheckScore checked", isCheckScore());

    if (isCheckScore()) {
      setIs2DChecked(true);
      setIs2D(value);
    } else {
      setIs2DChecked(false);
    }

    console.log("onChangeDimansion checked", value);
  };
  // Hiden box time 1s
  useEffect(() => {
    if (!is2DChecked) {
      const timer = setTimeout(() => {
        setIs2DChecked(true);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [is2DChecked]);
*/
  }

  return (
    <>
      <div className={style.container}>
        {quantity.map((index) => (
          <p key={index} className="firefly"></p>
        ))}
        <div className="sun">
          <div className={style.heart}>
            <AiFillHeart className={`${style.icon}`} style={{ color }} />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.content_user}>
            <div className={style.content_userAvar}>
              <img className={style.iconTitle} src={petRoom_hover} alt="" />
              <div className={style.boxTitle}></div>
              <span className={style.textTitle}>PET TUTOR</span>
            </div>

            <div className={style.content_userAvar_right}>
              <div className={style.avatarIconPet}>
                <img
                  className="w-full h-full object-cover"
                  src={LionAvatar}
                  alt="avatar"
                />
              </div>
              <div className={style.boxTitle}></div>
              <div className={style.medalStart}>
                <img className={style.medal} src={MedalStart} alt="" />
              </div>
              <span className={style.userPoint}>{point.total_point}</span>
              <span className={style.point}>Point</span>
            </div>
          </div>

          <div className={style.content_listPet}>
            <ModelViewer is2D={true} avatarPet={avatarPet} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PetRoomPage;
