import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import PopupCampusManager from "../../../../components/Popup/PopupCampusManager";
import { TableDataCampusEnrolment } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";
import style from "./style.module.css";

const CampusEnrolment = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [optionCountry, setOptionCountry] = useState([]);
  const [optionCampus, setOptionCampus] = useState([]);
  const [viewCampus, setViewCampus] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const campus_list = useSelector((state) => state.adminSlice.campus_list);
  const country_list = useSelector((state) => state.adminSlice.country_list);
  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_list_campus_enrolment(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectedDataChange = (selectedData) => {
    setSelectedData(selectedData);
  };

  useEffect(() => {
    setOptionCountry(
      country_list.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [country_list]);

  useEffect(() => {}, [campus_list]);

  return (
    <>
      <div className={style.container_listCampus}>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {/* <Col span={24}>
              <p className={style.title_listCampus}>Select Country</p>
            </Col> */}
            <Col span={24}>
              <div className={style.filter_Username}>
                <Row gutter={20}>
                  {/* <Col span={8}>
                    <Select
                      showSearch
                      placeholder="Select a country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={country_list.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Col>
                  <Col span={8}>
                    <Select
                      showSearch
                      placeholder="Select a campus"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={campus_list.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Col> */}
                  <Col span={4}>
                    <Button onClick={() => setOpenPopup(true)}>
                      Registration
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <TableDataCampusEnrolment
                dataUsers={dataUsers}
                filterValue={filterValue}
                dateRange={dateRange}
                selectedData={selectedData}
                onSelectedDataChange={handleSelectedDataChange}
                country_list={country_list}
                fetchData={fetchData}
              />
            </Col>
          </Row>
        </Col>

        {openPopup && (
          <PopupCampusManager
            fetchData={() => {
              fetchData();
            }}
            setOpenPopup={setOpenPopup}
            typePopup={"Campus"}
          />
        )}
      </div>
    </>
  );
};

export default CampusEnrolment;
