import React, { useState } from "react";
import style from "./style.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button, notification } from "antd";
import { APIService } from "../../services/apiService";
import { useCookies } from "react-cookie";
import { setCurrentPoint } from "../../redux/userSlice";
import Backdrop from "@mui/material/Backdrop";
import deafaultBgMascot from "../.././assets/icon_svg/DefaultBgMasscos.png";
import solidClose from "../.././assets/icon_svg/close-circle-solid.png";
import bandedMascot from "../.././assets/icon_svg/bandedMascot.png";
import medalStar from "../.././assets/icon_svg/medal-star.png";
import { Notification } from "../../components/Notification/index";

function PopupMascot({
  dataShowMascot,
  setIsShowPopUpMascot,
  updatetInitialData,
  statusPopupMascot,
}) {
  const dispatch = useDispatch();
  console.log(dataShowMascot);
  const user = useSelector((state) => state.userSlice.user);
  const point = useSelector((state) => state.userSlice.point);
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const handlePopupClose = () => {
    setIsShowPopUpMascot(false);
  };
  const addMascot = () => {
    if (dataShowMascot.is_owned) return;
    const current_point = point.total_point - dataShowMascot.point;
    if (current_point > 0) {
      APIService.change_point_mascot(
        {
          pet_id: dataShowMascot.pet_id,
        },
        cookies["access_token"]
      )
        .then((response) => {
          console.log(response);
          Notification(
            "success",
            `Congratulations on owning ${dataShowMascot?.pet_name}`
          );

          if (response.data.current_point !== -1) {
            // // console.log(response.data.current_point);
            dispatch(
              setCurrentPoint({
                current_point: response.data.current_point,
              })
            );
            updatetInitialData(dataShowMascot.pet_id);
            handlePopupClose();
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      Notification("warning", "You don't have enough point to add Pet");
    }
  };
  return (
    <Backdrop
      sx={{
        zIndex: 99,
        background: "rgba(255,255,255,50%)",
      }}
      open={statusPopupMascot}>
      <div className={style.container_popup}>
        <div className={style.content_popup_mascot}>
          <button className={style.btn_close_popup} onClick={handlePopupClose}>
            <img src={solidClose} alt="close-btn" />
          </button>
          <div
            style={{ backgroundImage: `url(${deafaultBgMascot})` }}
            className={style.bgMascot}>
            <div className={style.infoMascot}>
              <div className={style.detailMascot}>
                <div className={style.bgNameMascot}>
                  <img src={bandedMascot} alt="bandedMascot" />
                  <h3>{dataShowMascot?.pet_name}</h3>
                </div>
                <div className={style.levelMascot}>
                  {dataShowMascot?.pet_level?.substring(
                    0,
                    dataShowMascot?.pet_level.length - 6
                  )}
                </div>
              </div>
              <img
                className={style.imgMascot}
                src={
                  dataShowMascot.avatar_pet ? dataShowMascot.avatar_pet : null
                }
                alt="imgMascot"
              />
              <>
                {dataShowMascot?.is_owned ? (
                  <div className={style.owned}>OWNED</div>
                ) : (
                  <div className={style.Exchange}>
                    <Button
                      onClick={addMascot}
                      className={[style.styleBtn, style.exchange]}>
                      EXCHANGE
                    </Button>
                  </div>
                )}
              </>
            </div>
          </div>
          <div className={style.desc}>
            <div className={style.title}>
              <h3 className={style.petname}>{dataShowMascot?.pet_name}</h3>
              {dataShowMascot?.point == 0 && (
                <h3 className={style.point}>STARTER</h3>
              )}
              {!dataShowMascot?.is_owned && (
                <div className={style.wapperPoint}>
                  <img src={medalStar} alt="medalStar" />
                  <h3 className={style.point}>
                    {dataShowMascot?.point < 0
                      ? "STARTER "
                      : dataShowMascot?.point}{" "}
                    points
                  </h3>
                </div>
              )}
            </div>
            <div className={style.petStory}>
              {dataShowMascot?.pet_description}
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default PopupMascot;
