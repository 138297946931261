import Popover from "antd/lib/popover";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

const customPosition = {
  position: "relative",
  bottom: "33%",
  cursor: "pointer",
};
const User = (props) => {
  const { handle, type } = props;
  let user = useSelector((state) => state.userSlice);
  const [text, setText] = useState(user.user.username);

  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);

  const [open, setOpen] = useState(false);
  useEffect(() => {}, [user]);
  const _onLogOut = () => {
    removeCookie("access_token");
    window.location.href = "/";
  };

  useEffect(() => {
    setText(user.user.username);
  }, [user.user.username]);

  const content = (
    <div className="flex flex-col items-center">
      <p className="text-[#5BA3B9] mb-2">{text}</p>
      <div
        className="cursor-pointer flex items-center hover:text-red-500"
        style={{ columnGap: "16px" }}
        onClick={_onLogOut}>
        {/* <RiLogoutBoxLine width={30} height={30} /> */}
        Log out
      </div>
    </div>
  );
  return (
    <div className="flex items-center gap-4">
      <p>{text}</p>
      {type && <p>{user.user.email}</p>}
      <Popover content={content} trigger="click">
        <button
          type="button"
          onClick={handle}
          className="md:h-14 md:w-14 h-10 w-10 rounded-full border-2 border-primary overflow-hidden drop-shadow-md cursor-pointer">
          <img
            src={user.user.avatar}
            alt="alt"
            className="w-full h-full object-cover"
          />
        </button>
      </Popover>
    </div>
  );
};

export default User;
