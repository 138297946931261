import HTMLFlipBook from "react-pageflip";
import React from "react";
import Page1 from "../../../../assets/book/01.jpg";
import Page2 from "../../../../assets/book/page2.png";
import Page3 from "../../../../assets/book/page3.png";
import Page4 from "../../../../assets/book/page4.png";
import Page from "./bookPage";
import CoverPage from "./bookCoverPage";
import CoverImage from "../../../../assets/book/myWing.jpg";

export default function Book() {
  return (
    <div
      style={{
        width: "1000px",
        height: "600px",
        margin: "0 auto",
        paddingTop: "50px",
      }}
    >
      <HTMLFlipBook
        width={500}
        height={600}
        size="stretch"
        maxShadowOpacity={0.3}
        showCover={true}
        mobileScrollSupport={true}
      >
        <CoverPage>
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={CoverImage}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </CoverPage>
        <Page number="1">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page1}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <Page number="2">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page2}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <Page number="3">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page3}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <Page number="4">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page4}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <Page number="5">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page3}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <Page number="6">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page2}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <Page number="7">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page1}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <Page number="8">
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={Page4}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Page>
        <CoverPage>
          <div style={{ width: "500px", height: "600px" }}>
            <img
              src={CoverImage}
              alt="Cover Book"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </CoverPage>
      </HTMLFlipBook>
    </div>
  );
}
