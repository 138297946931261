import React, { useState } from "react";
import SuggestionEvaluation from "../../../../../components/SuggestionEvaluation/SuggestionEvaluation";
import TopicWritingCenter from "../../../../../components/TopicWritingCenter/index";
import style from "../style.module.css";
const FeedBack = ({ pointStarts }) => {
  const [type, setType] = useState(3);
  return (
    <div className={style.content_writingCenter}>
      <TopicWritingCenter type={type} pointStarts={pointStarts} />
      <SuggestionEvaluation></SuggestionEvaluation>
    </div>
  );
};

export default FeedBack;
