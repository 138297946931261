import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    category_list: [],
    country_list: [],
    campus_list: [],
    refetch: false,
  },
  reducers: {
    setCategory: (state, action) => {
      state.category_list = action.payload.category_list;
    },
    setCountry: (state, action) => {
      state.country_list = action.payload.country_list;
    },
    setCampus: (state, action) => {
      state.campus_list = action.payload.campus_list;
    },
    setRefetch: (state) => {
      state.refetch = !state.refetch;
    },
  },
});

export const { setCategory, setCountry, setCampus, setRefetch } =
  adminSlice.actions;
export default adminSlice.reducer;
