import { Tabs } from "antd";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CampusEnrolment from "./components/CampusEnrolment";
import CategoryManagement from "./components/CategoryManagement";
import ManagerSelection from "./components/ManagerSelection";
import RoleManager from "./components/RoleManager";
import style from "./style.module.css";

const { TabPane } = Tabs;

const AdminSetting = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [value, setValue] = useState("course");

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className={style.container_Dashboard}>
      <div className={style.content_Dashboard}>
        <p className={style.dashboard_title}>Setting</p>
        <Tabs defaultActiveKey="1" onChange={handleSetValue}>
          <TabPane tab="Role Manager " key="1">
            <RoleManager />
          </TabPane>
          <TabPane tab="Campus Management" key="2">
            <CampusEnrolment />
          </TabPane>
          <TabPane tab="Permission setting" key="3">
            <ManagerSelection />
          </TabPane>
          <TabPane tab="Category Management" key="4">
            <CategoryManagement />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminSetting;
