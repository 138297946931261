import { Button, Col, DatePicker, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { TableDataPointManager } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";
import style from "./style.module.css";

const { RangePicker } = DatePicker;

const { Search } = Input;

const PRODUCT_OPTIONS = [
  { label: "All products", value: "All products" },
  { label: "E4", value: "E4" },
  { label: "E5", value: "E5" },
  { label: "E6", value: "E6" },
  { label: "E7", value: "E7" },
  { label: "E8", value: "E8" },
  { label: "E9", value: "E9" },
  { label: "M11", value: "M11" },
  { label: "M12", value: "M12" },
  { label: "M13", value: "M13" },
  { label: "M14", value: "M14" },
  { label: "M15", value: "M15" },
];

const PointManager = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [filter, setFilter] = useState({ search: "", date: [], products: [] });
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_list_users_point(accessToken);

      setDataUser(data?.data);
      setFilterData(formatGroupByEmail(data?.data));
    } catch (error) {}
    setIsLoading(false);
  }

  const formatGroupByEmail = (data) => {
    return Object.values(
      data?.reduce((acc, current) => {
        const { email, fullname, nick_name, campus } = current;
        if (!acc[email]) {
          acc[email] = { email, fullname, campus, nick_name, details: [] };
        }
        acc[email].details.push({
          course: current.course,
          lesson: current.lesson,
          count: current.count,
          date: current.date,
          part: current.part,
          point: current.point,
          product: current.product,
        });
        return acc;
      }, {})
    );
  };

  const exportData = async () => {
    const sortedListUserByEmail = filterData.sort((a, b) => {
      const emailA = a.email.toLowerCase();
      const emailB = b.email.toLowerCase();
      if (emailA < emailB) {
        return -1;
      } else if (emailA > emailB) {
        return 1;
      } else {
        return 0;
      }
    });

    setIsLoadingExport(true);

    const res = await APIService.export_point(
      {
        records: sortedListUserByEmail,
      },
      cookies["access_token"]
    );
    if (res.status_code === 200) {
      const response = await fetch(res.data);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "point.xlsx";
      link.click();
    }
    setIsLoadingExport(false);
  };

  useEffect(() => {
    const { search, date, products } = filter;
    if (search === "" && date.length === 0 && products.length === 0) {
      setFilterData(dataUsers);
      return;
    }
    let resultFilter = dataUsers;
    if (search) {
      const lowerCaseQuery = search.toLowerCase();
      resultFilter = resultFilter.filter((user) => {
        const { email, fullname, nick_name, campus } = user;
        return (
          campus.toLowerCase().includes(lowerCaseQuery) ||
          email.toLowerCase().includes(lowerCaseQuery) ||
          fullname.toLowerCase().includes(lowerCaseQuery) ||
          nick_name.toLowerCase().includes(lowerCaseQuery)
        );
      });
    }
    if (date.length) {
      resultFilter = resultFilter.filter((user) => {
        const userDate = new Date(user.date.split(" ")[0]);
        const rangeStartDate = new Date(date[0]);
        const rangeEndDate = new Date(date[1]);
        return userDate >= rangeStartDate && userDate <= rangeEndDate;
      });
    }
    if (products.length && !products.includes("All products")) {
      resultFilter = resultFilter.filter((user) =>
        products.includes(user.product)
      );
    }
    setFilterData(resultFilter);
  }, [filter, dataUsers]);

  const onSearch = (value) => {
    setFilter((prev) => ({ ...prev, search: value }));
  };

  const onPickRangeDate = (value, dateStrings) => {
    setFilter((prev) => ({ ...prev, date: value ? dateStrings : [] }));
  };

  const onChangeSelectProduct = (value) => {
    setFilter((prev) => ({ ...prev, products: value }));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={style.container_listCampus}>
      <Col span={24}>
        <Row gutter={[20, 20]} justify="end">
          <RangePicker onChange={onPickRangeDate} />
          <Select
            className="w-[300px] mx-2"
            mode="multiple"
            placeholder="Select products"
            options={PRODUCT_OPTIONS}
            onChange={onChangeSelectProduct}
          />
          <Col span={8}>
            <Search
              onSearch={onSearch}
              placeholder="Search by username, email, full name, talksam ID"
              allowClear
              enterButton
              disabled={isLoading}
            />
          </Col>
          <Button
            loading={isLoadingExport}
            type="primary"
            className="bg-[#4E62EB]"
            onClick={exportData}>
            Download excel
          </Button>
          <Col span={24}>
            <TableDataPointManager
              isLoading={isLoading}
              dataUsers={formatGroupByEmail(filterData)}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default PointManager;
