import React, { useEffect, useMemo, useState } from "react";
import bg from "../../assets/artwork/bg.png";

import classNames from "classnames";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import WingLoading from "../../components/Loading/WingLoading";
import { APIService } from "../../services/apiService";
import BackButton from "./components/BackButton";
import ListCourses from "./components/ListCourses";
import ListItems from "./components/ListItems";
import PopupItem from "./components/PopupItem";

const CustomArtwork = () => {
  const navigate = useNavigate();
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [indexSelectedCourse, setIndexSelectedCourse] = useState(0);
  const [cookies] = useCookies(["access_token"]);
  const [listArtworks, setListArtworks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedCourse = useMemo(
    () => listArtworks.length > 0 && listArtworks[indexSelectedCourse],
    [indexSelectedCourse, listArtworks]
  );

  useEffect(() => {
    const accessToken = cookies["access_token"];
    setIsLoading(true);
    APIService.get_all_artwork(accessToken)
      .then((response) => {
        const res = response.data;
        let formatListArtworks = [];
        res.forEach((item) => {
          const { course_name, lesson_id, lesson_name, link_artwork } = item;
          const index = formatListArtworks.findIndex(
            (i) => i.course_name === course_name
          );
          // console.log("isExisted", isExisted);
          if (index === -1) {
            formatListArtworks.push({
              course_name,
              lessons: [{ lesson_id, lesson_name, link_artwork }],
            });
            return;
          }
          formatListArtworks[index]?.lessons?.push({
            lesson_id,
            lesson_name,
            link_artwork,
          });
        });
        formatListArtworks = formatListArtworks.map((i) => ({
          ...i,
          lessons: [
            ...new Map(
              i.lessons.map((item) => [item["lesson_id"], item])
            ).values(),
          ],
        }));
        setListArtworks(formatListArtworks);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [cookies]);

  const handleSelectCourse = (index) => {
    setIndexSelectedCourse(index);
  };

  const actionButton = () => {
    if (!selectedLesson) navigate("/e-mybook");
    setSelectedLesson(null);
  };

  const handleSelectLesson = (data) => {
    setSelectedLesson(data);
  };

  return (
    <div className="relative w-full h-[calc(100vh-4rem)] lg:h-[calc(100vh-5rem)] overflow-y-auto">
      <img
        src={bg}
        alt="bg"
        className="fixed w-full h-full top-0 left-0 object-cover object-bottom"
      />
      <BackButton action={actionButton} />
      <div className="relative w-full h-full pb-20">
        {isLoading && <WingLoading />}
        {!isLoading && listArtworks.length > 0 && (
          <>
            <div
              className={classNames(
                " relative 2xl:max-w-6xl lg:max-w-3xl mx-auto grid grid-cols-2 gap-2 lg:mt-20 font-jetBrains",
                "md:mt-40 md:max-w-2xl"
              )}>
              <ListCourses
                handleSelectCourse={handleSelectCourse}
                selectedLesson={selectedLesson}
                indexSelectedCourse={indexSelectedCourse}
                listArtworks={listArtworks}
              />
              <div className="w-full flex justify-end">
                <ListItems
                  listArtworks={listArtworks}
                  handleSelectLesson={handleSelectLesson}
                  selectedLesson={selectedLesson}
                  indexSelectedCourse={indexSelectedCourse}
                  selectedCourse={selectedCourse}
                />
              </div>
            </div>
            <PopupItem selectedLesson={selectedLesson} />
          </>
        )}
        {!isLoading && listArtworks.length === 0 && (
          <p className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white p-6 text-gray-900 text-3xl font-bold rounded-md">
            No artwork available
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomArtwork;
