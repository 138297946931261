import React, { useState } from "react";
import StudyRoom from "./UserPage";
import WritingCenterPage from "./WritingCenterPage";
import { useNavigate } from "react-router";

const MyPage = () => {
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [showUserPage, setShowUserPage] = useState(true);
  const navigate = useNavigate();

  const handleLessonId = (lessonId) => {
    setSelectedLessonId(lessonId);
    setShowUserPage(false);
    navigate(`/writing-center/${lessonId}`);
  };

  return (
    <>
      {showUserPage && <StudyRoom handleLessonId={handleLessonId} />}
      {!showUserPage && (
        <WritingCenterPage selectedLessonId={selectedLessonId} />
      )}
    </>
  );
};

export default MyPage;
