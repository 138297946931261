import { LeftOutlined } from "@ant-design/icons";
import { Button, Modal, Switch } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import sticker1 from "../../../../../../assets/sticker/E3 3.png";
import sticker2 from "../../../../../../assets/sticker/E3 4.png";
import sticker3 from "../../../../../../assets/sticker/E4 3.png";
import sticker4 from "../../../../../../assets/sticker/E4 4.png";
import sticker5 from "../../../../../../assets/sticker/E5 3.png";
import sticker6 from "../../../../../../assets/sticker/E5 4.png";
import sticker7 from "../../../../../../assets/sticker/E6 3.png";
import sticker8 from "../../../../../../assets/sticker/E6 4.png";
import sticker9 from "../../../../../../assets/sticker/E7 3.png";
import sticker10 from "../../../../../../assets/sticker/E7 4.png";
import sticker11 from "../../../../../../assets/sticker/E8 3.png";
import sticker12 from "../../../../../../assets/sticker/E8 4.png";
import templateBottomLeft from "../../../../../../assets/template/bottom left.png";
import templateBottomRight from "../../../../../../assets/template/bottom right.png";
import templateMiddle from "../../../../../../assets/template/middle.png";
import templateTopLeft from "../../../../../../assets/template/top left.png";
import templateTopRight from "../../../../../../assets/template/top right.png";
import templateTop from "../../../../../../assets/template/top.png";
import { setImageStableDiffusion } from "../../../../../../redux/lessionSlice";
import { APIService } from "../../../../../../services/apiService";
import DraggableDiv from "./component/DraggableDiv";
import ParagraphSplitter from "./component/ParagraphSplitter";
import style from "./style.module.css";

function ParentDiv({ onPrev, imageData }) {
  const dispatch = useDispatch();
  const parentRef = useRef();
  const { selectedImage } = imageData;
  const [stickers, setStickers] = useState([]);
  const [cookies] = useCookies(["access_token"]);
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Save and Export as PNG");

  const showModal = () => {
    setOpen(true);
  };

  const handleFinish = () => {
    setOpen(false);
    // toBase64(imageData.selectedImage)
    toBase64(imageData)
      .then((dataUrl) => {
        const img_main = document.getElementById("img_main");
        img_main.src = dataUrl;
      })
      .catch((e) => {
        console.error("Failed to encode image:", e);
      });
    const input = document.getElementById("ebook-save");
    const targetWidth = 700;
    const targetHeight = 1000;
    html2canvas(input, {
      useCORS: true,
    }).then((canvas) => {
      const scaledCanvas = document.createElement("canvas");
      const scaledContext = scaledCanvas.getContext("2d");

      scaledCanvas.width = targetWidth;
      scaledCanvas.height = targetHeight;

      scaledContext.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        targetWidth,
        targetHeight
      );
      const imgData = scaledCanvas.toDataURL("image/png");

      const b = base64ToBlob(imgData);
      let formData = new FormData();
      formData.append("file", b, "image.png");
      formData.append("lesson_id", lesson.lessonId);
      formData.append("number_img", lesson?.number_img ?? 1);
      formData.append("image_stable_diffusion", lesson.image_stable_diffusion);
      APIService.upload_artwork(formData, cookies["access_token"])
        .then((response) => {
          dispatch(setImageStableDiffusion([]));
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const parentDiv = parentRef.current;
    if (parentDiv) {
      parentRef.current = parentDiv;
    }
  }, []);

  const addSticker = (selectSticker) => {
    const newSticker = (
      <DraggableDiv key={stickers.length}>
        <img
          src={selectSticker}
          alt="Sticker"
          className="sticker"
          style={{ position: "absolute", opacity: "0.5", width: "15%" }}
        />
      </DraggableDiv>
    );
    if (stickers.length === 0) {
      setStickers([newSticker]);
    } else {
      setStickers([...stickers, newSticker]);
    }
  };

  function toBase64(url) {
    return axios.get(url, { responseType: "blob" }).then((response) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(response.data);
      });
    });
  }
  function base64ToBlob(base64) {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/png" });
  }

  const saveToImage = () => {
    setOpen(false);

    toBase64(imageData.selectedImage)
      // toBase64(imageData)
      .then((dataUrl) => {
        const img_main = document.getElementById("img_main");
        img_main.src = dataUrl;
      })
      .catch((e) => {
        console.error("Failed to encode image:", e);
      });
    const input = document.getElementById("ebook-save");
    const targetWidth = 700;
    const targetHeight = 1000;
    html2canvas(input, {
      useCORS: true,
    }).then((canvas) => {
      const scaledCanvas = document.createElement("canvas");
      const scaledContext = scaledCanvas.getContext("2d");

      scaledCanvas.width = targetWidth;
      scaledCanvas.height = targetHeight;

      scaledContext.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        targetWidth,
        targetHeight
      );
      const imgData = scaledCanvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.setAttribute("download", "screenshot.png");
      link.setAttribute("href", imgData);
      link.click();
    });
  };

  const [imgPosition, setImgPosition] = useState(1);

  function handleTemplatePositionChange(position) {
    setImgPosition(position);
  }
  const [isSquare, setIsSquare] = useState(true);
  const [rectangle, setRectangle] = useState("45%");
  const [rectangleSize, setRectangleSize] = useState("10px");
  const handleToggleClick = () => {
    setIsSquare(!isSquare);
    setRectangle(isSquare ? "80%" : "45%");
    setRectangleSize(!isSquare ? "10px" : "14px");
  };
  const ButtonGroup = Button.Group;
  function handlePrev() {
    onPrev(false);
  }
  const customOkButtonProps = {
    className: "custom-ok-button",
  };

  return (
    <>
      <div className={style.group_button}>
        <Modal
          title=""
          open={open}
          onOk={saveToImage}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          okButtonProps={customOkButtonProps}
          okText="Export"
        >
          <p>{modalText}</p>
        </Modal>
        <div>
          <Button className={style.btn_Return} onClick={handlePrev}>
            <LeftOutlined />
            RETURN
          </Button>
        </div>
        <div>
          <ButtonGroup className="group_button">
            <Button
              style={{
                borderRadius: "40px",
                background: "#ffffff",
                color: "#001CFF",
                border: "1px solid #333",
              }}
              className={style.btn_Download}
              onClick={showModal}
            >
              DOWNLOAD
            </Button>
            <Button className={style.btn_Dashboard} onClick={handleFinish}>
              SAVE
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className={style.container_dragable}>
        <div className={style.content_leftDragable}>
          <h2 style={{ color: "#2D45D3", textAlign: "center" }}>
            CHOOSE TEMPLATE
          </h2>

          <div className={style.btn_choseTemplates}>
            <Switch
              checkedChildren="Horizontal"
              unCheckedChildren="Vertical"
              defaultChecked
              onClick={handleToggleClick}
              className={style.btn_switch_template}
            />
          </div>
          <div className={style.content_templates}>
            <div
              onClick={() => handleTemplatePositionChange(0)}
              className={style.item_template}
            >
              <img
                src={templateTop}
                alt="Template Top"
                className={style.img_template}
              />
            </div>
            <div
              onClick={() => handleTemplatePositionChange(3)}
              className={style.item_template}
            >
              <img
                src={templateMiddle}
                alt="Template Middle"
                className={style.img_template}
              />
            </div>

            <div
              onClick={() => handleTemplatePositionChange(1)}
              className={style.item_template}
            >
              <img
                src={templateTopLeft}
                alt="Template Middle"
                className={style.img_template}
              />
            </div>
            <div
              onClick={() => handleTemplatePositionChange(5)}
              className={style.item_template}
            >
              <img
                src={templateBottomRight}
                alt="Template Middle"
                className={style.img_template}
              />
            </div>
            <div
              onClick={() => handleTemplatePositionChange(2)}
              className={style.item_template}
            >
              <img
                src={templateTopRight}
                alt="Template Middle"
                className={style.img_template}
              />
            </div>
            <div
              onClick={() => handleTemplatePositionChange(4)}
              className={style.item_template}
            >
              <img
                src={templateBottomLeft}
                alt="Template Middle"
                className={style.img_template}
              />
            </div>
          </div>
        </div>
        <div className={style.content_ebook}>
          <div id="ebook-save" className={style.ebook_save} ref={parentRef}>
            <div
              className={` ${
                isSquare ? style.ebook_square : style.ebook_rectangle
              }`}
            >
              {/* <p className={style.course_name}>{lesson.course_name}</p> */}
              <p className={style.lesson_name}>
                {lesson.writing_title ?? lesson.lesson_name}
              </p>
              {/* <p className={style.topic}>{lesson.topic}</p> */}
              <span>
                {selectedImage ? (
                  <ParagraphSplitter
                    text={
                      _.get(lesson, "content_self_editing", "") ||
                      _.get(lesson, "history.essay_self_edited", "")
                    }
                    img={selectedImage}
                    imgPosition={imgPosition}
                  />
                ) : null}
              </span>
              <div className="relative">{stickers}</div>
            </div>
          </div>
        </div>
        <div className={style.content_stickerPet}>
          <div>
            <h2>ADD STICKER</h2>
          </div>
          <div className={style.list_stickerPet}>
            <img
              src={sticker1}
              alt="Sticker"
              onClick={() => addSticker(sticker1)}
            />

            <img
              src={sticker2}
              alt="Sticker"
              onClick={() => addSticker(sticker2)}
            />

            <img
              src={sticker3}
              alt="Sticker"
              onClick={() => addSticker(sticker3)}
            />

            <img
              src={sticker4}
              alt="Sticker"
              onClick={() => addSticker(sticker4)}
            />

            <img
              src={sticker5}
              alt="Sticker"
              onClick={() => addSticker(sticker5)}
            />

            <img
              src={sticker6}
              alt="Sticker"
              onClick={() => addSticker(sticker6)}
            />

            <img
              src={sticker7}
              alt="Sticker"
              onClick={() => addSticker(sticker7)}
            />

            <img
              src={sticker8}
              alt="Sticker"
              onClick={() => addSticker(sticker8)}
            />

            <img
              src={sticker9}
              alt="Sticker"
              onClick={() => addSticker(sticker9)}
            />

            <img
              src={sticker10}
              alt="Sticker"
              onClick={() => addSticker(sticker10)}
            />

            <img
              src={sticker11}
              alt="Sticker"
              onClick={() => addSticker(sticker11)}
            />

            <img
              src={sticker12}
              alt="Sticker"
              onClick={() => addSticker(sticker12)}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}></div>
    </>
  );
}

export default ParentDiv;
