import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../assets/logo/logo_wing.png";
import Button from "../../../components/Button";
import User from "../../../components/User";
import LoginPage from "../../../pages/LoginPage";
import { setCampus, setCountry } from "../../../redux/adminSlice";
import { APIService } from "../../../services/apiService";
import "./index.css";

const Mainheader = () => {
  const user = useSelector((state) => state.userSlice.user);
  const refetch = useSelector((state) => state.adminSlice.refetch);
  console.log("refetch", refetch);
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const handleOpenPopup = () => {
    setPopup(!popup);
  };
  const [cookies] = useCookies(["access_token"]);
  useEffect(() => {
    APIService.get_all_campus(cookies["access_token"])
      .then((response) => {
        if (response && response.status_code === 200) {
          dispatch(
            setCampus({
              campus_list: response.data,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    APIService.get_all_country()
      .then((response) => {
        if (response && response.status_code === 200) {
          dispatch(
            setCountry({
              country_list: response.data,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refetch]);
  return (
    <>
      <div className="navbar hidden lg:flex bg-white sticky top-0 justify-around items-center rounded-b-2xl h-20 !z-0">
        <Link to="/">
          <div className="h-20 flex justify-center items-center">
            <img className="h-5/6 object-cover" src={logo} alt="logo" />
          </div>
        </Link>
        <div className="flex gap-4">
          {user.level !== "Campus" && user.level !== "Administrator" && <></>}
          <NavLink className="element_menu" to="/our-service">
            Our Service
          </NavLink>
          <NavLink className="element_menu" to="/curriculum">
            Curriculum
          </NavLink>
          <NavLink className="element_menu" to="/notice">
            Notice
          </NavLink>
          <NavLink className="element_menu" to="/ranking">
            Ranking
          </NavLink>
          <NavLink className="element_menu" to="/e-book">
            E-Book
          </NavLink>
          <NavLink className="element_menu" to="/inquiry">
            Inquiry
          </NavLink>
          {cookies["access_token"] && (
            <NavLink className="element_menu" to="/dashboard">
              DashBoard
            </NavLink>
          )}
        </div>
        <div className="navbar_btnlogin gap-2">
          {!cookies["access_token"] ? (
            <>
              <Button className="element_menu" to="/register" text="Register" />
              <Button className="element_menu" to="/login" text="Login" />
            </>
          ) : (
            <>
              <User
                alt="avatar"
                className="avatar_user"
                handle={handleOpenPopup}
                state={popup}
              />
              <LoginPage />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Mainheader;
