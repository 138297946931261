import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import icon_feedbackEvaluation from "../../../../assets/Lion.png";
import BrainStorming from "./BrainStorming";
import Drafting from "./Drafting";
import Feedback from "./Feedback";
import SelfEditing from "./SelfEditing";
import StableDiffusionAndSticker from "./StableDiffusion/index.jsx";
import MyStepBar from "./component/Progressbar";
import styles from "./style.module.css";

import { Modal, notification } from "antd";
import { useCookies } from "react-cookie";
import IconNext from "../../../../assets/avatar/icon_button.png";
import IconSaved from "../../../../assets/iconButton/Saved.png";
import IconSubmited from "../../../../assets/iconButton/Submited.png";
import { Notification } from "../../../../components/Notification/index";
import Popup from "../../../../components/Popup";
import { connect } from "../../../../redux/wsSlice";
import { APIService } from "../../../../services/apiService";

import { useSelector } from "react-redux";
import {
  setIsLoading,
  setLessonInfo,
  setSelfEditing,
  setStatusRender,
  setStatusWriting,
  setSuggestionArray,
} from "../../../../redux/lessionSlice";

import { useDispatch } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
import TopicWriting from "../../../../components/TopicWritingCenter/TopicWriting";
import { setFinalSuggestion } from "../../../../redux/lessionSlice";
import { findDifference, replaceWord } from "../../../../utils/index.js";
import "./custom-notification.css";
const buttonWidth = { width: "20%" };

const WritingCenterPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const message = useSelector((state) => state.webSocketSlice.message);
  let currentStatus = useSelector((state) => state.lessionSlice?.lesson.status);
  const { lessonId } = useParams();
  const lessonIdNumber = parseInt(lessonId);
  const [currentComponent, setCurrentComponent] = useState(1);
  const [writingToStable, setWritingToStable] = useState(false);
  const [backtoWriting, setBackToWriting] = useState(true);
  const [isPassConditionEssay, setIsPassConditionEssay] = useState(false);
  const [cookies] = useCookies(["access_token"]);
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const chat_history = useSelector((state) => state.chatSlice.conversation);
  const [popupBook, setPopupBook] = useState(false);
  const [saveSelfEditing, setSaveSelfEditing] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [pointStarts, setPoinStarts] = useState({});
  const [linkPlagiarism, setLinkPlagiarism] = useState([]);
  const [isPlagiarism, setIsPlagiarism] = useState(false);
  const [checkWords, setCheckWords] = useState(false);
  const navigate = useNavigate();
  const [type, setType] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const showModal = () => {
    setIsModalOpen(true);
    updateIsloading(false);
  };

  useEffect(() => {
    if (!lesson?.lessonId) {
      dispatch(
        setLessonInfo(JSON.parse(localStorage.getItem("setLessonInfo")))
      );
    }
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleNext = () => {
    if (currentComponent + 1 === 2) {
      onSaveChatHistory();
    }
    setCurrentComponent((prevComponent) => prevComponent + 1);
    setType(type + 1);
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement) => {
    api.info({
      message: `Notification `,
      description:
        "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      placement,
      className: "custom-notification",
    });
  };
  useEffect(() => {
    if (type === 1) {
      APIService.update_status_writing_center(
        {
          lesson_id: lessonIdNumber,
          status: "Brain Storming",
        },
        cookies["access_token"]
      )
        .then((res) => {
          dispatch(setStatusWriting({ status: "Brain Storming" }));
        })
        .catch((err) => {});
    } else if (type === 2) {
      APIService.update_status_writing_center(
        {
          lesson_id: lessonIdNumber,
          status: "Writing",
        },
        cookies["access_token"]
      )
        .then((res) => {
          dispatch(setStatusWriting({ status: "Writing" }));
        })
        .catch((err) => {});
    } else if (type === 3) {
      APIService.update_status_writing_center(
        {
          lesson_id: lessonIdNumber,
          status: "Revising Complete & Evaluation",
        },
        cookies["access_token"]
      )
        .then((res) => {
          dispatch(
            setStatusWriting({ status: "Revising Complete & Evaluation" })
          );
        })
        .catch((err) => {});
    } else if (type === 4) {
      APIService.update_status_writing_center(
        {
          lesson_id: lessonIdNumber,
          status: "Self-editing",
        },
        cookies["access_token"]
      )
        .then((res) => {
          dispatch(setStatusWriting({ status: "Self-editing" }));
        })
        .catch((err) => {});
    }
  }, [type]);

  // useEffect(() => {
  //   if (lesson.status) {
  //     switch (lesson.status) {
  //       case "Self-editing" || "Submited":
  //         setType(4);
  //         setCurrentComponent(4);
  //         break;
  //       case "Revising Complete & Evaluation":
  //         break;
  //       case "Writing":
  //         setType(2);
  //         break;

  //       default:
  //         setType(1);
  //         break;
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   setStatus(lesson.status);
  // }, [lesson]);
  // console.log("currentStatus", currentStatus);
  useEffect(() => {
    console.log("lesson.status", lesson.status);
    // if (type !== -1) return;
    switch (lesson.status) {
      case "Self-editing":
        setCurrentComponent(4);
        setType(4);
        break;
      case "Saved":
        navigate("/e-mybook/artwork");
        setCurrentComponent(4);
        setType(4);
        break;
      case "Revising Complete & Evaluation":
        setCurrentComponent(3);
        setType(3);
        break;
      case "Writing":
        setCurrentComponent(2);
        setType(2);
        break;
      case "Almost Over":
        setCurrentComponent(5);
        setType(5);
        break;
      default:
        setCurrentComponent(1);
        setType(1);
        break;
    }
  }, [lesson.status]);

  // useEffect(() => {
  //   console.log("type", type);
  //   console.log(currentStatus);
  // }, [type]);

  const handleSubmit = () => {
    const textarea = document.getElementById("notebook-textarea");
    const inputValue = textarea ? textarea.value : "";

    if (inputValue.trim() === "") {
      // openNotification("topRight");
      // Notification("warning", `Content less than ${lesson.minimum_words}`);
    } else {
      if (currentComponent + 1 === 2) {
        onSaveChatHistory();
      }
      setCurrentComponent((prevComponent) => prevComponent + 1);
    }
  };

  const handleBack = () => {
    setCurrentComponent((prevComponent) => prevComponent - 1);
    setType(type - 1);
    if (currentComponent === 1) {
      navigate("/study-room");
    }
  };
  const handleBackInEditArtWork = () => {
    console.log("currentComponent", currentComponent);
    setCurrentComponent((prevComponent) => prevComponent - 1);
    // setType(type - 1);
    // if (currentComponent === 1) {
    //   navigate("/study-room");
    // }
  };
  const updateIsloading = (value) => {
    setIsLoadingData(value);
  };
  const updateIsPlagiarism = (value) => {
    setIsPlagiarism(value);
  };
  // useEffect(() => {
  //   if (message) {
  //     updateIsPlagiarism(false);
  //     const mess = JSON.parse(message);
  //     if (parseInt(mess.client_id) === user.id && mess.is_pligiarism) {
  //       updateIsPlagiarism(true);
  //       setLinkPlagiarism([
  //         {
  //           title: mess.documents[0].title,
  //           introduction: mess.documents[0].introduction,
  //           url: mess.documents[0].url,
  //         },
  //       ]);

  //       showModal();
  //     }
  //   }
  // }, [message]);
  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (lesson.history?.essay_original.length > 0) {
      setIsSubmited(true);
      handleoadhistory();
    }
    if (lesson.history?.essay_self_editing) {
      setSaveSelfEditing(true);
    }
  }, [lesson.history]);

  const handleoadhistory = () => {
    if (!lesson.history) return;
    const response = lesson.history.suggestion;
    let arrSuggestion = [];
    const getpoint = lesson.history.evaluation;
    const final_suggestion = lesson.history.final_suggestion;
    dispatch(
      setFinalSuggestion({
        final_suggestion: final_suggestion,
      })
    );
    setPoinStarts(getpoint);
    const pattern = /(\*\*--(.*?)--\*\*)/gi;
    let old_text = lesson.history.essay_original;
    const isArray = Array.isArray(response?.sentences);
    let resultArray = replaceFunc(
      old_text,
      isArray ? response?.sentences : [],
      "error"
    );
    let resultArray1 = replaceFunc(
      old_text,
      response?.vocabulary,
      "improvement"
    );
    arrSuggestion.push(resultArray);
    arrSuggestion.push(resultArray1);
    dispatch(setStatusRender(true));
    dispatch(
      setSuggestionArray({
        arrSuggestion: arrSuggestion,
      })
    );
  };

  const onSaveChatHistory = async () => {
    const response = await APIService.add_chat_history(
      {
        lesson_id: lesson.lessonId,
        history: chat_history,
        id: lesson.lessonId,
      },
      cookies["access_token"]
    );
  };
  const onSaveStage = async () => {
    if (lesson.content_self_editing.trim() === "") {
      Notification("warning", `Content must be not empty`);
      return;
    }
    APIService.save_stage_writing_center(
      {
        writing_title: lesson.writing_title,
        lesson_id: lesson.lessonId,
        essay_original: lesson.content_original,
        essay_self_edited: lesson.content_self_editing,
      },
      cookies["access_token"]
    ).then((response) => {
      setSaveSelfEditing(true);
    });
  };
  const onCheckOriginal = () => {
    if (
      lesson.content_original.trim().split(/\s+/).length < lesson.minimum_words
    ) {
      Modal.error({
        title: `Content must be more than ${lesson.minimum_words} words`,
      });

      return false;
    }

    // if (lesson.content_original.split(" ").length > lesson.maximum_words) {
    //   Notification(
    //     "warning",
    //     `Content must be less than ${lesson.maximum_words} words`
    //   );
    //   return false;
    // }
    // console.log(
    //   "countNewLines(lesson.content_original)",
    //   countNewLines(lesson.content_original)
    // );
    // if (countNewLines(lesson.content_original) !== lesson.paragraph) {
    //   Notification(
    //     "warning",
    //     `Content must have ${lesson.paragraph} ${
    //       lesson.paragraph === 1 ? "paragraph" : "paragraphs"
    //     } `
    //   );
    //   return false;
    // }

    return true;
  };
  const onSubmit = async () => {
    if (!onCheckOriginal() || isSubmited) return;
    dispatch(
      setIsLoading({
        is_loading: true,
      })
    );
    await sendRequestSuggestion();
  };

  useEffect(() => {
    if (currentComponent > 4) {
      setWritingToStable(true);
    } else {
      setWritingToStable(false);
    }
  }, [currentComponent]);
  useEffect(() => {
    if (backtoWriting) {
    } else {
      setCurrentComponent(4);
    }
  }, [backtoWriting]);

  function replaceCaseInsensitive(originalString, search, replace) {
    var regex = new RegExp("\\W+" + search + "\\W+", "gi");
    var modifiedString = originalString.replace(regex, function (match) {
      // Check if the match is the same as the replacement or contains '#${search}#'
      if (match.includes(`#${search}#`)) {
        return match;
      } else {
        return replace;
      }
    });
    return modifiedString;
  }

  const replaceFunc = (_text, errorArray, type) => {
    let text = _text.replace(/"/g, "");
    let lowerCaseText = text.toLowerCase().replace(/[^\w\s]/gi, "");
    errorArray?.forEach((item) => {
      const initKey = Object.keys(item)[0];
      const lowerCaseKey = Object.keys(item)[0]
        ?.toLowerCase()
        .replace(/[^\w\s]/gi, "");
      let value = item[initKey].split("\n\n")[0];
      const differenceArr = findDifference(initKey, value);
      const filterDifferenceArr = differenceArr.filter(
        (i) =>
          i.added === undefined &&
          i.removed === true &&
          i.value !== "," &&
          i.value !== "."
      );

      if (lowerCaseText.includes(lowerCaseKey) && type === "improvement") {
        text = text.replace(
          initKey,
          `***${initKey.split(" ").join("~")}#${value.split(" ").join("~")}***`
        );
      }

      if (
        lowerCaseText.includes(lowerCaseKey) &&
        !value.includes("(No correction needed)") &&
        type === "error"
      ) {
        let newInitKey = initKey;

        if (filterDifferenceArr.length !== 0) {
          filterDifferenceArr.forEach((item) => {
            newInitKey = replaceWord(
              newInitKey,
              item.value,
              `<span style="color:red">${item.value}</span>`
            );
          });
        }

        text = text.replace(
          initKey,
          `***${newInitKey.split(" ").join("~")}#${value
            .split(" ")
            .join("~")}***`
        );
      }
    });

    text = text.replace(/~/g, " ");
    const segments = text.split(/(\*\*\*.*?\*\*\*|\b#\w+\b)/);
    const filteredSegments = segments.filter(
      (segment) => segment.trim() !== ""
    );

    console.log("filteredSegments", filteredSegments);
    return filteredSegments;
  };

  const sendRequestSuggestion = async () => {
    setIsLoadingData(true);
    if (
      lesson.suggestionArray === undefined ||
      lesson.suggestionArray.length === 0
    ) {
      APIService.suggestion(
        {
          title: lesson.writing_title,
          content: lesson.content_original,
          lesson_id: lesson.lessonId,
          is_sandbox: false,
          time_writing: lesson.time_writing,
        },
        cookies["access_token"]
      )
        .then((res) => {
          // if (isPlagiarism) return;
          if (_.get(res, "data.suggestion.vocabulary", [])?.length > 0) {
            const response = res.data.suggestion;
            let arrSuggestion = [];
            const getpoint = res.data.evaluation;
            const final_suggestion = res.data.final_suggestion;
            console.log("final_suggestion", final_suggestion);
            dispatch(
              setFinalSuggestion({
                final_suggestion: final_suggestion,
              })
            );
            setPoinStarts(getpoint);
            const pattern = /(\*\*--(.*?)--\*\*)/gi;
            let old_text = lesson.content_original;
            let hande_arr = [];

            //new
            const isArray = Array.isArray(response?.sentences);
            if (!isArray && response?.sentences.includes("off topic")) {
              Modal.error({
                title: response?.sentences,
              });
              // Notification("error", response?.sentences);
              setIsLoadingData(false);
              dispatch(
                setIsLoading({
                  is_loading: false,
                })
              );
              return;
            }
            if (!isArray) Notification("success", response?.sentences);
            let resultArray = replaceFunc(
              old_text,
              isArray ? response?.sentences : [],
              "error"
            );

            let resultArray1 = replaceFunc(
              old_text,
              response?.vocabulary,
              "improvement"
            );

            const dbrr = resultArray.filter((item) => item?.includes("***"));
            const dbrr2 = resultArray1.filter((item) => item?.includes("***"));

            if (dbrr2?.length > 0) {
              arrSuggestion.push(resultArray);
              arrSuggestion.push(resultArray1);

              //new

              dispatch(setStatusRender(true));

              dispatch(
                setSuggestionArray({
                  arrSuggestion: arrSuggestion,
                })
              );

              dispatch(
                setIsLoading({
                  is_loading: false,
                })
              );

              dispatch(setSelfEditing(lesson.content_original));
              APIService.save_stage_writing_center(
                {
                  writing_title: lesson.writing_title,
                  lesson_id: lesson.lessonId,
                  essay_original: lesson.content_original,
                },
                cookies["access_token"]
              ).then((response) => {
                setIsSubmited(true);
              });
            } else {
              Modal.error({
                title:
                  "OpenAI power outage or the essay is off topic, please rewrite!",
              });
              setIsLoadingData(false);
              dispatch(
                setIsLoading({
                  is_loading: false,
                })
              );
            }
          } else {
            Modal.error({
              title:
                "OpenAI power outage or the essay is off topic, please rewrite!",
            });
            setIsLoadingData(false);
            dispatch(
              setIsLoading({
                is_loading: false,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setIsLoading({
              is_loading: false,
            })
          );
        });
    } else {
      dispatch(setStatusRender(true));
      dispatch(
        setIsLoading({
          is_loading: false,
        })
      );
    }
    setIsLoadingData(false);
  };

  return (
    <>
      {contextHolder}
      {/* <Modal
        title="plagiarism detection"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className="relative w-full" style={{ right: "4%" }}>
            <button
              style={buttonWidth}
              key={"btnOk"}
              onClick={() => handleOk()}
              className="px-3 py-1 my-4 font-semibold border rounded border-transparent bg-cyan-300 text-gray-50 hover:text-gray-50 hover:bg-cyan-200 duration-300"
            >
              Ok
            </button>
            <button
              style={buttonWidth}
              key={"btnCancel"}
              onClick={() => handleCancel}
              className="px-3 py-1 my-4 font-semibold border rounded border-transparent bg-cyan-300 text-gray-50 hover:text-gray-50 hover:bg-cyan-200 duration-300 ml-2"
            >
              Cancel
            </button>
          </div>,
        ]}
      >
        {linkPlagiarism.map((item) => {
          return (
            <>
              <p>
                {item.title} - {item.introduction} -{" "}
                <a href={item.url} target="_blank">
                  Link reference
                </a>
              </p>
            </>
          );
        })}
      </Modal> */}

      <div>
        {lessonIdNumber === lesson.lessonId ? (
          <>
            {!writingToStable ? (
              <div className={styles.containerStepbar}>
                {currentComponent > 0 && currentComponent < 5 && (
                  <MyStepBar currentComponent={currentComponent} />
                )}

                <div className={styles.container}>
                  {currentComponent !== 1 && (
                    <>
                      <TopicWriting
                        type={type}
                        setPopupBook={setPopupBook}
                        pointStarts={pointStarts}
                      />
                    </>
                  )}
                  {type !== 1 && (
                    <div className={styles.content_topic_desktop}>
                      <h1 className={styles.content_title}>TOPIC</h1>
                      <p className={styles.box_title}>{lesson.topic}</p>
                    </div>
                  )}
                  <div className={styles.content_button}>
                    <div className={styles.group_btn}>
                      <div className={styles.sub_group_btn}>
                        {currentComponent !== 3 && (
                          <button
                            onClick={handleBack}
                            className={styles.button_writing_center}
                          >
                            BACK
                          </button>
                        )}
                      </div>
                    </div>

                    <div className={styles.group_btn}>
                      {currentComponent === 4 ? (
                        <>
                          <div className={styles.sub_group_btn}>
                            {!saveSelfEditing &&
                              (!lesson.history ||
                                !lesson.history?.essay_self_edited) && (
                                <>
                                  <img
                                    src={IconSaved}
                                    className={styles.btnAvatar}
                                    alt=""
                                    srcset=""
                                  />
                                  <button
                                    onClick={() => {
                                      onSaveStage();
                                    }}
                                    className={styles.button_writing_center}
                                  >
                                    SAVE
                                  </button>
                                </>
                              )}
                          </div>
                          {saveSelfEditing ||
                          lesson.history?.essay_self_edited ? (
                            <div className={styles.sub_group_btn}>
                              <img
                                src={IconSubmited}
                                className={styles.btnAvatar}
                                alt=""
                                srcset=""
                              />
                              <button
                                onClick={() => {
                                  handleSubmit();
                                  onSaveStage();
                                }}
                                className={styles.button_writing_center}
                                style={{ backgroundColor: "#FE998D" }}
                              >
                                CONTINUE
                              </button>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                      {currentComponent === 2 ? (
                        <>
                          <div className={styles.sub_group_btn}>
                            {isSubmited ? null : (
                              <>
                                <img
                                  src={IconSubmited}
                                  className={styles.btnAvatar}
                                  alt=""
                                  srcset=""
                                />
                                <button
                                  onClick={onSubmit}
                                  className={styles.button_writing_center}
                                >
                                  Submit
                                </button>
                              </>
                            )}
                          </div>
                          {isSubmited ? (
                            <>
                              <div className={styles.sub_group_btn}>
                                <img
                                  src={IconNext}
                                  className={styles.btnAvatar}
                                  alt=""
                                  srcset=""
                                />
                                <button
                                  onClick={handleNext}
                                  className={
                                    styles.button_writing_center_nextSuggestion
                                  }
                                  style={{ backgroundColor: "#FE998D" }}
                                >
                                  CONTINUE
                                </button>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      {currentComponent === 1 ? (
                        <div className={styles.sub_group_btn}>
                          <img
                            src={IconNext}
                            className={styles.btnAvatar}
                            alt=""
                            srcset=""
                          />
                          <button
                            onClick={handleNext}
                            className={styles.button_writing_center}
                          >
                            CONTINUE
                          </button>
                        </div>
                      ) : null}
                      {currentComponent === 3 ? (
                        <div className={styles.sub_group_btn}>
                          <img
                            src={IconNext}
                            className={styles.btnAvatar}
                            alt=""
                            srcset=""
                          />
                          <button
                            onClick={handleNext}
                            className={styles.button_writing_center}
                          >
                            CONTINUE
                          </button>
                        </div>
                      ) : null}

                      {currentComponent === 2 ? (
                        isPassConditionEssay ? (
                          <div className={styles.sub_group_btn}>
                            <img
                              src={IconSubmited}
                              className={styles.btnAvatar}
                              alt=""
                              srcset=""
                            />
                            <button
                              onClick={handleNext}
                              className={styles.button_writing_center}
                            >
                              CONTINUE
                            </button>
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.contents}>
                    {currentComponent === 1 && <BrainStorming />}
                    {currentComponent === 2 && (
                      <>
                        {isLoadingData ? <Loading></Loading> : null}
                        <Drafting
                          checkWords={checkWords}
                          setPopupBook={setPopupBook}
                          setSumited={setIsSubmited}
                        />
                      </>
                    )}
                    {currentComponent === 3 && (
                      <Feedback pointStarts={pointStarts} />
                    )}
                    {currentComponent === 4 && <SelfEditing />}
                  </div>

                  {currentComponent === 3 && (
                    <div
                      className={`${styles.content_feedbackEvaluation} h-[30%] bottom-[20%] lg:h-[50%] lg:bottom:[30%] flex justify-center items-center`}
                    >
                      <img
                        className={styles.icon_feedbackEvaluation}
                        src={icon_feedbackEvaluation}
                        alt=""
                      />
                      <div
                        style={{
                          borderRadius: "80% 63% 99% 0% / 100% 80% 79% 10%",
                          fontFamily: "Salsa",
                        }}
                        className="text-6xl font-bold bg-white w-36 h-36 flex items-center justify-center text-red-500 drop-shadow-md shadow-md"
                      >
                        {lesson.final_suggestion}
                      </div>
                    </div>
                  )}
                  {popupBook && <Popup setPopupBook={setPopupBook} />}
                </div>
              </div>
            ) : (
              <StableDiffusionAndSticker
                setWritingToStable={setWritingToStable}
                handleBack={() => {
                  handleBackInEditArtWork();
                }}
              />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default WritingCenterPage;
