import React, { useState } from "react";
import style from "./style.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button, Input, Select, Form } from "antd";
import { useCookies } from "react-cookie";
import TextArea from "antd/es/input/TextArea";
import { BsFillTrashFill } from "react-icons/bs";

function PopupAddPage({ setOpenPopup, type }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const point = useSelector((state) => state.userSlice.point);
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);

  return (
    <>
      <div className={style.container_popup_addPage}>
        <div className={style.popup_addPage}>
          <AiOutlineCloseCircle
            className={style.btn_close_popup}
            onClick={() => setOpenPopup(false)}
            style={{ cursor: "pointer" }}
          />
          <div className={style.left_popup_addPage}>
            <Form>
              <Form.Item className="pt-1">
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <Input placeholder="Enter page title" />
                  </Col>
                  <Col span={4}>
                    <Input placeholder="Domain" />
                  </Col>
                  <Col span={8}>
                    <Input placeholder="Enter url slug" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <p> Text | Editor | Html</p>
              </Form.Item>
              <Form.Item>
                <TextArea rows={10} />
              </Form.Item>
            </Form>
          </div>
          <div className={style.right_popup_addPage}>
            <div className={style.page_status_head}>
              <Form className={style.form_pageStatus}>
                <Row justify={"center"}>
                  <Col span={20}>
                    <Form.Item>
                      <Row gutter={[10, 10]}>
                        <Col span={24}>
                          <p className={style.title}>Page Status</p>
                        </Col>
                        <Col span={24}>
                          <Select placeholder="Status" options={[]} />
                        </Col>
                        <Col span={24} className="justify-center flex">
                          <Button className={style.btn_addPage}>
                            Add Page
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className={style.page_status_second}>
              <Form className={style.form_pageStatus}>
                <Row justify={"center"}>
                  <Col span={16}>
                    <Form.Item>
                      <Row gutter={[10, 10]}>
                        <Col span={24}>
                          <p className={style.title}>Page Status</p>
                        </Col>
                        <Col span={24}>
                          <Select placeholder="Status" options={[]} />
                        </Col>
                        <Col span={24}>
                          <p>Last modified date</p>
                        </Col>
                        <Col span={24}>
                          <p>2023.04.05 15:00</p>
                        </Col>
                        <Col span={18}>
                          <Button className={style.btn_View}>
                            Modify Page
                          </Button>
                        </Col>
                        <Col span={6}>
                          <Button className={style.btn_View}>
                            <BsFillTrashFill
                              width={30}
                              height={30}
                              className={style.icon}
                            />
                          </Button>
                        </Col>
                        <Col span={24}>
                          <p className={style.title}>Page Status</p>
                        </Col>
                        <Col span={24} className="justify-center flex">
                          <Button className={style.btn_View}>View</Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupAddPage;
