import { Button, Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import SortableList, { SortableItem } from "react-easy-sort";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { APIService } from "../../../../services/apiService";
import "./custom-antd.css";
import "./styles.css";
// import arrayMove from 'array-move'
import { arrayMoveImmutable } from "array-move";

export default function App(props) {
  const { blob, initialListItem, formValues, ebookId } = props;
  const [initialItems, setinitialItem] = useState([]);
  const [valueEbook, setValueEbook] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportPDF, setExportPFT] = useState(null);
  const [imgDiv, setImgDiv] = useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [bookId, setBookId] = useState(null);
  const [cookies] = useCookies(["access_token"]);
  const [items, setItems] = useState(initialItems);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showNextStep, setShowNextStep] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoadDing] = useState(false);
  const [artWorkID, setArtWorkID] = useState([]);
  const param = useParams();
  let ebook = useSelector((state) => state.ebookSlice);
  // const onHandleBack = ()=>{
  //   setOpenEbookFinal(false);
  // }
  const onHandleBackButton = () => {
    // window.location.href = `/createEbook/${ebookId}`;
    if (param.ebookId === "create") {
      window.location.href = "/createEbook/create";
    } else {
      navigate(`/createEbook/${ebookId}`);
    }
  };
  useEffect(() => {
    setValueEbook(ebook);
  }, [ebook]);

  useEffect(() => {
    setValueEbook(formValues);
  }, [formValues]);

  useEffect(() => {
    // console.log(ebookId);
    setBookId(ebookId);
  }, [ebookId]);

  useEffect(() => {
    if (initialListItem.length !== 0) {
      setinitialItem(
        initialListItem.map((item, index) => ({
          key: index,
          id: item.art_id,
          art_url: item.art_url,
        }))
      );
    }
  }, [initialListItem]);
  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const handleNextStep = () => {
    setShowNextStep(true);
  };

  useEffect(() => {
    let ids = [];
    if (checkedItems) {
      console.log("checkedItems", checkedItems);
      checkedItems.forEach((item) => ids.push(parseInt(item.id)));
      setArtWorkID(ids);
    }
  }, [checkedItems]);

  console.log("checkedItems", checkedItems);
  console.log("artWorkID", artWorkID);

  const handleSumbit = async () => {
    setIsModalOpen(true);
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("book_title", ebook.title_book);
    formData.append("artwork_ids", JSON.stringify(artWorkID));
    formData.append("author_name", ebook.author_name);
    formData.append("product_ids", JSON.stringify(ebook.product_ids));
    if (ebookId && param.ebookId !== "create") {
      formData.append("book_id", bookId);
    } else if (param.ebookId !== "create") {
      formData.append("book_id", param.ebookId);
    }
    console.log("formData", formData);
    await APIService.create_book(formData, cookies["access_token"])
      .then((response) => {
        console.log("response.data", response.data);
        setExportPFT(response.data);
        setSaveButtonDisabled(true);
      })
      .catch((error) => {});
    await navigate("/e-mybook/ebook");
  };
  const addCssPositionToElement = (item1, item2) => {
    if (param.ebookId === "create") {
      return item1;
    } else {
      return item2;
    }
  };

  const onSortEndNextStep = (oldIndex, newIndex) => {
    const updatedItems = arrayMoveImmutable(checkedItems, oldIndex, newIndex);
    setCheckedItems(updatedItems);
  };

  return (
    <>
      {showNextStep ? (
        <>
          <SortableList
            className="list relative"
            onSortEnd={onSortEndNextStep}
            draggedItemClassName="dragged"
            style={{ top: addCssPositionToElement("12%", "12%") }}>
            <Col span={20}>
              <Row gutter={[16, 16]} justify="center">
                {checkedItems.map((item) => (
                  <Col span={4}>
                    <SortableItem key={item.id}>
                      <div className="item">
                        <img src={item.art_url} alt="" />
                        {/* <p>{item.id}</p> */}
                      </div>
                    </SortableItem>
                  </Col>
                ))}
              </Row>
            </Col>
          </SortableList>

          <div
            className="button relative mt-10"
            style={{ bottom: addCssPositionToElement("30%", "30%") }}>
            <Button
              id="buttonSaveEbookFinal"
              onClick={handleSumbit}
              className="btn_save"
              disabled={saveButtonDisabled}
              style={{
                backgroundColor: "#fe998d",
                color: "white",
                fontSize: "1vw",
                width: "12vw",
              }}>
              SAVE
            </Button>
          </div>
        </>
      ) : (
        <>
          <SortableList
            allowDrag={false}
            className="list relative"
            draggedItemClassName="dragged"
            style={{ top: addCssPositionToElement("15%", "5%") }}>
            <Col span={20}>
              <Row gutter={[16, 16]} justify="center">
                {items.map((item) => (
                  <Col span={4}>
                    <SortableItem key={item.id}>
                      <div
                        className="item"
                        style={{ backgroundImage: `url(${item.art_url})` }}>
                        <div
                          className="relative"
                          style={{ left: "32%", top: "30%" }}>
                          <Checkbox
                            // className="relative"
                            // style={{left:"30%", top:"30%"}}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              let updatedItem = [...checkedItems];
                              if (checked) {
                                updatedItem.push({
                                  id: item.id,
                                  art_url: item.art_url,
                                });
                              } else {
                                updatedItem = updatedItem.filter(
                                  (i) => i.id !== item.id
                                );
                              }
                              setCheckedItems(updatedItem);
                              setArtWorkID(
                                updatedItem.map((i) => parseInt(i.id))
                              );
                            }}
                          />
                        </div>
                        <img src={item.art_url} alt="" />
                        {/* <p>{item.id}</p> */}
                      </div>
                    </SortableItem>
                  </Col>
                ))}
              </Row>
            </Col>
          </SortableList>
          <div
            className="button flex relative w-full h-full mt-10"
            style={{ bottom: addCssPositionToElement("17%", "-8%") }}>
            <Button
              id="buttonBackEbookFinal"
              className="text-gray-50 hover:text-gray-100 flex-inline relative bg-indigo-500 mr-2"
              style={{ width: "8vw", fontSize: "1vw" }}
              onClick={() => onHandleBackButton()}>
              BACK
            </Button>
            <Button
              id="buttonContinueEbookFinal"
              className="text-gray-50 hover:text-gray-100 flex-inline relative"
              style={{
                backgroundColor: "#5ca3ba",
                fontSize: "1vw",
                width: "8vw",
              }}
              onClick={handleNextStep}>
              CONTINUE
            </Button>
          </div>
        </>
      )}
    </>
  );
}
