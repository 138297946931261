import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import style from "./style.module.css";
import logo1 from "../../../assets/background/lion UFO.png";
import logo2 from "../../../assets/background/bear with plane.png";
import ranks1t from "../../../assets/icon_svg/Rankings1t.png";
import layer1 from "../../../assets/icon_svg/Layer1.png";
import user1 from "../../../assets/icon_svg/E721.png";
import medal_star from "../../../assets/icon_svg/medal-star.png";
import { Cookies } from "react-cookie";
import { APIService } from "../../../services/apiService";
import { Select } from "antd";

const { Option } = Select;

const Ranking = ({ children }) => {
  const [course, setCourse] = useState([]);
  const [level, setLevel] = useState([]);
  const [items, setItems] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [itemLevel, setItemLevel] = useState(null);
  async function fetchData() {
    try {
      const accessToken = Cookies["access_token"];
      const data = await APIService.get_ranking(accessToken);
      setCourse(data.data.course);
      setLevel(data.data.level);
    } catch (error) {
      console.error(error);
    }
  }

  const onChange = (value) => {
    setSelectedOption(value);
    setSelectedValue(value ? value.value : null);
    for (const [k, v] of Object.entries(level)) {
      if (k === value) {
        setItemLevel(v);
      }
    }
  };

  useEffect(() => {
    if (selectedValue) {
      for (const [k, v] of Object.entries(level)) {
        if (k === selectedValue) {
          setItemLevel(v);
        }
      }
    }
  }, [selectedValue, level]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const options = Object.keys(level).map((key) => ({
      value: key,
      label: key,
    }));
    setItems(options);
    setSelectedValue(options[0]?.value);
  }, [level]);

  useEffect(() => {
    console.log(itemLevel);
  }, [itemLevel]);

  return (
    <>
      <div className={style.container}>
        <div className={style.content}>
          <h2 className={style.comingSoon}>COMING SOON...</h2>
          <img src={logo1} alt="" className={style.img_1} />
          <img src={logo2} alt="" className={style.img_2} />

          {/* <Select
            showSearch
            placeholder="Select one option"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={items}
            value={
              selectedOption ? selectedOption.label : items && items[0]?.label
            }
          />
          {itemLevel?.map((item) => (
            <div className={style.rank}>
              <img className={style.rank_background} src={ranks1t} alt="" />
              <div className={style.user}>
                <div className={style.medal}>
                  <img src={layer1} style={{ position: "relative" }} alt="" />
                  <span className={style.userSt}>1ST</span>
                </div>
                .userA
                <div className={style.userAvatar}>
                  <img src={item.avatar_url} alt="" />
                </div>
                <div className={style.userInfor}>
                  <div className={style.userName}>
                    <span>{item.nick_name}</span>
                  </div>
                  <div className={style.userLevel}>{item.level} level</div>
                </div>
                <div
                  className={style.userPoin}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className={style.medal_start}>
                    <img src={medal_star} alt="" />
                  </div>
                  <div className={style.userPoin}>
                    <span>{item.point}</span>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
};

export default Ranking;
