import React, { useEffect, useState } from "react";
import ParentDiv from "./component/dragable";
import ImageCarousel from "./component/stableDiffusion";
import style from "../style.module.css";
import { useParams } from "react-router";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../../services/apiService";
import { set } from "react-hook-form";

const StableDiffusionUpdate = ({ onPrev }) => {
  const [cookies] = useCookies(["access_token"]);
  const [showComponents, setShowComponents] = useState(false);
  const [nextValue, setNextValue] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [prevToWriting, setPrevToWriting] = useState(null);
  const [callOnPrev, setCallOnPrev] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { lessonId } = useParams();
  const lessonIdNumber = parseInt(lessonId);
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    const accessToken = cookies["access_token"];
    APIService.get_all_artwork(accessToken).then((response) => {
      let items = response.data.filter(
        (item) => item.lesson_id === lessonIdNumber
      );
      setUpdateData(items);
    });
  }, []);

  useEffect(() => {
    console.log("updateData", updateData);
  }, [updateData]);

  useEffect(() => {
    setShowComponents(nextValue);
  }, [nextValue]);

  useEffect(() => {
    setShowComponents(prevValue);
  }, [prevValue]);

  useEffect(() => {
    if (callOnPrev) {
      onPrev(prevToWriting);
      setCallOnPrev(false);
    }
  }, [callOnPrev, onPrev, prevToWriting]);

  const handleNext = (eventOrValue, selectedImage) => {
    setNextValue(eventOrValue);
    setPrevValue(true);
    setSelectedImage(selectedImage);
  };

  const handlePrev = (eventOrValue) => {
    setPrevValue(eventOrValue);
    setNextValue(false);
  };

  function handlePrevToWriting(eventOrValue) {
    setPrevToWriting(eventOrValue);
    setCallOnPrev(true);
  }

  return (
    <>
      <div className={style.container_stable}>
        {!showComponents ? (
          <ImageCarousel
            onNext={handleNext}
            onPrev={handlePrevToWriting}
            mainIMG={setSelectedImage}
            updateData={updateData}
          />
        ) : (
          <ParentDiv
            onPrev={handlePrev}
            imageData={{ selectedImage }}
            updateData={updateData}
          />
        )}
      </div>
    </>
  );
};

export default StableDiffusionUpdate;
