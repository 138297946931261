import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router";
import closeButton from "../../../assets/artwork/close-button.png";
import editButton from "../../../assets/artwork/magic-button.png";

const PopupItem = ({ selectedLesson }) => {
  const { link_artwork, lesson_id } = selectedLesson ?? {};
  const navigate = useNavigate();
  const goToEditArtworkPage = () => {
    navigate(`/stable-diffusion/${lesson_id}`);
  };
  return (
    <div
      className={classNames(
        "fixed top-1/2 -translate-y-[calc(50%-1rem)] left-1/2 duration-1000 delay-100 -translate-x-1/2  shadow-lg",
        "w-[60vw] lg:w-auto lg:h-[80vh] h-auto",
        lesson_id
          ? "visible opacity-100 skew-y-0"
          : "invisible opacity-0 skew-y-6"
      )}>
      {lesson_id && (
        <div className="w-full h-full bg-[#859DC8] p-4">
          <div className="w-full h-full">
            <button
              onClick={goToEditArtworkPage}
              type="button"
              className="absolute -bottom-12  left-1/2 -translate-x-1/2 h-32 hover:scale-110 duration-200">
              <img
                src={editButton}
                alt="button"
                className="h-full object-cover"
              />
            </button>
            <div className="bg-white w-full h-full">
              <img
                src={link_artwork}
                alt="img"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupItem;
