import React from "react";
import { useSelector } from "react-redux";
import style from "./style.module.css";
import point_icon from "../../../assets/icon_svg/medal-star.png";
import PetList from "./PetList";
import logo_poin from "../../../assets/logo/logoPoint.png";
import { Col, Row } from "antd";

const PointPage = () => {
  let user = useSelector((state) => state.userSlice.user);
  let point = useSelector((state) => state.userSlice.point);
  return (
    <>
      <div className={style.container}>
        <div className={style.content}>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <div className={style.content_Layout}>
                <div className={style.container_Layout_Left}>
                  <img src={logo_poin} className={style.imagesLeft} alt="" />
                </div>
                <div className={style.content_point}>
                  <div className={style.inforUser_poin}>
                    <img className="h-6" src={point_icon} alt="Icon Point" />
                    <div className="mt-1">{point.total_point} POINT</div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className={style.content_listPet}>
                <PetList userPoint={point.total_point} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PointPage;
