import Draggable from "react-draggable";
import React, { useRef, useState } from "react";

export default function DraggableDiv({ children }) {
  const parentRef = useRef();
  const [isDragging, setIsDragging] = useState(false);

  const handleStart = (event, data) => {
    const parentRect = parentRef.current?.getBoundingClientRect();
    const { x, y } = data;

    if (
      parentRect &&
      (x < parentRect.left ||
        x > parentRect.left + parentRect.width ||
        y < parentRect.top ||
        y > parentRect.top + parentRect.height)
    ) {
      event.preventDefault();
    }
  };

  const handleDrag = (event, data) => {
    const parentRect = parentRef.current?.getBoundingClientRect();
    const { x, y } = data;

    if (isDragging) {
      if (
        parentRect &&
        (x < parentRect.left ||
          x > parentRect.left + parentRect.width ||
          y < parentRect.top ||
          y > parentRect.top + parentRect.height)
      ) {
        data.node.style.transform = "none";
      }
    }
  };

  const handleDragStop = (event, data) => {
    const parentRect = parentRef.current?.getBoundingClientRect();
    const { x, y } = data;

    if (isDragging) {
      if (
        parentRect &&
        (x < parentRect.left ||
          x > parentRect.left + parentRect.width ||
          y < parentRect.top ||
          y > parentRect.top + parentRect.height)
      ) {
        data.node.style.transform = "none";
      }
    } else {
      setIsDragging(true);
    }
  };

  return (
    <Draggable
      clone
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleDragStop}
    >
      <div onDoubleClick={() => setIsDragging(false)}>{children}</div>
    </Draggable>
  );
}
