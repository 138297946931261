import { Button, message, Popconfirm } from "antd";
import { APIService } from "../../services/apiService";
import { useCookies } from "react-cookie";

const DeleteConfirm = ({ data }) => {
  const [cookies] = useCookies(["access_token"]);
  const confirm = (e) => {
    APIService.delete_campus(
      { campusId: data?.campus_id },
      cookies["access_token"]
    )
      .then(() => {
        message.success("Delete successfully!");
        window.location.reload();
      })
      .catch((err) => {
        message.error("Error occurred");
      });
  };
  const cancel = () => {};
  return (
    <Popconfirm
      title="Delete the campus"
      description={
        data?.student_count > 0
          ? "This Campus currently has students. Are you sure to delete it?"
          : "Are you sure to delete this campus?"
      }
      onConfirm={confirm}
      onCancel={cancel}
      okText="Yes"
      cancelText="No">
      <Button danger>Delete</Button>
    </Popconfirm>
  );
};

export default DeleteConfirm;
