import React, { forwardRef } from "react";

const CoverPage = forwardRef((props, ref) => {
    return (
      <div className="demoPage cover" ref={ref} data-density="hard" >
        <div style={{width:"500px", height:"600px", background:"pink", overflow:"hidden"}}>
          <div style={{width:"100%", height:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            {props.children}
          </div>
        </div>
      </div>
    );
  });

export default CoverPage;