import { Rate } from "antd";
import React, { useState } from "react";
import "./custom.css";
import style from "./style.module.css";
function StarRating(props) {
  const { text, point } = props;
  const [rating, setRating] = useState(point);

  return (
    <div className={style.container_Start}>
      <div className={style.content_leftStart}>
        <p>{text}</p>
      </div>
      <div className={style.content_rightStart}>
        <Rate disabled allowHalf value={rating} className={style.rightStar} />
      </div>
    </div>
  );
}

export default StarRating;
