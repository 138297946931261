import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import FormApprovalRequest from "../../../../components/Form/FormApprovalReques";
import { TableDataApprovalRequest } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";

import Search from "antd/es/input/Search";
import style from "./style.module.css";

const ApprovalRequest = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [type, setType] = useState(2);
  const [refetch, setRefetch] = useState(false);

  async function fetchData() {
    try {
      // const accessToken = cookies["access_token"];
      const data = await APIService.get_all_campus_approval(
        cookies["access_token"]
      );
      setDataUser(data.data);
      setFilteredData(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, [refetch]);

  const onSearch = (value, _e, info) => {
    if (value === "") {
      setFilteredData(dataUsers);
      return;
    }
    const resultSearch = dataUsers?.filter(
      (item) =>
        item?.nick_name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.fullname?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.talksam_id?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setFilteredData(resultSearch);
  };
  return (
    <div>
      <div className={style.form_approval}>
        <FormApprovalRequest setRefetch={setRefetch} />
      </div>
      <div className="flex justify-end mb-4">
        <Search
          style={{
            width: 400,
          }}
          placeholder="Search username, email, fullname, talksam ID"
          allowClear
          enterButton="Search"
          onSearch={onSearch}
        />
      </div>
      <TableDataApprovalRequest
        className={style.form_approval_request_admin}
        dataUsers={filteredData}
        fetchData={fetchData}
      />
    </div>
  );
};

export default ApprovalRequest;
