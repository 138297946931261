import React, { createContext, useContext, useState } from "react";

// Create a context for the book name
const EBookContext = createContext();

// Create a custom hook to access the book name
export function useEBook() {
  return useContext(EBookContext);
}

// Create a provider component to wrap your app with
export function EBookContextProvider({ children }) {
  const [ebook, setEbook] = useState("");

  return (
    <EBookContext.Provider value={{ ebook, setEbook }}>
      {children}
    </EBookContext.Provider>
  );
}
