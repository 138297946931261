import { Button, Space, Table, DatePicker, Input, Col, Row, Modal } from "antd";
import React, { useEffect, useState } from "react";
import CreateEBook from "./CreateEBook";
import { APIService } from "../../../../services/apiService";
import { useCookies } from "react-cookie";
import Loading from "../../../../components/Loading/Loading";
import { useMediaQuery } from "react-responsive";
import { IoEyeSharp } from "react-icons/io5";
import { BsPencilFill } from "react-icons/bs";
import { FcDownload } from "react-icons/fc";
import PopupEbook from "../../../../components/PopupEbook/PopupEbook";
import { useNavigate, useParams } from "react-router";
import logo_ebook from "../../../../assets/icon_svg/logo-ebook.png";
// import style from "../../style.module.css"
import {
  getColumnSearchProps,
  renderSearchLayout1,
  renderSearchLayout2,
  handleSearch,
} from "../../../../components/AntdSearchTable/antSearchFunction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setEbookID, setIndex } from "../../../../redux/ebookSlice";
const { Search } = Input;

const { RangePicker } = DatePicker;
const MakeEBook = () => {
  const [cookies] = useCookies(["access_token"]);
  const [openCreateEbook, setOpenCreateEbook] = useState({
    type: "table",
    index: null,
    ebook_id: null,
  });
  const [tableData, setTableData] = useState([]);
  const [indexPopup, setIndexPopup] = useState(null);
  const navigate = useNavigate();
  const [ebookId, setEbookId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [openBookView, setOpenBookView] = useState({ type: "", index: null });

  const [currentPage, setCurrentPage] = useState(0);

  const { type } = useParams();
  const ebookIdSelector = useSelector((state) => state.ebookSlice.ebook_Id);
  const dispatch = useDispatch();
  const handleUpdateEbook = (record) => {
    setEbookId(record);
    setOpenCreateEbook({ type: "create", index: null });
  };
  const onOpenBookView = (type, index, record) => {
    setOpenCreateEbook({ type: "bookView", index, ebook_id: record.modify });
    dispatch(setEbookID({ ebook_Id: record.modify }));
    dispatch(setIndex({ book_index: index + 1 }));
  };
  const columns = [
    { title: "Book Title", dataIndex: "name", key: "name" },
    { title: "Product", dataIndex: "product", key: "product" },
    { title: "Page", dataIndex: "page", key: "page" },
    { title: "Date", dataIndex: "date", key: "date" },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (text, record, index) => (
        <IoEyeSharp
          className="icon_view"
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log(currentPage + index);
            onOpenBookView("bookView", currentPage + index, record);
          }}
        />
      ),
    },
    {
      title: "Modify",
      dataIndex: "modify",
      key: "modify",
      render: (record) => (
        <BsPencilFill
          onClick={() => handleUpdateEbook(record)}
          className="icon_modify"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: "PDF",
      dataIndex: "view",
      key: "view",
      render: (view) => (
        <FcDownload
          onClick={() => handleExportPDF(view)}
          className="icon_modify"
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const handleExportPDF = (exportPDF) => {
    const a = document.createElement("a");
    a.href = exportPDF;
    a.download = "file.pdf";
    a.click();
  };

  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const accessToken = cookies["access_token"];
    APIService.get_list_ebook(accessToken).then((response) => {
      const row = response.data;
      const filteredData = row?.filter(
        (item) =>
          item.ebook_name.includes(searchValue) ||
          item.product_name.includes(searchValue)
      );
      setTableData(
        filteredData?.map((item, index) => ({
          key: index + 1,
          name: item.ebook_name,
          product: item.product_name,
          page: item.include_artwork.length,
          date: item.date,
          modify: item.ebook_id,
          view: item["export_pdf "],
        }))
      );
    });
    if (type === "edit") {
      setEbookId(ebookIdSelector);
      setOpenCreateEbook({ type: "create", index: null });
    } else if (type === "list") {
      setOpenCreateEbook({ type: "table", index: null });
    }
  }, [searchValue]);

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
  };
  //pagination
  const onChangePage = (pageNumber, pageSize) => {
    setCurrentPage((pageNumber - 1) * pageSize);
  };
  let paginationConfig;

  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 8, onChange: onChangePage };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 5, onChange: onChangePage };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 6, onChange: onChangePage };
      break;
    default:
      paginationConfig = { pageSize: 4, onChange: onChangePage };
      break;
  }
  //
  const renderMain = (item) => {
    if (item.type === "create") {
      return <CreateEBook ebookId={ebookId} />;
    } else if (item.type === "table") {
      return (
        <Row className="talbe_makeEbook" gutter={[20, 20]}>
          <Col span={24} className="relative" style={{ top: "25%" }}>
            <Row>
              <Col span={24}>
                <Row gutter={[10, 10]} justify={"end"}>
                  <Col span={10} className="relative" style={{ right: "25%" }}>
                    <Search
                      placeholder="Input Title Search"
                      allowClear
                      enterButton="Search"
                      onSearch={(value) => setSearchValue(value)}
                    />
                  </Col>

                  <Col span={4} className="relative" style={{ right: "25%" }}>
                    <Button onClick={() => navigate("/createEbook/create")}>
                      Create ebook
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="tableData relative" style={{ top: "30%" }}>
            {tableData.length > 0 ? (
              <Table {...tableConfig} pagination={paginationConfig} />
            ) : (
              <Loading></Loading>
            )}
          </Col>
          <Modal
            title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <PopupEbook indexPopup={indexPopup} />
          </Modal>
        </Row>
      );
    } else if (item.type === "bookView") {
      navigate("/e-mybook/book");
    }
  };
  return renderMain(openCreateEbook);
};

export default MakeEBook;
