import React, { forwardRef } from "react";
import style from "./style.module.css";

const CoverPage = forwardRef((props, ref) => {
  return (
    <div className={style.demoPage_cover} ref={ref} data-density="hard">
      {props.children}
    </div>
  );
});

export default CoverPage;
