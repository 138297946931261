import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
const Popup = ({ setPopupBook }) => {
  const handlePopupClose = () => {
    setPopupBook(false);
  };

  const lesson = useSelector((state) => state.lessionSlice.lesson);
  console.log(lesson)
  return (
    <>
    {lesson.book_review.summary_content ? (<Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "rgba(255,255,255,50%)",
        }}
        open={true}
      >
          <div className={style.container_popup}>
            <div className={style.content_popup}>
              <AiOutlineCloseCircle
                className={style.btn_close_popup}
                onClick={handlePopupClose}
              />
              <div className={style.wapperContent}>
              <img src={lesson.book_review.image_book} alt="imgBook" />
              <div className={style.content_book}>
                <span>{lesson.book_review.summary_content}</span>
                <a
                  href={lesson.book_review.url_book}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here
                </a>
              </div>
              </div>
            </div>
          </div>
        </Backdrop>  ) : (<></>)}
    </>
 
  );
};

export default Popup;
