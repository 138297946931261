import wingLoading from "../../assets/loading/wing-loading.gif";
import stickLoading from "../../assets/loading/stick-loading.gif";
const WingLoading = ({ option = 1 }) => {
  return (
    <div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-60 md:w-40 h-60 md:h-40 rounded-md flex justify-center items-center">
      {option === 1 && (
        <img
          src={wingLoading}
          className="w-full h-full object-cover"
          alt="loading"
        />
      )}
      {option === 2 && (
        <img
          src={stickLoading}
          className="w-full h-full object-cover"
          alt="loading"
        />
      )}
    </div>
  );
};

export default WingLoading;
