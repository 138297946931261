import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  conversation: [],
  thinking: false,
  question: "",
  answer: "",
  onAppear: true,
  disable: false,
  speakerAudio: null,
};
let nextId = 0;
const chatSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    setDisable(state, { payload }) {
      state.count++;
      state.disable = true;
    },
    setEnable(state, { payload }) {
      state.count--;
      if (state.count == 0) {
        state.disable = false;
      }
    },
    setLoadHistoryConversation(state, { payload }) {
      state.conversation = payload;
    },
    setConversation(state, { payload }) {
      if (payload.id === -1) return;
      state.conversation.push({ ...payload, id: nextId++ });
    },
    resetConversation: (state) => {
      state.conversation = [];
    },
    setThinking(state, { payload }) {
      state.thinking = payload;
    },
    setQuestion(state, { payload }) {
      state.question = payload;
    },
    setAnswer(state, { payload }) {
      state.answer = payload;
    },
    setAppear(state, { payload }) {
      state.onAppear = payload;
    },
    setAppear(state, { payload }) {
      state.onAppear = payload;
    },
    setSpeakerAudio(state, { payload }) {
      state.speakerAudio = payload;
    },
  },
});

export const {
  setSpeakerAudio,
  setConversation,
  resetConversation,
  setThinking,
  setQuestion,
  setAnswer,
  setAppear,
  setDisable,
  setEnable,
  setLoadHistoryConversation,
} = chatSlice.actions;

export default chatSlice.reducer;
