import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  DatePicker,
} from "antd";
import { Option } from "antd/es/mentions";
import { APIService } from "../../services/apiService";
import { useCookies } from "react-cookie";
import TextArea from "antd/es/input/TextArea";
import { Notification } from "../../components/Notification/index";

import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

const level_list = [
  { id: "e4", name: "E4" },
  { id: "e5", name: "E5" },
  { id: "e6", name: "E6" },
  { id: "e7", name: "E7" },
  { id: "e8", name: "E8" },
  { id: "e9", name: "E9" },
  { id: "m11", name: "M11" },
  { id: "m12", name: "M12" },
  { id: "m13", name: "M13" },
  { id: "m14", name: "M14" },
  { id: "m15", name: "M15" },
];
export const FormRoleRegistration = ({ courses, categorys, product }) => {
  const [form] = Form.useForm();

  const [cookies] = useCookies(["access_token"]);
  const [updatedproduct, setUpdatedproduct] = useState(product);
  const [option, setOption] = useState([]);
  const [size, setSize] = useState("middle");

  const country_list = useSelector((state) => state.adminSlice.country_list);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  const [change, setChange] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const onSaveStage = async () => {
    try {
      await form.validateFields();
      APIService.create_product(
        {
          product_name: form.getFieldValue("product_name"),
          category_id: form.getFieldValue("category"),
          country_id: form.getFieldValue("country"),
          summary: form.getFieldValue("summary"),
          product_image: form.getFieldValue("product_image"),
          period: form.getFieldValue("period"),
          course_selection: form.getFieldValue("course_selection"),
          product_detailed_explanation: form.getFieldValue("product_detail"),
          price: form.getFieldValue("price"),
          level: form.getFieldValue("level"),
          discount_rate: form.getFieldValue("discount_rate"),
          discount_price: form.getFieldValue("discount_price"),
          search_tag: ["P1", "P2"],
          is_post: form.getFieldValue("is_posting"),
        },
        cookies["access_token"]
      )
        .then((response) => {
          // navigate("/");
          Notification("success", response.data);
          // window.location.reload();
          // console.log(response);
        })
        .catch((error) => {
          Notification("error", error);
        });
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    form.resetFields();
    setIsFormDisabled(true);
    setChange(false);
    if (product) {
      form.setFieldValue("product_name", product.product_name);
      form.setFieldValue("category", product.category);
      form.setFieldValue("country", product.country_id);
      form.setFieldValue("summary", product.sumary);
      form.setFieldValue("course_selection", product.course_id);
      form.setFieldValue(
        "product_detail",
        product.product_detailed_explanation
      );
      form.setFieldValue("price", product.price);
      form.setFieldValue("period", product.period);
      form.setFieldValue("discount_rate", product.discount_rate);
      form.setFieldValue("discount_price", product.discount_price);
      form.setFieldValue("search_tag", product.search_tag);
      form.setFieldValue("is_posting", product.is_posting);
      setUpdatedproduct(product);
    }
  }, [product]);
  const handleChange = () => {
    setChange(true);
    setIsFormDisabled(false);
  };

  useEffect(() => {
    if (courses) {
      const opt = [];
      courses.forEach((ele) => {
        opt.push({
          value: ele.id,
          label: ele.course_name,
        });
      });
      setOption(opt);
    }
  }, [courses]);

  const createNewUser = () => {
    setChange(true);
    setIsFormDisabled(false);
    form.resetFields();
  };
  const handleDeleteProduct = () => {
    APIService.delete_product(
      {
        product_name: form.getFieldValue("product_name"),
      },
      cookies["access_token"]
    )
      .then((response) => {
        Notification("success", response.data);
      })
      .catch((error) => {});
  };

  let rows;

  switch (true) {
    case isPc1920:
      rows = 2;
      break;
    case isPaptop1440:
      rows = 2;
      break;
    case isPaptop1280:
      rows = 4;
      break;
    default:
      rows = 2;
      break;
  }

  return (
    <>
      <div className="flex justify-center w-full">
        <div className="w-[60%] flex justify-between pb-2">
          <Button
            className={style.btn_addPage}
            onClick={createNewUser}
            style={{ marginLeft: "10px" }}
          >
            Registration
          </Button>
          {change ? (
            <>
              <Button className={style.btn_addPage} onClick={onSaveStage}>
                Saved
              </Button>
            </>
          ) : (
            <>
              <Button className={style.btn_addPage} onClick={handleChange}>
                Change
              </Button>
            </>
          )}
          <Button className={style.btn_addPage} onClick={handleDeleteProduct}>
            Delete
          </Button>
        </div>
      </div>

      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 20,
        }}
        form={form}
        disabled={isFormDisabled}
      >
        <Form.Item
          name="product_name"
          label="Product name"
          rules={[
            {
              required: true,
              message: "Please select your course",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select category",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Category"
            value={product?.category}
          >
            {categorys?.map((item) => {
              return <Option value={item.id}>{item.category}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
              message: "Please select country",
            },
          ]}
        >
          <Select showSearch placeholder="Select country">
            {country_list?.map((item) => {
              return <Option value={item.id}>{item.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="level"
          label="Level"
          rules={[
            {
              required: true,
              message: "Please select level",
            },
          ]}
        >
          <Select showSearch placeholder="Select level">
            {level_list?.map((item) => {
              return <Option value={item.id}>{item.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="summary"
          label="Summary"
          rules={[
            {
              required: true,
              message: "Please input book review",
            },
          ]}
        >
          <TextArea rows={rows} />
        </Form.Item>
        <Form.Item
          name="product_image"
          label="Product Image"
          rules={[
            {
              required: true,
              message: "Please select product image",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="period"
          label="Valid period of study"
          rules={[
            {
              required: true,
              message: "Please input period",
            },
          ]}
        >
          <Input type="number" placeholder="number of days" />
        </Form.Item>
        <Form.Item
          name="course_selection"
          label="Course selection"
          rules={[
            {
              required: true,
              message: "Please select course selection",
            },
          ]}
        >
          <Select
            mode="tags"
            size={size}
            placeholder="Please select course"
            onChange={handleChange}
            style={{
              width: "100%",
            }}
            options={option}
          />
        </Form.Item>
        <Form.Item
          name="product_detail"
          label="Product detailed explanation"
          rules={[
            {
              required: true,
              message: "Please input product detail",
            },
          ]}
        >
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              required: true,
              message: "Please input price",
            },
          ]}
        >
          <Input type="number" />

          {/* <p>'WON'</p> */}
        </Form.Item>
        <Form.Item
          name="discount_rate"
          label="Discount rate"
          rules={[
            {
              required: true,
              message: "Please select discount_rate",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={0}>0%</Radio>
            <Radio value={10}>10%</Radio>
            <Radio value={20}>20%</Radio>
            <Radio value={30}>30%</Radio>
            <Radio value={40}>40%</Radio>
            <Radio value={50}>50%</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="discount_price"
          label="Discount Price"
          rules={[
            {
              required: true,
              message: "Please input discount price",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="search_tag"
          label="Search tag"
          rules={[
            {
              required: true,
              message: "Please input search tag",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="is_posting"
          label="Whether to post"
          rules={[
            {
              required: true,
              message: "Please select posting",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Posting</Radio>
            <Radio value={false}>No Posting</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </>
  );
};
