import { Input, Modal } from "antd";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { ReactMic } from "react-mic";
import { useDispatch, useSelector } from "react-redux";
import soundWave from "../../assets/gifs/sound-wave.gif";
import {
  setConversation,
  setSpeakerAudio,
  setThinking,
} from "../../redux/chatSlice";
import { APIService } from "../../services/apiService";
import { chatService } from "../../services/chatService";
import { Notification } from "../Notification/index";
import "./recordModal.css";

const { TextArea } = Input;
const buttonWidth = { width: "20%" };

const inputWidth = { width: "80%", height: "100%" };
export default function RecordModalUpgrade({
  type,
  isOpenModal,
  setIsOpenModal,
  imageQuantity,
  setRegenerateText,
}) {
  const dispatch = useDispatch();
  const currentPet = useSelector((state) => state.petSlice.curentlyPet);
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const [cookies] = useCookies(["access_token"]);
  const [buttonSwitch, setButtonSwitch] = useState(1);

  const onHandleCancel = () => {
    setButtonSwitch(1);
    stopRecording();
    setIsOpenModal(false);
  };
  const onHandleOk = () => {
    startRecording();
    setButtonSwitch(2);
  };

  const [record, setRecord] = useState(false);

  const startRecording = () => {
    setRecord(true);
  };

  const stopRecording = () => {
    // console.log("stop");
    console.log("imageQuantity", imageQuantity);
    setRecord(false);
  };

  const onData = (recordedBlob) => {
    // console.log("chunk of real-time data is");
  };

  const onStop = (recordedBlob) => {
    fetchDataAudio(recordedBlob);
  };

  const func_thinking = (res) => {
    if (!res.data.question) return;
    dispatch(
      setConversation({
        type: "question",
        text: res.data.question,
      })
    );
  };

  const chat_bot_image = (question) => {
    setRegenerateText("");
    const formData = new FormData();
    formData.append("number_img", imageQuantity);
    formData.append("question", question);
    formData.append("topic", lesson.topic);
    formData.append("lesson_id", lesson.lessonId);
    formData.append("lesson_name", lesson.lesson_name);

    chatService
      .chat_bot_image(formData, cookies["access_token"])
      .then((res) => {
        if (!res.data.answer || !res.data.link_audio || !res.data.url_image) {
          dispatch(setThinking(false));
          setRegenerateText(question);
          return;
        }
        if (!res.data.answer && !res.data.link_audio) {
          Notification("error", "Please speak in English!");
          return;
        }
        dispatch(setSpeakerAudio(res.data?.link_audio));
        dispatch(
          setConversation({
            type: "answer",
            text: res.data.answer?.length > 0 ? res.data.answer : "Not reponse",
            link_audio: res.data?.link_audio,
            url: res.data?.url_image,
          })
        );
      })
      .catch(() => {
        Notification("error", "Something went wrong, try again!");
      })
      .finally(() => {
        dispatch(setThinking(false));
      });
  };

  const fetchDataAudio = (recordedBlob) => {
    // console.log("recordedBlob is: ", recordedBlob);
    const file = new File([recordedBlob.blob], "recordedAudio.webm", {
      type: "audio/webm",
    });

    if (type === "brainstorming") {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(setThinking(true));
      APIService.speech_to_text(formData, cookies["access_token"])
        .then((res) => {
          if (res.data.question === null) {
            dispatch(setThinking(false));
            Notification("error", "Please speak in English!");
            return;
          }
          func_thinking(res);
          chat_bot_image(res.data.question);
        })
        .catch((err) => {
          dispatch(setThinking(false));
        })
        .finally(() => {});
    } else {
      try {
        const formData = new FormData();
        formData.append("file", file);
        APIService.speech_to_text(formData, cookies["access_token"])
          .then((res) => {
            dispatch(
              setConversation({
                type: "question",
                text: res.data.question,
              })
            );

            APIService.chat_mascot_audio_mp3(
              {
                pet_id: currentPet?.id,
                question: res.data.question,
              },
              cookies["access_token"]
            ).then((res) => {
              dispatch(
                setConversation({
                  type: "answer",
                  text:
                    res.data.answer.length > 0
                      ? res.data.answer
                      : "Not reponse",
                })
              );
              const audio = new Audio(res.data.link_audio);
              audio.play();
            });
          })
          .catch((err) => console.log(err))
          .finally(() => dispatch(setThinking(false)));
      } catch (error) {
        Notification("error", "Please check your server reponse");
      }
    }
  };

  const renderButton = () => {
    return buttonSwitch === 1 ? (
      <button
        style={buttonWidth}
        key={"btnOk"}
        onClick={() => onHandleOk()}
        className="px-3 py-1 my-4 font-semibold border rounded border-transparent bg-cyan-300 text-gray-50 hover:text-gray-50 hover:bg-cyan-200 duration-300">
        Start
      </button>
    ) : (
      <button
        style={buttonWidth}
        key={"btnCancel"}
        onClick={onHandleCancel}
        className="px-3 py-1 my-4 font-semibold border rounded border-transparent bg-cyan-300 text-gray-50 hover:text-gray-50 hover:bg-cyan-200 duration-300 ml-2">
        Stop
      </button>
    );
  };
  return (
    <>
      <div style={{ display: "none" }}>
        <ReactMic record={record} onStop={onStop} onData={onData} />
      </div>

      <Modal
        open={isOpenModal}
        onCancel={onHandleCancel}
        footer={[
          <div className="relative w-full" style={{ right: "10%" }}>
            {renderButton()}
          </div>,
          <div style={{ display: "none" }}></div>,
        ]}>
        <div>
          <div className="w-full mt-10 flex justify-center px-8 items-center">
            <div className="h-28">
              <img
                alt="mic"
                className="h-full object-cover"
                src={require("../../assets/recordImage/Record.png")}
              />
            </div>

            {record ? (
              <div className="w-60">
                <img
                  alt="sound"
                  className="w-full object-cover"
                  src={soundWave}
                />
              </div>
            ) : (
              <div className="w-60">
                <img
                  alt="sound"
                  className="w-full object-cover my-[5.92rem]"
                  src={require("../../assets/recordImage/Voice.png")}
                />
              </div>
            )}
          </div>
          {/* <br /> */}
          {/* <div className="relative" style={{ left: "10%" }}>
                    <TextArea style={inputWidth} rows={4} className="rounded-md" />
                </div> */}
          <div></div>
        </div>
      </Modal>
    </>
  );
}
