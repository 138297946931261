import { Button, Col, Input, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Notification } from "../../components/Notification/index";
import { APIService } from "../../services/apiService";
import PopupListProduct from "../Popup/PopupListProduct";
import PopupListUser from "../Popup/PopupListUser";

import { useDispatch } from "react-redux";
import { setProductList, setUserList } from "../../redux/campusSlice";
import style from "./style.module.css";

const { Search } = Input;

const FormApprovalRequest = (props) => {
  const dispatch = useDispatch();
  const { type, setRefetch } = props;
  const [isViewSelectProduct, setIsViewSelectProduct] = useState(false);
  const [isViewSelectUser, setIsViewSelectUser] = useState(false);
  const product_list = useSelector((state) => state.campusSlice.products_list);
  const user_list = useSelector((state) => state.campusSlice.user_list);
  const [selectProduct, setSelectProduct] = useState("");
  const [selectUser, setSelectUser] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [userIds, setUserIds] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const handleCheckboxChange = (event) => {
    setSelectedCheckbox(event.target.value);
  };

  useEffect(() => {
    // console.log(product_list);
    let listProduct = "";
    let prodids = [];
    product_list.forEach((element) => {
      prodids.push(element.product_id);
    });
    setSelectProduct(
      product_list
        .filter((item) => item.courses.length > 0)
        .map((item) => item.product_name)
        .join(", ")
    );
    setProductIds(prodids);
  }, [product_list]);

  useEffect(() => {
    const newUserIds = user_list.map((element) => element.user_id);

    setUserIds(newUserIds);
    setSelectUser(user_list?.map((item) => item?.full_name)?.join(", "));
  }, [user_list]);

  const onClearDataChoose = () => {
    setSelectUser([]);
    setUserIds([]);
    setProductIds([]);
    setSelectProduct([]);
    setExpirationDate("");
    dispatch(
      setProductList({
        product_list: [],
      })
    );
    dispatch(
      setUserList({
        user_list: [],
      })
    );
  };

  const expiration105 = useMemo(() => {
    const currentDate = new Date();

    // Calculate the date after 105 days
    const futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 105);

    // Format the date as a string (e.g., "YYYY-MM-DD")
    const formattedDate = `${futureDate.getFullYear()}-${(
      futureDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${futureDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  }, []);
  const onSendApprovalToAdmin = () => {
    if (!userIds || !selectProduct) {
      Notification("warning", "Please choose userId, product");
      return;
    }
    APIService.send_approval_admin(
      {
        product_ids: product_list
          .filter((item) => item.courses.length > 0)
          .map((item) => item.product_id),
        user_ids: userIds,
        expiration_date: expirationDate,
        course_ids: product_list.reduce(
          (acc, curr) => acc.concat(curr.courses),
          []
        ),
      },
      cookies["access_token"]
    ).then((response) => {
      if (response && response.data === true) {
        Notification(
          "success",
          "Approval to admin successfully, please check for update"
        );
        setRefetch((prev) => !prev);
        onClearDataChoose();
      }
    });
  };
  return (
    <>
      <Col md={22} xl={16} xxl={12}>
        <Row gutter={[16, 15]}>
          {/* <Col span={24}> */}
          {/* <Row>
              <Col span={6}>
                <p className={style.title_ApprovalRequest}>Approval Request</p>
              </Col>
              <Col span={6}>
                <Checkbox
                  value="product"
                  checked={selectedCheckbox === 'product'}
                  onChange={() => [handleCheckboxChange, setIsViewSelectProduct(true)]}
                >
                  Product Selection
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox
                  value="user"
                  checked={selectedCheckbox === 'user'}
                  onChange={() => [handleCheckboxChange, setIsViewSelectUser(true)]}
                >
                  User List
                </Checkbox>
              </Col>
            </Row> */}
          {/* </Col> */}
          <Col span={24}>
            <Row gutter={[20, 0]}>
              <Col span={4}>
                <p>Product</p>
              </Col>
              <Col span={12}>
                <Input value={selectProduct} placeholder="Select Product" />
              </Col>
              <Col span={6}>
                <Button
                  onClick={() => setIsViewSelectProduct(true)}
                  className={style.antBtnFont}>
                  Select Product
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 0]}>
              <Col span={4}>
                <p>User</p>
              </Col>
              <Col span={12}>
                <Input value={selectUser} placeholder="Select Users" />
              </Col>
              <Col span={6}>
                <Button onClick={() => setIsViewSelectUser(true)}>
                  Select Student
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 0]}>
              <Col span={4}>
                <p>Expiration Date</p>
              </Col>
              <Col span={20}>
                {product_list.length > 0 && (
                  <p className="font-bold">{expiration105}</p>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col span={4}>
                <Button onClick={onSendApprovalToAdmin}>Confirm</Button>
              </Col>
              <Col span={4}>
                <Button onClick={onClearDataChoose}>Cancel</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      {isViewSelectProduct ? (
        <PopupListProduct
          setOpenPopup={setIsViewSelectProduct}></PopupListProduct>
      ) : null}
      {isViewSelectUser ? (
        <PopupListUser setOpenPopup={setIsViewSelectUser}></PopupListUser>
      ) : null}
    </>
  );
};

export default FormApprovalRequest;
