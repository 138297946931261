import { Tabs } from "antd";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import InforAdminManagement from "./components/InforAdminManagement";
import ListAdmin from "./components/ListAdmin";
import PointManager from "./components/PointManager";
import style from "./style.module.css";

const { TabPane } = Tabs;

const AdminUser = (props) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [value, setValue] = useState("1");
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className={style.container_Dashboard}>
      <div className={style.content_Dashboard}>
        <p className={style.dashboard_title}>User</p>
        <Tabs activeKey={value} onChange={handleSetValue}>
          <TabPane tab=" Information Management" key="1">
            <InforAdminManagement
              selectedUser={selectedUser}
              setRefetch={setRefetch}
            />
          </TabPane>
          <TabPane tab=" List (excel output)" key="2">
            <ListAdmin
              setSelectedUser={setSelectedUser}
              setTabValue={setValue}
              refetch={refetch}
            />
          </TabPane>
          <TabPane tab=" Point Management" key="3">
            <PointManager />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminUser;
