import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../assets/icon_svg/icon";
import IconMicroPhone from "../../../assets/icon_svg/microphone.png";
import { chatService } from "../../../services/chatService";
import RecordModal from "../../RecordModal/RecordModal";
import style from "./InputChat.module.css";

import { Button, Popover } from "antd";
import classNames from "classnames";
import { recommendation } from "../../../constants/brainStorming";
import {
  setAppear,
  setConversation,
  setQuestion,
  setSpeakerAudio,
  setThinking,
} from "../../../redux/chatSlice";
import { getRandomRecommendations } from "../../../utils";
import RecordModalUpgrade from "../../RecordModal/RecordModalUpgrade";

const ResTimeOut =
  "I’m sorry for the delay in my response. It seems like I'm experiencing a technical issue, but our team is doing their best to resolve it. Please refresh the page and try again. Thank you for your patience!";

function InputChat({ level }) {
  const dispatch = useDispatch();
  const [question, setQuestionText] = useState("");
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const [formData, setFormData] = useState(new FormData());
  const conversation = useSelector((state) => state.chatSlice.conversation);
  const thinking = useSelector((state) => state.chatSlice.thinking);
  const newQuestion = useSelector((state) => state.chatSlice.question);
  const newAnswer = useSelector((state) => state.chatSlice.answer);
  const newOnAppear = useSelector((state) => state.chatSlice.onAppear);
  const disable = useSelector((state) => state.chatSlice.disable);
  const [cookies, ,] = useCookies(["access_token"]);
  const [isOpenRecordModal, setIsOpenRecordModal] = useState(false);
  const [imageQuantity, setImageQuantity] = useState(1);
  const [regenerateText, setRegenerateText] = useState("");
  const textareaRef = useRef(null);
  const initialRecommendation = useMemo(
    () => getRandomRecommendations(recommendation, 4),
    []
  );
  useEffect(() => {
    const form = new FormData();
    form.append("lesson_name", lesson?.lesson_name);
    form.append("topic", lesson?.topic);
    form.append("lesson_id", lesson?.lessonId);
    setFormData(form);
  }, [lesson]);
  //
  useEffect(() => {
    if (newAnswer.length > 0) {
      dispatch(setAppear(false));
      dispatch(
        setConversation({
          type: "answer",
          text: newAnswer,
        })
      );
    }
  }, [newAnswer]);

  useEffect(() => {
    if (newQuestion) setQuestionText(newQuestion);
  }, [newQuestion]);

  let getAnswer = async (requirement) => {
    setRegenerateText("");
    try {
      const res = await chatService.chat(requirement, cookies["access_token"]);
      if (res) {
        const {
          data: { answer, link_audio },
        } = res;
        if (!answer || !link_audio) {
          dispatch(setThinking(false));
          setRegenerateText(requirement.question);
          return;
        }
        dispatch(setSpeakerAudio(res.data?.link_audio));
        dispatch(
          setConversation({
            type: "answer",
            text: res.data.answer.length > 0 ? res.data.answer : ResTimeOut,
            link_audio: res.data.link_audio,
          })
        );
      } else {
        dispatch(setThinking(false));
        setRegenerateText(requirement.question);
      }
      dispatch(setThinking(false));
      dispatch(setQuestion(""));
    } catch (error) {
      dispatch(setThinking(false));
      setRegenerateText(requirement.question);
    }
  };

  let getAnswerBotChatImage = async (requirement) => {
    setRegenerateText("");
    const bodyArr = Object.keys(requirement);
    const formData = new FormData();
    formData.append("number_img", imageQuantity);
    for (const item of bodyArr) {
      formData.append(item, requirement[item]);
    }

    try {
      const res = await chatService.chat_bot_image(
        formData,
        cookies["access_token"]
      );
      if (res) {
        const {
          data: { answer, url_image, link_audio },
        } = res;
        if (!answer || !url_image || !link_audio) {
          dispatch(setThinking(false));
          setRegenerateText(requirement.question);
          return;
        }
        dispatch(setSpeakerAudio(res.data?.link_audio));
        dispatch(
          setConversation({
            type: "answer",
            text: res.data.answer.length > 0 ? res.data.answer : ResTimeOut,
            link_audio: res.data.link_audio,
            url: res.data?.url_image,
          })
        );
      } else {
        dispatch(setThinking(false));
        setRegenerateText(requirement.question);
        return;
      }
      dispatch(setThinking(false));
      dispatch(setQuestion(""));
    } catch (error) {}
  };

  const handleSendMessage = async (text, delay) => {
    if (thinking) return;
    dispatch(setThinking(true));
    let simulateDelay = null;
    if (delay > 0) {
      simulateDelay = new Promise((resolve) => {
        setTimeout(() => {
          resolve(1);
        }, delay * 1000);
      });
    }
    await simulateDelay;
    dispatch(
      setConversation({
        type: "question",
        text: text,
      })
    );
    if (conversation.length === 1) {
      if (level === "UPGRADE") {
        getAnswerBotChatImage({
          question: text,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      } else {
        getAnswer({
          question: text,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      }
    } else {
      if (level === "UPGRADE") {
        getAnswerBotChatImage({
          question: text,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      } else {
        getAnswer({
          question: text,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      }
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (question.trim() == "") return;
      dispatch(setThinking(true));
      dispatch(
        setConversation({
          type: "question",
          text: question,
        })
      );
      setQuestionText("");

      if (conversation.length === 1) {
        if (level === "UPGRADE") {
          getAnswerBotChatImage({
            question: question,
            topic: lesson.topic ? lesson.topic : "Topic English Wing",
            lesson_name: lesson.lesson_name,
            lesson_id: lesson.lessonId,
          });
        } else {
          getAnswer({
            question: question,
            topic: lesson.topic ? lesson.topic : "Topic English Wing",
            lesson_name: lesson.lesson_name,
            lesson_id: lesson.lessonId,
          });
        }
      } else {
        if (level === "UPGRADE") {
          getAnswerBotChatImage({
            question: question,
            topic: lesson.topic ? lesson.topic : "Topic English Wing",
            lesson_name: lesson.lesson_name,
            lesson_id: lesson.lessonId,
          });
        } else {
          getAnswer({
            question: question,
            topic: lesson.topic ? lesson.topic : "Topic English Wing",
            lesson_name: lesson.lesson_name,
            lesson_id: lesson.lessonId,
          });
        }
      }
    }
  };

  const onChangeInputText = (e) => {
    setQuestionText(e.target.value);
    textareaRef.current.style.height = "3.5rem";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  };

  const handleOnClickSendMessage = () => {
    if (thinking) return;
    if (question.trim() === "") return;
    dispatch(setThinking(true));
    dispatch(
      setConversation({
        type: "question",
        text: question,
      })
    );
    setQuestionText("");

    if (conversation.length === 1) {
      if (level === "UPGRADE") {
        getAnswerBotChatImage({
          question: question,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      } else {
        getAnswer({
          question: question,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      }
    } else {
      if (level === "UPGRADE") {
        getAnswerBotChatImage({
          question: question,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      } else {
        getAnswer({
          question: question,
          topic: lesson.topic ? lesson.topic : "Topic English Wing",
          lesson_name: lesson.lesson_name,
          lesson_id: lesson.lessonId,
        });
      }
    }
  };

  return (
    <div className={style.container_inputChat}>
      <div className={classNames(style.inputForm, "relative")}>
        {conversation.length === 1 && (
          <div className="grid grid-cols-2 gap-2 w-full absolute bottom-[calc(100%+8px)] left-0">
            {initialRecommendation.map((item) => (
              <div
                className="border rounded-md p-2 text-center cursor-pointer hover:bg-gray-100 duration-200 flex justify-center items-center"
                onClick={() => {
                  handleSendMessage(item, 0);
                }}
                key={item}>
                <span className="whitespace-pre-line">{item}</span>
              </div>
            ))}
          </div>
        )}
        {regenerateText && (
          <div className="absolute bottom-[calc(100%+8px)] left-1/2 -translate-x-1/2 flex flex-col justify-center items-center gap-2 bg-white border w-full p-2 rounded-lg">
            <p className="text-sm text-red-500">
              There was an error generating a response
            </p>
            <button
              type="button"
              onClick={() => {
                handleSendMessage(regenerateText, 2);
                setRegenerateText("");
              }}
              className="w-fit bg-[#2aa799] text-white !hover:bg-[#2aa799] rounded-md px-4 py-2">
              Regenerate
            </button>
          </div>
        )}

        <textarea
          ref={textareaRef}
          disabled={thinking || disable ? true : false}
          value={newOnAppear ? question : ""}
          className={classNames(
            "py-4 pl-4 pr-[5.5rem] w-full border-2  rounded-lg no-scrollbar textareaScrollbar focus:outline-primary resize-none min-h-[3.5rem] h-[1rem] max-h-[7rem] overflow-auto"
          )}
          placeholder="Ask something"
          onChange={onChangeInputText}
          onKeyDown={onEnterPress}
          // rows={getRows()}
          // style={{ height: "auto", resize: "none" }}
        />
      </div>
      <div className="absolute right-7 bottom-[0.6rem] flex">
        {level === "UPGRADE" && (
          <Popover
            content={
              <div>
                <div className="grid grid-cols-2 gap-2">
                  {[1, 2, 3, 4].map((item) => (
                    <Button
                      onClick={() => {
                        setImageQuantity(item);
                      }}
                      key={item}>
                      {item}
                    </Button>
                  ))}
                </div>
              </div>
            }
            title="Select number of images to generate"
            trigger="click">
            <Button className="mr-3">{imageQuantity}</Button>
          </Popover>
        )}
        <button
          onClick={() => {
            setIsOpenRecordModal(true);
          }}>
          <img src={IconMicroPhone} className="mr-3" alt="" />
        </button>
        <button className={style.btnSend} onClick={handleOnClickSendMessage}>
          {Icon.Send}
        </button>
      </div>
      {level === "UPGRADE" ? (
        <>
          {imageQuantity === 1 && (
            <RecordModalUpgrade
              type="brainstorming"
              formData={formData}
              isOpenModal={isOpenRecordModal}
              setIsOpenModal={setIsOpenRecordModal}
              imageQuantity={1}
              setRegenerateText={setRegenerateText}
            />
          )}
          {imageQuantity === 2 && (
            <RecordModalUpgrade
              type="brainstorming"
              formData={formData}
              isOpenModal={isOpenRecordModal}
              setIsOpenModal={setIsOpenRecordModal}
              imageQuantity={2}
              setRegenerateText={setRegenerateText}
            />
          )}
          {imageQuantity === 3 && (
            <RecordModalUpgrade
              type="brainstorming"
              formData={formData}
              isOpenModal={isOpenRecordModal}
              setIsOpenModal={setIsOpenRecordModal}
              imageQuantity={3}
              setRegenerateText={setRegenerateText}
            />
          )}
          {imageQuantity === 4 && (
            <RecordModalUpgrade
              type="brainstorming"
              formData={formData}
              isOpenModal={isOpenRecordModal}
              setIsOpenModal={setIsOpenRecordModal}
              imageQuantity={4}
              setRegenerateText={setRegenerateText}
            />
          )}
        </>
      ) : (
        <RecordModal
          type="brainstorming"
          formData={formData}
          isOpenModal={isOpenRecordModal}
          setIsOpenModal={setIsOpenRecordModal}
          setRegenerateText={setRegenerateText}
        />
      )}
    </div>
  );
}

export default InputChat;
