import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { TableDataListPermision } from "../../../../components/TableData";
import { setEmail } from "../../../../redux/campusSlice";
import { APIService } from "../../../../services/apiService";
import style from "./style.module.css";

const { TabPane } = Tabs;

const ManagerSelection = () => {
  const [value, setValue] = useState("Korea");
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [user, setUser] = useState(null);
  const handleSetValue = (newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const handleRowClick = (record) => {
    dispatch(setEmail(record));
    setUser(record);
  };

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_list_account(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataUsers) {
      setUser(dataUsers[0]);
    }
  }, [dataUsers]);

  return (
    <div className={style.container_Infor}>
      <Col span={24}>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <div className={style.list_admin}>
              {/* <div className={style.checkBox}>
                <p>Manager Selection</p>
              </div> */}
              <div>
                <TableDataListPermision dataUsers={dataUsers} />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <p className="font-bold text-4xl w-full text-center mt-44">
              Coming soon
            </p>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default ManagerSelection;
