import { CloseCircleFilled } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import ChatMicroIcon from "../../../../../assets/icon_svg/ChatMicro.png";
import KingIcon from "../../../../../assets/icon_svg/KingIcon.png";
import StickerIcon from "../../../../../assets/icon_svg/SticketIcon.png";
import Candy from "../../../../../assets/icon_svg/candy.png";
import BotChatPet from "../../../../../components/BotChatPet/BotChat";
import { Notification } from "../../../../../components/Notification/index";
import { UseCharacterAnimation } from "../../../../../context/CharacterAnimation";
import { minusCurrentPoint } from "../../../../../redux/userSlice";
import { APIService } from "../../../../../services/apiService";
import style from "../../style.module.css";
import "./custome.css";

const InterFace = () => {
  const dispatch = useDispatch();
  const [typePop, setTypePop] = useState(0);
  const onChoosePopup = (index) => {
    setTypePop(index);
  };
  const currentPet = useSelector((state) => state.petSlice.curentlyPet);
  const { animations, animationsIndex, setAnimationIndex } =
    UseCharacterAnimation();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    APIService.exchange_item(
      {
        item_id: 1,
        pet_id: currentPet?.id,
      },
      cookies["access_token"]
    ).then((response) => {
      console.log(response);
      dispatch(minusCurrentPoint(100));
      Notification("success", "Exchange successfully!");
      if (response.data === -1) {
        Notification("error", "Exchange not success");
      }
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const customOkButtonProps = {
    className: "custom-ok-button",
  };
  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={customOkButtonProps}>
        <p style={{ textAlign: "center", fontSize: "20px" }}>
          Would you like to exchange candy bag with 100 point?
        </p>
      </Modal>

      <div className={style.label}>
        <div className={style.animationSticker}>
          {/* First item */}
          <div className="relative w-20 group">
            <div
              style={{
                background:
                  "radial-gradient(circle, rgba(248,188,18,1) 58%, rgba(0,0,0,0) 70%)",
              }}
              className={classNames(
                "absolute inset-0 -translate-y-1.5 group-hover:opacity-100 duration-100",
                typePop === 1 ? "opacity-100" : "opacity-0 "
              )}></div>
            <img
              alt=""
              className={classNames(
                "relative cursor-pointer w-full object-cover",
                typePop === 1
                  ? "brightness-110"
                  : "hover:brightness-110 duration-200"
              )}
              src={StickerIcon}
              onClick={() => onChoosePopup(1)}
            />
          </div>
          {/* Second item */}
          <div className="relative w-20 group">
            <div
              style={{
                background:
                  "radial-gradient(circle, rgba(248,188,18,1) 58%, rgba(0,0,0,0) 70%)",
              }}
              className={classNames(
                "absolute inset-0 -translate-y-1.5 group-hover:opacity-100 duration-100",
                typePop === 2 ? "opacity-100" : "opacity-0 "
              )}></div>
            <img
              alt=""
              className={classNames(
                "relative cursor-pointer w-full object-cover",
                typePop === 2
                  ? "brightness-110"
                  : "hover:brightness-110 duration-200"
              )}
              src={KingIcon}
              onClick={() => onChoosePopup(2)}
            />
          </div>
          {/* Third item */}
          <div className="relative w-20 group">
            <div
              style={{
                background:
                  "radial-gradient(circle, rgba(248,188,18,1) 58%, rgba(0,0,0,0) 70%)",
              }}
              className={classNames(
                "absolute inset-0 -translate-y-1.5 group-hover:opacity-100 duration-100",
                typePop === 3 ? "opacity-100" : "opacity-0 "
              )}></div>
            <img
              alt=""
              className={classNames(
                "relative cursor-pointer w-full object-cover",
                typePop === 3
                  ? "brightness-110"
                  : "hover:brightness-110 duration-200"
              )}
              src={ChatMicroIcon}
              onClick={() => onChoosePopup(3)}
            />
          </div>

          {typePop === 1 ? (
            <>
              <div className={style.popUpType1}>
                <div className={style.boxFirstType1}></div>
                <div className={style.boxLastType1}></div>

                <CloseCircleFilled
                  className={style.BtnClose}
                  onClick={() => setTypePop(0)}></CloseCircleFilled>
                <div className={style.contenPopUp}>
                  <p className={style.boxsticker}>Sticker</p>
                  <p className={style.comingSoon}>Coming Soon</p>
                  {/* <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className={style.item}>
                          <img src={StickerIcon} alt="" />
                        </div>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className={style.item}>
                          <img src={StickerIcon} alt="" />
                        </div>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className={style.item}>
                          <img src={StickerIcon} alt="" />
                        </div>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className={style.item}>
                          <img src={StickerIcon} alt="" />
                        </div>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className={style.item}>
                          <img src={StickerIcon} alt="" />
                        </div>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className={style.item}>
                          <img src={StickerIcon} alt="" />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col> */}
                </div>
              </div>
            </>
          ) : null}
          {typePop === 2 ? (
            <>
              <div className={style.popUpType2}>
                <div className={style.boxFirstType2}></div>
                <div className={style.boxLastType2}></div>
                <p className={style.boxsticker}>Item</p>
                <CloseCircleFilled
                  className={style.BtnClose}
                  onClick={() => setTypePop(0)}></CloseCircleFilled>
                <div className={style.contenPopUp}>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <div className={style.item}>
                            <img src={Candy} alt="" onClick={showModal} />
                            <p style={{ textAlign: "center" }}>Candy</p>
                            <p style={{ textAlign: "center" }}>100 Point</p>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            </>
          ) : null}
          {typePop === 3 ? (
            <>
              <div className={classNames(style.popUpType3)}>
                <div className={style.boxFirstType3}></div>
                <div className={style.boxLastType3}></div>

                <BotChatPet></BotChatPet>
                <CloseCircleFilled
                  className={style.BtnClose}
                  onClick={() => setTypePop(0)}></CloseCircleFilled>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {/* <Affix className={style.content_animations}>
        <Stack className={style.list_animations}>
          {animations.map((animation, index) => (
            <Button
            className={style.btn_animation}
              key={animation}
              variant={index === animationsIndex ? "filled" : "light"}
              onClick={() => setAnimationIndex(index)}
            >
              {animation}
            </Button>
          ))}
        </Stack>
      </Affix> */}
    </>
  );
};

export default InterFace;
