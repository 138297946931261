import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import avtLumio from "../../../../assets/avatar/Lion.png";
import { FormUser } from "../../../../components/Form";
import { TableDataUser } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";
import style from "./style.module.css";
const { TabPane } = Tabs;

const InforManagement = ({ selectedUser, setRefetch }) => {
  const [value, setValue] = useState("Korea");
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [user, setUser] = useState(selectedUser);
  const [disable, setDisable] = useState(true);
  const [changeStatus, setChangeStatus] = useState(false);
  const [isNewUserCreated, setIsNewUserCreated] = useState(false);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  const handleRowClick = (record) => {
    setUser(record);
  };

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_campus_user(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  const handle1 = async () => {
    setUser(null);
  };

  const handle2 = async () => {
    setDisable(false);
  };

  const handleCreateNewUser = async () => {
    await handle1();
    await handle2();
    setChangeStatus(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={style.container_Infor}>
      <div className={style.list_user}>
        <Tabs defaultActiveKey="1" onChange={handleSetValue}>
          <TabPane tab="Student" key="1">
            <TableDataUser
              dataUsers={dataUsers}
              handleClickRow={handleRowClick}
            />
          </TabPane>
        </Tabs>
      </div>
      <div className={style.user_infor}>
        <Col>
          <Row gutter={[10, 5]}>
            <Col span={5}>
              <p className={style.user_infor_title}>User Information</p>
              <div className={style.avatar_user}>
                <img
                  src={user?.avatar_url ? user?.avatar_url : avtLumio}
                  alt="avatarUser"
                />
                {/* <Upload>
                  <Button className={style.btn_chooseFile}>Choose File</Button>
                </Upload> */}
              </div>
            </Col>
            <Col span={18}>
              <FormUser
                setRefetch={setRefetch}
                fetchData={fetchData}
                user={user}
                disable={disable}
                is_admin={false}
                setDisable={setDisable}
                changeStatus={changeStatus}
              />
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
};

export default InforManagement;
