import { createSlice } from "@reduxjs/toolkit";

export const ebookSlice = createSlice({
  name: "ebook",
  initialState: {
    title_book: "",
    author_name: "",
    product_ids: [],
    ebook_Id:"",
    book_index:null
  },
  reducers: {
    setEbook: (state, action) => {
      state.title_book = action.payload.title_book;
      state.author_name = action.payload.author_name;
      state.product_ids = action.payload.product_ids;
    },
    setEbookID :(state, action)=>{
      console.log(action.payload);
      state.ebook_Id = action.payload.ebook_Id;
    },
    setIndex :(state, action) =>{
      console.log(action.payload);
      state.book_index = action.payload.book_index
    }
  },
});

export const { setEbook, setEbookID, setIndex } = ebookSlice.actions;
export default ebookSlice.reducer;
