import { Modal } from "antd";
import { useEBook } from "../../context/ViewEbook";
import EbookItem from "./EbookItem";
const ViewEbookModal = ({ isModalOpen, setIsModalOpen }) => {
  const { ebook } = useEBook();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title={`${ebook?.book_title} - ${ebook.author_name}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1100}
      footer={null}
    >
      <div className="bg-gray-200 py-6">
        <EbookItem />
      </div>
    </Modal>
  );
};
export default ViewEbookModal;
