import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarBot from "../../../assets/avatar/Lion.png";
import {
  resetConversation,
  setLoadHistoryConversation,
} from "../../../redux/chatSlice";
import Loading from "../../Loading/Loading";
import TypingEffect from "../../typingEffect/TypingEffect";
import MessageBlock from "./MessageBlock";
import style from "./contentChat.module.css";

function ContentChat() {
  const dispatch = useDispatch();
  const divRef = useRef();
  const thinking = useSelector((state) => state.chatSlice.thinking);
  const conversation = useSelector((state) => state.chatSlice.conversation);
  const [isLoadingData, setIsLoadingData] = useState(false);
  console.log("conversation", conversation);

  const onLoadChatHistory = async () => {
    setIsLoadingData(true);
    dispatch(
      setLoadHistoryConversation([
        {
          id: -1,
          type: "answer",
          text: "Hi there",
        },
      ])
    );
    setIsLoadingData(false);
  };

  useEffect(() => {
    dispatch(resetConversation());
    onLoadChatHistory();
    setTimeout(() => {
      if (divRef.current) {
        divRef.current.scrollTop = divRef.current?.scrollHeight;
      }
    }, 1);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current?.scrollHeight;
    }
  }, [conversation, thinking]);
  console.log("conversation", conversation);

  const [open, setOpen] = useState(false);
  const handleNextCopy = () => {
    setOpen(true);
  };

  const handleCloseCopy = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseCopy}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      // console.log(err);
    }
  };

  let renderConversation = () => {
    return (
      <>
        {isLoadingData ? <Loading></Loading> : null}
        {conversation?.map((mess, index) => (
          <Fragment key={mess.id}>
            {mess.type === "question" ? (
              <>
                {mess?.image && (
                  <img
                    className="h-32 object-cover ml-auto mt-4"
                    src={URL.createObjectURL(mess?.image)}
                    alt="img"
                  />
                )}
                <div key={mess.id} className={style.lineChatClient}>
                  <div className={style.fm_chat}>
                    <p className="break-words">{mess.text}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                {mess.type === "answer" ? (
                  <>
                    <div key={mess.id} className={style.lineChatBot}>
                      <div className={style.avt}>
                        <img src={AvatarBot} alt="" />
                      </div>

                      <div
                        className={style.fm_chat}
                        style={
                          window.innerWidth <= 900
                            ? null
                            : mess.text?.length <= 200
                            ? { maxWidth: "60%" }
                            : { maxWidth: "70%" }
                        }>
                        {mess?.url && (
                          <div className="grid grid-cols-2 gap-2 mb-3">
                            {mess?.url?.map((item) => (
                              <img
                                src={item}
                                alt="sub-img"
                                key={item}
                                className="w-full h-full object-cover rounded-lg"
                              />
                            ))}
                          </div>
                        )}
                        {index === conversation?.length - 1 ? (
                          <>
                            <TypingEffect
                              key={mess.id}
                              link_audio={mess.link_audio}
                              divRef={divRef}
                              content={mess.text}
                              idx={mess.id}
                              textKorea={mess.textKorea}
                            />
                          </>
                        ) : (
                          <MessageBlock
                            key={mess.id}
                            link_audio={mess.link_audio}
                            text={mess.text}
                            textKorea={mess.textKorea}
                            id={mess.id}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </Fragment>
        ))}

        {thinking ? (
          <>
            <div key="thingking" className={style.lineChatBot}>
              <div className={style.avt}>
                <img src={AvatarBot} alt="" />
              </div>
              <div className={style.loader}></div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseCopy}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <SnackbarContent
          action={action}
          message="Copied To Clipboard"
          sx={{ bgcolor: "white", color: "black" }}
        />
      </Snackbar>
      <div ref={divRef} className={style.container_mainChat}>
        {renderConversation()}
      </div>
    </>
  );
}

export default ContentChat;
