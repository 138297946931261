import React from "react";
import { Button, Col, DatePicker, Input, Row, Select, Space } from "antd";
import style from "./style.module.css";
import { APIService } from "../../services/apiService";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Notification } from "../../components/Notification/index";

const { Search } = Input;

const { RangePicker } = DatePicker;

const FormPointManager = (props) => {
  const [cookies] = useCookies(["access_token"]);
  const {
    setDataUser,
    setIsLoading,
    isLoading,
    handleSearch,
    placeholder = "Search",
  } = props;
  const [searchText, setSearchText] = useState("");

  const handleOnChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchForm = () => {
    handleSearch(searchText);
  };

  return (
    <>
      <div>
        <Col span={24}>
          <Row gutter={20} justify={"end"}>
            {/* <Col span={6}>
              <Row gutter={[16, 15]}>
                <Col span={24}>
                  <Select
                    showSearch
                    placeholder="Course > Lesson Count"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className={style.select_User}
                  />
                </Col>
                <Col span={24}>
                  {!type && (
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      className={style.select_User}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Space direction="vertical">
                <RangePicker showTime />
              </Space>
            </Col>
            <Col span={2}>
              <Button>Today</Button>
            </Col> */}
            <Col span={8}>
              <Search
                value={searchText}
                onChange={handleOnChangeSearch}
                onPressEnter={handleSearchForm}
                onSearch={handleSearchForm}
                placeholder={placeholder}
                allowClear
                enterButton
                disabled={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default FormPointManager;
