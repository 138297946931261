import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Radio,
  Col,
  Row,
  DatePicker,
  Select,
} from "antd";
import { APIService } from "../../../services/apiService";
import style from "./style.module.css";
import { useEffect } from "react";
import { RollbackOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import left_icon from "../../../assets/avatar/Letf_bird.png";
import { Notification } from "../../../components/Notification/index";
import right_icon from "../../../assets/avatar/Right_bird.png";
import "./custom-antd.css";
import classNames from "classnames";

const { Option } = Select;
const plainOptions = [
  "Agree to the terms and conditions",
  "Consent the handling personal information",
  "Agree to reveice marketing emails",
];

const defaultCheckedList = ["Apple", "Orange"];

const RegisterForm = () => {
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [statusCheck, setStatusCheck] = useState(0);
  const [isVerifyTalkSamId, setIsVerifyTalkSamId] = useState(true);
  const [isVerify, setIsVerify] = useState(false);
  const [statusVerify, setStatusVerify] = useState(false);
  const [existsEmail, setExistEmail] = useState(false);
  const [waittingVerify, setwaittingVerify] = useState(false);
  const [step, setStep] = useState(1);
  const [status, setStatus] = useState("");

  const [viewCampus, setViewCampus] = useState([]);
  const campus_list = useSelector((state) => state.adminSlice.campus_list);
  const country_list = useSelector((state) => state.adminSlice.country_list);
  const CheckboxGroup = Checkbox.Group;

  const [selectedValue, setSelectedValue] = useState("");

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChangeCountry = () => {
    if (form.getFieldValue("country")) {
      const cam = [];
      campus_list?.forEach((element) => {
        if (element.country_id === form.getFieldValue("country")) {
          cam.push(element);
        }
      });
      setViewCampus(cam);
    }
  };

  const onVerifyEmail = () => {
    const email = form.getFieldValue("email");
    if (email) {
      APIService.send_verify_email({
        email: email,
      })
        .then((response) => {
          const check = response.data.is_exists;
          if (!check) {
            setIsVerify(true);
            setStatusCheck(0);
            setwaittingVerify(true);
            setExistEmail(false);
            Notification("success", "Verify code was sent");
            return;
          } else {
            setStatusCheck(1);
            setwaittingVerify(false);
            setExistEmail(true);
          }
        })
        .catch((error) => {
          setIsVerify(false);
          console.error(error);
        });
    }
  };

  const onSendCode = () => {
    const email = form.getFieldValue("email");
    const code = form.getFieldValue("code");
    if (email && code) {
      APIService.send_code({
        email: email,
        code: parseInt(code),
      })
        .then((response) => {
          const check = response.data.status;
          if (!check) {
            Notification("error", "Not verify! Please check verify code");
            return;
          }
          setStatusCheck(2);
          setStatusVerify(true);
          setwaittingVerify(false);
          setIsVerify(false);
        })
        .catch((error) => {
          console.error(error); // On error, print the error
        });
    }
  };

  const onRegister = async () => {
    try {
      await form.validateFields();
      const password = form.getFieldValue("password");
      const email = form.getFieldValue("email");
      const verify_password = form.getFieldValue("verify_password");
      if (email && statusCheck !== 2) {
        setIsVerifyTalkSamId(true);
        Notification("error", "Please Verify Email");
        return;
      }

      if (password !== verify_password) {
        setIsVerifyTalkSamId(true);
        Notification("error", "Password difference verify password");
        return;
      }

      const full_name = form.getFieldValue("fullname");
      const nick_name = form.getFieldValue("nick_name");
      const birthdate = form.getFieldValue("birth_date");
      const country = form.getFieldValue("country");
      const campus = form.getFieldValue("campus");
      const talksam_id = form.getFieldValue("talksam_id");
      const formdata = {
        email: email,
        password: password,
        full_name: full_name,
        nick_name: nick_name,
        birth_date: birthdate,
        country: country,
        campus: campus,
        talksam_id: talksam_id,
      };
      APIService.register(formdata)
        .then((response) => {
          if (response.data.success) {
            Notification("success", "Success Register");
            window.location.href = "login";
          } else {
            Notification("error", "Fail Register! " + response.data.user_id);
          }
        })
        .catch((error) => {});
    } catch (errorInfo) {
      // setIsVerifyTalkSamId(true);
    }
  };

  const onVerifyTalkSamId = () => {
    setStatus("validating");
    if (form.getFieldValue("talksam_id")) {
      APIService.auth_talksamid({
        tmbid: form.getFieldValue("talksam_id"),
      })
        .then((response) => {
          if (response.data) {
            setStatus("success");
            Notification("success", "Verify talksam id success");
            setIsVerifyTalkSamId(false);
          } else {
            setStatus("error");
            Notification("error", "Verify talksam id fail!");
            setIsVerifyTalkSamId(true);
          }
        })
        .catch((error) => {});
    } else {
      Notification("warning", "Please input talksam id");
      setStatus("");
    }
  };

  const handleCheckFormStep1 = () => {
    if (statusVerify === true) {
      setStep(2);
    }
  };

  const handleCheckFormStep2 = async () => {
    try {
      await form.validateFields();
      setStep(3);
    } catch (error) {}
  };

  const handleBackStep = () => {
    if (step !== 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setIsVerify(false);
  }, []);

  return (
    <div className="container mx-auto">
      <img src={left_icon} alt="icon" className={style.left_icon} />
      <img src={right_icon} alt="icon" className={style.right_icon} />
      <div className="w-full border-[3px] bg-white/60 rounded-2xl md:max-w-xl py-10">
        <div className="separator">
          <span className={style.text}>Register</span>
        </div>
        <div className={style.content_form}>
          <Form
            className={style.content_box_form}
            layout="vertical"
            form={form}>
            {step === 1 && (
              <>
                <Form.Item
                  name="email"
                  label="Email"
                  hasFeedback={statusCheck !== 0 ? true : false}
                  validateStatus={
                    statusCheck !== 0
                      ? statusCheck === 2
                        ? "success"
                        : "error"
                      : null
                  }
                  className={style.labelInput}>
                  <Row gutter={[10, 10]}>
                    <Col span={19}>
                      <Input className={style.inputForm} placeholder="Email" />
                    </Col>
                    <Col span={5}>
                      <Button className={style.btnForm} onClick={onVerifyEmail}>
                        SEND CODE
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

                {/* {isVerify ? (
                  <> */}
                <Form.Item
                  name="code"
                  label="Validate code"
                  // hasFeedback
                  validateStatus="validating"
                  help="Please input code send in email"
                  className={style.labelInput}>
                  <Row gutter={[10, 10]}>
                    <Col span={19}>
                      <Input
                        className={style.inputForm}
                        placeholder="Code"
                        id="validating"
                      />
                    </Col>
                    <Col span={5}>
                      <Button className={style.btnForm} onClick={onSendCode}>
                        VERIFY
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                {/* </>
                ) : null} */}
                <div className="w-full flex justify-center mt-12">
                  <button
                    type="button"
                    className={classNames(
                      "px-4 py-2  rounded-lg font-medium text-lg",
                      statusVerify
                        ? "bg-[#fe998d]"
                        : "bg-gray-200 cursor-not-allowed"
                    )}
                    onClick={handleCheckFormStep1}
                    disabled={!statusVerify}>
                    CONTINUE
                  </button>
                </div>
                {/* {statusVerify ? (
                  <div className="w-full flex justify-center mt-6">
                    <button
                      type="button"
                      className="px-4 py-2 bg-[#fe998d] rounded-lg font-medium text-lg"
                      onClick={handleCheckFormStep1}>
                      CONTINUE
                    </button>
                  </div>
                ) : (
                  <div className="w-full flex justify-center mt-6">
                    <button
                      type="button"
                      className="px-4 py-2 bg-gray-200 rounded-lg mt-10 font-medium text-lg"
                      disabled
                      onClick={handleCheckFormStep1}>
                      CONTINUE
                    </button>
                  </div>
                )} */}
              </>
            )}

            {step === 2 && (
              <>
                <Form.Item
                  className={style.labelInput}
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      pattern: /^[a-zA-Z0-9 ]{8,20}$/,
                      message: "The password must be  the lowest 8 characters",
                    },
                  ]}>
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  className={style.labelInput}
                  name="verify_password"
                  label="Verify Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input verify your password!",
                    },
                    {
                      pattern: /^[a-zA-Z0-9 ]{8,}$/,
                      message: "The password must be  the lowest 8 characters",
                    },
                  ]}>
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  className={style.labelInput}
                  name="fullname"
                  label="Name ( please enter your full name )"
                  rules={[
                    {
                      required: true,
                      message: "Please input your full name",
                    },
                  ]}>
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  className={style.labelInput}
                  name="nick_name"
                  label="Nick Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your nick name",
                    },
                  ]}>
                  <Input placeholder="NickName" />
                </Form.Item>
                <Form.Item
                  className={style.labelInput}
                  name="birth_date"
                  label="Birth Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select your birth date",
                    },
                  ]}>
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Button
                  className={style.btn_next}
                  onClick={handleCheckFormStep2}>
                  CONTINUE
                </Button>
              </>
            )}
            {step === 3 && (
              <>
                <Form.Item
                  name="country"
                  label="Country"
                  hasFeedback
                  className={style.labelInput}
                  rules={[
                    {
                      required: true,
                      message: "Please select your country!",
                    },
                  ]}>
                  <Select
                    onChange={onChangeCountry}
                    placeholder="Please select a country">
                    {country_list?.map((c) => {
                      return <Option value={c.id}>{c.name}</Option>;
                    })}
                  </Select>
                </Form.Item>{" "}
                <Form.Item
                  name="campus"
                  label="Campus"
                  hasFeedback
                  className={style.labelInput}
                  rules={[
                    {
                      required: true,
                      message: "Please select your campus!",
                    },
                  ]}>
                  <Select placeholder="Please select a campus">
                    {viewCampus?.map((c) => {
                      return <Option value={c.id}>{c.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="talksam_id"
                  label="Campus TALKSAM ID"
                  hasFeedback
                  validateStatus={status}
                  className={style.labelInput}
                  rules={[
                    {
                      required: true,
                      message: "Please input campus",
                    },
                  ]}>
                  <Row gutter={[10, 10]}>
                    <Col span={18}>
                      <Input placeholder="TalkSam Id" />
                    </Col>
                    <Col span={6}>
                      <Button
                        className={style.btnFormTalkSam}
                        onClick={onVerifyTalkSamId}>
                        VERIFY
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                <Form layout="vertical" className={style.checkBoxGroup_style}>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    style={{ color: "#00A3BB" }}>
                    Agree to select all
                  </Checkbox>
                  <Divider className={style.diviver} />
                  <CheckboxGroup
                    className={style.checkeboxColor}
                    options={plainOptions}
                    value={checkedList}
                    onChange={onChange}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  />
                </Form>
                <div className={style.formJoin}>
                  <Button
                    className={style.btnFormJoin}
                    disabled={!checkAll || isVerifyTalkSamId}
                    onClick={onRegister}>
                    JOIN
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
        {/* {statusCheck !== 0 && statusCheck !== 1 && (
          <button className={style.btn_back} onClick={handleBackStep}>
            Back
          </button>
        )} */}
        {step !== 1 ? (
          <ArrowLeftOutlined
            className={style.btn_back}
            onClick={handleBackStep}></ArrowLeftOutlined>
        ) : null}
      </div>
    </div>
  );
};

export default RegisterForm;
