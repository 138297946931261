import React, { useEffect, useState } from "react";
import style from "../style.module.css";
import TopicWritingCenter from "../../../../../components/TopicWritingCenter/index";
import InputField from "../../../../../components/InputField";
// import { setContentOriginal } from "../../../redux/lessionSlice";
import BtnAvatar from "../../../../../assets/avatar/avatarUser.png";
import Loading from "../../../../../components/Loading/Loading";

const Drafting = ({ setPopupBook, setSumited, checkWords }) => {
  const [type, setType] = useState(2);
  return (
    <div className={style.content_writingCenter}>
      <TopicWritingCenter type={type} setPopupBook={setPopupBook} />
      <InputField setSumited={setSumited} />
    </div>
  );
};

export default Drafting;
