import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import Model from "../ModelPet/component/Model";
import InterFace from "./component/InterFace";
import ListOwnedPet from "./component/ListOwnedPet";
import Model2d from "./component/Model2d";
import { useSelector } from "react-redux";
// const ModelPet = () => {
//   return (
//     <>
//       <ambientLight />
//       <directionalLight
//         position={[-5, 5, 5]}
//         castShadow
//         shadow-mapSize-width={1024}
//         shadow-mapSize-height={1024}
//       />
//       <group position={[0, -1, 0]}>
//         <Model />

//       </group>
//       <mesh
//         rotation={[-0.5 * Math.PI, 0, 0]}
//         position={[0, -1, 0]}
//         receiveShadow
//       >
//         <planeBufferGeometry args={[10, 10, 1, 1]} />
//         <shadowMaterial transparent opacity={0.2} />
//       </mesh>
//     </>
//   );
// };

const ModelViewer = (props) => {
  const { is2D, avatarPet } = props;
  // const currentPet = useSelector((state) => state.petSlice.curentlyPet);
  return (
    <>
      {is2D ? (
        <>
          <Model2d></Model2d>
        </>
      ) : // currentPet.url_model ?
      // <Canvas>
      //   <ModelPet />
      //   <OrbitControls />
      // </Canvas> : null
      null}

      <InterFace avatarPet={avatarPet} />
      {/* <ListOwnedPet></ListOwnedPet> */}
    </>
  );
};

export default ModelViewer;
