import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Input, notification } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import lion from "../../../../../../assets/model/pet1.png";
import lemon from "../../../../../../assets/model/pet2.png";
import cat from "../../../../../../assets/model/pet3.png";
import monkey from "../../../../../../assets/model/pet4.png";
import { Notification } from "../../../../../../components/Notification/index";
import {
  setImageIndex,
  setImageStableDiffusion,
} from "../../../../../../redux/lessionSlice";
import { APIService } from "../../../../../../services/apiService";
import "./custom.css";
import styles from "./style.module.css";
import "./thumbStyle.css";

const listPet = [lion, lemon, cat, monkey];

const ImageCarousel = ({ onNext, mainIMG, updateData }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(listPet[0]);
  const [cookies] = useCookies(["access_token"]);
  const [isImageStableDiffusionSet, setIsImageStableDiffusionSet] =
    useState(false);
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const [prompt, setPrompt] = useState("");
  const { TextArea } = Input;
  const ButtonGroup = Button.Group;
  const handleNext = () => {
    onNext(true);
    mainIMG(selectedImage);
  };
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [openNotifi, setOpenNofiti] = useState(false);
  const [numberIMG, setNumberIMG] = useState(0);

  const handleCarouselChange = (currentIndex) => {
    setNumberIMG(currentIndex);
    setSelectedImage(lesson.image_stable_diffusion[currentIndex]);
    dispatch(setImageIndex(currentIndex));
  };

  // useEffect(() => {
  //   if (
  //     !isImageStableDiffusionSet &&
  //     lesson.image_stable_diffusion.length === 0
  //   ) {
  //     dispatch(setImageStableDiffusion(listPet));
  //     setIsImageStableDiffusionSet(true);
  //   }
  // }, [dispatch, isImageStableDiffusionSet]);

  async function handleGenerate(e) {
    try {
      setDisableBtn(true);
      setLoading(true);
      if (prompt.length > 0) {
        const res = await APIService.get_image_stablediffusion(
          {
            content: prompt,
          },
          cookies["access_token"]
        );

        if (res?.data?.length > 0) {
          setSelectedImage(res.data[0]);
          dispatch(setImageIndex(0));
          setNumberIMG(0);
          setLoading(false);
          setDisableBtn(false);
          dispatch(setImageStableDiffusion([...listPet, ...res.data]));
        } else {
          Notification(
            "warning",
            "Please provide additional keywords or descriptions to generate images!"
          );
          setLoading(false);
          setDisableBtn(false);
        }
      } else {
        setLoading(false);
        setDisableBtn(false);
        Notification(
          "warning",
          "Please enter the keyword to perform Generate!"
        );
      }
    } catch (error) {}
  }

  // useEffect(() => {
  //   console.log("lesson", lesson);
  //   if (lesson.number_img === null) {
  //     setSelectedImage(listPet[0]);
  //   } else {
  //     setSelectedImage(lesson.image_stable_diffusion[lesson.number_img]);
  //   }
  // }, [lesson, selectedImage]);

  useEffect(() => {
    if (prompt === "") {
      setOpenNofiti(true);
    } else {
      setOpenNofiti(false);
    }
  }, [prompt]);

  useEffect(() => {
    if (_.get(updateData, "[0].image_stable_diffusion", "")) {
      dispatch(
        setImageStableDiffusion(
          _.get(updateData, "[0].image_stable_diffusion")?.split(",")
        )
      );
      setNumberIMG(_.get(updateData, "[0].number_img", 0));
      setSelectedImage(
        _.get(updateData, "[0].image_stable_diffusion")?.split(",")[
          _.get(updateData, "[0].number_img", 0)
        ]
      );
      dispatch(setImageIndex(_.get(updateData, "[0].number_img", 0)));
    }
  }, [dispatch, updateData]);

  return (
    <>
      <div className={styles.boxBtnStableDiff}>
        <ButtonGroup className="group_button">
          <Button
            type="primary"
            onClick={handleNext}
            className={styles.btn_Back}>
            <LeftOutlined />
            BACK
          </Button>
        </ButtonGroup>
        <ButtonGroup className="group_button">
          <Button
            type="primary"
            onClick={handleNext}
            className={styles.btn_Next}>
            Next
            <RightOutlined />
          </Button>
        </ButtonGroup>
      </div>
      <div className={styles.content_boxPrompt}>
        <div className={styles.boxPrompt}>
          <div className={styles.boxLeftPrompt}>
            <div className={styles.boxLeft}>
              <div className={styles.title_Coures}>{lesson.course_name}</div>
              <div className={styles.title_boxleft}>UPDATE ARTWORK PROMPT</div>
              <TextArea
                value={prompt}
                autoSize={{ minRows: 3, maxRows: 6 }}
                className={styles.box_keyPrompt}
                onChange={(e) => setPrompt(e.target.value)}
                validateStatus={prompt}></TextArea>

              {loading && !openNotifi ? (
                <LoadingOutlined className={styles.icon_loadDing} />
              ) : null}
            </div>
            <Button
              className="btn_Generate"
              onClick={handleGenerate}
              disabled={disableBtn}>
              Generate
            </Button>
            {openNotifi && <>{contextHolder}</>}
          </div>

          <div className={styles.boxRightPrompt}>
            {/* <Carousel
              swipeable
              emulateTouch
              onChange={handleCarouselChange}
              className={styles.content_boxRightPrompt}
              defaultValue={selectedImage}
            >
              {lesson.image_stable_diffusion.map((item, index) => (
                <div key={index} className={styles.img_boxRightPrompt}>
                  <img src={item} alt="" className={styles.img} />
                </div>
              ))}
            </Carousel> */}

            <div
              className="flex flex-wrap w-[168px]"
              style={{ rowGap: "8px", columnGap: "8px" }}>
              {lesson.image_stable_diffusion.map((item, index) => (
                <>
                  {index < 4 && (
                    <div
                      key={index}
                      className="w-[80px] rounded-full cursor-pointer">
                      <img
                        src={item}
                        alt=""
                        className="rounded-full"
                        onClick={() => handleCarouselChange(index)}
                      />
                    </div>
                  )}
                </>
              ))}
            </div>

            <div
              className={styles.img_boxRightPrompt}
              style={{ margin: "0 50px" }}>
              <img
                src={selectedImage}
                alt=""
                className="w-[200px] h-[200px] xl:w-[250px] xl:h-[250px] 2xl:w-[460px] 2xl:h-[460px]"
                style={{
                  borderRadius: "30px",
                }}
              />
            </div>

            {lesson.image_stable_diffusion?.length > 4 && (
              <div
                className="flex flex-wrap w-[168px]"
                style={{ rowGap: "8px", columnGap: "8px" }}>
                {lesson.image_stable_diffusion.map((item, index) => (
                  <>
                    {index > 3 && (
                      <div
                        key={index}
                        className="w-[80px] rounded-full cursor-pointer">
                        <img
                          src={item}
                          alt=""
                          className="rounded-full"
                          onClick={() => handleCarouselChange(index)}
                        />
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageCarousel;
