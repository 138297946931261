import React from "react";
import style from "./chatBot.module.css";
import ContentChat from "./MainChat/ContentChat";
import InputChat from "./MainChat/InputChat";
import { useSelector } from "react-redux";
import { Radio } from 'antd';
import { useState } from "react";
function BotChatPet() {
  const [typeChat,setTypeChat] = useState("text")
  // const handleSizeChange = (e) => {
  //   setTypeChat("text");
  // };

  return (
    <div className={style.container_chatbot}>
      <ContentChat />
      <InputChat />
    </div>
  );
}

export default BotChatPet;
