import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import style from "./style.module.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ManagerSelection from "./components/ManagerSelection";
import CategoryCampus from "./components/CategoryCampus";
import AdminPageList from "./components/AdminPageList";
import AdminNoticeManager from "./components/AdminNoticeManager";

import iconWeb from "../../../assets/logo/IconWeb.svg";


const { TabPane } = Tabs;

const AdminWeb = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [value, setValue] = useState("course");

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className={style.container_Dashboard}>
      <div className={style.content_Dashboard}>
        <p className={style.dashboard_title}> <img src={iconWeb} alt=""/>Web</p>
        <Tabs defaultActiveKey="1" onChange={handleSetValue}>
          <TabPane tab="Page List" key="1">
            <AdminPageList />
          </TabPane>
          <TabPane tab="Notice Management" key="2">
            <AdminNoticeManager />
          </TabPane>
          <TabPane tab="Inquiry Management" key="4">
            <CategoryCampus />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminWeb;
