import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import { Button, Checkbox, Col, Row, Tabs, Upload } from "antd";
import {
  TableDataListAdmin,
  TableDataProductRegistration,
  TableDataUser,
} from "../../../../components/TableData";

import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
import { useDispatch } from "react-redux";
import { setEmail } from "../../../../redux/campusSlice";
import { useSelector } from "react-redux";
import { FormRoleRegistration } from "../../../../components/Form/FormRoleRegistration";

const { TabPane } = Tabs;

const ProductRegistration = () => {
  const [value, setValue] = useState("Korea");
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [product, setProduct] = useState(null);
  const [categorys, setCategorys] = useState([]);
  const [courses, setCourses] = useState([]);
  const handleSetValue = (newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const handleClickRow = (record) => {
    setProduct(record);
  };

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_product(accessToken);
      setDataUser(data.data);
      const res1 = await APIService.get_all_list_setiing_category(accessToken);
      setCategorys(res1.data);
      // console.log(res1.data)
      const res2 = await APIService.get_all_only_course(accessToken);
      setCourses(res2.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataUsers) {
      setProduct(dataUsers[0]);
    }
  }, [dataUsers]);

  return (
    <div className={style.container_Infor}>
      <Col span={24}>
        <Row gutter={[40, 20]}>
          <Col span={12}>
            <div className={style.list_admin}>
              {/* <div className={style.checkBox}>
                <p>List</p>
              </div> */}
              <TableDataProductRegistration
                dataUsers={dataUsers}
                handleClickRow={handleClickRow}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className={style.admin_pms}>
              <p className={style.user_infor_title}>Product Detail</p>
              <Row>
                <Col span={24}>
                  <FormRoleRegistration
                    courses={courses}
                    categorys={categorys}
                    product={product}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default ProductRegistration;
