import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { TableDataClassMagnager } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";

import Search from "antd/es/input/Search";
import { Notification } from "../../../../components/Notification/index";
import CampusSelect from "../../../../components/Selector/campus-select";
import CourseSelectModal from "../../DashBoardCampus/components/CourseSelectModal";

const ArtworkManager = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const accessToken = cookies["access_token"];
  const [isExporting, setIsExporting] = useState(false);
  const [filterValue, setFilterValue] = useState({
    search: "",
    campus: "",
  });

  console.log("filteredData", filteredData);

  const handleSelectedDataChange = (selectedData) => {
    setSelectedData(selectedData);
  };

  const onSearch = (value) => {
    setFilterValue({ ...filterValue, search: value });
  };

  const handleCampusFilter = (value) => {
    setFilterValue({ ...filterValue, campus: value });
  };

  useEffect(() => {
    const { campus, search } = filterValue;
    if (!campus && !search) {
      setFilteredData(dataUsers);
      return;
    }

    let resultSearch = [...dataUsers];

    resultSearch = dataUsers?.filter(
      (item) =>
        item?.nick_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.fullname?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.campus?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.talksam_id?.toLowerCase()?.includes(search?.toLowerCase())
    );
    if (campus) {
      resultSearch = resultSearch?.filter(
        (item) => item?.campus?.toLowerCase() === campus?.toLowerCase()
      );
    }
    setFilteredData(resultSearch);
  }, [filterValue, dataUsers]);

  useEffect(() => {
    if (!selectedCourses.length) {
      const newFilter = JSON.parse(JSON.stringify(filter));
      delete newFilter["courses"];
      setFilter(newFilter);
      return;
    }
    setFilter({ ...filter, courses: selectedCourses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourses]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeStartDate = (_, dateString) => {
    if (!dateString) {
      const newFilter = JSON.parse(JSON.stringify(filter));
      delete newFilter["start_date"];
      setFilter(newFilter);
      return;
    }
    setFilter({ ...filter, start_date: dateString });
  };

  const onChangeEndDate = (_, dateString) => {
    if (!dateString) {
      const newFilter = JSON.parse(JSON.stringify(filter));
      delete newFilter["end_date"];
      setFilter(newFilter);
      return;
    }
    setFilter({ ...filter, end_date: dateString });
  };

  const handleFilter = () => {
    const isFilter = Object.keys(filter).length === 0;
    if (isFilter) {
      setHasFilterData(false);
    } else {
      setHasFilterData(true);
    }
    setRefetch((prev) => !prev);
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await APIService.get_all_user_admin_role(accessToken);
        setDataUser(data.data);
        setFilteredData(data.data);
      } catch (error) {}

      setIsLoading(false);
    }

    async function fetchFilterData() {
      setIsLoading(true);
      try {
        const data = await APIService.get_all_user_lms(accessToken, filter);
        setDataUser(data.data);
        setFilteredData(data.data);
      } catch (error) {}
      setIsLoading(false);
    }

    if (hasFilterData) {
      fetchFilterData();
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const handleSaveExcel = () => {
    setIsExporting(true);
    let format = [...filteredData];
    format = format?.map((i) => ({
      ...i,
      lessons: i.lessons?.map((e) => ({
        ...e,
        point: e?.history?.evaluation?.everage_point ?? 0,
      })),
    }));

    APIService.export_excel_class_lms(accessToken, { users: format })
      .then(async (res) => {
        console.log("res", res);
        const response = await fetch(res.data);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "export.xlsx";
        link.click();
        Notification("success", "Save excel successfully!");
      })
      .catch(() => {
        Notification("error", "Error occurred");
      })
      .finally(() => {
        setIsExporting(false);
      });
  };
  return (
    <>
      <CourseSelectModal
        selectedCourses={selectedCourses}
        setSelectedCourses={setSelectedCourses}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="flex justify-end mt-4 gap-4">
              <CampusSelect onChange={handleCampusFilter} />
              <Search
                style={{
                  width: 400,
                }}
                placeholder="Search nickname, fullname, email, talksam ID, campus"
                allowClear
                enterButton="Search"
                onSearch={onSearch}
              />
            </div>
          </Col>
          <Col span={24}>
            <TableDataClassMagnager
              isArtwork
              hasFilterData={hasFilterData}
              isLoading={isLoading}
              selectedData={selectedData}
              dataUsers={filteredData}
              onSelectedDataChange={handleSelectedDataChange}
            />
          </Col>
        </Row>
      </Col>
      {/* Coming soon */}
      {/* <div className={style.comingSon}>
        <h1>Coming Soon</h1>
        
      </div> */}
    </>
  );
};

export default ArtworkManager;
