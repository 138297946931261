import React, { useState } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import logo from "../../../assets/logo/logo_wing.png";
import Button from "../../../components/Button";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import { useCookies } from "react-cookie";
import User from "../../../components/User";
import LoginPage from "../../../pages/LoginPage";

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [cookies] = useCookies(["access_token"]);
  const [popup, setPopup] = useState(false);
  const handleOpenPopup = () => {
    setPopup(!popup);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="sticky lg:hidden w-screen h-16 rounded-b-xl bg-white top-0 flex items-center justify-between px-6 z-40">
      <button type="button" onClick={toggle}>
        <HiMenuAlt2 size={30} />
      </button>
      <div
        className={classNames(
          "w-screen h-screen fixed top-0 left-0 bg-gray-900/20 z-40",
          isOpen ? "visible opacity-100" : "invisible opacity-0"
        )}>
        <div
          className={classNames(
            "relative max-w-[400px] h-screen bg-white p-10 duration-300",
            isOpen
              ? "translate-x-0 opacity-100 visible"
              : "-translate-x-full opacity-0 invisible"
          )}>
          <button
            type="button"
            className="absolute top-4 right-4"
            onClick={toggle}>
            <MdClose size={30} />
          </button>

          <div className="h-24 w-full flex items-center justify-center">
            <img
              src={logo}
              alt="Logo"
              className="h-full object-cover border-2"
            />
          </div>

          <div className="w-full flex flex-col mt-4 gap-2">
            <button onClick={toggle}>
              <NavLink
                className="element_menu w-full px-6 py-2 rounded-full bg-primary/20 font-medium flex items-center justify-center"
                to="/our-service">
                Our Service
              </NavLink>
            </button>
            <button onClick={toggle}>
              <NavLink
                className="element_menu w-full px-6 py-2 rounded-full bg-primary/20 font-medium flex items-center justify-center"
                to="/curriculum">
                Curriculum
              </NavLink>
            </button>
            <button onClick={toggle}>
              <NavLink
                className="element_menu w-full px-6 py-2 rounded-full bg-primary/20 font-medium flex items-center justify-center"
                to="/notice">
                Notice
              </NavLink>
            </button>
            <button onClick={toggle}>
              <NavLink
                className="element_menu w-full px-6 py-2 rounded-full bg-primary/20 font-medium flex items-center justify-center"
                to="/ranking">
                Ranking
              </NavLink>
            </button>
            <button onClick={toggle}>
              {" "}
              <NavLink
                className="element_menu w-full px-6 py-2 rounded-full bg-primary/20 font-medium flex items-center justify-center"
                to="/e-book">
                E-Book
              </NavLink>
            </button>
            <button onClick={toggle}>
              <NavLink
                className="element_menu w-full px-6 py-2 rounded-full bg-primary/20 font-medium flex items-center justify-center"
                to="/inquiry">
                Inquiry
              </NavLink>
            </button>
            {cookies["access_token"] && (
              <button onClick={toggle}>
                <NavLink
                  className="element_menu w-full px-6 py-2 rounded-full bg-primary/20 font-medium flex items-center justify-center"
                  to="/dashboard">
                  DashBoard
                </NavLink>
              </button>
            )}
          </div>
          <div className="gap-2 flex md:hidden mt-6 w-full justify-center items-center">
            <div onClick={toggle} className="w-auto h-auto">
              <Button
                className="element_menu border px-3 rounded-full py-1 border-primary"
                to="/register"
                text="Register"
              />
            </div>
            <div onClick={toggle} className="w-auto h-auto">
              <Button
                className="element_menu  px-3 border rounded-full py-1 border-primary"
                to="/login"
                text="Login"
              />
            </div>
          </div>
        </div>
      </div>
      <Link to="/">
        <div className="h-5/6 absolute top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 z-20">
          <img src={logo} alt="Logo" className="h-full object-cover" />
        </div>
      </Link>

      {!cookies["access_token"] ? (
        <div className="gap-2 hidden md:flex">
          <Button
            className="element_menu border px-3 rounded-full py-1 border-primary"
            to="/register"
            text="Register"
          />
          <Button
            className="element_menu  px-3 border rounded-full py-1 border-primary"
            to="/login"
            text="Login"
          />
        </div>
      ) : (
        <>
          <User
            alt="avatar"
            className="avatar_user"
            handle={handleOpenPopup}
            state={popup}
          />
          <LoginPage />
        </>
      )}
    </div>
  );
};

export default NavbarMobile;
