import React, { useEffect, useState, useRef } from "react";
import { Button, Cascader, Col, Form, Input, Row, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import style from "../../style.module.css";
import "./custom-antd.css";
import EbookFinal from "./EbookFinal";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
import html2canvas from "html2canvas";
import { useDispatch } from "react-redux";
import { setEbook } from "../../../../redux/ebookSlice";
import ButtonBack from "../../../../components/Button/ButtonBack";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
const formButtonWidth = "7vw";
const fontSizeLabel = "1.4vw";
const CreateEBook = ({ ebookId }) => {
  // const { ebookId, back } = props;

  const divRef = useRef(null);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    title_book: "",
    author_name: "",
    image_book: null,
    product_ids: [],
  });
  const [cookies] = useCookies(["access_token"]);
  const [listCourse, setListCourse] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [initialListItem, setInitialItems] = useState([]);
  const [residences, setResidences] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [isCoverImageSelected, setIsCoverImageSelected] = useState(false);
  const [blob, setBlod] = useState(null);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [isEbookOpened, setIsEbookOpened] = useState(false);
  const [book, setBook] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [openEbookFinal, setOpenEbookFinal] = useState(false);
  const bookSelector = useSelector((state) => state.ebookSlice);

  const currentUrl = useLocation();
  // Book edit
  //
  //Upload file
  // const props = {
  //   name: 'file',
  //   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //   headers: {
  //     authorization: 'authorization-text',
  //   },
  //   onChange(info) {
  //     if (info.file.status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const setValueForm = async (book_title, author_name) => {
    await form.setFieldsValue({
      title_book: book_title,
      author_name: author_name,
    });
  };
  const onHandleBack = () => {
    console.log(currentUrl.pathname);
    if (param.ebookId === "create") {
      navigate("/e-mybook/ebook");
    } else if (
      ebookId &&
      currentUrl.pathname !== "/ebook-making/artwork" &&
      currentUrl.pathname !== "/ebook-making/list"
    ) {
      navigate("/e-mybook/edit_back");
    } else {
      window.location.href = "/ebook-making/list";
    }
  };
  useEffect(() => {
    if (!param.ebookId) {
      console.log(ebookId);
      let bookItem = book.filter((item) => item.ebook_id === ebookId);
      if (bookItem && bookItem.length > 0) {
        setValueForm(bookItem[0].ebook_name, bookItem[0].author);
        setFormValues((prevValues) => ({
          ...prevValues,
          title_book: bookItem[0].ebook_name,
          author_name: bookItem[0].author,
        }));
      }
    } else {
      let bookItem = book.filter((item) => item.ebook_id == param.ebookId);
      if (bookItem && bookItem.length > 0) {
        setValueForm(bookItem[0].ebook_name, bookItem[0].author);
        setFormValues((prevValues) => ({
          ...prevValues,
          title_book: bookItem[0].ebook_name,
          author_name: bookItem[0].author,
        }));
      }
    }
  }, [ebookId, book]);

  useEffect(() => {
    if (listCourse) {
      setResidences(
        listCourse.map((item, index) => ({
          label: item.product_name,
          value: item.product_name,
          type: "product",
          id: item.product_id,
          children: item.course_id.map((item) => ({
            label: item.course_name,
            value: item.course_id,
            type: "course",
          })),
        }))
      );
    }
  }, [listCourse]);

  const handleFormValuesChange = (changedValues, allValues) => {
    if ("title_book" in changedValues) {
      setFormValues((prevValues) => ({
        ...prevValues,
        title_book: changedValues.title_book,
      }));
    }

    if ("author_name" in changedValues) {
      setFormValues((prevValues) => ({
        ...prevValues,
        author_name: changedValues.author_name,
      }));
    }
  };

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_course_product(accessToken);
      setListCourse(data.data);
    } catch (error) {}
  }

  async function fetchDataBook() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_book(accessToken);
      setBook(data.data);
      // const specificBook = data.data.filter((item)=>{
      //   return item.ebook_id=== ebookId;
      // })
      // setEditBookData(specificBook);
    } catch (error) {}
  }
  const hanldeOpenEbook = async () => {
    setIsEbookOpened(true);
    try {
      await form.validateFields();
      dispatch(setEbook(formValues));
      convertToImage();
      console.log("formValues", formValues);
      APIService.get_all_artwork_with_course_id(
        {
          course_id: selectedCourses,
        },
        cookies["access_token"]
      ).then((response) => {
        setInitialItems(response.data);
        console.log("response.data", response.data);
        setOpenEbookFinal(true);
      });
    } catch (error) {}
  };
  // const onHandleBackButton = (ebookIdBack) => {
  //   setOpenEbookFinal(false);
  //   ebookId = ebookIdBack;
  // }
  const onChange = (values, selectedOptions) => {
    const selectedProductCourses = [];
    const selectedProduct = [];
    const selectedIds = [];
    selectedOptions.forEach((value) => {
      if (value.length === 1) {
        // console.log(value[0].id);
        selectedIds.push(value[0].id);

        value[0].children.forEach((cid) => {
          selectedProduct.push();
          selectedProductCourses.push(cid.value);
        });
      } else if (value.length === 2) {
        selectedProductCourses.push(value[1].value);
      }
    });
    setSelectedCourses(selectedProductCourses);
    setIsProductSelected(selectedProductCourses.length > 0);
    setFormValues((prevValues) => ({
      ...prevValues,
      product_ids: selectedIds,
    }));
  };

  //Handle Image
  const handleImageUpload = (event) => {
    //html input file

    const file = event.target.files[0];
    setCoverImage(file);
    setImageData(URL.createObjectURL(file));
    setIsCoverImageSelected(true);

    //
    //ant design

    // const file = event.fileList[0];
    // if (event.file.status !== 'uploading') {
    // }
    // if (event.file.status === 'done') {
    //   console.log("a1");
    //   // message.success(`${info.file.name} file uploaded successfully`);
    //   setCoverImage(file);
    //   setImageData(URL.createObjectURL(file));
    //   setIsCoverImageSelected(true);
    // } else if (event.file.status === 'error') {
    // }

    //
  };
  const imageProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange: handleImageUpload,
  };
  //
  useEffect(() => {
    fetchData();
    fetchDataBook();
  }, []);

  const convertToImage = () => {
    html2canvas(divRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        console.log(URL.createObjectURL(blob));
        setBlod(blob);
      }, "image/png");
    });
  };
  const renderCreateBook = () => {
    return (
      <>
        <div className={classNames(style.container_createEbook, "mt-10 py-10")}>
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-10">
            <div
              style={{}}
              className="w-full max-w-xl lg:max-w-none flex flex-col">
              <Col span={24}>
                <Form
                  labelCol={{
                    span: 12,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  form={form}
                  onValuesChange={handleFormValuesChange}>
                  {/* <Form.Item>
                            <Row>
                              <Col span={24}>
                                <p>Making the front cover</p>
                              </Col>
                            </Row>
                          </Form.Item> */}
                  <Form.Item
                    label={
                      <span style={{ fontSize: fontSizeLabel }}>
                        Book Title
                      </span>
                    }
                    name="title_book"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: fontSizeLabel }}>
                        Add cover design image
                      </span>
                    }
                    name="image_book"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <input
                      id="image_book"
                      type="file"
                      onChange={handleImageUpload}
                    />
                    {/* <label className="absolute z-10" for="file">Choose a file (Click me)</label> */}

                    {/* <Upload {...imageProps}>
                          <Button style={{ fontSize: "1vw" }} icon={<UploadOutlined className="relative text-black" style={{ bottom: "1vh" }} />}>CLICK TO UPLOAD IMAGE</Button>
                        </Upload> */}
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: fontSizeLabel }}>
                        Author Name
                      </span>
                    }
                    name="author_name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="select_product"
                    label={
                      <span style={{ fontSize: fontSizeLabel }}>
                        Select Product
                      </span>
                    }
                    rules={[
                      {
                        type: "array",
                        required: true,
                      },
                    ]}
                    validateStatus={
                      !isProductSelected && isEbookOpened ? "error" : ""
                    }
                    help={
                      !isProductSelected && isEbookOpened
                        ? "Product is required!"
                        : ""
                    }>
                    <Cascader
                      options={residences}
                      onChange={onChange}
                      multiple
                    />
                  </Form.Item>

                  <Form.Item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}></Form.Item>
                </Form>
                <div className="flex relative" style={{ left: "37%" }}>
                  <Button
                    id="buttonBackCreateBook"
                    className="flex-inline bg-indigo-500 hover:bg-indigo-400 mr-2"
                    style={{
                      width: "8vw",
                      color: "white",
                      fontSize: "1vw",
                    }}
                    onClick={onHandleBack}>
                    BACK
                  </Button>
                  <Button
                    id="buttonContinueCreateBook"
                    className="flex-inline bg-indigo-500"
                    style={{
                      width: "8vw",
                      color: "white",
                      backgroundColor: "#5ca3ba",
                      fontSize: "1vw",
                    }}
                    onClick={hanldeOpenEbook}>
                    CONTINUE
                  </Button>
                </div>
              </Col>
            </div>
            <div className="w-full px-32 h-auto">
              <div
                className={classNames(
                  style.ui_book,
                  "w-full h-[40rem] flex justify-center flex-col items-center"
                )}
                ref={divRef}>
                <div className={classNames(style.content_book)}>
                  <div
                    className={classNames(
                      `w-9/12 h-16 truncate text-4xl text-primary font-bold text-center`
                    )}>
                    {formValues?.title_book}
                  </div>
                  {imageData && (
                    <div className="w-60 h-60 rounded-lg shadow-md overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                        src={imageData}
                        alt="Uploaded"
                      />
                    </div>
                  )}
                  <p className="w-9/12 h-10 text-xl font-medium truncate overflow-hidden text-center">
                    {formValues.author_name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {openEbookFinal ? (
        <div className="w-full h-full my-16">
          <EbookFinal
            blob={blob}
            initialListItem={initialListItem}
            formValues={formValues}
            coverImage={coverImage}
            ebookId={param.ebook_id ? param.ebookId : ebookId}
            // onHandleBackButton={onHandleBackButton}
          />
        </div>
      ) : (
        <>
          {renderCreateBook()}
          {/* <div className={style.container_createEbook}>
            <Col span={24}>
              <Row gutter={[20, 20]}>
                {/* <Col span={24}>
                  <div className={style.content_step}>
                    {/* <p>Make Storytelling</p>
                    <p>Making the front cover</p>
                    <p>Load Output</p> */}
          {/* </div>
                </Col>  */}
          {/* <Col span={24}/>
               <Col span={24}/>
                <Col span={12}>
                  <div className={style.form_createEbook}>
                    <div style={{}} className={style.content_formCreate}>
                      <Col span={24}>
                        <Form
                          labelCol={{
                            span: 12,
                          }}
                          wrapperCol={{
                            span: 16,
                          }}
                          form={form}
                          onValuesChange={handleFormValuesChange}
                        >
                          {/* <Form.Item>
                            <Row>
                              <Col span={24}>
                                <p>Making the front cover</p>
                              </Col>
                            </Row>
                          </Form.Item> */}
          {/* <Form.Item
                            label={<span style={{fontSize:fontSizeLabel}}>Book Title</span>}
                            name="title_book"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label={<span style={{fontSize:fontSizeLabel}}>Add cover design image</span>}
                            name="image_book"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <input type="file" onChange={handleImageUpload} />
                          </Form.Item>
                          <Form.Item
                            label={<span style={{fontSize:fontSizeLabel}}>Author Name</span>}
                            name="author_name"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="select_product"
                            label={<span style={{fontSize:fontSizeLabel}}>Select Product</span>}
                            rules={[
                              {
                                type: "array",
                                required: true,
                              },
                            ]}
                            validateStatus={
                              !isProductSelected && isEbookOpened ? "error" : ""
                            }
                            help={
                              !isProductSelected && isEbookOpened
                                ? "Product is required!"
                                : ""
                            }
                          >
                            <Cascader
                              options={residences}
                              onChange={onChange}
                              multiple
                            />
                          </Form.Item>

                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                          </Form.Item>
                        </Form>
                        <div className="flex relative" style={{ left: "37%" }}>
                          <div className="flex-inline" style={{ width: formButtonWidth, marginRight:"4%" }}>
                            <ButtonBack back={onHandleBack} />
                          </div>
                          <button
                            type="primary"
                            onClick={hanldeOpenEbook}
                            // className={style.btn_Next}
                            className="flex-inline bg-indigo-500 mr-2 text-gray-50 rounded-lg font-semibold"
                            style={{ width: formButtonWidth, height: "3vw", fontSize:"1vw" }}
                          >
                            CONTINUE
                          </button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <Row justify="center" align="stretch">
                    <div className={style.ui_book} ref={divRef}>
                      <div className={style.content_book}>
                        <p className={style.titleBook}>
                          {formValues?.title_book}
                        </p>
                        {imageData && (
                          <img
                            img
                            src={imageData}
                            alt="Uploaded"
                            // width={700}
                            // height={300}
                            style={{width:"90%", height:"30%"}}
                          />
                        )}
                        <p>{formValues.author_name}</p>
                      </div>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>  */}
        </>
      )}
    </>
  );
};

export default CreateEBook;
