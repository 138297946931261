import React from 'react'
import { Spin } from 'antd'
import styles from './styles.module.css'
function Loading() {
  return (
    <div className={styles.container_loading}>
        <Spin />
    </div>
  )
}

export default Loading