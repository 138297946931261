import { configureStore } from "@reduxjs/toolkit";
import { webSocketMiddleware } from "../redux/wsSlice";
import webSocketSlice from "../redux/wsSlice";

import userSlice from "../redux/userSlice";
import chatSlice from "../redux/chatSlice";
import petSlice from "../redux/petSlice";
import lessionSlice from "../redux/lessionSlice";
import campusSlice from "../redux/campusSlice";
import writingSlice from "../redux/writingSlice";
import adminSlice from "../redux/adminSlice";
import ebookSlice from "../redux/ebookSlice";
export default configureStore({
  reducer: {
    userSlice,
    chatSlice,
    lessionSlice,
    webSocketSlice,
    campusSlice,
    petSlice,
    writingSlice,
    adminSlice,
    ebookSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(webSocketMiddleware),
});
