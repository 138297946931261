import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Table, notification } from "antd";
import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../components/Loading/Loading";
import { setLessonInfo } from "../../../../../redux/lessionSlice";
import { APIService } from "../../../../../services/apiService";
import "./custom-antd.css";
import style from "./style.module.css";
const { Search } = Input;
const inputSearch = "60%";
const columnWidthSearch = "25%";
const inputSearchPointWidth = "90%";
const inpuSearchTopPosition = "20%";
const Course = () => {
  const navigate = useNavigate();
  const [courseLessons, setCourseLessons] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  //search
  const [courseNameSearch, setCourseNameSearch] = useState("");
  const [levelSearch, setLevelSearch] = useState("");
  //
  const dispatch = useDispatch();
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  // Notification
  const [api, contextHolder] = notification.useNotification();
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "start_date"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "start_date") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  async function fetchData() {
    try {
      setIsLoading(true);
      const accessToken = cookies["access_token"];
      const data = await APIService.get_coures_lession(accessToken);
      setCourseLessons(data.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = courseLessons?.map((item, index) => {
      const entries = Object.entries(item)[0][1].lesson;
      const count_completed = entries.filter(
        (i) => i.status === "Saved"
      ).length;

      return {
        key: index + 1,
        name: Object.entries(item)[0][1].course_name,
        level: Object.entries(item)[0][1].level,
        count_completed: count_completed + "/" + entries.length,
        start_date: Object.entries(item)[0][1].start_date,
        end_date: Object.entries(item)[0][1].end_date,
        lesson: Object.entries(item)[0][1].lesson,
      };
    });
    setTableData(result);
    setFilterTableData(result);
  }, [courseLessons]);

  const expandedRowRender = (record) => {
    const columns = [
      { title: "No.", dataIndex: "id", key: "id" },
      {
        title: "Lesson Title( Lesson Info)",
        dataIndex: "lesson_name",
        key: "lesson_name",
      },
      {
        title: "Topic",
        dataIndex: "topic",
        key: "topic",
      },
      { title: "Status", dataIndex: "status", key: "status" },
    ];
    const data = [];

    data.push(
      ...record.lesson.map((lesson, index) => ({
        id: index + 1,
        lesson_id: lesson.id,
        lesson_name: lesson.lesson_name,
        paragraph: lesson.paragraph,
        status: lesson.status,
        start_date: lesson.start_date,
        end_date: lesson.end_date,
        topic: lesson.topic,
        board: lesson.status === "Saved" ? "view" : "writing",
        level: lesson.level,
        course_name: lesson.course_name,
        useful_exp: lesson.useful_exp,
        time_writing: lesson.time_writing,
        minimum_words: lesson.minimum_words,
        book_review: lesson.book_review,
        history: lesson.history,
        title: lesson?.writing_title,
        writing: lesson?.writing,
        writing_title: lesson?.writing_title,
        self_editing: lesson?.self_editing,
      }))
    );

    const handleRowClick = (record) => {
      // if (record.status == "Class Over"){
      //   handelNotification(record.id,`Topic ${record.topic} has ended`,'Please do not select a topic with the status of Class Over')
      //   console.log(record)
      //   return
      // }
      dispatch(setLessonInfo(record));
      localStorage.setItem("setLessonInfo", JSON.stringify(record));

      navigate(`/writing-center/${record.lesson_id}`);
    };
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        className={[style.childTable, "tableChildCourses-custom"]}
      />
    );
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "name",
      key: "name", // ...getColumnSearchProps("name")
    },
    {
      title: "Total Lesson count\n(completed count )",
      dataIndex: "count_completed",
      key: "count_completed",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level", // ...getColumnSearchProps("level")
    },
    { title: "Validity period", dataIndex: "start_date", key: "start_date" },
    { title: "Expiration date", dataIndex: "end_date", key: "end_date" },
  ];
  const tableConfig = {
    dataSource: filterTableData,
    columns: columns,
    expandedRowRender: expandedRowRender,
    expandRowByClick: true,
    expandable: false,
  };
  //search
  const onSetCourseNameSearch = (e) => {
    const value = e.target.value;
    setCourseNameSearch(value);
    onHandleSearch(value, levelSearch);
  };
  const onSetLevelSearch = (e) => {
    const value = e.target.value;
    setLevelSearch(value);
    onHandleSearch(courseNameSearch, value);
  };
  const onHandleSearch = (course, level) => {
    if (level) {
      if (course) {
        const levelData = tableData.filter((item) => {
          const internalResult = item.level.toLowerCase().trim();
          const external = level.toLowerCase().trim();
          return internalResult.includes(external);
        });
        const courseData = levelData.filter((item) => {
          const internalResult = item.name.toLowerCase().trim();
          const external = course.toLowerCase().trim();
          return internalResult.includes(external);
        });
        setFilterTableData(courseData);
      } else {
        const levelData = tableData.filter((item) => {
          const internalResult = item.level.toLowerCase().trim();
          const external = level.toLowerCase().trim();
          return internalResult.includes(external);
        });
        setFilterTableData(levelData);
      }
    } else {
      if (course) {
        const courseData = tableData.filter((item) => {
          const internalResult = item.name.toLowerCase().trim();
          const external = course.toLowerCase().trim();
          return internalResult.includes(external);
        });
        setFilterTableData(courseData);
      } else {
        setFilterTableData(tableData);
      }
    }
  };
  // Notification - Status course - class over
  const handelNotification = (key, message, description) => {
    api.open({
      key,
      message: message,
      description: description,
    });
  };
  const renderSearch = () => {
    return (
      <div className="flex relative" style={{ left: "50%" }}>
        <div
          className="flex-inline flex mr-4"
          style={{ width: columnWidthSearch }}
        >
          <label className="flex-inline mr-2" style={{ lineHeight: "2vw" }}>
            Course Name
          </label>
          <Search
            placeholder="Course Name"
            className="flex-inline"
            style={{ width: inputSearch }}
            onChange={onSetCourseNameSearch}
          />
        </div>
        <div className="flex-inline flex" style={{ width: columnWidthSearch }}>
          <label className="flex-inline mr-2" style={{ lineHeight: "2vw" }}>
            Level
          </label>
          <Search
            placeholder="Level"
            className="flex-inline"
            style={{ width: inputSearch }}
            onChange={onSetLevelSearch}
          />
        </div>
      </div>
    );
  };
  //
  return (
    <>
      {contextHolder}
      <div className={classNames(style.container)}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {courseLessons.length > 0 ? (
              <Fragment>
                <Table
                  {...tableConfig}
                  pagination={false}
                  className={[style.parentTable, "tableCourses-custom"]}
                />
              </Fragment>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <p className="mt-56 text-xl font-medium">
                  Class in Preparation
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Course;
