import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { TableDataListAdmin } from "../../../../components/TableData";
import style from "./style.module.css";

import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { FormRoleManager } from "../../../../components/Form/FormRoleManager";
import { setEmail } from "../../../../redux/campusSlice";
import { APIService } from "../../../../services/apiService";

const { TabPane } = Tabs;

const RoleManager = () => {
  const [value, setValue] = useState("Korea");
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [user, setUser] = useState(null);
  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const handleRowClick = (record) => {
    dispatch(setEmail(record));
    setUser(record);
  };

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_list_account(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={style.container_Infor}>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <div className={style.list_admin}>
              {/* <div className={style.checkBox}>
                <p>List</p>
              </div> */}
              <div>
                <TableDataListAdmin
                  dataUsers={dataUsers}
                  handleClickRow={handleRowClick}
                />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className={style.user_infor}>
              <p className={style.user_infor_title}>Role registration</p>
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <FormRoleManager user={user} fetchData={fetchData} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default RoleManager;
