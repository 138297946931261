import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import style from "./style.module.css";
import ApprovalRequestAdmin from "./components/ApprovalRequestAdmin";

import iconApprovalRequest from "../../../assets/logo/IconAppoval.svg";


const { TabPane } = Tabs;

const AdminApprovalRequest = (props) => {
  const [value, setValue] = useState("course");

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };
  return (
    <div className={style.container_Dashboard}>
      <div className={style.content_Dashboard}>
        <p className={style.dashboard_title}><img src={iconApprovalRequest} alt=""/>Class Approval</p>
        <ApprovalRequestAdmin is_admin={true} />
      </div>
    </div>
  );
};

export default AdminApprovalRequest;
