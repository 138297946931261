import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  resetConversation,
  setLoadHistoryConversation,
} from "../../../redux/chatSlice";
import { APIService } from "../../../services/apiService";
import MessageBlock from "../../BotChat/MainChat/MessageBlock";
import Loading from "../../Loading/Loading";
import TypingEffect from "../../typingEffect/TypingEffect";
import style from "./contentChat.module.css";

function ContentChat() {
  const dispatch = useDispatch();
  const divRef = useRef();
  const thinking = useSelector((state) => state.chatSlice.thinking);
  const conversation = useSelector((state) => state.chatSlice.conversation);
  const currentPet = useSelector((state) => state.petSlice.curentlyPet);
  const user = useSelector((state) => state.userSlice.user);
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const onLoadChatHistory = async () => {
    setIsLoadingData(true);
    APIService.clear_memory_chat_mascot(
      { pet_id: currentPet?.id },
      cookies["access_token"]
    )
      .then((res) => {
        // console.log(res)
      })
      .catch((err) => {})
      .finally(() => {});
    dispatch(
      setLoadHistoryConversation([
        {
          type: "answer",
          text: `Hi there ${user?.username}, My name is ${currentPet?.name}.`,
        },
      ])
    );
    setIsLoadingData(false);
  };

  useEffect(() => {
    dispatch(resetConversation());
    onLoadChatHistory();
    setTimeout(() => {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }, 1);
  }, [currentPet]);

  useEffect(() => {
    divRef.current.scrollTop = divRef.current.scrollHeight;
    // // console.log(conversation)
  }, [conversation, thinking]);

  const [open, setOpen] = useState(false);
  const handleNextCopy = () => {
    setOpen(true);
  };

  const handleCloseCopy = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // useEffect(()=>{
  //   dispatch(setConversation([]))
  // },[currentPet])
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseCopy}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      // console.log(err);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let renderConversation = () => {
    return (
      <>
        <Modal
          title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
        {isLoadingData ? <Loading></Loading> : null}
        {conversation?.map((mess, index) => (
          <>
            {mess.type === "question" ? (
              <div key={mess.id} className={style.lineChatClient}>
                <div className={style.fm_chat}>
                  <p className={style["break-words"]}>{mess.text}</p>
                </div>
              </div>
            ) : (
              <>
                {mess.type === "answer" ? (
                  <>
                    <div key={mess.id} className={style.lineChatBot}>
                      <div className={style.avt}>
                        <img src={currentPet?.url_avatar} alt="" />
                      </div>
                      <div
                        className={style.fm_chat}
                        // style={
                        //   window.innerWidth <= 900
                        //     ? null
                        //     : mess.text?.length <= 200
                        //     ? { maxWidth: "60%" }
                        //     : { maxWidth: "70%" }
                      >
                        {index === conversation?.length - 1 ? (
                          <>
                            <TypingEffect
                              divRef={divRef}
                              content={mess.text}
                              idx={mess.id}
                              textKorea={mess.textKorea}
                            />
                          </>
                        ) : (
                          <MessageBlock
                            text={mess.text}
                            textKorea={mess.textKorea}
                            id={mess.id}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ))}

        {thinking ? (
          <>
            <div key="thingking" className={style.lineChatBot}>
              <div className={style.avt}>
                <img src={currentPet?.url_avatar} alt="" />
              </div>
              <div className={style.loader}></div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseCopy}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <SnackbarContent
          action={action}
          message="Copied To Clipboard"
          sx={{ bgcolor: "white", color: "black" }}
        />
      </Snackbar>
      <div ref={divRef} className={style.container_mainChat}>
        {renderConversation()}
      </div>
    </>
  );
}

export default ContentChat;
