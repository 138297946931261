import style from "../style.module.css";

const ParagraphSplitter = ({ text, img, imgPosition }) => {
  const words = text?.split(" ");
  console.log("text", text);
  const key = imgPosition;

  const partLength = Math.ceil(words.length / 6);

  const part1 = words.slice(0, partLength).join(" ");
  const part2 = words.slice(partLength, partLength * 2).join(" ");
  const part3 = words.slice(partLength * 2, partLength * 3).join(" ");
  const part4 = words.slice(partLength * 3, partLength * 4).join(" ");
  const part5 = words.slice(partLength * 4, partLength * 5).join(" ");
  const part6 = words.slice(partLength * 5).join(" ");

  return (
    <div>
      {key === 0 && (
        <div style={{ width: "150px", height: "150px", margin: "10px auto" }}>
          <img
            id="img_main"
            src={img}
            alt="123"
            className={style.img_splitter}
          />
        </div>
      )}
      <p>
        {key === 1 && (
          <div
            style={{
              width: "150px",
              height: "150px",
              float: "left",
              margin: "10px",
            }}
          >
            <img
              id="img_main"
              src={img}
              alt="123"
              className={style.img_splitter}
            />
          </div>
        )}
        {key === 2 && (
          <div
            style={{
              width: "150px",
              height: "150px",
              float: "right",
              margin: "10px",
            }}
          >
            <img
              id="img_main"
              src={img}
              alt="123"
              className={style.img_splitter}
            />
          </div>
        )}
        {part1}

        {part2}

        {key === 3 && (
          <div style={{ width: "150px", height: "150px", margin: "10px auto" }}>
            <img
              id="img_main"
              src={img}
              alt="123"
              className={style.img_splitter}
            />
          </div>
        )}

        {part3}
        {part4}
        {key === 4 && (
          <div
            style={{
              width: "150px",
              height: "150px",
              float: "left",
              margin: "10px",
            }}
          >
            <img
              id="img_main"
              src={img}
              alt="123"
              className={style.img_splitter}
            />
          </div>
        )}
        {key === 5 && (
          <div
            style={{
              width: "150px",
              height: "150px",
              float: "right",
              margin: "10px",
            }}
          >
            <img
              id="img_main"
              src={img}
              alt="123"
              className={style.img_splitter}
            />
          </div>
        )}
        {key === 6 && (
          <div
            style={{
              width: "150px",
              height: "150px",
              float: "bottom",
              margin: "10px",
            }}
          >
            <img
              id="img_main"
              src={img}
              alt="123"
              className={style.img_splitter}
            />
          </div>
        )}
        {part5}

        {part6}
      </p>
    </div>
  );
};

export default ParagraphSplitter;
