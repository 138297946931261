import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Notification } from "../../components/Notification/index";
import style from "./style.module.css";

import "./custom.css";
const TopicWritingCenter = (props) => {
  const { type, setPopupBook, pointStarts } = props;
  // // console.log(pointStarts);
  const [popup, setPopup] = useState(false);
  const handlePopupOpen = () => {
    setPopupBook(true);
  };

  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const onPopupBookReview = () => {
    Notification("success", "Book review");
  };

  const [isDesktop, setDesktop] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleSize = () => {
      setDesktop({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleSize);
    handleSize();
    return () => window.removeEventListener("resize", handleSize);
  }, []);

  return (
    <>
      <div
        className={`${
          type === 1
            ? style.container_brainstorming
            : type === 3 || (type === 4 && style.container3)
        }`}
      >
        {type === 1 ? (
          <div
            className={
              type === 1
                ? style.content_topic_branstorming
                : style.content_topic
            }
          >
            <h1 className={style.content_title_topic}>Topic</h1>
            <p className={style.box_title_topic}>{lesson.topic}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TopicWritingCenter;
