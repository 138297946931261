import React from "react";
import bg from "../../assets/ebook/ebook-background.png";
import wingGif from "../../assets/ebook/wing.gif";
import BackButton from "./components/BackButton";
import ListEBooks from "./components/ListEBooks";
import classNames from "classnames";
import WingLoading from "../../components/Loading/WingLoading";

const EBookStore = ({
  isLoading,
  listEBooks,
  indexSelectedItem,
  itemSelected,
  actionButton,
  handleSelectItem,
  actionActiveView,
}) => {
  return (
    <div className="relative w-full h-[calc(100vh-4rem)] lg:h-[calc(100vh-5rem)] overflow-y-auto">
      <img
        src={bg}
        alt="bg"
        className="fixed w-full h-full top-0 left-0 object-cover object-bottom"
      />
      <BackButton action={actionButton} />
      <div className="relative w-full h-full pb-20">
        {isLoading && <WingLoading />}
        {!isLoading && listEBooks.length > 0 && (
          <>
            <div
              className={classNames(
                "relative 2xl:max-w-7xl lg:max-w-4xl mx-auto grid grid-cols-2 2xl:gap-28 lg:gap-20 lg:mt-20 font-jetBrains",
                "md:mt-40 md:max-w-2xl gap-0"
              )}>
              <ListEBooks
                handleSelectItem={handleSelectItem}
                indexSelectedItem={indexSelectedItem}
                listEBooks={listEBooks}
              />
              <div className="relative w-full flex justify-center items-center">
                <div className="w-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
                  <img
                    src={wingGif}
                    alt="wing-gif"
                    className="w-full object-cover"
                  />
                </div>
                {itemSelected && (
                  <div
                    onClick={actionActiveView}
                    className="h-[40%] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 cursor-pointer hover:scale-105 duration-200 aspect-[2/3]">
                    <img
                      src={itemSelected?.ebook_link}
                      alt="book"
                      className="h-full object-cover"
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!isLoading && listEBooks.length === 0 && (
          <p className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white p-6 text-gray-900 text-3xl font-bold rounded-md">
            No E-book available
          </p>
        )}
      </div>
    </div>
  );
};

export default EBookStore;
