import { Button, Col, DatePicker, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { TableDataListCampus } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";
import style from "./style.module.css";

const { RangePicker } = DatePicker;

const ListCampus = ({ is_admin, setSelectedUser, setTabValue, refetch }) => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_campus_user(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleSelectedDataChange = (selectedData) => {
    setSelectedData(selectedData);
  };

  const exportData = async () => {
    let data_export = [];
    dataUsers.forEach((ele) => {
      data_export.push({
        country: ele.country_name,
        campus: ele.campus_name,
        talksam_id: ele.talksam_id,
        fullname: ele.fullname,
        nickname: ele.nick_name,
        email: ele.email,
        registration_date: ele.created_at,
      });
    });
    // console.log(data_export);
    const res = await APIService.export_data_campus(
      {
        records: data_export,
      },
      cookies["access_token"]
    );
    if (res.status_code === 200) {
      const response = await fetch(res.data);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "export.xlsx";
      link.click();
    }
  };

  return (
    <>
      <div className={style.container_listCampus}>
        <Col span={24}>
          <Row justify={"center"} gutter={[20, 20]}>
            <Col span={14}>
              <Row gutter={[10, 10]}>
                <Col span={16}>
                  {/* <Checkbox>Search by entire registration date</Checkbox> */}
                </Col>
                <Col span={16}>
                  <Input
                    placeholder="Search username, fullname, talksam ID, email"
                    value={filterValue}
                    onChange={handleFilterChange}
                  />
                </Col>
                <Col span={4}>
                  <Button>Search</Button>
                </Col>
                <Col span={4}>
                  <Button onClick={exportData}>Save Excel</Button>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <div className={style.list_tableCampus}>
                <TableDataListCampus
                  dataUsers={dataUsers}
                  filterValue={filterValue}
                  dateRange={dateRange}
                  selectedData={selectedData}
                  onSelectedDataChange={handleSelectedDataChange}
                  setSelectedUser={setSelectedUser}
                  setTabValue={setTabValue}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default ListCampus;
