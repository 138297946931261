import notification from "antd/lib/notification";

export const Notification = (type, desc, duration) => {
  notification[type]({
    message: type,
    description: desc,
    placement: "topRight",
    className: "notification-global",
    duration: duration ? duration : 2,
    style: {
      border:
        type === "success"
          ? "1px solid #95de64"
          : type === "warning"
          ? "1px solid #FFC069"
          : type === "info"
          ? "1px solid #85A5FF"
          : "1px solid #FF7875",
    },
  });
};

// export const NotificationInfo = (
//   title,
//   desc,
//   onClickNoti,
//   objectID,
//   recorID,
//   ID,
//   item
// ) => {
//   notification.info({
//     message: title,
//     description: <Des>{parse(desc, optionsParse)}</Des>,
//     placement: "topRight",
//     className: "notification-global",
//     style: {
//       border: "1px solid #85A5FF",
//     },
//     onClick: () => {
//       onClickNoti(desc, objectID, recorID, ID, item);
//     },
//   });
// };

// const Des = styled.div`
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   font-size: 16px;
//   color: #6b6b6b;
// `;
