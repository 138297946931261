import React from "react";

const Translator = () => {
  return (
    <iframe
      className="mr-6"
      src="https://en.dict.naver.com/#/mini/main"
      width="500px"
      height="600px"
    />
  );
};

export default Translator;
