import React, { useEffect, useState } from "react";
import "./Login.css";
import LoginForm from "./component/LoginForm";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { APIService } from "../../services/apiService";
import { setUser } from "../../redux/userSlice";

const LoginPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const login_with_access_token = async () => {
    const response = await APIService.get_information_user(
      cookies["access_token"]
    );

    if (response && response.status_code === 200) {
      let total_point = 0;
      APIService.get_total_point(cookies["access_token"])
        .then((resp) => {
          total_point = resp.data.total_point;
          dispatch(
            setUser({
              username: response.data.fullname,
              email: response.data.email,
              avatar: response.data.avatar_url,
              level: response.data.level,
              birth_day: response.data.birth_day,
              total_point: total_point,
              campus_id: response.data.campus_id,
              campus_name: response.data.campus_name,
              country_id: response.data.country_id,
              course_done: response.data.course_done,
              inquiry_done: response.data.inquiry,
              id: response.data.id,
            })
          );
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    if (cookies["access_token"]) {
      // navigate("/dashboard");
      login_with_access_token();
    }
  }, [cookies["access_token"]]);

  return <>{cookies["access_token"] ? null : <LoginForm />}</>;
};

export default LoginPage;
