import React, { useState } from "react";
import bg from "../../../../../assets/writingCenterImage/brain-storming-bg.jpg";
import defaultBtn from "../../../../../assets/writingCenterImage/default-btn.png";
import upgradeBtn from "../../../../../assets/writingCenterImage/upgrade-btn.png";
import Chat from "../../../../../components/BotChat/BotChat";
import TopicWritingCenter from "../../../../../components/TopicWritingCenter/index";
import style from "../style.module.css";
// import { }
function BrainStorming({ onNext, onBack }) {
  const [type, setType] = useState(1);
  const [level, setLevel] = useState("STANDARD");

  return (
    <>
      <div className="absolute top-1/4 -translate-y-1/2 left-0 -translate-x-3/4 flex flex-col gap-8 z-50">
        <button
          style={{
            background:
              level === "STANDARD" &&
              "radial-gradient(circle, rgba(255,251,115,1) 69%, rgba(255,251,115,0) 100%)",
          }}
          onClick={() => {
            setLevel("STANDARD");
          }}>
          <img src={defaultBtn} alt="default-btn" />
        </button>
        <button
          style={{
            background:
              level === "UPGRADE" &&
              "radial-gradient(circle, rgba(255,251,115,1) 69%, rgba(255,251,115,0) 100%)",
          }}
          onClick={() => {
            setLevel("UPGRADE");
          }}>
          <img src={upgradeBtn} alt="upgrade-btn" />
        </button>
      </div>
      <div className={style.content_writingCenter_BrainStorming}>
        {level === "UPGRADE" && (
          <img
            className="absolute top-0 left-0 w-full h-full object-cover opacity-70 rounded-[25px]"
            src={bg}
            alt="bg"
          />
        )}
        <TopicWritingCenter type={1} />
        <Chat level={level} />
      </div>
    </>
  );
}

export default BrainStorming;
