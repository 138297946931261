import React, { useRef, useState } from "react";
import style from "../../style.module.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import angry from "../../../../../assets/icon_svg/angry.png";
import nextBtn from "../../../../../assets/ebook/next-page-icon.png";
import prevBtn from "../../../../../assets/ebook/previous-page-icon.png";
import classNames from "classnames";
import { useMemo } from "react";
import { APIService } from "../../../../../services/apiService";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { setPets, setCurentlyPet } from "../../../../../redux/petSlice";
import WingLoading from "../../../../../components/Loading/WingLoading";
function Model2d() {
  const [cookies] = useCookies(["access_token"]);
  const dispatch = useDispatch();
  const audioRef = useRef(); // reference to audio element
  const [selectedIndex, setSelectedIndex] = useState(0);
  const PetList = useSelector((state) => state.petSlice.pets);
  const [isLoading, setIsLoading] = useState(false);

  console.log("PetList", PetList);

  useEffect(() => {
    setIsLoading(true);
    APIService.get_owned_pet(cookies["access_token"])
      .then((response) => {
        console.log("response pet", response);
        if (response && response.status_code === 200 && response.data) {
          dispatch(
            setPets({
              pets: response.data,
            })
          );
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (PetList.length > 0) {
      dispatch(setCurentlyPet(PetList[selectedIndex]));
    }
  }, [selectedIndex, PetList, dispatch]);

  // const playAudio = () => {
  //   // stopAudio();
  //   if (audioRef.current) {
  //     audioRef.current.play();
  //   }
  // };
  // const pauseAudio = () => {
  //   if (audioRef.current) {
  //     audioRef.current.pause();
  //   }
  // };

  // const stopAudio = () => {
  //   if (audioRef.current) {
  //     audioRef.current.pause();
  //     audioRef.current.currentTime = 0;
  //   }
  // };

  const handleGoToPreviousPet = () => {
    if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
    else setSelectedIndex(PetList?.length - 1);
  };
  const handleGoToNextPet = () => {
    if (selectedIndex < PetList?.length - 1)
      setSelectedIndex(selectedIndex + 1);
    else setSelectedIndex(0);
  };

  // console.log("check list of pets", PetList);
  // console.log("Current", currentPet);

  return (
    <>
      {isLoading && <WingLoading />}
      {!isLoading && (
        <div className={classNames(style.bgModel2D)}>
          <audio ref={audioRef}>
            <source
              src={PetList[selectedIndex]?.voice_speech}
              type="audio/mpeg"
            />
          </audio>
          <div className={classNames(style.boxImage, "relative")}>
            {PetList?.length > 1 && (
              <>
                <button
                  onClick={handleGoToPreviousPet}
                  className="absolute top-1/2 -translate-y-1/2 left-0 -translate-x-full h-20">
                  <img
                    src={prevBtn}
                    alt="btn"
                    className="h-full object-cover"
                  />
                </button>
                <button
                  onClick={handleGoToNextPet}
                  className="absolute top-1/2 -translate-y-1/2 right-0 translate-x-full h-20">
                  <img
                    src={nextBtn}
                    alt="btn"
                    className="h-full object-cover"
                  />
                </button>
              </>
            )}

            {PetList[selectedIndex] && (
              <>
                <img
                  // onClick={playAudio}
                  className={classNames(style.pet2D, "border-2")}
                  src={
                    PetList[selectedIndex]?.gif_avatar
                      ? PetList[selectedIndex]?.gif_avatar
                      : PetList[selectedIndex]?.url_avatar
                  }
                  alt="pet"
                />
                <p
                  style={{ fontFamily: "Salsa" }}
                  className="w-fit py-5 px-10  font-extrabold text-4xl mx-auto rounded-2xl bg-[#FBBC51] text-[#456983]">
                  {PetList[selectedIndex]?.name}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Model2d;
