import { Tag, Tooltip } from "antd";
import React from "react";

const spaces = "\u00A0\u00A0\u00A0\u00A0\u00A0";

const CustomTooltip = ({
  colorContent,
  content,
  tooltip,
  setIndexActiveTooltip,
  indexActiveTooltip,
  index,
}) => {
  return (
    <Tooltip
      placement="top"
      open={index === indexActiveTooltip}
      title={
        <p
          dangerouslySetInnerHTML={{ __html: tooltip }}
          className="prevent-select"
        ></p>
      }
    >
      <Tag
        className={("text-base cursor-pointer", colorContent)}
        style={{ marginInlineEnd: "0" }}
        onClick={() => {
          if (index === indexActiveTooltip) {
            setIndexActiveTooltip(-1);
          } else {
            setIndexActiveTooltip(index);
          }
        }}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          className="whitespace-pre-wrap break-words cursor-pointer text-base"
        ></p>
      </Tag>
    </Tooltip>
  );
};

export default CustomTooltip;
