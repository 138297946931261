import { Button, Form, Input, Modal, S, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import style from "./style.module.css";
const PopupInquiryQuestion = () => {
  const [modal2Open, setModal2Open] = useState(false);

  return (
    <>
      <Modal
        centered
        open={modal2Open}
        footer={null}
        onCancel={() => setModal2Open(false)}
      >
        <section className={style.inquiryContainer}>
          <div className={style.inquiryContent}>
            <h1 className={style.title}>Question registration</h1>
          </div>
          <div>
            <button className={style.btnList}>List</button>
          </div>
        </section>
        <section className={style.inquirySelect}>
          <Form>
            <section
              style={{
                display: "flex",
                marginTop: "20px",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <div className="" style={{ width: "200px" }}>
                <Form.Item style={{ width: "100%", height: "auto" }}>
                  <Select
                    // onChange={}
                    placeholder="Inquiry Division"
                  ></Select>
                </Form.Item>
              </div>
              <div className="" style={{ width: "282px" }}>
                <Form.Item>
                  <Input placeholder="Enter Question Title" />
                </Form.Item>
              </div>
            </section>
            <section
              className=""
              style={{
                border: "1px solid #2A4365",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Name"
                name="Name"
                rules={[
                  { required: true, message: "Please input your Fullname!" },
                ]}
              >
                <Input />
              </Form.Item>
              <TextArea
                rows={4}
                style={{ border: "0", outline: "none", textAlign: "center" }}
                placeholder="Question"
              />
            </section>
            <section
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <Button
                className={style.btnSubmit}
                style={{
                  background: "#465ae6",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10%",
                }}
              >
                Register Inquiry
              </Button>
            </section>
          </Form>
        </section>
      </Modal>
    </>
  );
};

export default PopupInquiryQuestion;
