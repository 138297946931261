import React from "react";
import styles from "./style.module.css";

import { Tag, Tooltip } from "antd";
import { useSelector } from "react-redux";
const spaces = "\u00A0\u00A0\u00A0\u00A0\u00A0";

const SuggestionEvaluation = () => {
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const type = useSelector((state) => state.writingSlice.type);

  return (
    <div className={styles.container}>
      <div className={styles.textareaWrapper}>
        <div className="w-full flex items-center gap-2 py-2 rounded-lg mb-3 font-medium">
          <p className="w-full bg-transparent focus:outline-none focus:border-none text-center mr-12">
            {lesson?.writing_title}
          </p>
        </div>
        <div className={styles.boxSuggestion}>
          {type === "error" &&
            lesson.suggestionArray[0]?.map((words, index) => {
              if (words?.includes("***")) {
                return (
                  <>
                    <Tooltip
                      placement="top"
                      title={words?.split("#")[1]?.split("***")}
                    >
                      <Tag
                        className={styles.textError_red}
                        style={{ marginInlineEnd: "0" }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: words?.split("#")[0]?.split("***")[1],
                          }}
                          className="whitespace-pre-wrap break-words"
                        ></p>
                      </Tag>
                    </Tooltip>
                  </>
                );
              } else return <>{words}</>;
            })}
          {type === "improvement" &&
            lesson.suggestionArray[1]?.map((words, index) => {
              if (words.includes("***")) {
                return (
                  <>
                    <Tooltip
                      placement="top"
                      title={words?.split("#")[1]?.split("***")}
                    >
                      <Tag
                        className={styles.textError_green}
                        style={{ marginInlineEnd: "0" }}
                      >
                        <p className="whitespace-pre-wrap break-words">
                          {index === 0 && <>{spaces}</>}
                          {words?.split("#")[0]?.split("***")[1]}
                        </p>
                      </Tag>
                    </Tooltip>
                  </>
                );
              } else return <>{words}</>;
            })}
        </div>
      </div>
    </div>
  );
};

export default SuggestionEvaluation;
