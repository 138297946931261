import React from "react";
import backButton from "../../../assets/artwork/back-button.png";

const BackButton = ({ action }) => {
  return (
    <button
      onClick={action}
      type="button"
      className="h-14 z-10 absolute top-10 left-10 hover:brightness-110 duration-200"
    >
      <img src={backButton} alt="btn" className="h-full object-cover" />
    </button>
  );
};

export default BackButton;
