import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import TableDataCourse, {
  TableDataInquiry,
  TableDataPointHistory,
} from "../../../components/TableData";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { APIService } from "../../../services/apiService";
import "./custom-antd.css";
import style from "./style.module.css";
const { Search } = Input;
const inputSearchPointWidth = "95%";
export const TableDataHistory = ({
  filterData,
  dataPoint,
  dataInquiry,
  dataAvg,
}) => {
  const [form] = Form.useForm();
  const [cookies] = useCookies(["access_token"]);

  let user = useSelector((state) => state.userSlice.user);
  const type_history = useSelector((state) => state.userSlice.type_history);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalIquiry, setIsModalIquiry] = useState(false);
  const [formValues, setFormValues] = useState({
    selectDevision: "lession",
    questionTitle: "",
    textArea: "",
  });
  const [courseData, setCourseData] = useState([]);
  const [filterCourse, setFilterCourse] = useState([]);

  console.log("Student", filterCourse);
  const [courseName, setCourseName] = useState("");

  const [titleInquirySearch, setTitleInquirySearch] = useState("");
  const [inquiryData, setInquiryData] = useState([]);
  const [inquiryDataFilter, setInquiryDataFilter] = useState([]);

  const [pointData, setPointData] = useState(dataPoint);
  const [pointDataFilter, setPointDataFilter] = useState(dataPoint);

  const [coursePointSearch, setCoursePointSearch] = useState("");
  const [emailPointSearch, setEmailPointSearch] = useState("");
  const [fullNamePointSearch, setFullNamePointSearch] = useState("");
  const [lessonPointSearch, setLessonPointSearch] = useState("");
  //
  const [inquiryRecord, setInquiryRecord] = useState(null);
  // redux
  //
  const handleClickRow = (record) => {
    setInquiryRecord(record);
    setIsModalIquiry(true);
  };
  //Search
  const onSetCourseNameSearch = (e) => {
    const value = e.target.value;
    setCourseName(value);
    onHandleSearch(value);
  };
  const onHandleSearch = (name) => {
    const nameData = courseData.filter((item) => {
      // const internal = Object.values(item);
      const internalResult = item.course_name.toLowerCase().trim();
      const external = name.toLowerCase().trim();
      return internalResult.includes(external);
    });
    setFilterCourse(nameData);
  };

  const onSetTitleSearch = (e) => {
    const value = e.target.value;
    setCourseName(value);
    onHandleInquirySearch(value);
  };
  const onHandleInquirySearch = (title) => {
    const titleData = inquiryData.filter((item) => {
      // const internal = Object.values(item);
      const internalResult = item.title.toLowerCase().trim();
      const external = title.toLowerCase().trim();
      return internalResult.includes(external);
    });
    setInquiryDataFilter(titleData);
  };

  const onSetCourseSearch = (e) => {
    const value = e.target.value;
    setCoursePointSearch(value);
    onHandlePointSearch(
      value,
      emailPointSearch,
      fullNamePointSearch,
      lessonPointSearch
    );
  };
  const onSetFullNameSearch = (e) => {
    const value = e.target.value;
    setFullNamePointSearch(value);
    onHandlePointSearch(
      coursePointSearch,
      emailPointSearch,
      value,
      lessonPointSearch
    );
  };
  const onSetLessonSearch = (e) => {
    const value = e.target.value;
    setLessonPointSearch(value);
    onHandlePointSearch(
      coursePointSearch,
      emailPointSearch,
      fullNamePointSearch,
      value
    );
  };
  const onSetEmailSearch = (e) => {
    const value = e.target.value;
    setEmailPointSearch(value);
    onHandlePointSearch(
      coursePointSearch,
      value,
      fullNamePointSearch,
      lessonPointSearch
    );
  };
  const onHandlePointSearch = (course, email, fullname, lesson) => {
    if (email) {
      if (lesson) {
        if (fullname) {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const lessonData = fullnameData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const emailData = lessonData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          } else {
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const lessonData = fullnameData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const emailData = lessonData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          }
        } else {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const lessonData = courseData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const emailData = lessonData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          } else {
            const lessonData = pointData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const emailData = lessonData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          }
        }
      } else {
        if (fullname) {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const emailData = fullnameData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          } else {
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const emailData = fullnameData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          }
        } else {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const emailData = courseData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          } else {
            const emailData = pointData.filter((item) => {
              const internalResult = item.email.toLowerCase().trim();
              const external = email.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(emailData);
          }
        }
      }
    } else {
      if (lesson) {
        if (fullname) {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const lessonData = fullnameData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(lessonData);
          } else {
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const lessonData = fullnameData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(lessonData);
          }
        } else {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const lessonData = courseData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(lessonData);
          } else {
            const lessonData = pointData.filter((item) => {
              const internalResult = item.lesson.toLowerCase().trim();
              const external = lesson.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(lessonData);
          }
        }
      } else {
        if (fullname) {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(fullnameData);
          } else {
            const fullnameData = courseData.filter((item) => {
              const internalResult = item.fullname.toLowerCase().trim();
              const external = fullname.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(fullnameData);
          }
        } else {
          if (course) {
            const courseData = pointData.filter((item) => {
              const internalResult = item.course.toLowerCase().trim();
              const external = course.toLowerCase().trim();
              return internalResult.includes(external);
            });
            setPointDataFilter(courseData);
          } else {
            setPointDataFilter(pointData);
          }
        }
      }
    }
    // const titleData = inquiryData.filter((item) => {
    //   // const internal = Object.values(item);
    //   const internalResult = item.title.toLowerCase().trim();
    //   const external = title.toLowerCase().trim();
    //   return internalResult.includes(external);
    // });
    // setInquiryDataFilter(titleData);
  };
  //
  useEffect(() => {
    setCourseData(filterData);
    setFilterCourse(filterData);
    setInquiryData(dataInquiry);
    setInquiryDataFilter(dataInquiry);
    setPointData(dataPoint);
    setPointDataFilter(dataPoint);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalIquiry(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalIquiry(false);
    setIsModalOpen(false);
  };

  // useEffect(() => { }, [user]);

  const handleCreateInquiry = async () => {
    try {
      await form.validateFields();
      APIService.create_inquiry(
        {
          user_id: -1,
          name: user.username,
          email: user.email,
          division: formValues.selectDevision,
          title: formValues.questionTitle,
          question: formValues.textArea,
        },
        cookies["access_token"]
      )
        .then((response) => {
          if (response) {
            setIsModalOpen(false);
            window.location.reload();
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  switch (type_history) {
    case "course_history":
      return (
        <>
          <Row className="d-flex h-full justify-center items-start">
            <Col span={24}>
              <Form>
                <Form.Item
                  className={style.nameTable}
                  style={{
                    display: "inline-block",
                  }}
                >
                  <p className={style.titlTalbe}>Course History</p>
                </Form.Item>
                <TableDataCourse data={filterCourse} />
              </Form>
            </Col>
          </Row>
        </>
      );

    case "inquiry_history":
      return (
        <>
          <Row className="d-flex h-full justify-center items-start">
            <Col span={24}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Form>
                    <Form.Item
                      className={style.nameTable}
                      style={{
                        display: "inline-block",
                        width: "calc(20% - 8px)",
                      }}
                    >
                      <p className={style.titlTalbe}>Inquiry History</p>
                    </Form.Item>
                    <Form.Item
                      className={style.nameTable}
                      style={{
                        display: "inline-block",
                        width: "calc(70% - 8px)",
                      }}
                    >
                      {/* <Col span={24}>
                          <Row justify={"end"}>
                            <Button onClick={showModal}>Create Inquiry</Button>
                          </Row>
                        </Col> */}
                    </Form.Item>
                    {/* <Form.Item
                        style={{
                          display: "inline-block",
                          width: "calc(20% - 8px)",
                        }}
                      >
                        <p> Sum Point : {dataAvg.sum_point}</p>
                      </Form.Item>
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "calc(20% - 8px)",
                        }}
                      >
                        {" "}
                        <p> Avg Campus : {dataAvg.avg_campus}</p>
                      </Form.Item>
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "calc(20% - 8px)",
                        }}
                      >
                        {" "}
                        <p> Avg Country : {dataAvg.avg_country}</p>
                      </Form.Item> */}
                  </Form>
                  <TableDataInquiry
                    dataInquiry={inquiryDataFilter}
                    dataAvg={dataAvg}
                    handleClickRow={handleClickRow}
                  />
                </Col>
              </Row>
              <Modal
                title="Inquiry"
                open={isModalOpen}
                onOk={handleCreateInquiry}
                onCancel={handleCancel}
                okText="Create"
              >
                <Form form={form}>
                  <Form.Item>
                    <Form.Item
                      label="Division"
                      name="division"
                      style={{
                        display: "inline-block",
                        width: "calc(40% - 8px)",
                        marginRight: "8px",
                      }}
                      rules={[{ required: true }]}
                    >
                      <Select
                        value={formValues.selectDevision}
                        onChange={(value) =>
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            selectDevision: value,
                          }))
                        }
                        options={[
                          { value: "lession", label: "Lession" },
                          { value: "course", label: "Course" },
                          { value: "product", label: "Product" },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Title"
                      name="title"
                      style={{
                        display: "inline-block",
                        width: "calc(60% - 8px)",
                      }}
                      rules={[
                        { required: true, message: "Please input your title!" },
                      ]}
                    >
                      <Input
                        value={formValues.questionTitle}
                        onChange={(e) =>
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            questionTitle: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label="Question"
                    name="question"
                    rules={[
                      {
                        required: true,
                        message: "Please input your question!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={5}
                      value={formValues.textArea}
                      onChange={(e) =>
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          textArea: e.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                </Form>
              </Modal>
              <Modal
                title={
                  "[ " + inquiryRecord?.division + " ] " + inquiryRecord?.title
                }
                open={isModalIquiry}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: "none" } }}
                okText="Delete"
              >
                <Form>
                  <Form.Item style={{ textAlign: "center" }}>
                    <p>Question : {inquiryRecord?.question}</p>
                  </Form.Item>
                  <Form.Item style={{ textAlign: "center" }}>
                    <p>Answer : {inquiryRecord?.answer}</p>
                  </Form.Item>
                </Form>
              </Modal>
            </Col>
          </Row>
        </>
      );
    case "point_history":
      return (
        <>
          <Row className="d-flex h-full justify-center items-start">
            <Col span={24}>
              <Form>
                <Form.Item
                  className={style.nameTable}
                  style={{
                    display: "inline-block",
                    width: "calc(20% - 8px)",
                  }}
                >
                  <p className={style.titlTalbe}>Point History</p>
                </Form.Item>
                <TableDataPointHistory dataPoint={pointDataFilter} />
              </Form>
            </Col>
          </Row>
        </>
      );

    default:
      return null;
  }
};
