import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./custome.css";

const PopupSetting = ({ user }) => {
  const { TextArea } = Input;
  const dateFormat = "YYYY/MM/DD";

  const [modal2Open, setModal2Open] = useState(false);
  useEffect(() => {
    setModal2Open(true);
  }, []);
  const handleCancel = () => {
    setModal2Open(false);
  };

  useEffect(() => {
    // console.log(user);
  }, [user]);

  const layout = {
    labelCol: { span: 5 },
    textAlign: "start",
  };
  const onFinish = (values) => {
    // console.log(values);
  };
  const customOkButtonProps = {
    className: "custom-ok-button",
  };

  return (
    <>
      <Modal
        centered
        width={800}
        onCancel={handleCancel}
        onOk={() => setModal2Open(false)}
        open={modal2Open}
        cancelText="Update"
        okButtonProps={customOkButtonProps}
      >
        <Form {...layout} name="control-hooks" onFinish={onFinish}>
          <Row>
            <Col span={6}>
              <Form.Item name="avatar" valuePropName="fileList">
                <Upload.Dragger
                  name="files"
                  action="/upload.do"
                  disabled={true}
                >
                  <img src={user.user.avatar} alt="avata" />
                </Upload.Dragger>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="E-mail">
                <Input value={user.user.email} disabled={true} />
              </Form.Item>
              <Form.Item label="Name">
                <Space.Compact>
                  <Input value={user.user.username} disabled={true} />
                </Space.Compact>
              </Form.Item>

              <Form.Item label="Level ">
                <Input value={user.user.level} disabled={true} />
              </Form.Item>
              <Form.Item label="Birthday">
                <DatePicker
                  value={dayjs(user?.user.birth_day, dateFormat)}
                  format={dateFormat}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PopupSetting;
