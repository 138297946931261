import HTMLFlipBook from "react-pageflip";
import React, { useEffect, useState } from "react";
import Page from "./bookPage";
import CoverPage from "./bookCoverPage";
import style from "./style.module.css";
import { useCookies } from "react-cookie";
import { APIService } from "../../services/apiService";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";

export default function PopupEbook({ indexPopup }) {
  const [cookies] = useCookies(["access_token"]);
  const [ebook, setEbook] = useState([]);
  let index = indexPopup;
  const book = useRef(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  const calculateBookSizeWidth = () => {
    const specificElement = document.getElementById("textbookContainer");
    const elementWidth = specificElement.offsetWidth;
    const width = (elementWidth * 40) / 100;
    return width;
  };

  const calculateBookSizeHeigh = () => {
    const specificElement = document.getElementById("textbookContainer");
    const elemenHeight = specificElement.offsetHeight;
    const height = (elemenHeight * 50) / 100;
    return height;
  };
  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_list_ebook(accessToken);
      setEbook(data.data);
    } catch (error) {}
  }
  const updatePage = ({ data }) => setPage(data + 1);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      try {
        setTotal(book.current.pageFlip().getPageCount());
      } catch (error) {}
    });
  }, [index]);

  return (
    <>
      <div className={style.flipbook_container}>
        <div
          id="textbookContainer"
          style={{ position: "relative", width: "80%" }}
        >
          {ebook.length > 0 && ebook[index] && (
            <HTMLFlipBook
              onFlip={updatePage}
              size="stretch"
              showCover={true}
              ref={book}
              maxShadowOpacity={0}
              width={calculateBookSizeWidth()}
              height={400}
            >
              <CoverPage>
                <div className={style.cover_page}>
                  <img src={ebook[index].ebook_link} alt="Cover Book" />
                </div>
              </CoverPage>

              {ebook[index].include_artwork?.map((item) => (
                <Page number="1">
                  <div className={style.page}>
                    <img src={item} alt="Cover Book" />
                  </div>
                </Page>
              ))}

              <CoverPage>
                <div className={style.cover_page}>
                  <img src={ebook[index].ebook_link} alt="Cover Book" />
                </div>
              </CoverPage>
            </HTMLFlipBook>
          )}
        </div>
      </div>
    </>
  );
}
