import {
  Button,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
} from "antd";
import { Option } from "antd/es/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Notification } from "../../components/Notification/index";
import { APIService } from "../../services/apiService";
import style from "./style.module.css";

const buttonWidth = "14%";
export const FormUser = ({
  avatar_upload,
  fileList,
  user,
  is_admin,
  fetchData,
  setCountry,
  setCampus,
  setRefetch,
}) => {
  const [form] = Form.useForm();
  const [cookies] = useCookies(["access_token"]);
  const userCampus = useSelector((state) => state.userSlice.user);
  const [updatedUser, setUpdatedUser] = useState(user);
  const campus_list = useSelector((state) => state.adminSlice.campus_list);
  const country_list = useSelector((state) => state.adminSlice.country_list);
  const [change, setChange] = useState(false);
  const [onChangeCountry, setOnChangeCountry] = useState(-1);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [checkDuplicate, setDuplicate] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isLoadingCheckDuplicate, setIsLoadingCheckDuplicate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setValueForm = async () => {
    setOnChangeCountry(updatedUser.country);
    await form.setFieldsValue({
      email: updatedUser.email,
      first_name: updatedUser.first_name,
      last_name: updatedUser.last_name,
      nick_name: updatedUser.nick_name,
      birth_date: moment(updatedUser.created_at),
      member: updatedUser.member,
      country: is_admin ? updatedUser.country : userCampus.country_id,
      campus: is_admin ? updatedUser.campus : userCampus.campus_id,
      talksam_id: updatedUser.talksam_id,
    });
  };

  useEffect(() => {
    if (updatedUser) {
      // console.log(updatedUser);
      setValueForm();
    }
  }, [updatedUser]);

  const onSaveStage = async () => {
    try {
      await form.validateFields();
      const data = form.getFieldsValue();
      console.log("data", data);

      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("first_name", "");
      formData.append("last_name", data.last_name);
      formData.append("nick_name", data.nick_name);
      formData.append("birth_date", data.birth_date);
      formData.append("member", data.member);
      formData.append(
        "country",
        is_admin ? data.country : userCampus.country_id
      );
      formData.append("talksam_id", data.talksam_id);
      formData.append("campus", is_admin ? data.campus : userCampus.campus_id);
      formData.append("password", data.password);
      // formData.append("file",avatar_upload)
      APIService.save_user_campus_admin(formData, cookies["access_token"])
        .then((res) => {
          if (res.status_code === 200) {
            Notification("success", "Success Register/Updated User");
            form.resetFields();
            // if (is_admin) {
            //   setCountry({
            //     country_item: country_list.find(
            //       (item) => item.id === data.country
            //     ).name,
            //     country: data.country,
            //   });
            //   setCampus({
            //     campus_item: campus_list.find((item) => item.id === data.campus)
            //       .name,
            //     campus: data.campus,
            //   });
            // }
            fetchData();
            setRefetch((prev) => !prev);
          }
        })
        .catch((err) => {
          Notification("error", "");
        })
        .finally(() => {});
    } catch (error) {}
  };

  // const handleChange = () => {
  //   setIsFormDisabled(false);
  //   setChange(true);
  //   form.setFieldsValue({
  //     campus: updatedUser?.campus,
  //   });
  // };
  const eventOnChangeCountry = (value) => {
    setOnChangeCountry(value);
    form.setFieldValue("campus", null);
  };
  const handleCheckDuplicateEmail = async () => {
    if (form.getFieldValue("email")) {
      setIsLoadingCheckDuplicate(true);
      const body = { email: form.getFieldsValue("email")?.email };
      APIService.check_exist_email(body)
        .then((response) => {
          const isExist = response?.data?.is_exists ?? true;
          isExist
            ? Notification("error", "Email existed")
            : Notification("success", "Email can be use");
        })
        .finally(() => {
          setIsLoadingCheckDuplicate(false);
        });
    }
  };

  useEffect(() => {
    form.resetFields();
    setUpdatedUser(user);
    setIsFormDisabled(false);
    setDuplicate(false);
    form.setFieldsValue({
      campus: updatedUser?.campus,
    });
  }, [user]);
  const onFinish = (values) => {};
  const onRegisterNewUser = () => {
    form.resetFields();
    setIsFormDisabled(false);
    setChange(true);
    setIsNewUser(true);
    setDuplicate(false);
  };

  const handleDeleteUser = async () => {
    if (!updatedUser || !updatedUser?.email) {
      Notification("warning", "Please choose user!");
      return;
    }
    const body = {
      email: updatedUser?.email,
    };
    setIsLoading(true);
    APIService.delete_user_by_email(cookies["access_token"], body)
      .then(() => {
        Notification("success", "Delete successfully");
        fetchData();
        setUpdatedUser(null);
        form.resetFields();
      })
      .catch(() => {
        Notification("error", "Something went wrong, please try again!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="w-[90%]">
      <br />
      <div className="relative flex justify-evenly">
        <Button onClick={onRegisterNewUser} className={style.btn_addPage}>
          New User
        </Button>
        <Button onClick={onSaveStage} className={style.btn_addPage}>
          Register / Change
        </Button>
        <Popconfirm
          title="Delete the user"
          description="This user is having Courses. Are you sure to delete?"
          onConfirm={handleDeleteUser}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No">
          <Button
            style={{ width: buttonWidth }}
            className={style.btn_addPage}
            // isLoading={isLoading}
            // disabled={isLoading}
          >
            Delete
          </Button>
        </Popconfirm>
      </div>
      <br />
      <Form
        onFinish={onFinish}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        form={form}
        disabled={isFormDisabled}>
        <Form.Item
          className="m-0"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input full name",
            },
          ]}>
          <Form.Item
            name="email"
            disabled={true}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
              {
                required: true,
                message: "Please enter your email address!",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(60% - 8px)",
              marginRight: "6%",
            }}
            validateStatus="success">
            <Input
              disabled={
                isFormDisabled ||
                (change && !isNewUser) ||
                isLoadingCheckDuplicate
              }
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            style={{
              display: "inline-block",
              width: "calc(36% - 8px)",
            }}>
            <Button
              className="w-full truncate"
              disabled={isLoadingCheckDuplicate}
              onClick={handleCheckDuplicateEmail}>
              Check duplicate
            </Button>
          </Form.Item>
        </Form.Item>

        <Form.Item name="password" label="Password">
          <Input.Password placeholder="password" />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Full name"
          className={style.formItem}
          rules={[
            {
              required: true,
              message: "Please input full name!",
            },
          ]}>
          <Input placeholder="Full name" />
        </Form.Item>
        <Form.Item
          name="nick_name"
          label="Username"
          className={style.formItem}
          rules={[
            {
              required: true,
              message: "Please input Nick Name!",
            },
            {
              pattern: /^[a-zA-Z0-9]+$/,
              message: "Nickname should only contain letters and numbers!",
            },
          ]}>
          <Input placeholder="Username" />
        </Form.Item>

        <Form.Item
          name="birth_date"
          label="Birth Date"
          rules={[
            {
              required: true,
              message: "Please input Birth date!",
            },
          ]}>
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="member"
          label="Member"
          rules={[
            {
              required: true,
              message: "Please select member",
            },
          ]}>
          <Radio.Group>
            <Radio value={true}>Member</Radio>
            <Radio value={false}>Withdraw</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="country"
          label="Select country"
          rules={[
            {
              required: is_admin,
              message: "Please select your country!",
            },
          ]}>
          <Select
            disabled={!is_admin}
            onChange={eventOnChangeCountry}
            placeholder="Please select a country">
            {country_list.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="campus"
          label="Select Campus"
          rules={[
            {
              required: is_admin,
              message: "Please select your campus!",
            },
          ]}>
          <Select disabled={!is_admin} placeholder="Please select a campus">
            {campus_list.map((item) =>
              item.country_id === onChangeCountry ? (
                <Option value={item.id}>{item.name}</Option>
              ) : null
            )}
          </Select>
        </Form.Item>

        <Form.Item name="talksam_id" label="Campus (TalkSam) ID">
          <Input placeholder="Please input talksam id" />
        </Form.Item>
      </Form>
    </div>
  );
};
