import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { FormUser } from "../../../../components/Form";
import { TableDataUsersAdmin } from "../../../../components/TableData";
import { setEmail } from "../../../../redux/campusSlice";
import { APIService } from "../../../../services/apiService";
import style from "./style.module.css";

const InforAdminManagement = ({ selectedUser, setRefetch }) => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [user, setUser] = useState(null);
  const [disable, setDisable] = useState(true);
  const [country, setCountry] = useState(null);
  const [campus, setCampus] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);
  const [avatar_upload, setAvatar_upload] = useState(null);
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "",
    },
  ]);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  const dispatch = useDispatch();

  const handleRowClick = (record) => {
    dispatch(setEmail(record));
    let avt = fileList[0];
    if (!avt) {
      avt = {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: "",
      };
    }
    avt.url = record?.avatar_url;
    setFileList([avt]);
    setUser(record);
  };
  const handleClickRowCountry = (record) => {
    setCountry(record?.id);
  };
  const handleClickRowCampus = (record) => {
    setCampus(record?.id);
  };

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_list_users_admin(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
    // console.log('check data',)
  }, []);

  // useEffect(() => {
  //   if (dataUsers) {
  //     setUser(dataUsers[0]);
  //   }
  // }, [dataUsers]);

  return (
    <div className={style.container_Infor}>
      <div className={style.list_user}>
        <div className="text-center">
          <p className="text-blue-600 text-xl w-full center">
            First, Select Country
          </p>
        </div>
        <div>
          <TableDataUsersAdmin
            country={country}
            campus={campus}
            dataUsers={dataUsers}
            handleClickRow={handleRowClick}
            handleClickRowCountry={handleClickRowCountry}
            handleClickRowCampus={handleClickRowCampus}
          />
        </div>
      </div>
      <div className={style.user_infor}>
        <Col span={24}>
          <Row gutter={[10, 5]}>
            {/* <Col span={6}>
                <div className="relative flex justify-center flex-col items-center">
                  <p className="text-blue-600">User Information</p>
                  <div>
                    <AvatarUser
                      setAvatar_upload={setAvatar_upload}
                      avatar_url={user?.avatar_url}
                      fileList={fileList}
                      setFileList={setFileList}
                    ></AvatarUser>
                  </div>
                </div>
              </Col> */}
            <Col span={24}>
              <FormUser
                setRefetch={setRefetch}
                setCountry={setCountry}
                setCampus={setCampus}
                fetchData={fetchData}
                avatar_upload={avatar_upload}
                fileList={fileList}
                user={user}
                is_admin={true}></FormUser>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
};

export default InforAdminManagement;
