import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import "@fontsource/poppins";
import { Button, Checkbox, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaKey } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import left_icon from "../../../assets/avatar/Letf_bird.png";
import right_icon from "../../../assets/avatar/Right_bird.png";
import logoTalksam from "../../../assets/logo/logo-talksam.jpg";
import { APIService } from "../../../services/apiService";
import "../Login.css";
import style from "../component/style.module.css";
import ModalTalksamLogin from "./ModalTalksamLogin";
import RegisterForm from "./RegisterForm";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(32).required(),
});
const warning = () => {
  Modal.warning({
    title: "Email or password is incorrect, please try again later",
  });
};
const model_error = () => {
  Modal.error({
    title: "Server is under maintenance, please try again later",
  });
};
function MyForm() {
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const nagivagte = useNavigate();
  const [openState, setOpenState] = useState(false);
  const [isShowTalksamLogin, setIsShowTalksamLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenState = () => {
    // setOpenState(true);
    nagivagte("/register");
  };

  let user = useSelector((state) => state.user);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  useEffect(() => {}, [user]);

  const validateForm = () => {
    const validationErrors = {};

    if (!userData.email) {
      validationErrors.email = "Please enter your email.";
    }

    if (!userData.password) {
      validationErrors.password = "Please enter your password.";
    } else if (userData.password.length < 5 || userData.password.length > 32) {
      validationErrors.password =
        "Password must be between 5 and 32 characters.";
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      // // console.log(event);
      const data = {
        email: userData.email,
        password: userData.password,
      };
      try {
        setIsLoading(true);
        APIService.login(data)
          .then((response) => {
            if (!response.status_code) {
              warning();
              return;
            }
            if (response && response.data.access_token) {
              // // console.log(response.data.access_token);

              if (response.data.access_token) {
                let expires = new Date();

                expires.setTime(expires.getTime() + 60 * 60 * 8 * 1000);
                setCookie("access_token", response.data.access_token, {
                  path: "/",
                  expires,
                });
                navigate("/dashboard");
              }
            }
          })
          .catch((error) => {
            model_error();
            // navigate('/login')
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const onChange = (e) => {
    // // console.log(`checked = ${e.target.checked}`);
  };

  const handleLogin = (e) => {};

  return (
    <div className="container mx-auto">
      <img src={left_icon} alt="icon" className={style.left_icon} />
      <img src={right_icon} alt="icon" className={style.right_icon} />
      {openState ? (
        <>
          <RegisterForm />
        </>
      ) : (
        <>
          <div className="w-full border-[3px] bg-white/60 rounded-2xl md:max-w-xl py-10">
            <div className="separator">
              <span
                className="line"
                style={{ color: "rgba(101, 191, 204, 1)" }}></span>
              <span className={style.text}>Login</span>
              <span
                className="line"
                style={{ color: "rgba(101, 191, 204, 1)" }}></span>
            </div>
            <br />
            <form onSubmit={onSubmitHandler} className={style.form_Login}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  background: "#ffffff",
                  borderRadius: "8px",
                }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "10%",
                  }}>
                  <MailOutlined
                    style={{ fontSize: "20px", margin: "0 auto" }}
                  />
                </div>
                <div
                  className={style.form_field}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "90%",
                  }}>
                  <Input
                    className={style.form_input}
                    value={userData.email}
                    onChange={onInputChange}
                    placeholder="Please input email or username"
                    type="text"
                    name="email"
                    required
                  />
                  <label className={style.form_label}>Email or Username</label>
                  {errors.email && (
                    <span style={{ color: "red" }}>{errors.email}</span>
                  )}
                </div>
              </div>
              <br />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  background: "#ffffff",
                  borderRadius: "8px",
                }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "10%",
                  }}>
                  <FaKey style={{ fontSize: "20px", margin: "0 auto" }} />
                </div>

                <div
                  className={style.form_field_password}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "90%",
                  }}>
                  {/*Input.Password -> Input*/}
                  <Input
                    className={style.form_input_password}
                    value={userData.password}
                    placeholder="Please input password"
                    onChange={onInputChange}
                    name="password"
                    type="password"
                    required
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  <label className={style.form_label_password}>Password</label>

                  {errors.password && (
                    <span style={{ color: "red" }}>{errors.password}</span>
                  )}
                </div>
              </div>
              <br />
              <div style={{ width: "100%" }}>
                <div style={{ width: "50%" }}>
                  <Checkbox onChange={onChange}>Remember me</Checkbox>
                </div>
                <div style={{ width: "50%", textAlign: "right" }}>
                  {/* <a
                    className={style.forgot_password}
                    href="#"
                    style={{ color: "#6358DC", textDecoration: "none" }}
                  >
                    Forgot Password?
                  </a> */}
                </div>
              </div>
              <br />
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                htmlType="submit"
                size="large"
                style={{ background: "#FE998D", width: "50%" }}>
                Login
              </Button>
              <span className="my-2">or</span>
              <button
                type="button"
                className="h-[90px] relative"
                onClick={() => {
                  setIsShowTalksamLogin(true);
                }}>
                <img
                  src={logoTalksam}
                  alt="logo"
                  className="h-full object-cover"
                />
              </button>
              <ModalTalksamLogin
                isModalOpen={isShowTalksamLogin}
                setIsModalOpen={setIsShowTalksamLogin}
              />
              <br />
              <br />
              <div>
                <p
                  className={style.haveAnAccount}
                  style={{ textAlign: "center" }}>
                  Don't have an account?{" "}
                  <button
                    className={style.register}
                    onClick={handleOpenState}
                    style={{
                      color: "#6358DC",
                      textDecoration: "none",
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                    }}>
                    Join
                  </button>
                </p>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default MyForm;
