import React, { useLayoutEffect } from "react";
import { Route, Routes } from "react-router";
import Mainlayout from "../layouts/MainLayout";
import SubLayout from "../layouts/SubLayout";
import CurriculumPage from "../pages/CurriculumPage";
import DashBoardPage from "../pages/DashBoardPage";
import AdminApprovalRequest from "../pages/DashBoardPage/DashBoardAdministrator/AdminApprovalRequest";
import AdminCMS from "../pages/DashBoardPage/DashBoardAdministrator/AdminCMS";
import AdminLMS from "../pages/DashBoardPage/DashBoardAdministrator/AdminLMS";
import AdminPMS from "../pages/DashBoardPage/DashBoardAdministrator/AdminPMS";
import AdminSetting from "../pages/DashBoardPage/DashBoardAdministrator/AdminSetting";
import AdminUser from "../pages/DashBoardPage/DashBoardAdministrator/AdminUser";
import AdminWeb from "../pages/DashBoardPage/DashBoardAdministrator/AdminWeb";
import CampusApprovalRequest from "../pages/DashBoardPage/DashBoardCampus/CampusApprovalRequest";
import CampusLMS from "../pages/DashBoardPage/DashBoardCampus/CampusLMS";
import CampusUser from "../pages/DashBoardPage/DashBoardCampus/CampusUser";
import UserPage from "../pages/DashBoardPage/MyPage/UserPage";
import WritingCenterPage from "../pages/DashBoardPage/MyPage/WritingCenterPage";
import StableDiffusionCreate from "../pages/DashBoardPage/MyPage/WritingCenterPage/StableDiffusion";
import PetRoomPage from "../pages/DashBoardPage/PetRoomPage";
import PointPage from "../pages/DashBoardPage/PointPage";
import Ranking from "../pages/DashBoardPage/Ranking";
import EBookMaking from "../pages/EbookColection/components/EBookMaking";
import CreateEBook from "../pages/EbookColection/components/components/CreateEBook";
import Inquiry from "../pages/Inquiry";
import LoginPage from "../pages/LoginPage";
import RegisterForm from "../pages/LoginPage/component/RegisterForm";
import NoticePage from "../pages/NoticePage";
import OurservicePage from "../pages/OurservicePage";
// import RecorderComponent from "../components/RecordingAudio/RecorderComponent.jsx";
// import StableDiffusionAndSticker from "../pages/DashBoardPage/MyPage/WritingCenterPage/StableDiffusion";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { EBookContextProvider } from "../context/ViewEbook";
import CustomArtwork from "../pages/CustomArtwork";
import CustomDashBoardEBook from "../pages/CustomDashboardEBook";
import CustomEBook from "../pages/CustomEBook";
import StableDiffusionUpdate from "../pages/DashBoardPage/MyPage/WritingCenterPage/StableDiffusionUpdate";
import Ebook from "../pages/Ebook";

const Router = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("access_token");
  const [, setCookie] = useCookies(["access_token"]);

  useLayoutEffect(() => {
    if (token) {
      let expires = new Date();
      console.log(token);

      expires.setTime(expires.getTime() + 60 * 60 * 2 * 1000);
      setCookie("access_token", token, {
        path: "/",
        expires,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <React.Suspense>
      <Routes>
        <Route path="/" element={<Mainlayout />}>
          {/* <Route path="/audio" element={<RecorderComponent />} /> */}
          <Route index element={<OurservicePage />} />
          <Route path="dashboard" element={<DashBoardPage />} />
          <Route path="our-service" element={<OurservicePage />} />
          <Route path="curriculum" element={<CurriculumPage />} />
          <Route path="notice" element={<NoticePage />} />
          <Route path="inquiry" element={<Inquiry />}></Route>
          <Route
            path="writing-center/:lessonId"
            element={<WritingCenterPage />}
          />
          <Route path="stable-diffusion" element={<StableDiffusionCreate />} />
          <Route
            path="stable-diffusion/:lessonId"
            element={<StableDiffusionUpdate />}
          />

          <Route path="pet-room" element={<PetRoomPage />} />
          {/* <Route path="e-mybook/:type" element={<EbookColections />} /> */}
          <Route path="e-mybook" element={<CustomDashBoardEBook />} />
          <Route path="e-mybook/artwork" element={<CustomArtwork />} />
          <Route path="e-mybook/ebook" element={<CustomEBook />} />
          <Route path="ebook-making/:type" element={<EBookMaking />} />
          <Route path="point" element={<PointPage />} />
          <Route path="study-room" element={<UserPage />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="ranking" element={<Ranking />} />
          <Route path="campus-user" element={<CampusUser />} />
          <Route
            path="campus-lms"
            element={
              <EBookContextProvider>
                <CampusLMS />
              </EBookContextProvider>
            }
          />
          <Route path="e-book" element={<Ebook />} />
          <Route
            path="campus-approval_request"
            element={<CampusApprovalRequest />}
          />
          <Route path="admin-setting" element={<AdminSetting />} />
          <Route path="admin-user" element={<AdminUser />} />
          <Route path="admin-pms" element={<AdminPMS />} />
          <Route path="admin-cms" element={<AdminCMS />} />

          <Route
            path="admin-lms"
            element={
              <EBookContextProvider>
                <AdminLMS />
              </EBookContextProvider>
            }
          />
          <Route path="admin-web" element={<AdminWeb />} />
          <Route path="admin-approval" element={<AdminApprovalRequest />} />
          <Route
            path="createEbook/:ebookId"
            element={<CreateEBook ebookId={{}} />}
          />
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route path="/" element={<SubLayout />}>
          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/forgot-password" />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default Router;
