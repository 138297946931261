import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      username: "",
      avatar: "",
      email: "",
      course: "",
      inquiry: "",
      id: null,
      // point: "",
      level: "",
      birth_day: "",
      first_name: "xxx",
      last_name: "xxx",
      campus_id: "",
      country_id: "",
      course_done: "0/0",
      campus_name: "",
    },
    point: {
      total_point: 0,
    },
    type_history: "course_history",
  },
  reducers: {
    userLogin: (state, { payload }) => {
      state.user.email = payload.email;
    },
    setUser: (state, { payload }) => {
      state.user.email = payload.email;
      state.user.avatar = payload.avatar;
      state.user.username = payload.username;
      state.user.level = payload.level;
      state.user.birth_day = payload.birth_day;
      state.point.total_point = Math.floor(payload.total_point);
      state.user.country_id = payload.country_id;
      state.user.campus_id = payload.campus_id;
      state.user.campus_name = payload.campus_name;
      state.user.course_done = payload.course_done;
      state.user.inquiry_done = payload.inquiry_done;
      state.user.id = payload.id;
    },
    setCurrentPoint: (state, { payload }) => {
      // // console.log(payload)
      state.point.total_point = Math.floor(payload.current_point);
    },
    minusCurrentPoint: (state, { payload }) => {
      const remainPoint = state.point.total_point - +payload;
      // // console.log(payload)
      state.point.total_point = remainPoint > 0 ? remainPoint : 0;
    },
    setTypeHistory: (state, { payload }) => {
      // // console.log(payload)
      state.type_history = payload.type_history;
    },
  },
});

export const {
  userLogin,
  setUser,
  setCurrentPoint,
  setTypeHistory,
  minusCurrentPoint,
} = userSlice.actions;
export default userSlice.reducer;
