import React, { useState, useEffect } from "react";
import HomePage from "./DashBoard";
import PointPage from "./PointPage";
import EBookPage from "./EBookPage";
import MyPage from "./MyPage";
import PetRoomPage from "./PetRoomPage";
import { useNavigate } from "react-router";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { sendMessage, connect, disconnect } from "../../redux/wsSlice";
import { useDispatch } from "react-redux";



const DashBoardPage = () => {
  const dispatch = useDispatch();
  
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);

  const [currentPage, setCurrentPage] = useState("home");
  const user = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();

  const handleChangeType = (item, navigateTo) => {
    setCurrentPage(item);
    navigate(navigateTo);
  };

  // useEffect(() => {
  //   dispatch(connect());
  // }, [dispatch]);

  const renderPage = () => {
    switch (currentPage) {
      case "home":
        return (
          <HomePage handleChangeType={handleChangeType} level={user.level} />
        );
      case "mypage":
        return <MyPage />;
      case "petroom":
        return <PetRoomPage />;
      case "point":
        return <PointPage />;
      case "ebook":
        return <EBookPage />;
      default:
        return <HomePage />;
    }
  };
  return <>{cookies["access_token"] && renderPage()}</>;
};
export default DashBoardPage;
