import React, { forwardRef } from "react";

const Page =forwardRef((props, ref) => {
    return (
      <div className="demoPage" ref={ref}>
        <div style={{width:"100%", height:"100%",background:"pink"}}>{props.children}</div>
      </div>
    );
  });

export default Page;