import { createSlice } from "@reduxjs/toolkit";
import { number } from "yup";

export const campusSlice = createSlice({
  name: "campus",
  initialState: {
    campusUser: {
      avatar_url: null,
      birth_day: null,
      campus: null,
      country: null,
      created_at: null,
      email: null,
      fullname: null,
      level: null,
      member: null,
      nick_name: null,
      talksam_id: "",
    },
    products_list: [],
    user_list: []
  },
  reducers: {
    setEmail: (state, action) => {
      state.campusUser.avatar_url = action.payload.avatar_url;
      state.campusUser.birth_day = action.payload.birth_day;
      state.campusUser.campus = action.payload.campus;
      state.campusUser.country = action.payload.country.toString();
      state.campusUser.created_at = action.payload.created_at;
      state.campusUser.email = action.payload.email;
      state.campusUser.fullname = action.payload.fullname;
      state.campusUser.level = action.payload.level;
      state.campusUser.member = action.payload.member;
      state.campusUser.nick_name = action.payload.nick_name;
      // state.campusUser.talksam_id = action.payload.talksam_id;
    },
    updateCampus: (state, action) => {
      state.campusUser = {
        ...state.campusUser,
        ...action.payload,
      };
      // // console.log(state.campusUser);
    },
    setProductList: (state,action) =>{
      // // console.log(action.payload)
      state.products_list = action.payload.product_list
    },
    setUserList: (state,action) =>{
      state.user_list =  action.payload.user_list
    }
  },
});

export const { setEmail, updateCampus,setProductList,setUserList } = campusSlice.actions;
export default campusSlice.reducer;
