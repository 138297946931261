import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { setSpeakerAudio } from "../../redux/chatSlice";
import ContentChat from "./MainChat/ContentChat";
import InputChat from "./MainChat/InputChat";
import style from "./chatBot.module.css";
function BotChat({ level }) {
  const dispatch = useDispatch();
  const speakerAudio = useSelector((state) => state.chatSlice.speakerAudio);
  const [typeChat, setTypeChat] = useState("text");
  const speakerRef = useRef(null);
  const handleSizeChange = (e) => {
    setTypeChat("text");
  };

  useEffect(() => {
    if (!speakerRef.current || !speakerAudio) return;
    speakerRef.current.play();
  }, [speakerAudio]);

  return (
    <div className={style.container_chatbot}>
      <button
        className={classNames(
          "absolute right-12 -top-6 rounded-full bg-sky-100 hover:bg-sky-200 duration-200 w-16 h-16 shadow-md group",
          speakerAudio ? "block" : "hidden"
        )}
        onClick={() => {
          speakerRef.current.pause();
          dispatch(setSpeakerAudio(null));
        }}>
        <HiSpeakerWave
          size={40}
          className="group-hover:invisible visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
        <HiSpeakerXMark
          size={40}
          className="group-hover:visible invisible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
        <audio
          className="absolute"
          ref={speakerRef}
          src={speakerAudio}
          onEnded={() => {
            dispatch(setSpeakerAudio(null));
          }}
        />
      </button>
      {/* <Radio.Group value={typeChat} onChange={handleSizeChange}>
        <Radio.Button value="text">Text</Radio.Button>
        <Radio.Button onClick={()=>alert("Feature upcomping!")} value="voice">Voice</Radio.Button>
      </Radio.Group> */}
      <ContentChat />
      <InputChat level={level} />
    </div>
  );
}

export default BotChat;
