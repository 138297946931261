import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../../components/Notification/index";
import { setRefetch } from "../../redux/adminSlice";
import { APIService } from "../../services/apiService";
import style from "./style.module.css";

function PopupCampusManager({ setOpenPopup, typePopup, fetchData }) {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(typePopup);
  }, []);
  // const user = useSelector((state) => state.userSlice.user);
  // const point = useSelector((state) => state.userSlice.point);

  const country_list = useSelector((state) => state.adminSlice.country_list);
  const [campusName, setCampusName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [countryId, setCountryId] = useState(0);
  const [isCheckOke, setIsCheckOke] = useState(false);
  const duplicateCheckCampus = () => {
    if (campusName) {
      APIService.check_campus_duplicate(
        {
          campus_name: campusName,
          country_id: countryId,
        },
        cookies["access_token"]
      )
        .then((response) => {
          setIsCheckOke(!response.data);
        })
        .catch((error) => {});
    }
  };
  const duplicateCheckCategory = () => {
    if (categoryName) {
      APIService.check_category_duplicate(
        {
          category: categoryName,
        },
        cookies["access_token"]
      )
        .then((response) => {
          setIsCheckOke(!response.data);
        })
        .catch((error) => {});
    }
  };

  const handleSelectChange = (selectedValue) => {
    setCountryId(selectedValue);
  };
  const onRegisterCampus = () => {
    APIService.register_campus(
      {
        campus_name: campusName,
        country_id: countryId,
      },
      cookies["access_token"]
    )
      .then((response) => {
        Notification("success", "Success create campus");
        setIsCheckOke(false);
        setOpenPopup(false);
        fetchData();
        dispatch(setRefetch());
      })
      .catch((error) => {});
  };
  const onRegisterCategory = () => {
    APIService.create_category(
      {
        category: categoryName,
      },
      cookies["access_token"]
    )
      .then((response) => {
        Notification("success", "Success create category");
        setIsCheckOke(false);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (country_list.length > 0) {
      setCountryId(country_list[0].id);
    }
  }, [country_list]);

  return (
    <>
      <div className={style.container_popup_campus}>
        <div className={style.content_popup_enrolment}>
          <AiOutlineCloseCircle
            className={style.btn_close_popup}
            onClick={() => setOpenPopup(false)}
            style={{ cursor: "pointer" }}
          />
          <div className={style.content_popup_campus}>
            <Col span={24}>
              <Row>
                <Form>
                  <Form.Item>
                    <Row>
                      {typePopup === "Category" ? null : (
                        <>
                          <Col span={8}>
                            <p className={style.title_popup_campus}>Country</p>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item>
                      {typePopup !== "Category" && (
                        <Row>
                          <Col span={8}>
                            <Select
                              showSearch
                              placeholder="Select a country"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={country_list.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              onChange={handleSelectChange}
                            />
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                    <Row gutter={8}>
                      {typePopup === "Category" ? (
                        <>
                          <Col span={8}>
                            <p>Category</p>
                          </Col>
                          <Col xl={10} xxl={12}>
                            <Input
                              onChange={(e) => {
                                setCategoryName(e.target.value);
                                setIsCheckOke(false);
                              }}
                              value={categoryName}
                            />
                          </Col>
                          <Col span={4}>
                            <Button onClick={duplicateCheckCategory}>
                              Duplicate check
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={8}>
                            <p>Campus name</p>
                          </Col>
                          <Col xl={10} xxl={12}>
                            <Input
                              onChange={(e) => {
                                setCampusName(e.target.value);
                                setIsCheckOke(false);
                              }}
                              value={campusName}
                            />
                          </Col>
                          <Col span={4}>
                            <Button onClick={duplicateCheckCampus}>
                              Duplicate check
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row justify="space-evenly">
                      {isCheckOke && typePopup !== "Category" ? (
                        <Col span={4}>
                          <Button onClick={onRegisterCampus}>Saved</Button>
                        </Col>
                      ) : null}
                      {isCheckOke && typePopup === "Category" ? (
                        <Col span={4}>
                          <Button onClick={onRegisterCategory}>Saved</Button>
                        </Col>
                      ) : null}
                    </Row>
                  </Form.Item>
                </Form>
              </Row>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupCampusManager;
