import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Button, Col, Input, Row, Select } from "antd";
import { TableDataAdminPageList } from "../../../../components/TableData";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
import PopupAddPage from "../../../../components/Popup/PopupAddPage";
import PopupAnswerRegistration from "../../../../components/Popup/PopupAnswerRegistration";
import logo1 from "../../../../assets/background/lion UFO.png";
import logo2 from "../../../../assets/background/bear with plane.png";

const { Search } = Input;

const AdminPageList = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [type, setType] = useState("create");

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupAnswerRegistration, setOpenAnswerRegistration] =
    useState(false);

  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_list_campus_enrolment(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setOpenAnswerRegistration(true);
  }, []);

  return (
    <>
      <div className={style.container_listCampus}>
        <div className={style.comingSon}>
          <h1>Coming Soon</h1>
          
        </div>

        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <div className={style.filter_Username}>
                <Row gutter={20}>
                  <Col span={4}>
                    {/* <Button
                      className={style.btn_addPage}
                      onClick={() => setOpenPopup(true)}
                    >
                      Add Page
                    </Button> */}
                  </Col>
                  {/* <Col span={8}>
                    <Select
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Col> */}
                  {/* <Col span={10}>
                    <Row justify={"end"}>
                      <Search
                        placeholder="input search text"
                        allowClear
                        enterButton="Search"
                      />
                    </Row>
                  </Col> */}
                </Row>
              </div>
            </Col>

            {/* <Col span={24}>
              <div className={style.list_tableCampus}>
                <TableDataAdminPageList type={type} dataUsers={dataUsers} />
              </div>
            </Col> */}
          </Row>
        </Col>

        {openPopup && <PopupAddPage setOpenPopup={setOpenPopup} />}
        {/* {openPopupAnswerRegistration && (
          <PopupAnswerRegistration></PopupAnswerRegistration>
        )} */}
      </div>
    </>
  );
};

export default AdminPageList;
