import { Tabs } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import Course from "./Course";
import style from "./style.module.css";
const { TabPane } = Tabs;

const StudyRoom = ({ handleLessonId }) => {
  const [value, setValue] = useState("course");
  const navigate = useNavigate();

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={style.mypage}>
        <div className={style.content_mypage}>
          {/* <img
            src={btn_home}
            className={style.btn_home}
            alt="btn_Home"
            onClick={() => navigate("/dashboard")}
          /> */}
          <div className={style.logo_studyRoom}></div>
          <div className={style.table_Course}>
            <Course handleLessonId={handleLessonId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyRoom;
