import React, { useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { BiConversation } from "react-icons/bi";
import { GrCopy } from "react-icons/gr";
import { IoMdCloudDownload } from "react-icons/io";
import { APIService } from "../../services/apiService";

import { EyeFilled, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import classNames from "classnames";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Notification } from "../../components/Notification/index";
import { useEBook } from "../../context/ViewEbook";
import DeleteConfirm from "./DeleteConfirm";
import ViewArtworkModal from "./ViewArtworkModal";
import ViewEbookModal from "./ViewEbookModal";
import ViewWritingModal from "./ViewWritingModal";
import "./customAntTableData.css";
const inputSearchPointWidth = "90%";
const { Search } = Input;
const inpuSearchTopPosition = "20%";

const TableDataCourse = (props) => {
  const { data, text, className, isLoading } = props;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //

  useEffect(() => {
    setTableData(
      data?.map((item, index) => ({
        key: index + 1,
        name: item.product_name,
        course_name: item.course_name,
        start_date: item.registration_date,
        valid_period: item.valid_period,
      }))
    );
  }, [data]);
  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      className: "table_Courese",
    },
    {
      title: "Course",
      dataIndex: "course_name",
      key: "course_name",
      with: "40%",
      className: "table_Courese",
    },
    {
      title: "Registration Date",
      dataIndex: "start_date",
      key: "start_date",
      className: "table_Courese",
    },
    {
      title: "Valid Period",
      dataIndex: "valid_period",
      key: "valid_period",
      className: "table_Courese",
    },
  ];

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    pagination: false,
  };

  return (
    <div className={className}>
      <h1>{text}</h1>
      <div className="tableCourse">
        <Table {...tableConfig} />
      </div>
    </div>
  );
};

export const TableDataInquiry = (props) => {
  const { dataInquiry, className, handleClickRow, dataAvg } = props;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  const [tableData, setTableData] = useState([]);
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  useEffect(() => {
    setTableData(
      dataInquiry?.map((item) => ({
        name: item.name,
        ID: item.ID,
        email: item["e-mail"],
        division: item.division,
        title: item.title,
        question: item.question,
        answer: item.answer,
        reply_status: item.reply_status,
        created_at: item.created_at,
      }))
    );
    // const newTableData = [...tableData, ...Object.values(dataAvg)];
    // console.log(dataAvg);
    // setTableData(
    //   Object.values(dataAvg))
    //   console.log(tableData);
  }, [dataInquiry, dataAvg]);

  const columns = [
    // { title: "Name", dataIndex: "name", key: "name" },
    // { title: "ID", dataIndex: "ID", key: "ID" },
    // { title: "Email", dataIndex: "email", key: "email" },
    { title: "Division", dataIndex: "division", key: "division" },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    // { title: "Question", dataIndex: "question", key: "question" },
    // { title: "Answer", dataIndex: "answer", key: "answer" },
    {
      title: "Status Reply",
      dataIndex: "reply_status",
      key: "reply_status",
    },
    { title: "Date Create", dataIndex: "created_at", key: "valid_period" },
  ];

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    pagination: false,
    onRow: (record) => ({
      onClick: () => handleClickRow(record),
    }),
  };

  return (
    <div className={className}>
      <Table {...tableConfig} />
    </div>
  );
};

export const TableDataPointHistory = (props) => {
  const { dataPoint, text, className } = props;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  const [tableData, setTableData] = useState([]);
  //search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    setTableData(
      dataPoint?.map((item) => ({
        count: item.count + "/4",
        course: item.course,
        date: item.date,
        email: item.email,
        fullname: item.fullname,
        lesson: item.lesson,
        nick_name: item.nick_name,
        part: item.part,
        point: item.point,
      }))
    );
  }, [dataPoint]);

  const columns = [
    { title: "Course", dataIndex: "course", key: "course" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Fullname", dataIndex: "fullname", key: "fullname" },
    { title: "Lesson", dataIndex: "lesson", key: "lesson" },
    { title: "Count", dataIndex: "count", key: "count" },
    { title: "Nick Name", dataIndex: "nick_name", key: "nick_name" },
    { title: "Part", dataIndex: "part", key: "part" },
    { title: "Point", dataIndex: "point", key: "point" },
  ];

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    pagination: false,
  };
  let paginationConfig;

  return (
    <>
      <Table {...tableConfig} />
    </>
  );
};

export const TableDataUser = (props) => {
  const { dataUsers, handleClickRow } = props;
  const [tableData, setTableData] = useState([]);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search

  useEffect(() => {}, [tableData]);

  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        id: index + 1,
        email: item.email,
        avatar_url: item.avatar_url,
        fullname: item.first_name + item.last_name,
        first_name: item.first_name,
        last_name: item.last_name,
        nick_name: item.nick_name,
        birth_day: item.birth_day,
        level: item.level,
        member: item.member,
        country: item.country,
        campus: item.campus,
        talksam_id: item.talksam_id,
        created_at: item.created_at,
      }))
    );
  }, [dataUsers]);

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Nick Name",
      dataIndex: "nick_name",
      key: "nick_name",
    },
  ];
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickRow(record),
    }),
  };

  return <Table {...tableConfig} />;
};

export const TableDataListCampus = (props) => {
  const {
    dataUsers,
    filterValue,
    dateRange,
    selectedData,
    isLoading,
    countryFilter,
    campusFilter,
    setSelectedUser,
    setTabValue,
  } = props;
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log("selectedKeys, confirm, dataIndex", {
      selectedKeys,
      confirm,
      dataIndex,
    });
    console.log("selectedKeys, confirm, dataIndex", {
      selectedKeys,
      confirm,
      dataIndex,
    });
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "start_date"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "start_date") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    const filteredData = filterData(dataUsers, filterValue, dateRange);
    setTableData(filteredData);
  }, [dataUsers, filterValue, dateRange]);

  useEffect(() => {
    setSelectedRows(selectedData);
  }, [selectedData]);

  const columns = useMemo(
    () => [
      {
        title: "Country",
        dataIndex: "country_name",
        key: "country",
        filters: countryFilter?.map((item) => ({
          text: item,
          value: item,
        })),
        onFilter: (value, record) => record.country_name.indexOf(value) === 0,
      },
      {
        title: "Campus",
        dataIndex: "campus_name",
        key: "campus",
        filters: campusFilter?.map((item) => ({
          text: item,
          value: item,
        })),
        onFilter: (value, record) => record.campus_name.indexOf(value) === 0,

        // // ...getColumnSearchProps("campus_name"),
      },
      {
        title: "Campus (TALKSAM ID)",
        dataIndex: "talksam_id",
        key: "talksam_id",
      },
      {
        title: "Full Name",
        dataIndex: "fullname",
        key: "fullname",
        // // ...getColumnSearchProps("fullname"),
      },
      { title: "Username", dataIndex: "nick_name", key: "nick_name" },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        // // ...getColumnSearchProps("email"),
      },
      {
        title: "Registration Date",
        dataIndex: "created_at",
        key: "created_at",
      },
    ],
    [countryFilter, campusFilter]
  );

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => {
        setSelectedUser && setSelectedUser(record);
        setTabValue("1");
      },
    }),
    // rowSelection: {
    //   selectedRowKeys: selectedRows.map((row) => row.key),
    //   onChange: handleRowSelection,
    // },
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return <Table loading={isLoading} {...tableConfig} onChange={onChange} />;
};

const filterData = (dataUsers, filterValue, dateRange) => {
  let filteredData = dataUsers;

  if (filterValue) {
    filteredData = filteredData.filter(
      (user) =>
        user?.nick_name?.toLowerCase().includes(filterValue.toLowerCase()) ||
        user?.talksam_id?.toLowerCase().includes(filterValue.toLowerCase()) ||
        user?.fullname?.toLowerCase().includes(filterValue.toLowerCase()) ||
        user?.email?.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  if (dateRange && dateRange.length === 2) {
    const [start, end] = dateRange;
    filteredData = filteredData.filter((user) => {
      const createdAt = new Date(user.created_at);
      return createdAt >= start && createdAt <= end;
    });
  }

  return filteredData;
};

export const TableDataPointManager = (props) => {
  const { dataUsers, isLoading } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        key: index + 1,
        ...item,
      }))
    );
  }, [dataUsers]);

  const columns = [
    { title: "No", dataIndex: "key", key: "key" },
    {
      title: "Campus",
      dataIndex: "campus",
      key: "campus",
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Nick Name",
      dataIndex: "nick_name",
      key: "nick_name",
    },
  ];

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    pagination: { pageSize: 20 },
  };
  return (
    <Table
      loading={isLoading}
      {...tableConfig}
      expandable={{
        expandedRowRender: (record) => {
          return <ExpandedTablePointManager data={record?.details} />;
        },
      }}
    />
  );
};

const ExpandedTablePointManager = ({ data }) => {
  const tableData = useMemo(
    () =>
      data.map((item, index) => ({
        subKey: index + 1,
        ...item,
      })),
    [data]
  );
  const columns = [
    { title: "No", dataIndex: "subKey", key: "subKey" },
    { title: "Product", dataIndex: "product", key: "product" },
    { title: "Course", dataIndex: "course", key: "course" },
    { title: "Lesson", dataIndex: "lesson", key: "lesson" },
    { title: "Count (Total Count)", dataIndex: "count", key: "count" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Part", dataIndex: "part", key: "part" },
    { title: "Point", dataIndex: "point", key: "point" },
  ];

  return (
    <>
      <Table columns={columns} dataSource={tableData} />
    </>
  );
};

export const TableDataClassMagnager = (props) => {
  const [cookies] = useCookies(["access_token"]);
  const { dataUsers, onSelectedDataChange, isLoading } = props;
  const [tableData, setTableData] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });

  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        ...item,
        key: index,
        index: index + 1,
        email: item.email,
        nick_name: item?.nick_name ?? item?.user_name,
        fullname: item?.fullname,
        listCourses: item.listCourse,
        talksam_id: item.talksam_id,
      }))
    );
  }, [dataUsers]);

  const columns = [
    { title: "No", dataIndex: "index", key: "index" },
    {
      title: "Campus",
      dataIndex: "campus",
      key: "campus",
      className: "tableLMS_Class",
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
      className: "tableLMS_Class",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "tableLMS_Class",
    },
    {
      title: "Talksam ID",
      dataIndex: "talksam_id",
      key: "talksam_id",
      className: "tableLMS_Class",
    },
    {
      title: "Username",
      dataIndex: "nick_name",
      key: "nick_name",
      className: "tableLMS_Class",
    },
  ];

  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 10 };
      paginationConfig = { pageSize: 10 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 10 };
      paginationConfig = { pageSize: 10 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 10 };
      paginationConfig = { pageSize: 10 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  if (props?.hasFilterData) {
    return (
      <Table
        dataSource={tableData}
        columns={columns}
        loading={isLoading}
        pagination={paginationConfig}
        expandable={{
          expandedRowRender: (record) => {
            return <ExpandedRowRenderTableNoFetch data={record} />;
          },
        }}
      />
    );
  }

  return (
    <Table
      dataSource={tableData}
      columns={columns}
      loading={isLoading}
      pagination={paginationConfig}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <ExpandedRowRenderTable
              isArtwork={props?.isArtwork}
              cookies={cookies}
              userId={record?.id}
            />
          );
        },
      }}
    />
  );
};

const ExpandedRowRenderTableNoFetch = ({ data }) => {
  const [cookies] = useCookies(["access_token"]);
  const accessToken = cookies["access_token"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const formattedData = useMemo(() => {
    return data.lessons?.map((item, index) => ({
      ...item,
      index: index + 1,
      start_date: data?.start_date,
      end_date: data?.end_date,
      update_date: item?.update_date?.split("T")[0],
      point: item?.history?.evaluation?.everage_point,
    }));
  }, [data]);

  const handleDownloadChatHistory = (user_id, lesson_id) => {
    const body = {
      user_id,
      lesson_id,
    };
    APIService.get_user_chat_history(accessToken, body)
      .then(async (res) => {
        const response = await fetch(res.data);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "chat_history.txt";
        link.click();
      })
      .catch(() => {
        Notification("error", "Something was wrong!");
      });
  };

  const handleDownloadCorrection = (text, name) => {
    const body = {
      text,
    };
    APIService.export_gpt(accessToken, body)
      .then(async (res) => {
        const response = await fetch(res.data);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.click();
      })
      .catch(() => {
        Notification("error", "Something was wrong!");
      });
  };

  const onClickDownloadCorrection = (record) => {
    const text = record?.history?.suggestion?.sentences;
    if (!text) {
      Notification("error", "This student does not reach this part!");
      return;
    }
    handleDownloadCorrection(text, "correction.txt");
  };

  const onClickDownloadImprovement = (record) => {
    const text = record?.history?.suggestion?.vocabulary;
    if (!text) {
      Notification("error", "This student does not reach this part!");
      return;
    }
    handleDownloadCorrection(text, "improvement.txt");
  };

  const columns = [
    { title: "No", dataIndex: "index", key: "index" },
    { title: "Course", dataIndex: "course_name", key: "course_name" },
    { title: "Start Date", dataIndex: "start_date", key: "start_date" },
    { title: "End Date", dataIndex: "end_date", key: "end_date" },
    // { title: "Lesson ID", dataIndex: "id", key: "id" },
    { title: "Lesson", dataIndex: "lesson_name", key: "lesson_name" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Date", dataIndex: "update_date", key: "update_date" },
    { title: "Evaluation Point", dataIndex: "point", key: "point" },
    {
      title: "Brain Storming",
      dataIndex: "brain_storming",
      key: "brain_storming",
      render: (_, record) => (
        <button
          onClick={() => {
            handleDownloadChatHistory(data?.user_id, record?.id);
          }}
        >
          <BiConversation />
        </button>
      ),
    },
    {
      title: "Essay",
      dataIndex: "essay",
      key: "essay",
      render: (_, record) => (
        <button
          onClick={() => {
            setIsModalOpen(true);
            setSelectedData(record);
          }}
        >
          <GrCopy />
        </button>
      ),
    },
    {
      title: "Correction",
      dataIndex: "",
      key: "correction",
      render: (_, record) => (
        <button
          onClick={() => {
            onClickDownloadCorrection(record);
          }}
        >
          <IoMdCloudDownload />
        </button>
      ),
    },
    {
      title: "Improvement",
      dataIndex: "",
      key: "improvement",
      render: (_, record) => (
        <button
          onClick={() => {
            onClickDownloadImprovement(record);
          }}
        >
          <IoMdCloudDownload />
        </button>
      ),
    },
    {
      title: "Artwork",
      dataIndex: "",
      key: "Artwork",
      render: (_, record) => <ViewArtworkModal artworkUrl={record?.artwork} />,
    },
  ];

  return (
    <>
      <ViewWritingModal
        data={selectedData}
        key={data?.user_id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Table columns={columns} dataSource={formattedData} />
    </>
  );
};

const ExpandedRowRenderTable = ({ userId, cookies, isArtwork }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [data, setData] = useState([]);
  const accessToken = cookies["access_token"];
  const fetchDetailUser = async () => {
    try {
      const res = await APIService.get_course_by_user_admin_role(accessToken, {
        userId,
      });
      let newData = [];
      const formattedData = res?.data?.map(
        (item) => Object.entries(item)[0][1]
      );
      for (const i of formattedData) {
        for (const j of i?.lesson) {
          newData.push({
            ...j,
            start_date: i?.start_date,
            end_date: i?.end_date,
          });
        }
      }
      newData = newData?.map((item, index) => ({
        ...item,
        index: index + 1,
        update_date: item?.update_date?.split("T")[0],
      }));
      setData(newData);
    } catch (error) {}
  };

  useEffect(() => {
    if (!userId) return;
    setIsLoading(true);
    setTimeout(() => {
      fetchDetailUser();
      setIsLoading(false);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleDownloadChatHistory = (lessonId) => {
    const body = {
      user_id: userId,
      lesson_id: lessonId,
    };
    APIService.get_user_chat_history(accessToken, body)
      .then(async (res) => {
        const response = await fetch(res.data);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "chat_history.txt";
        link.click();
      })
      .catch(() => {
        Notification("error", "Something was wrong!");
      });
  };

  const handleDownloadCorrection = (text, name) => {
    const body = {
      text,
    };
    APIService.export_gpt(accessToken, body)
      .then(async (res) => {
        const response = await fetch(res.data);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.click();
      })
      .catch(() => {
        Notification("error", "Something was wrong!");
      });
  };

  const onClickDownloadCorrection = (record) => {
    const text = record?.history?.suggestion?.sentences;
    if (!text) {
      Notification("error", "This student does not reach this part!");
      return;
    }
    handleDownloadCorrection(text, "correction.txt");
  };

  const onClickDownloadImprovement = (record) => {
    const text = record?.history?.suggestion?.vocabulary;
    if (!text) {
      Notification("error", "This student does not reach this part!");
      return;
    }
    handleDownloadCorrection(text, "improvement.txt");
  };

  const columns = [
    { title: "No", dataIndex: "index", key: "index" },
    { title: "Course", dataIndex: "course_name", key: "course_name" },
    { title: "Start Date", dataIndex: "start_date", key: "start_date" },
    { title: "End Date", dataIndex: "end_date", key: "end_date" },
    { title: "Lesson", dataIndex: "lesson_name", key: "lesson_name" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Date", dataIndex: "update_date", key: "update_date" },
    { title: "Evaluation Point", dataIndex: "point", key: "point" },
  ];

  const classColumns = [
    ...columns,
    {
      title: "Brain Storming",
      dataIndex: "brain_storming",
      key: "brain_storming",
      render: (_, record) => (
        <button
          onClick={() => {
            handleDownloadChatHistory(record?.id);
          }}
        >
          <BiConversation />
        </button>
      ),
    },
    {
      title: "Essay",
      dataIndex: "essay",
      key: "essay",
      render: (_, record) => (
        <button
          onClick={() => {
            setIsModalOpen(true);
            setSelectedData(record);
          }}
        >
          <GrCopy />
        </button>
      ),
    },
    {
      title: "Correction",
      dataIndex: "",
      key: "correction",
      render: (_, record) => (
        <button
          onClick={() => {
            onClickDownloadCorrection(record);
          }}
        >
          <IoMdCloudDownload />
        </button>
      ),
    },
    {
      title: "Improvement",
      dataIndex: "",
      key: "improvement",
      render: (_, record) => (
        <button
          onClick={() => {
            onClickDownloadImprovement(record);
          }}
        >
          <IoMdCloudDownload />
        </button>
      ),
    },
    {
      title: "Artwork",
      dataIndex: "",
      key: "Artwork",
      render: (_, record) => <ViewArtworkModal artworkUrl={record?.artwork} />,
    },
  ];

  const artworkColumns = [
    ...columns,
    {
      title: "Artwork",
      dataIndex: "",
      key: "Artwork",
      render: (_, record) => <ViewArtworkModal artworkUrl={record?.artwork} />,
    },
  ];

  return (
    <>
      <ViewWritingModal
        data={selectedData}
        key={userId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Table
        loading={isLoading}
        columns={isArtwork ? artworkColumns : classColumns}
        dataSource={data}
      />
    </>
  );
};

export const TableDataEbookLMS = (props) => {
  const { dataUsers, isLoading } = props;

  const [tableData, setTableData] = useState([]);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "create_at"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "create_at") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        ...item,
        key: index + 1,
        all_name: item.fullname,
        book_id: item.book_id,
        book_title: item.book_title,
        author_name: item.author_name,
        page: item.page,
        create_at: item.create_at,
        viewer: item.id,
      }))
    );
  }, [dataUsers]);

  const columns = [
    {
      title: "Total Ebooks",
      dataIndex: "details",
      key: "details",
      render: (values) => {
        return <>{values.length}</>;
      },
    },
    {
      title: "Campus",
      dataIndex: "campus",
      key: "campus",
      // ...getColumnSearchProps("all_name"),
    },
    {
      title: "Full Name",
      dataIndex: "all_name",
      key: "all_name",
      // ...getColumnSearchProps("all_name"),
    },
  ];

  let paginationConfig;

  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 10 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 10 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 10 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    pagination: { pageSize: 20 },
  };
  return (
    <>
      <Table
        loading={isLoading}
        {...tableConfig}
        pagination={paginationConfig}
        expandable={{
          expandedRowRender: (record) => {
            return <ExpandedTableEbookLMS data={record?.details} />;
          },
        }}
      />
    </>
  );
};

const ExpandedTableEbookLMS = ({
  data,
  handlePublicEbook,
  isAdmin = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setEbook } = useEBook();
  const tableData = useMemo(
    () =>
      data.map((item, index) => ({
        subKey: index + 1,
        ...item,
      })),
    [data]
  );
  const columns = [
    { title: "No", dataIndex: "subKey", key: "subKey" },
    { title: "Book Title", dataIndex: "book_title", key: "book_title" },
    {
      title: "Date",
      dataIndex: "create_at",
      key: "create_at",
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (_, record) => {
        return (
          <EyeFilled
            onClick={() => {
              setIsModalOpen(true);
              setEbook(record);
            }}
          />
        );
      },
    },
  ];

  const adminColumns = [
    { title: "No", dataIndex: "subKey", key: "subKey" },
    { title: "Book Title", dataIndex: "book_title", key: "book_title" },
    {
      title: "Date",
      dataIndex: "create_at",
      key: "create_at",
    },
    {
      title: "Posting",
      dataIndex: "is_public",
      key: "is_public",
      className: "tableEbook_Management",
      render: (checkbox, record) => (
        <Checkbox
          checked={checkbox}
          onChange={(e) => handlePublicEbook(record.book_id, !record.is_public)}
        />
      ),
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (_, record) => {
        return (
          <EyeFilled
            onClick={() => {
              setIsModalOpen(true);
              setEbook(record);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <ViewEbookModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Table
        rowKey={data.fullname}
        columns={isAdmin ? adminColumns : columns}
        dataSource={tableData}
      />
    </>
  );
};

export const TableDataEbookLMSAdmin = (props) => {
  const { setEbook } = useEBook();
  const { dataUsers, handlePublicEbook, isLoading } = props;
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "create_at"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "create_at") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        ...item,
        key: index + 1,
        all_name: item.fullname,
        book_id: item.book_id,
        book_title: item.book_title,
        author_name: item.author_name,
        page: item.page,
        create_at: item.create_at,
        viewer: item.id,
        is_public: item.is_public,
      }))
    );
  }, [dataUsers]);

  const columns = [
    {
      title: "Total Ebooks",
      dataIndex: "details",
      key: "details",
      render: (values) => <>{values.length}</>,
    },
    {
      title: "FullName",
      dataIndex: "all_name",
      key: "all_name",
      className: "tableEbook_Management",
      // ...getColumnSearchProps("all_name"),
    },
    {
      title: "Campus",
      dataIndex: "campus",
      key: "campus",
      className: "tableEbook_Management",
      // ...getColumnSearchProps("all_name"),
    },
  ];

  let paginationConfig;

  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 10 };
      paginationConfig = { pageSize: 10 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 10 };
      paginationConfig = { pageSize: 10 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 10 };
      paginationConfig = { pageSize: 10 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  console.log("tableData ebook", tableData);
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => {
        // console.log(record);
      },
    }),
  };
  return (
    <>
      <Table
        loading={isLoading}
        {...tableConfig}
        pagination={paginationConfig}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <ExpandedTableEbookLMS
                isAdmin
                handlePublicEbook={handlePublicEbook}
                data={record?.details}
              />
            );
          },
        }}
      />
    </>
  );
};

export const TableDataApprovalRequest = (props) => {
  const { dataUsers, fetchData } = props;
  const [tableData, setTableData] = useState([]);
  const [cookies] = useCookies(["access_token"]);

  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });

  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "created_at"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "created_at") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        nick_name: item.nick_name,
        talksam_id: item.talksam_id,
        product: item.product.join(" - "),
        courses: item.courses_name.join(" - "),
        created_at: item.created_at.split(" ")[0],
        status: item.status,
        approval_id: item.approval_id,
        fullname: item.fullname,
        email: item.email,
        expiration_date: item?.expiration_date.split("T")[0],
      }))
    );
  }, [dataUsers]);

  const handleDeleteAR = (id) => {
    APIService.delete_request_approval(cookies["access_token"], {
      approval_id: id,
    })
      .then(() => {
        fetchData();
        Notification("success", "Delete successfully!");
      })
      .catch(() => {
        Notification("error", "Something was wrong!");
      });

    // alert(id);
  };

  const columns = [
    {
      title: "Full name",
      dataIndex: "fullname",
      key: "fullname",
      className: "table_approvalRequestAdmin",
      width: 200,
    },
    {
      title: "Nick Name",
      dataIndex: "nick_name",
      key: "nick_name",
      className: "table_approvalRequestAdmin",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "table_approvalRequestAdmin",
      width: 200,
    },
    {
      title: "Campus (TALKSAM ID)",
      dataIndex: "talksam_id",
      key: "talksam_id",
      className: "table_approvalRequestAdmin",
      width: 160,

      // ...getColumnSearchProps("talksam_id"),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      className: "table_approvalRequestAdmin",
      width: 200,

      // ...getColumnSearchProps("product"),
    },
    {
      title: "Courses",
      dataIndex: "courses",
      key: "courses",
      className: "table_approvalRequestAdmin",
      width: 250,
      // ...getColumnSearchProps("courses"),
    },
    {
      title: "Approval Request Date",
      dataIndex: "created_at",
      key: "created_at",
      className: "table_approvalRequestAdmin",
      width: 160,
      // ...getColumnSearchProps("created_at"),
    },
    {
      title: "Expiration Date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      className: "table_approvalRequestAdmin",
      width: 160,
      // ...getColumnSearchProps("created_at"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_approvalRequestAdmin",
      width: 100,
      // ...getColumnSearchProps("status"),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      className: "table_approvalRequestAdmin",
      width: 40,
      render: (_, record) => (
        <AiOutlineCloseSquare
          onClick={() => {
            if (record?.status === "Accepted") return;
            handleDeleteAR(record?.approval_id);
          }}
          size={24}
          className={classNames(
            "text-red-500",
            record?.status === "Accepted" && "opacity-40 cursor-not-allowed"
          )}
        />
      ),
      // ...getColumnSearchProps("status"),
    },
  ];

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
  };
  return <Table {...tableConfig} pagination={{ pageSize: 10 }} />;
};

export const TableDataApprovalRequestAdmin = (props) => {
  const { dataUsers, setSelectedApproval, isLoading, filterValue, fetchData } =
    props;
  const [tableData, setTableData] = useState([]);
  const [cookies] = useCookies(["access_token"]);
  console.log("tableData", tableData);
  //Search
  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");

  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleDeleteAR = (id) => {
    APIService.delete_request_approval(cookies["access_token"], {
      approval_id: id,
    })
      .then(() => {
        fetchData();
        Notification("success", "Delete successfully!");
      })
      .catch(() => {
        Notification("error", "Something was wrong!");
      });

    // alert(id);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "start_date"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "start_date") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    const filteredData = dataUsers.filter(
      (item) =>
        item?.campus?.toLowerCase().includes(filterValue.toLowerCase()) ||
        item?.email?.toLowerCase().includes(filterValue.toLowerCase()) ||
        item?.fullname?.toLowerCase().includes(filterValue.toLowerCase())
    );
    setTableData(
      filteredData.map((item, index) => ({
        key: index,
        id: item.approval_id,
        country: item.country,
        campus: item.campus,
        talksam_id: item.talksam_id,
        nick_name: item.nick_name,
        email: item.email,
        product: item.product.join(" - "),
        courses: item.courses_name.join(" - "),
        created_at: item.created_at.split(" ")[0],
        status: item.status,
        expiration_date: item?.expiration_date?.split("T")[0],
      }))
    );
  }, [dataUsers, filterValue]);

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      // ...getColumnSearchProps("country"),
      width: "10%",
      className: "table-ClassApproval",
    },
    {
      title: "Campus",
      dataIndex: "campus",
      key: "campus",
      // ...getColumnSearchProps("campus"),
      width: "10%",
      className: "table-ClassApproval",
    },
    {
      title: "Campus (TALKSAM ID)",
      dataIndex: "talksam_id",
      key: "talksam_id",
      width: "10%",
      className: "table-ClassApproval",
    },
    {
      title: "Nick Name",
      dataIndex: "nick_name",
      key: "nick_name",
      width: "10%",
      className: "table-ClassApproval",

      // ...getColumnSearchProps("nick_name"),
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   width: "10%",
    //   className: "table-ClassApproval",

    //   // ...getColumnSearchProps("email"),
    // },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: "10%",
      className: "table-ClassApproval",

      // ...getColumnSearchProps("product"),
    },
    {
      title: "Courses",
      dataIndex: "courses",
      key: "courses",
      width: "30%",
      className: "table-ClassApproval",

      // ...getColumnSearchProps("courses"),
    },
    {
      title: "Approval Request Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      className: "table-ClassApproval",

      // ...getColumnSearchProps("created_at"),
    },
    {
      title: "Expiration Date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      width: "10%",
      className: "table-ClassApproval",
      // ...getColumnSearchProps("created_at"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      className: "table-ClassApproval",

      // ...getColumnSearchProps("status"),
    },
    Table.SELECTION_COLUMN,
    {
      title: "select",
      dataIndex: "select",
      key: "select",
      width: "2%",
      className: "table-ClassApproval",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      className: "table_approvalRequestAdmin",
      width: 40,
      render: (_, record) => (
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => {
            handleDeleteAR(record.approval_id ?? record?.id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <AiOutlineCloseSquare size={24} className="text-red-500" />
        </Popconfirm>
      ),
      // ...getColumnSearchProps("status"),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //   // console.log( selectedRows);
      // setSelectionRow(selectedRows)
      let approval_id = [];
      selectedRows?.forEach((element) => {
        approval_id.push(element.id);
      });
      setSelectedApproval(approval_id);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === "Accepted", // Column configuration not to be checked
      name: record.name,
    }),
  };
  const paginationConfig = {
    pageSize: 10,
  };
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    rowSelection: rowSelection,
  };

  return (
    <>
      <Table
        loading={isLoading}
        {...tableConfig}
        // rowSelection={rowSelection}
        pagination={paginationConfig}
        rowKey="id"
      />
    </>
  );
};

export const TableDataListAdmin = (props) => {
  const { dataUsers, handleClickRow } = props;

  const [tableData, setTableData] = useState([]);
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "start_date"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "start_date") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        if (item) {
          const internalData = item.toLowerCase().trim();
          const externalData = value.toLowerCase().trim();
          return internalData.includes(externalData);
        } else {
          return item;
        }
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        country: item.country,
        country_name: item.country_name,
        role: item.role,
        profile_name: item.profile_name,
        info: item.info.id,
        infor_id: item.id,
        name: item.info.name,
        role_profile: item.info.role_profile,
        email: item.info.email,
        campus: item.info.campus,
        talksam_id: item.talksam_id,
      }))
    );
  }, [dataUsers]);

  const columns = [
    {
      title: "Country",
      dataIndex: "country_name",
      key: "country_name",
      render: (text) => <span className="capitalize">{text}</span>,
      // ...getColumnSearchProps("country"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      // ...getColumnSearchProps("role"),
    },
    {
      title: "Profile Name",
      dataIndex: "profile_name",
      key: "profile_name",
      // ...getColumnSearchProps("profile_name"),
    },
  ];
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => {
        console.log("record", record);
        handleClickRow(record);
      },
    }),
  };
  const paginationConfig = {
    pageSize: 6,
  };

  return <Table {...tableConfig} pagination={paginationConfig} />;
};

export const TableDataListPermision = (props) => {
  const { dataUsers, handleClickRow } = props;
  const [tableData, setTableData] = useState([]);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "start_date"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "start_date") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else if (dataIndex === "country") {
        const internal = item;
        const external = parseInt(value);
        return internal === external;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        country: item.country,
        role: item.role,
        id: item.id,
      }))
    );
  }, [dataUsers]);

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      // ...getColumnSearchProps("country"),
    },
    {
      title: "Role Profile",
      dataIndex: "role",
      key: "role",
      // ...getColumnSearchProps("role"),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id", // ...getColumnSearchProps("id")
    },
  ];
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    // onRow: (record) => ({
    //   onClick: () => handleClickRow(record, console.log(record)),
    // }),
  };
  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 8 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 5 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 6 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  return <Table {...tableConfig} pagination={paginationConfig} />;
};

export const TableDataCampusEnrolment = (props) => {
  const { dataUsers, handleClickRow, country_list, fetchData } = props;
  const [tableData, setTableData] = useState([]);
  const [openModal, $openModal] = useState(false);
  const [form] = Form.useForm();
  const [cookies] = useCookies(["access_token"]);

  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "created_at"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "created_at") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });

  const _onEdit = () => {
    $openModal(false);

    const data = {
      campus_name: form.getFieldValue("campus_name"),
      country_id: form.getFieldValue("country_id"),
    };

    APIService.update_campus(
      data,
      cookies["access_token"],
      form.getFieldValue("campus_id")
    )
      .then((response) => {
        if (!response) {
          Notification("error", response.data);
        } else {
          Notification("success", "Update success");
          fetchData();
        }
      })
      .catch((error) => {
        Notification("error", "Update fail");
        // setChange(true);
      });
  };

  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        country: item.country,
        campus_code: item.campus_code,
        campus: item.campus,
        created_at: item.created_at,
        campus_id: item.id,
        student_count: item?.student_count,
        student_count: item?.student_count,
      }))
    );
  }, [dataUsers]);

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      // ...getColumnSearchProps("country"),
    },

    {
      title: "Campus",
      dataIndex: "campus",
      key: "campus",
      // ...getColumnSearchProps("campus"),
    },
    {
      title: "Registration Date",
      dataIndex: "created_at",
      // ...getColumnSearchProps("created_at"),
    },
    {
      title: "Modify",
      dataIndex: "info",
      render: (text, record) => (
        <Button
          onClick={() => {
            form.setFieldsValue({
              campus_id: record?.campus_id,
              campus_name: record?.campus,
              country_id: country_list?.find(
                (item) => item?.name === record?.country
              )?.id,
            });
            $openModal(true);
          }}
        >
          Modify
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      render: (_, record) => <DeleteConfirm data={record} />,
    },
  ];
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
  };
  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 8 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 5 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 6 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  return (
    <>
      <Modal
        open={openModal}
        title="Edit"
        onCancel={() => $openModal(false)}
        onOk={_onEdit}
      >
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 20,
          }}
          form={form}
        >
          <Form.Item
            name="campus_name"
            label="Campus name"
            rules={[
              {
                // type: "array",
                required: true,
                message: "Please input name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="country_id"
            label="Country"
            rules={[
              {
                required: true,
                message: "Please select your Country",
              },
            ]}
          >
            <Select placeholder="Please select a country">
              {country_list?.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Table {...tableConfig} pagination={paginationConfig} />
    </>
  );
};

export const TableDataCategory = (props) => {
  const { dataUsers, handleClickRow, isLoading } = props;
  const [tableData, setTableData] = useState([]);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "created_at"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "created_at") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        key: index + 1,
        name: item.name,
        ID: item.ID,
        division: item.division,
        title: item.title,
        question: item.question,
        answer: item.answer,
        reply_status: item.reply_status,
        created_at: item.created_at,
        email: item["e-mail"],
      }))
    );
  }, [dataUsers]);

  const columns = [
    { title: "", dataIndex: "key", key: "key" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "ID", dataIndex: "ID", key: "ID" },
    { title: "E-mail", dataIndex: "email", key: "email" },
    { title: "Inquiry Division", dataIndex: "division", key: "division" },
    { title: "Inquiry Title", dataIndex: "title", key: "title" },
    { title: "Reply Status", dataIndex: "reply_status", key: "reply_status" },
    { title: "Regostration Date", dataIndex: "created_at", key: "created_at" },
    // {
    //   title: "Registration Date",
    //   dataIndex: "created_at",
    //   filters: [{}],
    // },
    // {
    //   title: "Modify",
    //   dataIndex: "info",
    //   filters: [{}],
    //   render: () => <Button>Modify</Button>,
    // },
  ];
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickRow(record),
    }),
  };
  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 8 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 4 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 6 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  return (
    <Table loading={isLoading} {...tableConfig} pagination={paginationConfig} />
  );
};

export const TableDataUsersAdmin = (props) => {
  const {
    country,
    campus,
    dataUsers,
    handleClickRow,
    handleClickRowCountry,
    handleClickRowCampus,
  } = props;

  const [tableData, setTableData] = useState([]);
  const [tableCountry, setTableCountry] = useState([]);
  const [tableCampus, setTableCampus] = useState([]);
  const campus_list = useSelector((state) => state.adminSlice.campus_list);
  const country_list = useSelector((state) => state.adminSlice.country_list);

  useEffect(() => {
    setTableCountry(country_list);
  }, [country_list]);

  useEffect(() => {
    const newCampusList = campus_list?.filter(
      (item) => item.country_id === country
    );

    setTableCampus(newCampusList);
  }, [country, campus_list]);

  console.log("dataUsers", dataUsers);

  useEffect(() => {
    setTableData(
      dataUsers
        .map((item, index) => ({
          id: index + 1,
          email: item.email,
          avatar_url: item.avatar_url,
          fullname: item.fullname,
          nick_name: item.nick_name,
          birth_day: item.birth_day,
          first_name: item.first_name,
          last_name: item.last_name,
          level: item.level,
          member: item.member,
          country: item.country,
          campus: item.campus,
          country_name: item.country_name,
          campus_name: item.campus_name,
          talksam_id: item.talksam_id,
          created_at: item.created_at,
        }))
        .filter((item) => item.campus === campus)
    );
  }, [campus, dataUsers]);

  const columns_country = [
    { title: "Country", dataIndex: "name", key: "name" },
  ];
  const columns_campus = [{ title: "Campus", dataIndex: "name", key: "name" }];
  const columns_user = [
    { title: "User Name", dataIndex: "fullname", key: "fullname" },
    { title: "ID", dataIndex: "id", key: "id" },
  ];

  const tableConfig_Country = {
    dataSource: tableCountry,
    columns: columns_country,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickRowCountry(record, console.log(record)),
    }),
  };
  const tableConfig_Campus = {
    dataSource: tableCampus,
    columns: columns_campus,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickRowCampus(record, console.log(record)),
    }),
  };

  const tableConfig_User = {
    dataSource: tableData,
    columns: columns_user,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickRow(record),
    }),
  };

  const paginationConfig = {
    pageSize: 6,
  };
  const paginationConfig_country = {
    pageSize: 3,
  };
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Table
            {...tableConfig_Country}
            pagination={paginationConfig_country}
          />
        </Col>
        <Col span={24}>
          <Table
            {...tableConfig_Campus}
            pagination={paginationConfig_country}
          />
        </Col>
        <Col span={24}>
          <Table {...tableConfig_User} pagination={paginationConfig} />
        </Col>
      </Row>
    </>
  );
};

export const TableDataProductRegistration = (props) => {
  const { dataUsers, handleClickRow } = props;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });
  const [tableData, setTableData] = useState([]);
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "date"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "date") {
        const originData = item.split(" ");
        const internalData = new Date(originData[0]).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        no: index + 1,
        unique: item.id,
        product_name: item.product_name,
        price: item.price,
        category: item.category_name,
        is_posting: item.is_posting,
        date: item.created_at,
        country_id: item.country_id,
        sumary: item.sumary,
        period: item.period,
        course_id: item.courses_id,
        product_detailed_explanation: item.product_detailed_explanation,
        discount_rate: item.discount_rate,
        discount_price: item.discount_price,
        tag: item.tag,
      }))
    );
  }, [dataUsers]);

  const columns = [
    { title: "No", dataIndex: "no", key: "no" },
    {
      title: "Unique Number",
      dataIndex: "unique",
      key: "unique",
      // ...getColumnSearchProps("unique"),
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      // ...getColumnSearchProps("product_name"),
    },
    {
      title: "Price",
      dataIndex: "price",
      // ...getColumnSearchProps("price"),
    },
    {
      title: "Category",
      dataIndex: "category",
      // ...getColumnSearchProps("category"),
    },
    {
      title: "Posted",
      dataIndex: "posted",
    },
    {
      title: "Date",
      dataIndex: "date",
      // ...getColumnSearchProps("date"),
    },
  ];
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickRow(record, console.log(record)),
    }),
  };
  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 8 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 4 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 4 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  return <Table {...tableConfig} pagination={paginationConfig} />;
};
export const TableDataAdminCMS = (props) => {
  const { dataUsers, handleClickRow } = props;

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      dataUsers.map((item, index) => ({
        country: item.country,
        role: item.role,
        profile_name: item.profile_name,
        info: item.info.id,
      }))
    );
  }, [dataUsers]);

  const columns = [
    { title: "", dataIndex: "country", key: "country" },
    { title: "", dataIndex: "role", key: "role" },
    { title: "", dataIndex: "profile_name", key: "profile_name" },
  ];
  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
  };
  const paginationConfig = {
    pageSize: 6,
  };

  return (
    <>
      <Table {...tableConfig} pagination={paginationConfig} />
      <Table {...tableConfig} pagination={paginationConfig} />
    </>
  );
};

export const TableDataCMS = (props) => {
  const { dataCourse, datalesson, handleClickCourse, handleClickLesson } =
    props;

  const [tableData, setTableData] = useState([]);
  const [tableDataLesson, setTableDataLesson] = useState([]);
  //Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //
  //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const renderSearchLayout1 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="flex relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        <Input
          className="relative"
          style={{ width: inputSearchPointWidth }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </div>
    );
  };
  const renderSearchLayout2 = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    dataIndex
  ) => {
    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        className="relative"
        style={{ top: inpuSearchTopPosition, left: "3%" }}
      >
        {/* <div className="relative w-full"> */}
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          // value={dayjs(selectedKeys[0]?selectedKeys[0]:"", "YYYY/MM/DD")}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: "53%",
          }}
          className="flex-inline"
          size="middle"
        />
        {/* </div> */}
        {/* <div className="relative w-full" > */}
        {/* <Space> */}
        <Button
          className="bg-cyan-400 hover:bg-cyan-300 flex-inline"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="middle"
          style={{
            width: "38%",
            marginLeft: "1vw",
          }}
        >
          Search
        </Button>
        {/* </Space> */}
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) =>
      dataIndex === "date"
        ? renderSearchLayout2(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          )
        : renderSearchLayout1(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            dataIndex
          ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const item = record[dataIndex];
      if (dataIndex === "date") {
        const originData = item.split(" ");
        var date = new Date(originData[0]);

        var year = date.getFullYear();
        var month = String(date.getMonth() + 1).padStart(2, "0");
        var day = String(date.getDate()).padStart(2, "0");
        const internalData = new Date(`${year}-${month}-${day}`).getTime();
        const externalData = new Date(value).getTime();
        return internalData === externalData;
      } else {
        const internalData = item.toLowerCase().trim();
        const externalData = value.toLowerCase().trim();
        return internalData.includes(externalData);
      }
    },
  });
  useEffect(() => {
    setTableData(
      dataCourse?.map((item, index) => ({
        course: item.course_name,
        date: item.start_date,
        lesson: item.lesson,
      }))
    );
  }, [dataCourse]);

  useEffect(() => {
    setTableDataLesson(
      datalesson?.map((item, index) => ({
        lesson: item.lesson_name,
        date: item.start_date,
        information: item.information,
      }))
    );
  }, [datalesson]);

  const columns_course = [
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      // ...getColumnSearchProps("course"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // ...getColumnSearchProps("date"),
    },
  ];

  const columns_lesson = [
    {
      title: "Lesson",
      dataIndex: "lesson",
      key: "lesson",
      // ...getColumnSearchProps("lesson"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // ...getColumnSearchProps("date"),
    },
  ];

  const tableConfigCourse = {
    dataSource: tableData,
    columns: columns_course,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickCourse(record),
    }),
  };

  const tableConfigLesson = {
    dataSource: tableDataLesson,
    columns: columns_lesson,
    expandRowByClick: true,
    expandable: false,
    onRow: (record) => ({
      onClick: () => handleClickLesson(record),
    }),
  };
  const paginationConfig = {
    pageSize: 4,
  };

  return (
    <>
      <Table {...tableConfigCourse} pagination={paginationConfig} />
      <Table {...tableConfigLesson} />
    </>
  );
};

export const TableDataAdminPageList = (props) => {
  const { dataUsers, type } = props;

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(dataUsers.map((item, index) => ({})));
  }, [dataUsers]);

  let columns = [
    { title: "No", dataIndex: "country", key: "country" },
    { title: "Page Title", dataIndex: "role", key: "role" },
    { title: "Status", dataIndex: "profile_name", key: "profile_name" },
    { title: "Date", dataIndex: "profile_name", key: "profile_name" },
  ];
  if (type === "add") {
    columns = [
      ...columns,
      { title: "Division", dataIndex: "country", key: "country" },
    ];
  }

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
  };
  const paginationConfig = {
    pageSize: 6,
  };

  return (
    <>
      <Table {...tableConfig} pagination={paginationConfig} />
    </>
  );
};

export const TableDataNoticeManager = (props) => {
  const { dataUsers, type } = props;
  const [tableData, setTableData] = useState([]);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });

  useEffect(() => {
    setTableData(dataUsers.map((item, index) => ({})));
  }, [dataUsers]);

  let columns = [
    { title: "No", dataIndex: "country", key: "country" },
    { title: "Page Title", dataIndex: "role", key: "role" },
    { title: "Status", dataIndex: "profile_name", key: "profile_name" },
    { title: "Date", dataIndex: "profile_name", key: "profile_name" },
  ];
  if (type === "add") {
    columns = [
      ...columns,
      { title: "Division", dataIndex: "country", key: "country" },
    ];
  }

  const tableConfig = {
    dataSource: tableData,
    columns: columns,
    expandRowByClick: true,
    expandable: false,
  };
  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 10 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 6 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 6 };
      break;
    default:
      paginationConfig = { pageSize: 4 };
      break;
  }

  return (
    <>
      <Table {...tableConfig} pagination={paginationConfig} />
    </>
  );
};

export default TableDataCourse;
