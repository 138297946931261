import { createSlice } from "@reduxjs/toolkit";

export const writingSlice = createSlice({
  name: "writing",
  initialState: {
    type : "error"
  },
  reducers: {
    setType: (state, { payload }) => {
      state.type= payload.type;
    }
    }
});

export const { setType } = writingSlice.actions;
export default writingSlice.reducer;
