export const recommendation = [
  "What can I learn about this?",
  "What is important in this topic?",
  "Inspire me!",
  "Why is this interesting?",
  "Tell me the keywords!",
  "Can you show me an example?",
  "What can I like about this?",
  "Can you tell me a fun fact?",
  "Explain the topic in simple terms, please.",
];
