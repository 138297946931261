import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconIdeal from "../../assets/iconButton/Ideal.png";
import lesson_icon from "../../assets/icon_svg/icon_lesson.png";
import level_icon from "../../assets/icon_svg/icon_level.png";
import topic_icon from "../../assets/icon_svg/icon_topic.png";
import { Notification } from "../../components/Notification/index";
import { setType } from "../../redux/writingSlice";
import StarRating from "./StarRating";
import "./custom.css";
import style from "./style.module.css";

const TopicWriting = (props) => {
  const { type, setPopupBook, pointStarts } = props;

  useEffect(() => {}, [type]);
  // // console.log(pointStarts);
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const handlePopupOpen = () => {
    setPopupBook(true);
  };

  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const onPopupBookReview = () => {
    Notification("success", "Book review");
  };

  const [isDesktop, setDesktop] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleSize = () => {
      setDesktop({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleSize);
    handleSize();
    return () => window.removeEventListener("resize", handleSize);
  }, []);

  return (
    <>
      <div
        className={type === 1 ? style.container_brainstorming : style.container}
      >
        <div
          className={`${
            type === 1 ? style.content_topic_branstorming : style.content_topic
          } mt-[55px] lg:mt-0 `}
        >
          <img
            src={level_icon}
            alt="topic"
            className={type === 1 ? style.img_topic_storming : style.img_topic}
          />
          <h1 className={style.content_title}>Level</h1>
          <p className={style.box_title}>{lesson.course_name}</p>
        </div>

        {type === 1 ? (
          <div
            className={
              type === 1
                ? style.content_topic_branstorming
                : style.content_topic
            }
          >
            <img
              src={topic_icon}
              alt="topic"
              className={
                type === 1 ? style.img_topic_storming : style.img_topic
              }
            />
            <h1 className={style.content_title}>Topic</h1>
            <p className={style.box_title}>{lesson.topic}</p>
          </div>
        ) : null}

        <div
          className={
            type === 1 ? style.content_topic_branstorming : style.content_topic
          }
        >
          <img
            src={lesson_icon}
            alt="topic"
            className={type === 1 ? style.img_topic_storming : style.img_topic}
          />
          <h1 className={style.content_title}>Lesson</h1>
          <p className={style.box_title}>{lesson.lesson_name}</p>
        </div>
        {/* {type !== 1 && (
          <div className={style.content_topic_desktop}>
            <img
              src={topic_icon}
              alt="topic"
              className={style.img_topic_desktop}
            />
            <h1 className={style.content_title}>Topic</h1>
            <p className={style.box_title}>{lesson.course_name}</p>


          </div>
        )} */}

        {type === 2 && lesson.book_review ? (
          // lesson.book_review.summary_content?.length > 0
          <>
            <div className={style.content_book_review}>
              <img
                onClick={handlePopupOpen}
                src={IconIdeal}
                alt="topic"
                className={style.img_popup_help}
              />
            </div>
          </>
        ) : null}

        {type === 3 ? (
          <>
            <div className={`${style.content_final_evaluation} `}>
              <h1 className={style.content_title_evaluation}>
                Final Evaluation
              </h1>
              <div className={style.content_star_rating}>
                <StarRating point={pointStarts?.grammar} text={"Grammar"} />
                <StarRating
                  point={pointStarts?.punctuation}
                  text={"Punctuation"}
                />
                <StarRating point={pointStarts?.choice} text={"Word Choice"} />
                <StarRating
                  point={pointStarts?.fluency}
                  text={"Sentence Fluency"}
                />
                <StarRating
                  point={pointStarts?.organization}
                  text={"Organization"}
                />
                <StarRating point={pointStarts?.content} text={"Contents"} />
                <StarRating point={pointStarts?.tone} text={"Tone"} />
              </div>
            </div>
            <Button
              onClick={() => {
                dispatch(setType({ type: "error" }));
              }}
              className={style.btn_mistake}
            >
              ERROR
            </Button>
            <Button
              onClick={() => {
                dispatch(setType({ type: "improvement" }));
              }}
              className={style.btn_approvement}
            >
              IMPROVEMENT
            </Button>
          </>
        ) : null}
      </div>
    </>
  );
};

export default TopicWriting;
