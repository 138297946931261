import React from "react";
import { Link } from "react-router-dom";
import artworkWing from "../../assets/dashboard-ebook/artwork-wing.png";
import bookCreate from "../../assets/dashboard-ebook/book-create.gif";
import buttonCreate from "../../assets/dashboard-ebook/create-button.png";
import bg from "../../assets/dashboard-ebook/dashboard-ebook-bg.jpg";
import ebookWing from "../../assets/dashboard-ebook/ebook-wing.png";
import particle from "../../assets/dashboard-ebook/particle.gif";

const CustomDashBoardEBook = () => {
  return (
    <div className="relative w-full h-[calc(100vh-4rem)] lg:h-[calc(100vh-5rem)] flex items-center justify-center overflow-hidden">
      <img
        src={bg}
        alt="bg"
        className="absolute w-full h-full top-0 left-0 object-cover object-bottom"
      />

      <div className="relative 2xl:max-w-6xl max-w-4xl  mx-auto flex flex-col items-center gap-6 -translate-y-6">
        <img
          src={particle}
          alt="bg"
          className="absolute w-96 h-96 top-1/2 -translate-y-1/2 left-0 object-cover"
        />
        <img
          src={particle}
          alt="bg"
          className="absolute w-96 h-96 top-1/2 -translate-y-1/2 right-0 object-cover"
        />
        <img
          src={particle}
          alt="bg"
          className="absolute w-96 h-96 top-0 left-1/2 -translate-x-1/2 object-cover"
        />
        <div className="w-full flex lg:gap-20">
          <Link to="/e-mybook/artwork">
            <div className="relative group">
              <div
                className="hidden md:block absolute inset-0 -translate-x-4 w-full h-full group-hover:visible group-hover:opacity-100 invisible opacity-0 duration-500"
                style={{
                  background:
                    "radial-gradient(circle, rgba(210,230,51,1) 19%, rgba(0,0,0,0) 37%)",
                }}></div>
              <div
                className="hidden md:block absolute inset-0 translate-x-2 translate-y-20 w-full h-full group-hover:visible group-hover:opacity-100 invisible opacity-0 duration-500"
                style={{
                  background:
                    "radial-gradient(circle, rgba(210,230,51,1) 10%, rgba(0,0,0,0) 37%)",
                }}></div>
              <div
                className="hidden md:block absolute inset-0 -translate-x-24 -translate-y-16 w-full h-full group-hover:visible group-hover:opacity-100 invisible opacity-0 duration-500"
                style={{
                  background:
                    "radial-gradient(circle, rgba(210,230,51,1) 6%, rgba(0,0,0,0) 37%)",
                }}></div>
              <img src={artworkWing} alt="ebook-wing" className="relative" />
            </div>
          </Link>
          <Link to="/e-mybook/ebook">
            <div className="relative group">
              <div
                className="hidden md:block absolute inset-0 w-full h-full group-hover:visible group-hover:opacity-100 invisible opacity-0 duration-500"
                style={{
                  background:
                    "radial-gradient(circle, rgba(210,230,51,1) 19%, rgba(0,0,0,0) 37%)",
                }}></div>
              <div
                className="hidden md:block absolute inset-0 -translate-x-2 translate-y-20 w-full h-full group-hover:visible group-hover:opacity-100 invisible opacity-0 duration-500"
                style={{
                  background:
                    "radial-gradient(circle, rgba(210,230,51,1) 10%, rgba(0,0,0,0) 37%)",
                }}></div>
              <div
                className="hidden md:block absolute inset-0 translate-x-24 -translate-y-16 w-full h-full group-hover:visible group-hover:opacity-100 invisible opacity-0 duration-500"
                style={{
                  background:
                    "radial-gradient(circle, rgba(210,230,51,1) 10%, rgba(0,0,0,0) 37%)",
                }}></div>

              <img src={ebookWing} alt="ebook-wing" className="relative" />
            </div>
          </Link>
        </div>
        <div>
          <Link to="/createEbook/create">
            <div className="flex flex-col justify-center items-center">
              <div className="relative group md:h-64 h-32">
                <div
                  className="absolute inset-0 w-full h-full group-hover:visible group-hover:opacity-100 invisible opacity-0 duration-500"
                  style={{
                    background:
                      "radial-gradient(circle, rgba(255,82,162,1) 9%, rgba(0,0,0,0) 61%)",
                  }}></div>
                <img
                  src={bookCreate}
                  alt="book"
                  className="relative h-full object-cover"
                />
              </div>
              <img
                src={buttonCreate}
                alt="text"
                className="relative lg:h-16 md:h-12 h-8 object-cover"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CustomDashBoardEBook;
