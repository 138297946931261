import React from "react";
import Masonry from "react-masonry-css";
import ArtworkItem from "./ArtworkItem";
const breakpointColumnsObj = {
  default: 2,
  //   1100: 3,
  //   700: 2,
  //   500: 1,
};

const ListItems = ({ selectedLesson, handleSelectLesson, selectedCourse }) => {
  const { lessons } = selectedCourse;
  return (
    <div className="w-2/3 h-full">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {lessons?.map((item, index) => (
          <ArtworkItem
            handleSelectLesson={handleSelectLesson}
            selectedLesson={selectedLesson}
            data={item}
            index={index}
            key={item?.key}
          />
        ))}
      </Masonry>
    </div>
  );
};

export default ListItems;
