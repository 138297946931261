import classNames from "classnames";
import React, { useMemo, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import downloadIcon from "../../assets/ebook/download-icon.png";
import editIcon from "../../assets/ebook/edit-icon.png";
import nextIcon from "../../assets/ebook/next-page-icon.png";
import prevIcon from "../../assets/ebook/previous-page-icon.png";
import bg from "../../assets/ebook/reading-background.png";
import zoomInIcon from "../../assets/ebook/zoom-in-icon.png";
import zoomOutIcon from "../../assets/ebook/zoom-out-icon.png";
import coverImage from "../../assets/ebook/cover-ebook.png";
import BackButton from "./components/BackButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setEbookID, setIndex } from "../../redux/ebookSlice";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useMediaQuery } from "react-responsive";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="w-full h-auto bg-white" ref={ref}>
      {props.image && (
        <img
          src={props.image}
          alt="img"
          className="w-full h-full object-cover"
        />
      )}
    </div>
  );
});

const ViewEBook = ({ actionButton, itemSelected, indexSelectedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { include_artwork, ebook_link } = itemSelected;

  const slides = useMemo(() => {
    const newSlides = include_artwork;
    if (include_artwork.length % 2 === 1) {
      newSlides.push("");
    }
    return newSlides;
  }, [include_artwork]);

  console.log("slides", slides);

  console.log("include_artwork", include_artwork?.length % 2 === 1);
  const pdfFile = itemSelected["export_pdf "];
  const bookRef = useRef(null);
  const [cover, setCover] = useState("FRONT");
  const isBigScreen = useMediaQuery({ query: "(min-width: 1536px)" });
  const nextButtonClick = () => {
    if (bookRef.current) bookRef.current.pageFlip().flipNext();
  };

  const prevButtonClick = () => {
    if (bookRef.current) bookRef.current.pageFlip().flipPrev();
  };

  const onPage = (e) => {
    console.log("e.data === 0", e.data);
    if (e.data === 0) {
      setCover("FRONT");
      return;
    }
    if (e.data === include_artwork.length + 1) {
      setCover("BACK");
      return;
    }
    setCover("NONE");
  };

  const goToEditPage = () => {
    dispatch(setEbookID({ ebook_Id: itemSelected.ebook_id }));
    dispatch(setIndex({ book_index: indexSelectedItem }));
    navigate("/ebook-making/edit");
  };
  return (
    <div className="relative w-full h-[calc(100vh-4rem)] lg:h-[calc(100vh-5rem)] overflow-y-auto">
      <img
        src={bg}
        alt="bg"
        className="fixed w-full h-full top-0 left-0 object-cover object-bottom"
      />
      <BackButton action={actionButton} />
      <div
        className={classNames(
          "relative 2xl:max-w-4xl max-w-2xl mx-auto lg:mt-20 font-jetBrains flex justify-center flex-col items-center",
          "md:mt-40"
        )}>
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <div
                className={classNames(
                  "w-full h-full",
                  cover === "NONE" && "bg-white/20 backdrop-blur-md"
                )}>
                <TransformComponent>
                  <div
                    className={classNames(
                      "relative 2xl:w-[56rem] w-[44rem] mx-auto h-full"
                    )}>
                    <div className={classNames("w-full h-full ")}>
                      <HTMLFlipBook
                        width={isBigScreen ? 100 : 90}
                        height={isBigScreen ? 150 : 135}
                        size="stretch"
                        // minWidth={315}
                        // maxWidth={1000}
                        flippingTime={300}
                        // minHeight={400}
                        // maxHeight={1533}
                        maxShadowOpacity={0.5}
                        showCover={true}
                        mobileScrollSupport={true}
                        onFlip={onPage}
                        //   onChangeOrientation={this.onChangeOrientation}
                        //   onChangeState={this.onChangeState}
                        className={classNames(
                          "flex items-center justify-center duration-200",
                          cover === "FRONT" && "-translate-x-1/4",
                          cover === "NONE" && "-translate-x-0",
                          cover === "BACK" && "translate-x-1/4"
                        )}
                        ref={bookRef}>
                        {/* <PageCover>BOOK TITLE</PageCover> */}
                        <Page image={ebook_link} />
                        {slides?.map((item, index) => (
                          <Page image={item} />
                        ))}
                        {/* {include_artwork?.length % 2 === 1 && (
                          <Page image={coverImage} />
                        )} */}
                        <Page image={coverImage} />

                        {/* <PageCover>THE END</PageCover> */}
                      </HTMLFlipBook>
                    </div>

                    {cover === "NONE" && (
                      <div className="absolute inset-0 w-full h-full"> </div>
                    )}
                  </div>
                </TransformComponent>
              </div>

              <div className="tools">
                {cover === "NONE" && (
                  <div className="flex mt-4 gap-4">
                    <button
                      className="h-14 hover:brightness-110"
                      onClick={() => zoomIn()}>
                      <img
                        src={zoomInIcon}
                        alt="download"
                        className="h-full object-cover"
                      />
                    </button>
                    <button
                      className="h-14 hover:brightness-110"
                      onClick={() => zoomOut()}>
                      <img
                        src={zoomOutIcon}
                        alt="download"
                        className="h-full object-cover"
                      />
                    </button>
                  </div>
                )}
                {/* <button onClick={() => zoomIn()}>+</button>
                <button onClick={() => zoomOut()}>-</button>
                <button onClick={() => resetTransform()}>x</button> */}
              </div>
            </>
          )}
        </TransformWrapper>
        {/* Next, Prev Button */}
        {cover === "NONE" && (
          <>
            <button
              onClick={prevButtonClick}
              type="button"
              className="h-14 absolute top-1/2 -translate-y-1/2 -left-16">
              <img
                src={prevIcon}
                alt="prev-icon"
                className="h-full object-cover"
              />
            </button>
            <button
              onClick={nextButtonClick}
              type="button"
              className="h-14 absolute top-1/2 -translate-y-1/2 -right-16">
              <img
                src={nextIcon}
                alt="prev-icon"
                className="h-full object-cover"
              />
            </button>
          </>
        )}
        {/* {cover === "NONE" && (
          <div className="flex mt-4 gap-4">
            <button className="h-14 hover:brightness-110">
              <img
                src={zoomInIcon}
                alt="download"
                className="h-full object-cover"
              />
            </button>
            <button className="h-14 hover:brightness-110">
              <img
                src={zoomOutIcon}
                alt="download"
                className="h-full object-cover"
              />
            </button>
          </div>
        )} */}
        {cover !== "NONE" && (
          <div className="flex gap-5 mt-4 items-center">
            <button
              className="h-12 hover:brightness-110"
              onClick={goToEditPage}>
              <img src={editIcon} alt="edit" className="h-full object-cover" />
            </button>
            <a href={pdfFile} download className="h-14 hover:brightness-110">
              <img
                src={downloadIcon}
                alt="download"
                className="h-full object-cover"
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewEBook;
