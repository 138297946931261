import React, { useState } from "react";
import style from "./style.module.css";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { APIService } from "../../services/apiService";
import { useMemo } from "react";
import MyBookCollection from "./components/MyEBookCollection";
import ViewEBook from "../CustomEBook/ViewEBook";

const Ebook = () => {
  const [openEbook, setOpenEbook] = useState(false);
  const [listEBooks, setListEBooks] = useState([]);
  const cookies = useCookies();
  const [selectedEbookIndex, setSelectedEbookIndex] = useState(0);
  const itemSelected = useMemo(
    () => listEBooks[selectedEbookIndex],
    [selectedEbookIndex, listEBooks]
  );

  const backToEBookGallery = () => {
    setOpenEbook(false);
  };

  const handleSelectEBook = (index) => {
    setSelectedEbookIndex(index);
  };
  const onHandleOpenBookList = () => {
    setOpenEbook(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const accessToken = cookies["access_token"];
        const res = await APIService.get_ebook(accessToken);

        setListEBooks(res?.data ?? []);
        // setListEbooks(data.data);
        // setListBookFilter(data.data);
      } catch (error) {}
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="w-full">
        {openEbook ? (
          <ViewEBook
            indexSelectedItem={selectedEbookIndex}
            itemSelected={itemSelected}
            actionButton={backToEBookGallery}
          />
        ) : (
          <MyBookCollection
            setOpenEbook={setOpenEbook}
            handleSelectEBook={handleSelectEBook}
            listEBooks={listEBooks}
          />
        )}
      </div>
    </>
  );
};

export default Ebook;
