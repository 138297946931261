import { Col, Row } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Notification } from "../../../../components/Notification/index";
import { TableDataClassMagnager } from "../../../../components/TableData";
import { APIService } from "../../../../services/apiService";
import CourseSelectModal from "./CourseSelectModal";

const ArtworkManager = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const accessToken = cookies["access_token"];

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await APIService.get_all_user_campus(accessToken);
        setDataUser(data.data);
        setFilteredData(data.data);
      } catch (error) {}
      setIsLoading(false);
    }

    async function fetchFilterData() {
      setIsLoading(true);
      try {
        const data = await APIService.get_all_user_lms(accessToken, filter);
        setDataUser(data.data);
        setFilteredData(data.data);
      } catch (error) {}
      setIsLoading(false);
    }

    if (hasFilterData) {
      fetchFilterData();
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    if (!selectedCourses.length) {
      const newFilter = JSON.parse(JSON.stringify(filter));
      delete newFilter["courses"];
      setFilter(newFilter);
      return;
    }
    setFilter({ ...filter, courses: selectedCourses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourses]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeStartDate = (_, dateString) => {
    if (!dateString) {
      const newFilter = JSON.parse(JSON.stringify(filter));
      delete newFilter["start_date"];
      setFilter(newFilter);
      return;
    }
    setFilter({ ...filter, start_date: dateString });
  };

  const onChangeEndDate = (_, dateString) => {
    if (!dateString) {
      const newFilter = JSON.parse(JSON.stringify(filter));
      delete newFilter["end_date"];
      setFilter(newFilter);
      return;
    }
    setFilter({ ...filter, end_date: dateString });
  };

  const handleFilter = () => {
    const isFilter = Object.keys(filter).length === 0;
    if (isFilter) {
      setHasFilterData(false);
    } else {
      setHasFilterData(true);
    }
    setRefetch((prev) => !prev);
  };

  const onSearch = (value, _e, info) => {
    if (value === "") {
      setFilteredData(dataUsers);
      return;
    }
    const resultSearch = dataUsers?.filter(
      (item) =>
        item?.nick_name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.fullname?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.campus_name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.talksam_id?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setFilteredData(resultSearch);
  };

  const handleSaveExcel = () => {
    setIsExporting(true);
    console.log("filteredData", filteredData);
    APIService.export_excel_class_lms(accessToken, { users: filteredData })
      .then(async (res) => {
        console.log("res", res);
        const response = await fetch(res.data);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "export.xlsx";
        link.click();
        Notification("success", "Save excel successfully!");
      })
      .catch(() => {
        Notification("error", "Error occurred");
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <>
      <CourseSelectModal
        selectedCourses={selectedCourses}
        setSelectedCourses={setSelectedCourses}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Col span={24}>
        <Row gutter={[16, 15]}>
          <Col span={24}>
            <div className="flex justify-end mt-4">
              <Search
                style={{
                  width: 400,
                }}
                placeholder="Search username, email, fullname, talksam ID"
                allowClear
                enterButton="Search"
                onSearch={onSearch}
              />
            </div>
          </Col>
          <Col span={24}>
            <TableDataClassMagnager
              isArtwork
              hasFilterData={hasFilterData}
              isLoading={isLoading}
              dataUsers={filteredData}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ArtworkManager;
