import {
  Button,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  notification,
} from "antd";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Notification } from "../../components/Notification/index";
import { APIService } from "../../services/apiService";
import style from "./style.module.css";

export const FormRoleManager = ({ user, disable, fetchData }) => {
  console.log("user", user);
  const [form] = Form.useForm();
  const dateFormat = "YYYY/MM/DD";
  const [cookies] = useCookies(["access_token"]);
  const [updatedUser, setUpdatedUser] = useState(user);
  const [checked, setChecked] = useState(null);
  const [checkedRadio, setcheckedRadio] = useState(null);
  const [duplicate, setDuplicate] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const campus_list = useSelector((state) => state.adminSlice.campus_list);
  const country_list = useSelector((state) => state.adminSlice.country_list);
  const [change, setChange] = useState(false);
  const [changeCreate, setChangeCreate] = useState(false);
  const [onChangeCountry, setOnChangeCountry] = useState(-1);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [countryID, setCountryID] = useState(null);
  const [campusID, setcampusID] = useState(null);

  console.log("campus_list", campus_list);

  const eventOnChangeCountry = (value) => {
    setOnChangeCountry(value);
    form.setFieldValue("campus", null);
  };
  const onSaveStage = () => {
    const data = {
      id: form.getFieldValue("ID"),
      role_profile: form.getFieldValue("role_profile"),
      country: form.getFieldValue("country"),
      email: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      campus: form.getFieldValue("campus"),
      name: form.getFieldValue("name"),
      role_selection: form.getFieldValue("role_selection"),
      talksam_id: form.getFieldValue("talksam_id"),
    };

    APIService.update_role_manager(data, cookies["access_token"])
      .then((response) => {
        if (!response) {
          Notification("error", response.data);
        } else {
          Notification("success", "Update/Register success");
          setChange(false);
          setChangeCreate(false);
          form.resetFields();
          fetchData();
        }
      })
      .catch((error) => {
        Notification("error", "Update/Register role management fail");
        // setChange(true);
      });
  };
  const _onDelete = () => {
    const data = {
      email: form.getFieldValue("email"),
    };

    APIService.delete_role_manager(data, cookies["access_token"])
      .then((response) => {
        if (!response) {
          Notification("error", response.data);
        } else {
          Notification("success", "Delete success");
          setChange(false);
          fetchData();
        }
      })
      .catch((error) => {
        Notification("error", "Delete role management fail");
        // setChange(true);
      });
  };

  const handleonCheckDuplicate = async () => {
    const res = await APIService.post_email_duplicate(
      {
        email: user.email,
      },
      cookies["access_token"]
    );
    // console.log(res.data);
  };

  const handleChange = () => {
    setIsFormDisabled(false);
    setChange(true);
  };

  useEffect(() => {}, [duplicate]);
  const handleSelectChangeCountry = (value) => {
    setCountryID(value);
  };

  useEffect(() => {
    form.setFieldValue("ID", user?.infor_id);
    form.setFieldValue("name", user?.name);
    form.setFieldValue("role_profile", user?.profile_name);
    form.setFieldValue("role_selection", user?.role);
    form.setFieldValue("email", user?.email);
    form.setFieldValue("country", user?.country);
    form.setFieldValue("campus", user?.campus);
    form.setFieldValue("talksam_id", user?.talksam_id);
    setOnChangeCountry(user?.country);
    setUpdatedUser(user);
    setIsFormDisabled(true);
    setChange(false);
    setChangeCreate(false);
    setDuplicate(false);
  }, [form, user]);

  return (
    <>
      <div className="flex justify-center w-full">
        <div className="w-[60%] flex justify-between pb-2">
          {changeCreate ? (
            <>
              <Button className={style.btn_addPage} onClick={onSaveStage}>
                Saved
              </Button>
            </>
          ) : (
            <Button
              className={style.btn_addPage}
              onClick={() => {
                setIsFormDisabled(false);
                form.resetFields();
                setChangeCreate(true);
              }}
            >
              Create new
            </Button>
          )}

          {change ? (
            <>
              <Button className={style.btn_addPage} onClick={onSaveStage}>
                Saved
              </Button>
            </>
          ) : (
            <>
              <Button className={style.btn_addPage} onClick={handleChange}>
                Change
              </Button>
            </>
          )}
          <Popconfirm
            title="Delete the profile"
            description="Are you sure to delete this profile?"
            onConfirm={_onDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button className={style.btn_addPage}>Delete</Button>
          </Popconfirm>
        </div>
      </div>

      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 20,
        }}
        form={form}
        disabled={isFormDisabled}
      >
        {contextHolder}

        <Form.Item
          name="ID"
          label="ID"
          rules={[
            {
              required: true,
              message: "Please input ID",
            },
          ]}
        >
          <Input disabled={disable} />
        </Form.Item>
        <Form.Item name="password" label="Password">
          <Input disabled={disable} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              // type: "array",
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role_profile"
          label="Role Profile"
          rules={[
            {
              // type: "array",
              required: true,
              message: "Please input role profile",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              // type: "array",
              required: true,
              message: "Please input email",
            },
            {
              type: "email",
              message: "Please enter a validate email!",
            },
          ]}
          name="email"
          label="Email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              // type: "array",
              required: true,
              message: "Please select your Country",
            },
          ]}
        >
          <Select
            onChange={eventOnChangeCountry}
            placeholder="Please select a country"
          >
            {country_list.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="role_selection"
          label="Role Selection"
          rules={[
            {
              // type: "array",
              required: true,
              message: "Please Select your role",
            },
          ]}
        >
          <Radio.Group defaultValue="campus">
            <Radio value="Campus">Campus Manager</Radio>
            <Radio value="Administrator">Administrator</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="campus"
          label="Campus"
          rules={[
            {
              required: true,
              message: "Please select your Campus",
            },
          ]}
        >
          <Select placeholder="Please select a campus">
            {campus_list?.map((item) =>
              item.country_id === onChangeCountry ? (
                <Option value={item.id}>{item.name}</Option>
              ) : null
            )}
          </Select>
        </Form.Item>
        <Form.Item name="talksam_id" label="Talksam ID">
          <Input />
        </Form.Item>
      </Form>
    </>
  );
};
