import { createSlice } from "@reduxjs/toolkit";
import { randInt } from "three/src/math/MathUtils";

export const lessonSlice = createSlice({
  name: "lession",
  initialState: {
    lesson: {
      lessonId: null, // hoặc giá trị mặc định khác nếu cần,
      level: null,
      warming_up_question: null,
      course_name: null,
      lesson_name: null,
      topic: null,
      useful_exp: null,
      history_chatbot: null,
      book_review: null,
      point: null,
      content_self_editing: "",
      content_original: "",
      prompt: "",
      image_stable_diffusion: [],
      minimum_words: 0,
      time_writing: 0,
      history_chat: [],
      suggestionArray: [],
      status_render: false,
      is_loading: false,
      final_suggestion: "Final suggestion",
      clearTimer: null,
      history: null,
      number_img: null,
      status: null,
      title: "",
      paragraph: null,
      writing: "",
      writing_title: "",
      temp_self_writing: "",
      reloadEditing: false,
      self_editing: "",
    },
  },
  reducers: {
    setLessonInfo: (state, action) => {
      state.lesson.course_name = action.payload.course_name;
      state.lesson.lesson_name = action.payload.lesson_name;
      state.lesson.topic = action.payload.topic;
      state.lesson.lessonId = action.payload.lesson_id;
      state.lesson.book_review = action.payload.book_review;
      state.lesson.level = action.payload.level;
      state.lesson.minimum_words = action.payload.minimum_words;
      state.lesson.time_writing = action.payload.time_writing;
      state.lesson.course_name = action.payload.course_name;
      state.lesson.warming_up_question = action.payload.warming_up_question;
      state.lesson.useful_exp = action.payload.useful_exp;
      state.lesson.final_suggestion = "Final suggestion";
      state.lesson.history = action.payload.history;
      state.lesson.status = action.payload.status;
      state.lesson.suggestionArray = [];
      state.lesson.title = action.payload.title;
      state.lesson.paragraph = action.payload.paragraph;
      state.lesson.writing = action.payload.writing;
      state.lesson.writing_title = action.payload.writing_title;
      state.lesson.self_editing = action.payload.self_editing;
    },
    setPoint: (state, action) => {
      state.lesson.point = action.payload;
    },
    setReloadEditing: (state, action) => {
      state.lesson.reloadEditing = action.payload;
    },
    setTempSelfWriting: (state, action) => {
      state.lesson.temp_self_writing = action.payload;
    },
    setTitle: (state, action) => {
      state.lesson.writing_title = action.payload;
    },
    setContentOriginal: (state, action) => {
      state.lesson.content_original = action.payload;
    },
    setPrompt: (state, action) => {
      state.lesson.prompt = action.payload;
    },
    setSelfEditing: (state, action) => {
      state.lesson.content_self_editing = action.payload;
    },
    setImageStableDiffusion: (state, action) => {
      state.lesson.image_stable_diffusion = action.payload;
    },
    setImageIndex: (state, action) => {
      state.lesson.number_img = action.payload;
    },
    setHistoryChat: (state, action) => {
      state.lesson.history_chat = [
        ...state.lesson.history_chat,
        action.payload,
      ];
    },
    setSuggestionArray: (state, action) => {
      state.lesson.suggestionArray = action.payload.arrSuggestion;
    },
    setStatusRender: (state, action) => {
      state.lesson.status_render = action.payload.status_render;
    },
    setFinalSuggestion: (state, action) => {
      // console.log(action.payload.final_suggestion)
      state.lesson.final_suggestion = action.payload.final_suggestion;
    },
    setIsLoading: (state, action) => {
      // console.log(action.payload)
      state.lesson.is_loading = action.payload.is_loading;
    },
    setClearTimer: (state, action) => {
      state.lesson.clearTimer = randInt(0, 10000);
    },
    setWritingTime: (state, action) => {
      state.lesson.time_writing = action.payload.time_writing;
    },
    setStatusWriting: (state, action) => {
      state.lesson.status = action.payload.status;
    },
  },
});

export const {
  setTitle,
  setClearTimer,
  setIsLoading,
  setFinalSuggestion,
  setLessonInfo,
  setContentOriginal,
  setPrompt,
  setSelfEditing,
  setImageStableDiffusion,
  setPoint,
  setSuggestionArray,
  setStatusRender,
  setImageIndex,
  setWritingTime,
  setStatusWriting,
  setReloadEditing,
  setTempSelfWriting,
} = lessonSlice.actions;
export default lessonSlice.reducer;
