import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Button, Col, Row, Select } from "antd";
import {
  TableDataAdminPageList,
  TableDataNoticeManager,
} from "../../../../components/TableData";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
import PopupAddPage from "../../../../components/Popup/PopupAddPage";


import './custom.css'

const AdminNoticeManager = () => {
  const [cookies] = useCookies(["access_token"]);
  const [dataUsers, setDataUser] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [type, setType] = useState("add");
  async function fetchData() {
    try {
      const accessToken = cookies["access_token"];
      const data = await APIService.get_all_list_campus_enrolment(accessToken);
      setDataUser(data.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log('check data user', dataUsers)

  return (
    <>
      <div className={style.container_listCampus}>
        {/* <div className={style.comingSon}>
          <h1>Coming Soon</h1>
          <img src={logo1} alt="" className={style.img_1} />
          <img src={logo2} alt="" className={style.img_2} />
        </div> */}
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <div className={style.filter_Username}>
                <Row gutter={20}>
                  <Col span={4}>
                    <Button
                      className={style.btn_addPage}
                      onClick={() => setOpenPopup(true)}
                    >
                      Add Post
                    </Button>
                  </Col>
                   {/* <Col span={8}>
                    <Select
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Col> */}
                  {/* <Col span={8}>
                    <Select
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Col> */}
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <div className={style.list_tableCampus}>
                <TableDataNoticeManager type={type} dataUsers={dataUsers} />
              </div>
            </Col>
          </Row>
        </Col>

        {openPopup && <PopupAddPage setOpenPopup={setOpenPopup} />}
      </div>
    </>
  );
};

export default AdminNoticeManager;
