import classNames from "classnames";
import React from "react";

const ArtworkItem = ({ index, selectedLesson, handleSelectLesson, data }) => {
  const { link_artwork } = data;
  return (
    <div
      onClick={() => {
        handleSelectLesson(data);
      }}
      className={classNames(
        "h-auto w-full bg-white skew-y-6 cursor-pointer shadow-lg duration-500 hover:-translate-y-4 hover:drop-shadow-lg hover:skew-y-0",
        index === 0 && "mt-24",
        selectedLesson ? "translate-x-[2000px]" : "translate-x-0"
      )}>
      <img src={link_artwork} alt="artwork" className="w-full object-cover" />
    </div>
  );
};

export default ArtworkItem;
