import React, { useEffect, useRef, useState } from "react";
import style from "./InputChat.module.css";
import { Icon } from "../../../assets/icon_svg/icon";
import { chatService } from "../../../services/chatService";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import IconMicroPhone from "../../../assets/icon_svg/microphone.png";
// import {
//   setConversation,
//   setThinking,
//   setQuestion,
//   setAppear,
// } from "../../../../redux/reduxToolkit/chatSlice";
import {
  setConversation,
  setThinking,
  setQuestion,
  setAppear,
} from "../../../redux/chatSlice";
import RecordModal from "../../RecordModal/RecordModal";
import classNames from "classnames";

const ResTimeOut =
  "I’m sorry for the delay in my response. It seems like I'm experiencing a technical issue, but our team is doing their best to resolve it. Please refresh the page and try again. Thank you for your patience!";

function InputChat() {
  const dispatch = useDispatch();
  const [question, setQuestionText] = useState("");
  const [data, setData] = useState({});
  const currentPet = useSelector((state) => state.petSlice.curentlyPet);
  const thinking = useSelector((state) => state.chatSlice.thinking);
  const newQuestion = useSelector((state) => state.chatSlice.question);
  const newAnswer = useSelector((state) => state.chatSlice.answer);
  const newOnAppear = useSelector((state) => state.chatSlice.onAppear);
  const disable = useSelector((state) => state.chatSlice.disable);
  const [cookies] = useCookies(["access_token"]);
  const [isOpenRecordModal, setIsOpenRecordModal] = useState(false);

  useEffect(() => {
    const d = {
      pet_id: currentPet?.id,
    };
    setData(d);
  }, [currentPet]);

  useEffect(() => {
    if (newAnswer.length > 0) {
      dispatch(setAppear(false));

      dispatch(
        setConversation({
          type: "answer",
          text: newAnswer,
        })
      );
    }
  }, [newAnswer]);

  useEffect(() => {
    if (newQuestion) setQuestionText(newQuestion);
  }, [newQuestion]);

  let getAnswer = async (requirement) => {
    try {
      const res = await chatService.chat_pet(
        requirement,
        cookies["access_token"]
      );
      if (res) {
        dispatch(
          setConversation({
            type: "answer",
            text: res.data.length > 0 ? res.data : ResTimeOut,
          })
        );
      } else {
        dispatch(
          setConversation({
            type: "answer",
            text: ResTimeOut,
          })
        );
      }
      dispatch(setThinking(false));
      dispatch(setQuestion(""));
    } catch (error) {
      dispatch(setThinking(false));
      dispatch(setQuestion(""));
      // console.log(error)
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (question.trim() == "") return;
      dispatch(setThinking(true));
      dispatch(
        setConversation({
          type: "question",
          text: question,
        })
      );
      setQuestionText("");

      getAnswer({
        question: question,
        pet_id: currentPet?.id,
      });
    }
  };

  const onChangeInputText = (e) => {
    setQuestionText(e.target.value);
  };

  const getRows = () => {
    const lineCount = (question.match(/\n/g) || []).length + 1;
    return Math.min(lineCount, 3);
  };
  return (
    <div
      //   style={showHeader ? { alignItems: "center" } : { alignItems: "end" }}
      className={style.container_inputChat}>
      <div className={style.inputForm}>
        <input
          disabled={thinking || disable ? true : false}
          value={newOnAppear ? question : ""}
          // className={style.inputChat}
          className={classNames(
            "py-3 pl-4 pr-[5.5rem] w-full border-2  rounded-lg  focus:outline-primary"
          )}
          placeholder="Ask something"
          onChange={onChangeInputText}
          onKeyDown={onEnterPress}
          // rows={getRows()}
          // style={{ height: "auto", resize: "none" }}
        />
      </div>
      <div className="absolute gap-3 top-1/2 -translate-y-1/2 right-4 flex items-center">
        <button
          className=""
          onClick={() => {
            setIsOpenRecordModal(true);
          }}>
          <img src={IconMicroPhone} alt="" />
        </button>
        <button
          className={style.btnSend}
          onClick={() => {
            if (thinking) return;
            if (question.trim() === "") return;
            dispatch(setThinking(true));
            dispatch(
              setConversation({
                type: "question",
                text: question,
              })
            );
            setQuestionText("");
            getAnswer({
              question: question,
              pet_id: currentPet?.id,
            });
          }}>
          {Icon.Send}
        </button>
      </div>
      <RecordModal
        type="pets"
        data={data}
        isOpenModal={isOpenRecordModal}
        setIsOpenModal={setIsOpenRecordModal}
      />
    </div>
  );
}

export default InputChat;
