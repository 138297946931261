import React from "react";
import Book from "./component/book";
const EBookPage = () => {
  return ( 
    <>
      <Book />
    </> 
  );
}
 
export default EBookPage;