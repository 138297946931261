import { EyeOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
const ViewArtworkModal = ({ artworkUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const download = async () => {
    const response = await fetch(artworkUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "artwork.jpg";
    link.click();
  };

  return (
    <>
      <button
        className="flex justify-center items-center -translate-y-0.5"
        onClick={showModal}
      >
        <EyeOutlined />
      </button>
      <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
        {artworkUrl ? (
          <>
            <Button onClick={download}>Download</Button>
            <img src={artworkUrl} alt="artwork" />
          </>
        ) : (
          <span>This student does not reach this part</span>
        )}
      </Modal>
    </>
  );
};
export default ViewArtworkModal;
