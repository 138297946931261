import React, { useState } from "react";
import InputFieldSelfEditing from "../../../../../components/InputField/indexSelfEditing";
import TopicWritingCenter from "../../../../../components/TopicWritingCenter/index";
import style from "../style.module.css";

const SeftEditing = ({ onNext, onBack }) => {
  const [type, setType] = useState(4);
  return (
    <>
      <div className={style.content_writingCenter}>
        <TopicWritingCenter type={type} />
        <InputFieldSelfEditing></InputFieldSelfEditing>
      </div>
    </>
  );
};

export default SeftEditing;
