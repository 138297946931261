import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setType as setTypeFeedback } from "../../redux/writingSlice";
import CustomTooltip from "./CustomTooltip";

const TemplateEditing = () => {
  const dispatch = useDispatch();
  const feedbackType = useSelector((state) => state.writingSlice.type);
  const [indexActiveTooltip, setIndexActiveTooltip] = useState(-1);

  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const type = useSelector((state) => state.writingSlice.type);

  useEffect(() => {
    setIndexActiveTooltip(-1);
  }, [type]);

  return (
    <>
      <div className="absolute -top-12 left-0 flex gap-4  w-full justify-center">
        <button
          onClick={() => {
            dispatch(setTypeFeedback({ type: "error" }));
          }}
          className={classNames(
            "bg-[#bc0000] text-white font-medium px-6 py-1.5 rounded-md duration-200",
            feedbackType === "error" &&
              "shadow-[0px_0px_18px_0px_rgba(180,0,0,0.6)]"
          )}
        >
          ERROR
        </button>
        <button
          onClick={() => {
            dispatch(setTypeFeedback({ type: "improvement" }));
          }}
          className={classNames(
            "bg-[#00a3bb] text-white font-medium px-6 py-1.5 rounded-md duration-200",
            feedbackType === "improvement" &&
              "shadow-[0px_0px_18px_0px_rgba(0,163,187,0.6)]"
          )}
        >
          IMPROVEMENT
        </button>
      </div>
      <div className="h-[36vh] overflow-y-auto">
        {type === "error" &&
          lesson.suggestionArray[0]?.map((words, index) => {
            if (words?.includes("***")) {
              return (
                <CustomTooltip
                  key="error"
                  colorContent={
                    indexActiveTooltip === index
                      ? "bg-[#fb923c]"
                      : "bg-[#ffedd5]"
                  }
                  content={words?.split("#")[0]?.split("***")[1]}
                  tooltip={words?.split("#")[1]?.split("***")}
                  setIndexActiveTooltip={setIndexActiveTooltip}
                  indexActiveTooltip={indexActiveTooltip}
                  index={index}
                />
              );
            } else return <>{words}</>;
          })}
        {type === "improvement" &&
          lesson.suggestionArray[1]?.map((words, index) => {
            if (words.includes("***")) {
              return (
                <>
                  <CustomTooltip
                    key="improvement"
                    colorContent="bg-[#b7eb8f]"
                    content={words?.split("#")[0]?.split("***")[1]}
                    tooltip={words?.split("#")[1]?.split("***")}
                    setIndexActiveTooltip={setIndexActiveTooltip}
                    indexActiveTooltip={indexActiveTooltip}
                    index={index}
                  />
                  {/* <Tooltip
                    placement="top"
                    title={
                      <p className="prevent-select">
                        {words?.split("#")[1]?.split("***")}
                      </p>
                    }>
                    <Tag
                      className="bg-[#b7eb8f] text-base"
                      style={{ marginInlineEnd: "0" }}>
                      <p className="whitespace-pre-wrap break-words">
                        {words?.split("#")[0]?.split("***")[1]}
                      </p>
                    </Tag>
                  </Tooltip> */}
                </>
              );
            } else return <>{words}</>;
          })}
      </div>
    </>
  );
};

export default TemplateEditing;
