import React from "react";
import Ranking from "../DashBoardPage/Ranking";

const CurriculumPage = () => {
  return (
    <>
      <Ranking />
    </>
  );
};

export default CurriculumPage;
