import React from "react";
import { NavLink } from "react-router-dom";

const Button = (props) => {
  const { className, to, text } = props;

  return <NavLink className={className} to={to}>
    {text}
  </NavLink>;
};
export default Button;
