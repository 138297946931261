import React from "react";
import { Button, Form, Input, Modal, Select, notification } from "antd";

import { useState } from "react";
import style from "./style.module.css";
import { margin } from "@mui/system";
import { useEffect } from "react";
import { APIService } from "../../services/apiService";
import { useCookies } from "react-cookie";
import "./custom.css";
const { Option } = Select;
const { TextArea } = Input;

const Inquiry = () => {
  const [modal2Open, setModal2Open] = useState(false);
  const [form] = Form.useForm();
  const [cookies] = useCookies(["access_token"]);
  const [api, contextHolder] = notification.useNotification();
  const [formValues, setFormValues] = useState({
    selectDevision: "lession",
    questionTitle: "",
    textArea: "",
    email: "",
    name: "",
  });

  const openNotification = () => {
    api.open({
      message: "Notification Title",
      description:
        "I will never close automatically. This is a purposely very very long description that has many many characters and words.",
    });
  };

  const handleRegister = async () => {
    try {
      await form.validateFields();
      APIService.create_inquiry(
        {
          user_id: -1,
          name: formValues.username,
          email: formValues.email,
          division: formValues.selectDevision,
          title: formValues.questionTitle,
          question: formValues.textArea,
        },
        cookies["access_token"]
      )
        .then((response) => {
          if (response) {
            openNotification();
            form.resetFields();
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div className={style.inquiryPage}>
        <div className={style.inquiryContent}>
          <h1 className={style.title}>Question Registration</h1>
        </div>
        {/* <div>
            <button className={style.btnList}>List</button>
          </div> */}
        <Form
          form={form}
          labelCol={{
            lg: { span: 5 },
            xl: { span: 6 },
            xll: { span: 5 },
          }}
          wrapperCol={{
            span: 20,
          }}
          className={style.formInquiry}
        >
          <Form.Item
            label="Devision"
            name="devision"
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  selectDevision: value,
                }))
              }
              options={[
                { value: "lession", label: "Lession" },
                { value: "course", label: "Course" },
                { value: "product", label: "Product" },
              ]}
              placeholder="Inquiry Division"
            ></Select>
          </Form.Item>

          <Form.Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: "Please input your Question Title!" },
            ]}
          >
            <Input
              placeholder="Enter Question Title"
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  questionTitle: e.target.value,
                }))
              }
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  email: e.target.value,
                }))
              }
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your Fullname!" }]}
          >
            <Input
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  name: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            label="Question"
            name="question"
            rules={[
              { required: true, message: "Please input your Qurestion!" },
            ]}
          >
            <TextArea
              style={{ border: "0", outline: "none" }}
              rows={4}
              placeholder="Question"
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  textArea: e.target.value,
                }))
              }
            />
          </Form.Item>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button className={style.btn_Inquiry} onClick={handleRegister}>
              REGISTER INQUIRY
            </Button>
          </div>
        </Form>
      </div>
      {contextHolder}
    </>
  );
};

export default Inquiry;
