import { Button, Layout, Space, Modal, Form, Select } from "antd";
import React, { useState, useEffect, Fragment } from "react";
import { Content } from "antd/es/layout/layout";
import { Col, Row, Input } from "antd";
import style from "./style.module.css";
import "./dashBoardIndex.css";
import User from "../../../components/User";
import wingWriting from "../../../assets/logo/logo_wingWriting.svg";
import petRoom from "../../../assets/logo/logo_Petroom.svg";
import eBook from "../../../assets/logo/logo_eBook.svg";
import point from "../../../assets/logo/logo_point.svg";
import wingWriting_M from "../../../assets/logo/logo_Writing_M.svg";
import petroom_M from "../../../assets/logo/logo_PetRoom_M.svg";
import point_M from "../../../assets/logo/logo_Point_M.svg";
import ebook_M from "../../../assets/logo/logo_Ebook_M.svg";
import iconUser from "../../../assets/logo/iconUser.svg";
import iconLMS from "../../../assets/logo/IconLMS.svg";
import iconApprovalRequest from "../../../assets/logo/IconAppoval.svg";
import iconWeb from "../../../assets/logo/IconWeb.svg";
import iconCMS from "../../../assets/logo/iconCMS.svg";
import iconPMS from "../../../assets/logo/iconPMS.svg";
import iconSetting from "../../../assets/logo/IconSetting.svg";
import { APIService } from "../../../services/apiService";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { TableDataHistory } from "./TableDataHistory";
import UserDashboard from "../../../components/User/UserDashboard";
import wing_icon from "../../../assets/background/Group 238416.png";

import { Flex } from "@mantine/core";

const { Search } = Input;

const HomePage = (props) => {
  const { handleChangeType, level } = props;
  const [type, setType] = useState("home");
  const [contentHomeUser, setContentHomeUser] = useState([
    {
      img_hover: level.toUpperCase().includes("E")
        ? wingWriting
        : wingWriting_M,
      name: "Study Room",
      type: "mypage",
      navigate: "/study-room",
    },
    {
      img_hover: level.toUpperCase().includes("E") ? petRoom : petroom_M,
      name: " Pet Tutor",
      type: "petroom",
      navigate: "/pet-room",
    },
    {
      img_hover: level.toUpperCase().includes("E") ? point : point_M,
      name: "Point",
      type: "point",
      navigate: "/point",
    },
    {
      img_hover: level.toUpperCase().includes("E") ? eBook : ebook_M,
      name: " E-Book",
      type: "ebook",
      navigate: "/e-mybook",
    },
  ]);
  const [contentHomeCampus, setContentHomeCampus] = useState([
    {
      img_hover: iconUser,
      name: "User",
      navigate: "/campus-user",
    },
    {
      img_hover: iconLMS,
      name: "LMS",
      type: "petroom",
      navigate: "/campus-lms",
    },
    {
      img_hover: iconApprovalRequest,
      name: "Approval Request",
      type: "point",
      navigate: "/campus-approval_request",
    },
  ]);

  const [contentHomeAdmin, setContentHomeAdmin] = useState([
    {
      img_hover: iconUser,
      name: "USER",
      type: "User",
      navigate: "/admin-user",
    },

    {
      img_hover: iconWeb,
      name: "WEB",
      type: "web",
      navigate: "/admin-web",
    },
    {
      img_hover: iconApprovalRequest,
      name: "CLASS APPROVAL",
      type: "approval",
      navigate: "/admin-approval",
      className: "approvalMenu",
    },
    {
      img_hover: iconLMS,
      name: "LMS",
      type: "cms",
      navigate: "/admin-lms",
    },
    {
      img_hover: iconPMS,
      name: "PMS",
      type: "pms",
      navigate: "/admin-pms",
    },
    {
      img_hover: iconCMS,
      name: "CMS",
      type: "cms",
      navigate: "/admin-cms",
    },
    {
      img_hover: iconSetting,
      name: "SETTING",
      type: "setting",
      navigate: "/admin-setting",
    },
  ]);

  const [data, setSetData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dataInquiry, setDataInquiry] = useState([]);
  const [dataPoint, setDataPoint] = useState([]);
  const [dataAvg, setDataAvg] = useState([]);
  const navigate = useNavigate();
  const [cookies] = useCookies(["access_token"]);
  const [loadTableCheck, setLoadTableCheck] = useState(0);
  const [courseName, setCourseName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = cookies["access_token"];
        const data = await APIService.get_coures_history(accessToken);
        setSetData(data.data);
        setFilterData(data.data);
        const inquiry = await APIService.get_inquiry_history_user(accessToken);
        setDataInquiry(inquiry.data);
        const point = await APIService.get_point_history(accessToken);
        setDataPoint(point.data);
        const avgpoint = await APIService.get_inquiry_point(accessToken);
        setDataAvg(avgpoint.data);
        setLoadTableCheck(1);
      } catch (error) {
        console.error("Error call API:", error);
      }
    };
    fetchData();
  }, []);

  const onHandleSearch = (name) => {
    const nameData = data.filter((item) => {
      const internal = Object.values(item);
      // console.log(internal);
      const internalResult = internal[0].course_name.toLowerCase().trim();
      const external = name.toLowerCase().trim();
      return internalResult.includes(external);
    });
    setFilterData(nameData);
  };

  return (
    <div className={style.container_Home}>
      <Space direction="vertical" size={[0, 48]} className={style.contentHome}>
        <Layout hasSider className={style.container_Home}>
          <Content style={{ width: "100%", height: "100%" }}>
            {/* dashboard student */}
            {level !== "Campus" && level !== "Administrator" ? (
              <Row className={style.row_contentHome}>
                <Col
                  span={8}
                  // className={style.contentHome_contentUser}
                  className="w-[30%] h-full flex justify-center items-start">
                  <div className={style.contentHome_inforUser}>
                    <UserDashboard
                      className={style.boxAvt}
                      classImga={style.contentHome_imgUser}
                      type={type}
                    />
                  </div>
                </Col>
                <Col
                  span={16}
                  className={`${style.contentHome_contentHome} ${
                    level !== "E" ? style.dashboadAdmin : ""
                  }`}>
                  <Row
                    className="w-full"
                    gutter={[20, 20]}
                    style={{ marginTop: "32px" }}>
                    <Col span={23}>
                      <Row className="d-flex h-full justify-center items-center">
                        {level.toUpperCase().includes("M") &&
                          level !== "Administrator" &&
                          level !== "Campus" && (
                            <div
                              className="flex flex-wrap justify-center pt-5"
                              style={{ rowGap: "40px", columnGap: "20px" }}>
                              {contentHomeUser?.map((item) => (
                                <div className="">
                                  <div
                                    className="w-[190px] h-[190px] lg:w-[270px] lg:h-[270px] rounded-[30px] hover:bg-white cursor-pointer hover:shadow-2xl transition-all p-5 flex justify-center items-center"
                                    onClick={() =>
                                      handleChangeType(item.type, item.navigate)
                                    }>
                                    <div className="flex flex-col items-center">
                                      <img src={item.img_hover} alt="" />
                                      <Button className="w-[180px] h-[40px] text-[#5BA3B9] border-[#5BA3B9] rounded-[63px] hover:!bg-[#5BA3B9] hover:!text-white">
                                        {item.name}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        {level.toUpperCase().includes("E") &&
                          level !== "Administrator" &&
                          level !== "Campus" && (
                            <>
                              {contentHomeUser?.map((item) => (
                                <div
                                  className="w-[270px] h-[270px] rounded-[30px] hover:bg-white cursor-pointer hover:shadow-contentHomeUser transition-all p-5 flex justify-center items-center"
                                  onClick={() =>
                                    handleChangeType(item.type, item.navigate)
                                  }>
                                  <div className="flex flex-col items-center">
                                    <img src={item.img_hover} alt="" />
                                    <Button className="w-[180px] h-[40px] text-[#5BA3B9] border-[#5BA3B9] rounded-[63px] hover:!bg-[#5BA3B9] hover:!text-white">
                                      {item.name}
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                      </Row>
                    </Col>

                    {level !== "Administrator" && level !== "Campus" && (
                      <Col span={23} className="my-10">
                        <Fragment>
                          {loadTableCheck === 0 ? (
                            <></>
                          ) : (
                            <TableDataHistory
                              filterData={filterData}
                              className={style.contentTable}
                              dataInquiry={dataInquiry}
                              dataPoint={dataPoint}
                              dataAvg={dataAvg}
                            />
                          )}
                        </Fragment>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ) : null}
            {/* dashboard admin and campus */}
            {level === "Campus" || level === "Administrator" ? (
              <Row className={style.row_contentCampus}>
                <Row
                  className={style.row_information}
                  style={{ position: "relative" }}>
                  <img src={wing_icon} alt="" className={style.Icon_left} />
                  <img src={wing_icon} alt="" className={style.Icon_right} />
                  <Col
                    span={24}
                    className="w-[30%] h-full flex justify-center items-center">
                    <div className={style.contentHome_inforUser_campus}>
                      <UserDashboard
                        className={style.boxAvt}
                        classImga={style.contentHome_imgUser}
                        type={type}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={24}
                    className={`${style.contentHome_contentHome} ${
                      level !== "E" ? style.dashboadAdmin : ""
                    }`}>
                    <Row className="w-full" gutter={[20, 20]}>
                      <Col span={23}>
                        <Row className="d-flex h-full justify-center items-center">
                          {level === "Campus" && (
                            <div
                              className="flex flex-wrap justify-center"
                              style={{ rowGap: "16px", columnGap: "24px" }}>
                              {contentHomeCampus?.map((item) => (
                                <div
                                  className={style.content_col}
                                  onClick={() =>
                                    handleChangeType(item.type, item.navigate)
                                  }>
                                  <div className={style.content_Menu_Campus}>
                                    <img
                                      src={item.img_hover}
                                      alt=""
                                      className={style.img_cms}
                                    />
                                    <Button className={style.content_button}>
                                      {item.name}
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {level === "Administrator" && (
                            <div
                              className="flex flex-wrap justify-center"
                              style={{ rowGap: "16px", columnGap: "24px" }}>
                              {contentHomeAdmin?.map((item) => (
                                <div
                                  className={style.content_col}
                                  onClick={() =>
                                    handleChangeType(item.type, item.navigate)
                                  }>
                                  <div className={style.content_Menu_Campus}>
                                    <img
                                      src={item.img_hover}
                                      alt=""
                                      className={style.img_cms}
                                    />
                                    <Button className={style.content_button}>
                                      {item.name}
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            ) : null}
          </Content>
        </Layout>
      </Space>
    </div>
  );
};

export default HomePage;
