import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import { Button, Checkbox, Col, Row, Tabs, Upload } from "antd";
import {
  TableDataAdminCMS,
  TableDataCMS,
  TableDataListAdmin,
  TableDataProductRegistration,
  TableDataUser,
} from "../../../../components/TableData";
import { FormUser } from "../../../../components/Form";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
import { useDispatch } from "react-redux";
import { setEmail } from "../../../../redux/campusSlice";
import { useSelector } from "react-redux";
import { FormRoleRegistration } from "../../../../components/Form/FormRoleRegistration";
import { FormCmsCourse } from "../../../../components/Form/FormCmsCourse";

const { TabPane } = Tabs;

const CmsCourse = () => {
  const [value, setValue] = useState("Korea");
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [dataCourse, setDataCourse] = useState([]);
  const [datalesson, setDataLesson] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [datalessondetail, setdatalessondetail] = useState(null);
  const handleSetValue = (newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const handleClickCourse = (record) => {
    if (record.lesson.length > 0) {
      setDataLesson(
        record.lesson.map((item, index) => ({
          key: index + 1,
          lesson_name: item.lesson_name,
          start_date: record.date,
          information: item,
        }))
      );
    }
  };
  const handleClickLesson = (record) => {
    // console.log(record)
    // // console.log(record)
    setdatalessondetail(record);
  };

  async function fetchData() {
    try {
      // // console.log(cookies["access_token"])
      APIService.get_all_data_cms(cookies["access_token"]).then((response) => {
        if (response && response.data) {
          setDataCourse(
            response.data?.map((item, index) => ({
              key: index + 1,
              course_name: Object.entries(item)[0][1].course_name,
              start_date: Object.entries(item)[0][1].start_date,
              lesson: Object.entries(item)[0][1].lesson,
            }))
          );
        }
      });
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataCourse && dataCourse[0]?.lesson.length > 0) {
      setDataLesson(
        dataCourse[0].lesson.map((item, index) => ({
          key: index + 1,
          lesson_name: item.lesson_name,
          start_date: dataCourse[0].start_date,
          information: item,
        }))
      );
    }
  }, [dataCourse]);

  return (
    <div className={style.container_Infor}>
      <Col span={24}>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <div className={style.list_admin}>
              <TableDataCMS
                dataCourse={dataCourse}
                datalesson={datalesson}
                handleClickLesson={handleClickLesson}
                handleClickCourse={handleClickCourse}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className={style.admin_pms}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <FormCmsCourse datalesson={datalessondetail} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default CmsCourse;
