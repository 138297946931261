import { Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { APIService } from "../../services/apiService";

const CampusSelect = ({ onChange }) => {
  const [cookies] = useCookies(["access_token"]);
  const [campusList, setCampusList] = useState([]);

  useEffect(() => {
    APIService.get_all_campus(cookies["access_token"])
      .then((response) => {
        setCampusList(response?.data ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cookies]);

  const options = useMemo(() => {
    const campus = campusList.map((campus) => ({
      value: campus.name,
      label: campus.name,
    }));
    return [
      {
        value: "",
        label: "All",
      },
      ...campus,
    ];
  }, [campusList]);

  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <Select
      placeholder="Select Campus"
      style={{
        width: 160,
      }}
      onChange={handleChange}
      options={options}
    />
  );
};
export default CampusSelect;
