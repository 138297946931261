import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { useMediaQuery } from "react-responsive";
import coverImage from "../../assets/ebook/cover-ebook.png";
import nextIcon from "../../assets/ebook/next-page-icon.png";
import prevIcon from "../../assets/ebook/previous-page-icon.png";
import { useEBook } from "../../context/ViewEbook";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="w-full h-auto bg-white" ref={ref}>
      {props.image && (
        <img
          src={props.image}
          alt="img"
          className="w-full h-full object-cover"
        />
      )}
    </div>
  );
});

const EbookItem = () => {
  const { ebook } = useEBook();

  const { include_artwork, ebook_link } = ebook;
  const slides =
    useMemo(() => {
      const newSlides = include_artwork;
      if (include_artwork.length % 2 === 1) {
        newSlides.push("");
      }
      return newSlides;
    }, [include_artwork]) ?? [];

  const bookRef = useRef(null);
  const [cover, setCover] = useState("FRONT");
  const isBigScreen = useMediaQuery({ query: "(min-width: 1536px)" });
  const nextButtonClick = () => {
    if (bookRef.current) bookRef.current.pageFlip().flipNext();
  };

  useEffect(() => {
    setCover("FRONT");
  }, [ebook]);

  const prevButtonClick = () => {
    if (bookRef.current) bookRef.current.pageFlip().flipPrev();
  };

  const onPage = (e) => {
    console.log("e.data === 0", e.data);
    if (e.data === 0) {
      setCover("FRONT");
      return;
    }
    if (e.data === include_artwork.length + 1) {
      setCover("BACK");
      return;
    }
    setCover("NONE");
  };
  return (
    <div
      className={classNames(
        "relative 2xl:max-w-4xl max-w-2xl mx-auto font-jetBrains flex justify-center flex-col items-center"
      )}>
      <div
        className={classNames(
          "w-full h-full",
          cover === "NONE" && "bg-white/20 backdrop-blur-md"
        )}>
        <div
          className={classNames(
            "relative 2xl:w-[56rem] w-[44rem] mx-auto h-full"
          )}>
          <div className={classNames("w-full h-full ")}>
            <HTMLFlipBook
              key={ebook?.book_id}
              width={isBigScreen ? 100 : 90}
              height={isBigScreen ? 150 : 135}
              size="stretch"
              // minWidth={315}
              // maxWidth={1000}
              flippingTime={300}
              // minHeight={400}
              // maxHeight={1533}
              maxShadowOpacity={0.5}
              showCover={true}
              mobileScrollSupport={true}
              onFlip={onPage}
              //   onChangeOrientation={this.onChangeOrientation}
              //   onChangeState={this.onChangeState}
              className={classNames(
                "flex items-center justify-center duration-200",
                cover === "FRONT" && "-translate-x-1/4",
                cover === "NONE" && "-translate-x-0",
                cover === "BACK" && "translate-x-1/4"
              )}
              ref={bookRef}>
              {/* <PageCover>BOOK TITLE</PageCover> */}
              <Page image={ebook_link} />
              {slides?.map((item) => (
                <Page image={item} key={item} />
              ))}
              {/* {include_artwork?.length % 2 === 1 && (
                   <Page image={coverImage} />
                 )} */}
              <Page image={coverImage} />

              {/* <PageCover>THE END</PageCover> */}
            </HTMLFlipBook>
          </div>

          {cover === "NONE" && (
            <div className="absolute inset-0 w-full h-full"> </div>
          )}
        </div>
      </div>
      {/* Next, Prev Button */}
      {cover === "NONE" && (
        <>
          <button
            onClick={prevButtonClick}
            type="button"
            className="h-14 absolute top-1/2 -translate-y-1/2 -left-16">
            <img
              src={prevIcon}
              alt="prev-icon"
              className="h-full object-cover"
            />
          </button>
          <button
            onClick={nextButtonClick}
            type="button"
            className="h-14 absolute top-1/2 -translate-y-1/2 -right-16">
            <img
              src={nextIcon}
              alt="prev-icon"
              className="h-full object-cover"
            />
          </button>
        </>
      )}
    </div>
  );
};

export default EbookItem;
