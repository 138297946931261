import { https } from "./urlConfig";

export const APIService = {
  get_user_chat_history: (access_token, body) => {
    let uri = "writing_center/user_chat_history";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  export_gpt: (access_token, body) => {
    let uri = "campus/export_gpt";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  login_talksam: (body) => {
    let uri = "auth/login_talksam";
    return https.post(uri, body);
  },
  delete_user_by_email: (access_token, body) => {
    let uri = "auth/delete";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_all_course: (access_token) => {
    let uri = "courses/getAllCourse";
    return https.post(uri, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  delete_request_approval: (access_token, data) => {
    let uri = "campus/deleteRequestApproval";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  export_excel_class_lms: (access_token, data) => {
    let uri = "users/export_excel";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  save_writing: ({ access_token, body }) => {
    let uri = "courses/writingSave";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_class_approval: ({ access_token, body }) => {
    let uri = "admin/getClassApprovalByUserName";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_inquiry_management: ({ access_token, body }) => {
    let uri = "inquiry/getInquiryByEmail";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_user_point: ({ access_token, body }) => {
    let uri = "point/getPointByFullname";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_user_point_for_campus: ({ access_token, body }) => {
    let uri = "campus/point";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_lms_ebook: ({ access_token, body }) => {
    let uri = "admin/lms/ebook";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_lms_ebook_campus: ({ access_token, body }) => {
    let uri = "campus/lms/ebook";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_lms_class_management: ({ access_token, body }) => {
    let uri = "admin/lms/class_management";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  search_lms_class_management_campus: ({ access_token, body }) => {
    let uri = "campus/lms/class_management";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  check_exist_email: (data) => {
    let uri = "auth/check_exist_email ";
    return https.post(uri, data);
  },
  login: (data) => {
    let uri = "auth/login";
    return https.post(uri, data);
  },
  auth_talksamid: (data) => {
    let uri = "auth/auth_talksamid";
    return https.post(uri, data);
  },
  get_information_user: (access_token) => {
    let uri = "users/me";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_ranking: (access_token) => {
    let uri = "ranking";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  exchange_item: (data, access_token) => {
    let uri = "pets/exchange_item";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_coures_lession: (access_token) => {
    let uri = "courses/me";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  delete_lesson: (data, access_token) => {
    let uri = "admin/cms/delete_lesson";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_coures_history: (access_token) => {
    let uri = "courses/history_course";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_inquiry_history: (access_token) => {
    let uri = "inquiry/get_all_inquiry";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_inquiry_history_user: (access_token) => {
    let uri = "inquiry/history";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_inquiry_point: (access_token) => {
    let uri = "point/avg_point";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_point_history: (access_token) => {
    let uri = "point/point_history";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_only_course: (access_token) => {
    let uri = "admin/cms/get_only_course";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },

  get_image_stablediffusion: (data, access_token) => {
    let uri = "gpt/stable_diffustion";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  post_image_stablediffusion: (data, access_token) => {
    let uri = "courses/upload_ebook";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  upload_artwork: (data, access_token) => {
    let uri = "courses/upload_artwork";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  get_inforList_Pet: (access_token) => {
    let uri = "point/get_all_pets_point";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  suggestion: (data, access_token) => {
    let uri = "gpt/ai_suggestion";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  save_self_editting: (data, access_token) => {
    let uri = "courses/save_content_essay";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  save_stage_writing_center: (data, access_token) => {
    let uri = "writing_center/save_stage";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  update_status_writing_center: (data, access_token) => {
    let uri = "writing_center/update_status";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  add_chat_history: (data, access_token) => {
    let uri = "writing_center/add_chat_history";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  // get_chat_history: (data, access_token) => {
  //   let uri = "writing_center/chat_history";
  //   return https.post(uri, data, {
  //     headers: {
  //       Authorization: `Bearer ` + access_token,
  //     },
  //   });
  // },
  get_pet_chat_history: (data, access_token) => {
    let uri = "writing_center/pet_chat_history";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  clear_memory_chat_mascot: (data, access_token) => {
    let uri = "pets/clear_history_chat_mascot";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  send_verify_email: (data) => {
    let uri = "auth/send_code";
    return https.post(uri, data);
  },
  send_code: (data) => {
    let uri = "auth/verify_code";
    return https.post(uri, data);
  },
  change_point_mascot: (data, access_token) => {
    let uri = "point/change_mascot";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_list_ebook: (access_token) => {
    let uri = "ebook/me";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_ebook: (access_token) => {
    let uri = "ebook/public";
    return https.post(uri, {});
  },
  get_all_artwork: (access_token) => {
    let uri = "ebook/get_all_artwork";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_total_point: (access_token) => {
    let uri = "point/get_total_point";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  register: (data) => {
    let uri = "auth/join";
    return https.post(uri, data);
  },
  save_user_campus_admin: (data, access_token) => {
    let uri = "auth/register";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  get_all_campus_user: (access_token) => {
    let uri = "campus/users";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },

  save_data_campus: (data, access_token) => {
    let uri = "campus/save";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  update_role_manager: (data, access_token) => {
    let uri = "admin/setting/update_role";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  delete_role_manager: (data, access_token) => {
    let uri = "admin/role/delete";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  get_all_point_manager_campus: (access_token) => {
    let uri = "campus/point";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  // get_all_point_manager_admin: (access_token) => {
  //   let uri = "admin/users/point";
  //   return https.post(uri, {
  //     headers: {
  //       Authorization: `Bearer ` + access_token,
  //     },
  //   });
  // },
  get_all_class_manager_campus: (access_token) => {
    let uri = "campus/lms/class_management";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_user_campus: (access_token) => {
    let uri = "campus/getUserByCampus";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_user_lms: (access_token, body) => {
    let uri = "courses/getFilterCourse";
    return https.post(uri, body, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_all_class_manager_admin: (access_token) => {
    let uri = "admin/lms/class_management";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_user_admin_role: (access_token) => {
    let uri = "admin/getAllUser";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_course_by_user_admin_role: (access_token, data) => {
    let uri = "courses/getCourseByUser";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_all_ebook_lms_campus: (access_token) => {
    let uri = "campus/lms/ebook";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_ebook_lms_admin: (access_token) => {
    let uri = "admin/lms/ebook";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_campus_approval: (access_token) => {
    let uri = "campus/approval";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },

  export_data_campus: (data, access_token) => {
    let uri = "campus/export";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  export_point: (data, access_token) => {
    let uri = "campus/export_point";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  update_campus: (data, access_token, id) => {
    let uri = `campus/update/${id}`;
    return https.patch(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  delete_campus: (data, access_token) => {
    let uri = `admin/setting/delete_campus`;
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  get_all_list_account: (access_token) => {
    let uri = "admin/setting/list_account";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_list_campus_enrolment: (access_token) => {
    let uri = "admin/setting/campus";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_list_setiing_category: (access_token) => {
    let uri = "admin/setting/category";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_list_users_point: (access_token) => {
    let uri = "admin/users/point";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_list_users_admin: (access_token) => {
    let uri = "admin/users";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  campus_product_list: (access_token) => {
    let uri = "campus/product_list";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  campus_user_list: (access_token) => {
    let uri = "campus/user_list";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  campus_approval_request_user_list: (access_token) => {
    let uri = "campus/user_list_approval";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  send_approval_admin: (data, access_token) => {
    let uri = "campus/send_approval";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_all_admin_approval: (access_token) => {
    let uri = "admin/approval";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  accept_approval_admin: (data, access_token) => {
    let uri = "admin/accept_approval";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_owned_pet: (access_token) => {
    let uri = "pets/my_pets";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  check_duplicate: (data, access_token) => {
    let uri = "courses/duplicate_check";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  check_campus_duplicate: (data, access_token) => {
    let uri = "campus/duplicate_check";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_all_course_cms: (access_token) => {
    let uri = "admin/cms/all_course";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_product: (access_token) => {
    let uri = "product/get_all_product";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  post_email_duplicate: (data, access_token) => {
    let uri = "campus/email_duplicate";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },

  get_all_course_product: (access_token) => {
    let uri = "ebook/get_course_product";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_book: (access_token) => {
    let uri = "ebook/me";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  get_all_artwork_with_course_id: (data, access_token) => {
    let uri = "ebook/get_artwork";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  create_book: (data, access_token) => {
    let uri = "ebook/create";
    return https.post(uri, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  create_inquiry: (data, access_token) => {
    let uri = "inquiry/send";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  answer_inquiry: (data, access_token) => {
    let uri = "inquiry/answer";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_all_data_cms: (access_token) => {
    let uri = "admin/cms/all_course";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  create_course: (data, access_token) => {
    let uri = "courses/create";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  create_lesson: (data, access_token) => {
    let uri = "courses/create_lesson";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  create_product: (data, access_token) => {
    let uri = "product/create";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  get_all_country: () => {
    let uri = "country/list_all_country";
    return https.post(uri);
  },
  get_all_campus: (access_token) => {
    let uri = "campus/get_all_list_campus";
    return https.post(
      uri,
      {},
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },

  get_lession: (access_token, lesson_id) => {
    let uri = "courses/get_lesson";
    // console.log(lesson_id);
    return https.post(
      uri,
      {
        lesson_id: lesson_id,
      },
      {
        headers: {
          Authorization: `Bearer ` + access_token,
        },
      }
    );
  },
  register_campus: (data, access_token) => {
    let uri = "campus/register_campus";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  check_category_duplicate: (data, access_token) => {
    let uri = "admin/setting/duplicate_category";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  create_category: (data, access_token) => {
    let uri = "admin/setting/create_category";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  chat_brainstorm_audio_mp3: (data, access_token) => {
    let uri = "gpt/chat_bot_audio";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  speech_to_text: (data, access_token) => {
    let uri = "gpt/speech_to_text";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  chat_mascot_audio_mp3: (data, access_token) => {
    let uri = "pets/chat_mascot_audio";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  delete_product: (data, access_token) => {
    let uri = "product/delete";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
  public_ebook: (data, access_token) => {
    let uri = "ebook/public_ebook";
    return https.post(uri, data, {
      headers: {
        Authorization: `Bearer ` + access_token,
      },
    });
  },
};
