import { Checkbox, Divider, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { APIService } from "../../../../services/apiService";
const CheckboxGroup = Checkbox.Group;

const CourseSelectModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  setSelectedCourses,
  selectedCourses,
}) => {
  const [cookies] = useCookies(["access_token"]);
  const [options, setOptions] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = cookies["access_token"];

  useEffect(() => {
    const fetchAllCourses = () => {
      setIsLoading(true);
      APIService.get_all_course(accessToken)
        .then((data) => {
          const listCourses = data?.data?.map((item) => ({
            label: item?.course_name,
            value: item?.id,
          }));
          setOptions(listCourses);
        })
        .catch((e) => {
          console.error("e", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchAllCourses();
  }, [accessToken]);

  const checkAll = options.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < options.length;
  const onChange = (list) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    console.log("options", options);
    setCheckedList(e.target.checked ? options.map((item) => item.value) : []);
  };
  return (
    <Modal
      width={1100}
      title="Course selection"
      open={isModalOpen}
      onOk={() => {
        setSelectedCourses(checkedList);
        handleOk();
      }}
      onCancel={() => {
        handleCancel();
        setCheckedList(selectedCourses);
      }}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}>
            Select all
          </Checkbox>
          <Divider />
          <CheckboxGroup
            className="grid w-full grid-cols-3 gap-x-6 gap-y-4"
            options={options}
            value={checkedList}
            onChange={onChange}
          />
        </>
      )}
    </Modal>
  );
};

export default CourseSelectModal;
