import { Tabs } from "antd";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ClassManager from "./components/ClassManager";
import EbookManager from "./components/EbookManager";
import style from "./style.module.css";

import iconLMS from "../../../assets/logo/IconLMS.svg";
import ArtworkManager from "./components/ArtworkManager";

const { TabPane } = Tabs;

const AdminLMS = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [value, setValue] = useState("course");

  const handleSetValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className={style.container_Dashboard}>
      <div className={style.content_Dashboard}>
        <p className={style.dashboard_title}>
          {" "}
          <img src={iconLMS} alt="" />
          LMS
        </p>
        <Tabs defaultActiveKey="1" onChange={handleSetValue}>
          <TabPane tab="Class Management " key="1">
            <ClassManager />
          </TabPane>
          <TabPane tab="Ebook Management" key="2">
            <EbookManager />
          </TabPane>
          <TabPane tab="Artwork Management" key="3">
            <ArtworkManager />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminLMS;
