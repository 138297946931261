import React from "react";
import { Outlet } from "react-router";
import Mainheader from "./header";
import NavbarMobile from "./header/NavbarMobile";
import bg from "../../assets/background/background_login.png";

const Mainlayout = () => {
  return (
    <div className="relative w-full">
      <img
        src={bg}
        alt="BG"
        className="w-screen h-full object-cover absolute top-0 left-0"
      />
      <div className="relative min-h-screen overflow-y-auto">
        <Mainheader />
        <NavbarMobile />
        <Outlet />
      </div>
    </div>
  );
};

export default Mainlayout;
