import { configureStore, createSlice } from '@reduxjs/toolkit';
import uuid from 'react-uuid';
import { useDispatch } from 'react-redux';
const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState: { connected: false, message: null, client_id:null },
  reducers: {
    connected: (state) => {
      state.connected = true;
    },
    setClientId: (state,action) => {
      state.client_id = action.payload.client_id;
    },
    disconnected: (state) => {
      state.connected = false;
    },
    messageReceived: (state, action) => {  
      console.log(action.payload)
      state.message = action.payload;
      // console.log(action.payload)
    },
  },
});

export const { connected, disconnected, messageReceived } = webSocketSlice.actions;
export default webSocketSlice.reducer;
export const sendMessage = (message) => ({
    type: 'webSocket/sendMessage',
    payload: message,
});
export const connect = () => ({ type: 'webSocket/connect' });
export const disconnect = () => ({ type: 'webSocket/disconnect' });


export const webSocketMiddleware = () => {
  let socket = null;
 
  return (next) => (action) => {
    switch (action.type) {
      case 'webSocket/connect':
        if (socket !== null) {
          socket.close();
        }
        // Replace with your server's WebSocket endpoint
        const client_id = uuid();
        socket = new WebSocket(process.env.REACT_APP_WS+client_id+"/ws");
        socket.onopen = () => next(connected());
        socket.onmessage = (event) => next(messageReceived(event.data));
        socket.onclose = () => next(disconnected());
        break;

      case 'webSocket/disconnect':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;

      case 'webSocket/sendMessage':
        if (socket !== null) {
          socket.send(JSON.stringify(action.payload));
        }
        break;

      default:
        return next(action);
    }
  };
}