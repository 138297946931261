import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Space, Table } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Highlighter from "react-highlight-words";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setUserList } from "../../redux/campusSlice";
import { APIService } from "../../services/apiService";
import style from "./style.module.css";

const PRODUCT_OPTIONS = [
  { label: "All products", value: "All products" },
  { label: "E4", value: "E4" },
  { label: "E5", value: "E5" },
  { label: "E6", value: "E6" },
  { label: "E7", value: "E7" },
  { label: "E8", value: "E8" },
  { label: "E9", value: "E9" },
  { label: "M11", value: "M11" },
  { label: "M12", value: "M12" },
  { label: "M13", value: "M13" },
  { label: "M14", value: "M14" },
  { label: "M15", value: "M15" },
];

function PopupListUser({ setOpenPopup, typePopup }) {
  const dispatch = useDispatch();
  //   const user = useSelector((state) => state.userSlice.user);
  //   const point = useSelector((state) => state.userSlice.point);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectionRow, setSelectionRow] = useState([]);
  const searchInput = useRef(null);

  const isTablet = useMediaQuery({ minWidth: 768 });
  const isPaptop1280 = useMediaQuery({ minWidth: 1280 });
  const isPaptop1440 = useMediaQuery({ minWidth: 1440 });
  const isPc1920 = useMediaQuery({ minWidth: 1920 });

  const onSearch = (value, _e, info) => {
    if (value === "") {
      setFilteredData(data);
      return;
    }
    const newData = data.filter((item) => {
      return (
        item?.full_name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.products?.some((e) =>
          value
            ?.trim()
            ?.split(" ")
            ?.map((i) => i.toLowerCase())
            .includes(e.toLowerCase())
        )
      );
    });
    setFilteredData(newData);
  };

  const onChangeSelectProduct = (selectedProduct) => {
    if (!selectedProduct || selectedProduct.length === 0) {
      setFilteredData(data);
      return;
    }

    if (selectedProduct.includes("All products")) {
      const newData = data.filter((item) => {
        return item?.products.length > 0;
      });
      setFilteredData(newData);
      return;
    }
    const newData = data.filter((item) => {
      return item?.products?.some((e) => selectedProduct.includes(e));
    });
    setFilteredData(newData);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    APIService.campus_approval_request_user_list(cookies["access_token"])
      .then((response) => {
        if (response && response.status_code === 200 && response.data) {
          let datares = [];
          // // console.log(response)
          response.data.forEach((element, index) => {
            datares.push({
              key: index,
              user_id: element.user_id,
              email: element.email,
              full_name: element.fullname,
              nick_name: element.nick_name,
              recently_learning: element.products.join(", "),
              products: element.products,
            });
          });
          setData(datares);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined></SearchOutlined>}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      // ...getColumnSearchProps("email"),
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
      width: "20%",
      // ...getColumnSearchProps("full_name"),
    },
    {
      title: "Nick Name",
      dataIndex: "nick_name",
      key: "nick_name",
      width: "20%",
      // ...getColumnSearchProps("nick_name"),
    },
    {
      title: "Recent Learning Courses",
      dataIndex: "recently_learning",
      key: "recently_learning",
      // ...getColumnSearchProps("recently_learning"),
      //   sorter: (a, b) => a.price - b.price,
      //   sortDirections: ["descend", "ascend"],
    },
    Table.SELECTION_COLUMN,
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      // // ...getColumnSearchProps('price'),
      // sorter: (a, b) => a.price - b.price,
      // sortDirections: ['descend', 'ascend'],
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //   // console.log( selectedRows);
      setSelectionRow(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const confirmSelectProduct = () => {
    dispatch(
      setUserList({
        user_list: selectionRow,
      })
    );
    setOpenPopup(false);
  };

  let paginationConfig;
  switch (true) {
    case isPc1920:
      paginationConfig = { pageSize: 8 };
      break;
    case isPaptop1440:
      paginationConfig = { pageSize: 4 };
      break;
    case isPaptop1280:
      paginationConfig = { pageSize: 3 };
      break;
    default:
      paginationConfig = { pageSize: 3 };
      break;
  }

  return (
    <>
      <div className={style.container_popup_product}>
        <div className={style.content_popup_detail}>
          <Col span={24}>
            <Row gutter={[10, 10]} justify={"center"}>
              <Col span={22}>
                <Row justify={"end"}>
                  <AiOutlineCloseCircle
                    className={style.btn_close_popup}
                    onClick={() => setOpenPopup(false)}
                  />
                </Row>
              </Col>
              <Col span={22}>
                <Row justify="start">
                  <p className={style.title_PopupUserList}>User List</p>
                </Row>
              </Col>
              <Col span={22} className="my-4">
                <Row justify="end">
                  <Select
                    className="w-[300px] mr-6"
                    mode="multiple"
                    placeholder="Select products"
                    options={PRODUCT_OPTIONS}
                    onChange={onChangeSelectProduct}
                  />
                  <Search
                    placeholder="Search name, email, products"
                    allowClear
                    enterButton="Search"
                    style={{ width: 304 }}
                    onSearch={onSearch}
                  />
                </Row>
              </Col>
              <Col span={22}>
                <Table
                  rowSelection={rowSelection}
                  className={style.tableProduct}
                  columns={columns}
                  dataSource={filteredData}
                  pagination={paginationConfig}
                />
              </Col>
              <Col span={22}>
                <Row justify="center">
                  <Button onClick={confirmSelectProduct}>Confirm</Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
}

export default PopupListUser;
