import { Button, Col, Row } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AiFillFileWord } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../../components/Notification/index";
import {
  setContentOriginal,
  setSelfEditing,
  setTitle,
  setWritingTime,
} from "../../redux/lessionSlice";
import { APIService } from "../../services/apiService";
import TranslatorModal from "../Iframe/TranslatorModal";
import WingLoading from "../Loading/WingLoading";
import styles from "./style.module.css";
const spaces = "\u00A0\u00A0\u00A0\u00A0\u00A0";

const InputField = (props) => {
  const { type, setSumited } = props;
  const dispatch = useDispatch();
  const [cookies] = useCookies(["access_token"]);
  const lesson = useSelector((state) => state.lessionSlice.lesson);
  const user = useSelector((state) => state.userSlice.user);
  const [inputValue, setInputValue] = useState("");
  const [inputselfValue, setInputSelfValue] = useState("");
  const [numberWord, setNumberWord] = useState(0);
  const [countdown, setCountdown] = useState("Time Writing");
  const [time, setTime] = useState(0);
  const [statusWriting, setStatusWriting] = useState("Start");
  const [disableWriting, setDisableWriting] = useState(false);
  const [isWriting, setIsWriting] = useState(false);
  const [inputTitle, setInputTitle] = useState("");
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    if (!lesson?.writing) return;
    setInputValue(lesson?.writing);
    setNumberWord(lesson?.writing.trim().split(/\s+/).length);
    dispatch(setContentOriginal(lesson?.writing));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson?.writing]);

  useEffect(() => {
    if (!lesson?.writing_title) return;
    setInputTitle(lesson?.writing_title);
  }, [lesson?.writing_title]);

  // let distance = lesson?.time_writing;

  useEffect(() => {
    const textarea = document.getElementById("notebook-textarea");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [inputValue]);

  const countNewLines = (str) => {
    const matches = str.match(/\n/g);
    return matches ? matches.length : 0;
  };

  useEffect(() => {
    // console.log(lesson)
    if (lesson.history?.essay_original.length > 0) {
      setInputValue(lesson.history?.essay_original);
      setDisableWriting(true);
      setTime(lesson.time_writing);
      setIsWriting(true);
    }
  }, [lesson]);

  const onChangeInputTitle = (e) => {
    setInputTitle(e.target.value);
    dispatch(setTitle(e.target.value));
  };

  const handleInputChange = (e) => {
    if (!isWriting) {
      starting_clock();
      setIsWriting(true);
    }
    // if (countNewLines(e.target.value) > 3) {
    //   Notification("warning", "Max break line > 3");
    // }
    // if (e.target.value[e.target.value.length - 1] === "." && countSentencesWithLessThanEightWords(e.target.value)>0) {
    //   countSentencesWithLessThanEightWords(e.target.value)
    //   return
    // }

    if (type === "self_editing") {
      setInputSelfValue(e.target.value);
      dispatch(setSelfEditing(spaces + e.target.value));
    } else {
      setInputValue(e.target.value);
      setNumberWord(e.target.value.trim().split(/\s+/).length);
      dispatch(setContentOriginal(spaces + e.target.value));
    }
  };

  // const onStartWriting = (e) => {
  //   if (statusWriting === "Done writing" || statusWriting === "Writing") {
  //     setStatusWriting("Time out");
  //     setDisableWriting(true);
  //     clearInterval(timer);
  //     setCountdown("Writing: Overtime");
  //   } else {
  //     setStatusWriting("Writing");
  //     // // console.log(lesson.time_writing)
  //     setDisableWriting(false);
  //     // CountDown();
  //   }
  // };
  // const onStopWriting = ()=>{
  //   const hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
  //   const minutes = Math.floor((distance % (60 * 60)) / (60));
  //   const seconds = Math.floor((distance % (60)));
  //   setCountdown(`Writing Time: ${hours}h ${minutes}m ${seconds}s`);
  // }

  const starting_clock = () => {
    const timerID = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    // Cleanup function in useEffect is similar to componentWillUnmount
    // return () => {
    //   clearInterval(timerID);
    // };
  };

  const formatTime = (time) => {
    dispatch(
      setWritingTime({
        time_writing: time,
      })
    );
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = time - hours * 3600 - minutes * 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleSaveWriting = async () => {
    setIsLoadingSave(true);
    const body = {
      userId: user?.id,
      lessonId: lesson.lessonId,
      status: "Writing",
      writing: inputValue,
      writingTitle: inputTitle,
    };
    try {
      await APIService.save_writing({
        access_token: cookies["access_token"],
        body,
      });
      Notification("success", "Save successfully");
    } catch (error) {
      Notification("error", "Error occurred");
    }
    setIsLoadingSave(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.textareaWrapper}>
        {lesson.is_loading ? <WingLoading /> : null}
        <br /> <br />
        <div className="w-full flex flex-col items-center justify-center gap-2 bg-white/30 py-2 px-2 rounded-lg mb-1 ">
          <span className="font-medium">Title:</span>
          <input
            placeholder="Input title"
            value={inputTitle}
            onChange={onChangeInputTitle}
            type="text"
            className="w-full bg-transparent focus:outline-none focus:border-none font-medium text-center"
          />
        </div>
        <textarea
          style={{
            textIndent: "50px",
          }}
          disabled={disableWriting}
          id="notebook-textarea"
          placeholder="Input essay ..."
          value={type !== "self_editing" ? inputValue : inputselfValue}
          onChange={handleInputChange}
        />
      </div>
      <br /> <br />
      {type !== "self_editing" ? (
        <>
          <div className={classNames(styles.content_icon, "mt-12")}>
            <Row style={{ width: "100%" }}>
              <Col span={20}>
                <br />
                <div className="flex">
                  <div className="flex-inline mt-1">
                    <AiFillFileWord />
                  </div>
                  <div className="flex-inline">
                    Minimum words: {lesson.minimum_words}
                  </div>
                </div>

                {/* <div className="flex"><div className="flex-inline mt-1"><AiFillFileWord /></div><div className="flex-inline">Maximum words: {lesson.maximum_words}</div></div> */}

                <div className="flex">
                  <div className="flex-inline mt-1">
                    <AiFillFileWord />
                  </div>
                  <div className="flex-inline">
                    Current word count: {numberWord}
                  </div>
                </div>
                <br />
              </Col>
              <Col span={4}>
                <div className="Clock">
                  {time === 0 ? "Time" : formatTime(time)}
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : null}
      <div className="relative w-full flex justify-center items-center mb-4">
        <Button
          loading={isLoadingSave}
          onClick={handleSaveWriting}
          size="large"
          className="bg-[#5BA3B9] hover:!bg-[#4c91a7]"
          type="primary"
        >
          Save
        </Button>
        <TranslatorModal />
      </div>
    </div>
  );
};

export default InputField;
